import {
  LABEL_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
  LABEL_SELECT_PRODUCTS,
  SETUP_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
  SETUP_SELECT_PRODUCTS,
  LGS_ID,
  PANORAMA,
  PRISMA_ACCESS_PANORAMA,
  LOGGING_SERVICE_DISPLAY_NAME,
} from '../../../constants/AppConstants'
import { CheckLight, CogsLight, KeyIcon, UserShieldLight } from '../../../images/svg-icons'

const backBtnProps = {
  v2: false,
  label: 'Back',
}

const nextBtnProps = {
  v2: false,
  label: 'Next'
}

const progressStep = {
  useSimpleProgress: true,
  maxWidth: 1280,
  showOTP: true,
  showPSK: true,
  title: {
    // use default values
  },
}

export const FLS = {
  theme: {
    primaryColor: '#006fcc',
    secondaryColor: ''
  },
  steps: [
    {
      title: LABEL_SELECT_PRODUCTS,
      icon: KeyIcon,
      style: {
        maxWidth: 500,
        minWidth: 300,
      },
      key: 0,
      allowedApps: [LGS_ID, PANORAMA, PRISMA_ACCESS_PANORAMA],
      comp_enum: 'SETUP_ACTIVATE_PRODUCT',
      setup_enum: SETUP_SELECT_PRODUCTS,
      description: 'The following products are available for activation. Please select which products you would like to activate now:',
      backBtnProps: { ...backBtnProps, label: 'Quit', href: '/apps' },
      nextBtnProps: { ...nextBtnProps, label: 'Start Activation' },
      help: '',
      current: '0',
      prev: null,
      next: '1',
    },
    {
      title: LABEL_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
      icon: UserShieldLight,
      responsiveConfig: { xl: 14, xxl: 10, lg: 16, md: 18, sm: 23 },
      key: 1,
      comp_enum: 'SETUP_ACCOUNT_SELECTION',
      setup_enum: SETUP_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
      description: 'We have detected you have multiple support accounts. Please assign your new products to a support account, If you would like to assign these new products to different support accounts, you may do so.',
      backBtnProps,
      nextBtnProps,
      help: '',
      current: '1',
      prev: '0',
      next: '2',
      fields: false
    },
    {
      title: 'Select a Region and Panorama',
      icon: CogsLight,
      style: {
        width: 500,
      },
      key: 2,
      comp_enum: 'FLS_CONFIG',
      setup_enum: 'FLS_CONFIG',
      description: 'Select the region in which you would like to host your data and connect a Panorama appliance to your instance. If you don\'t want to connect a Panorama appliance, select None.',
      backBtnProps,
      nextBtnProps,
      help: '',
      defaultSetupValues: {
        value: ''
      },
      current: '2',
      prev: '1',
      next: '3'
    },
    {
      title: `Store Firewall Data in ${LOGGING_SERVICE_DISPLAY_NAME}`,
      icon: CogsLight,
      style: {
        width: 800,
      },
      key: 3,
      comp_enum: 'FLS_FIREWALL_SELECTION',
      setup_enum: 'FLS_FIREWALL_SELECTION',
      description: 'Select whether you want to store firewall data. Turn storage off if you plan to only stream data to other applications. Leave storage on if you want to retain log records.',
      backBtnProps,
      nextBtnProps,
      help: '',
      current: '3',
      prev: '2',
      next: '4',
    },
    {
      title: 'Finalize Selections',
      icon: CheckLight,
      style: {
        width: 450,
      },
      show_data_lake: false,
      show_cmp: ['CMP_SUPPORT_ACCOUNT', 'CMP_REGION', 'CMP_EXPIRATION', 'FLS_DEVICE_COUNT', 'CMP_TERM_CONDITION'],
      comp_enum: 'CONFIRMATION',
      key: 4,
      description: 'Please finalize your selections below to complete your setup.',
      backBtnProps,
      nextBtnProps: { ...nextBtnProps, label: 'Confirm Selections' },
      prev: '3',
      current: '4',
    }
  ],
  progressStep,
}
