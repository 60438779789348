/**
 * Created by vsuthar on 4/20/20
 * Project: App Portal ©Palo Alto Networks
 */
import React, { useMemo, useCallback, useLayoutEffect, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Select } from '@pan/cloud-base'
import { FT } from '@pan/cloud-appframework'
import { LGS_ID, SETUP_CREATE_NEW, PRISMA_ACCESS, PA_PANORAMA, CDL_INSTANCE_FIELD, REGION_FIELD, ACTIVATE_NEW, STRATA_INSIGHTS, APP_REGION_FIELD, ZINGBOX } from '../../constants/AppConstants'
import { renderInstances, useMemoFieldDecorator, isNonemptyArray, isClcsOrTelemetryInstance, filterRegions, indexArray, isClcsInstance, isMultiRegionAllowed, isIoTMultiCdlRegionAllowed } from '../../utils/activateUtils'
import ListItem from '../../components/ListItem'
import DescriptionField from '../../components/Activate/fields/DescriptionField'
import './dataLake.scss'
import CrossRegionWarning from './common/CrossRegionWarning'

const FT_SETUP_WITH_DESC_FIELD = FT('SETUP_WITH_DESC_FIELD')

const renderOptions = tenants => tenants.map(({ key, group, display, extra, ...props }) => (
  <Select.Option {...props} key={key}>
    <ListItem toolTipProps={{ placement: 'left' }}>{display} {extra}</ListItem>
  </Select.Option>
))
const renderOptionGroups = (optionsGroups) => {
  const last = optionsGroups.pop()
  if (!last[0]) { // new option first
    optionsGroups.unshift(last)
  }
  else {
    optionsGroups.push(last)
  }
  return optionsGroups.map(([region, tenants]) => {
    return !region ? renderOptions(tenants) : <Select.OptGroup key={region} label={region}>
      {renderOptions(tenants)}
    </Select.OptGroup>
  })
}

const DataLake = ({
  getAnyApp,
  contextApp,
  primaryAppId = contextApp?.app_id,
  toBeActivatedApp,
  onCDLChange,
  stateRef,
  cdlInstance,
  dataLakeLabel = 'Cortex Data Lake Selection',
  dataLakeRegionLabel = 'Region Selection',
  useDataLakeRegion,
  dropDownProps,
  skipSingleAssoIdSet,
  showAllCdlRegions,
  hasMSP,
  supportAccounts,
  setupSelectedAccount,
  form: {
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
    validateFields,
  },
}) => {
  const { app_id: contextAppId, associations } = contextApp || {}  // may not same as context
  const isTelemetryAllowed = associations?.some(each => each.app_id === LGS_ID && each?.allow_free_telemetry)
  const { accountIsFederal } = stateRef.current
  const isMultiRegion = isMultiRegionAllowed(supportAccounts, setupSelectedAccount)
  const hasMultiRegion = isIoTMultiCdlRegionAllowed(supportAccounts, setupSelectedAccount)
  const regions = useMemo(() => {
    const cdlRegions = getAnyApp(LGS_ID)?.regions
    if (showAllCdlRegions) {
      return cdlRegions
    }
    const appRegions = primaryAppId && getAnyApp(primaryAppId)?.regions?.filter(r => {
      // logic to support same cdl region to map two different IoT regions, which is required during IoT prod testing
      if (primaryAppId === ZINGBOX && hasMultiRegion) {
        return r.name !== 'us_ca'
      }
      if (hasMSP) {
        // MSP only support americas so filter our rest
        return r.logging_service_region === 'americas' || r.region === 'americas'
      }
      if (isMultiRegion && (isNonemptyArray(r.platform_regions) || r?.extra?.easy_onboarding_only)) {
        return !(r?.extra?.easy_onboarding_only) && r.platform_regions.filter(pr => cdlRegions.has(pr))
      }
      return cdlRegions.has(r.logging_service_region || r.region)
    }
    ) // ensure cdl region is available
    if (useDataLakeRegion && isNonemptyArray(appRegions)) { // use data lake display name
      const getCdlRegions = (appRegion) => {
        if (isMultiRegion && appRegion.platform_regions) {
          const list = appRegion.platform_regions.reduce((res, region) => {
            if (cdlRegions.has(region)) {
              res.push(cdlRegions.get(region))
            }
            return res
          }, [])
          return list
        }
        return [cdlRegions.get(appRegion.logging_service_region || appRegion.region)]
      }
      const constructCdlRegionList = (appRegions) => {
        const list = appRegions.reduce((result, appRegion) => {
          const cdlRegions = getCdlRegions(appRegion)  // must be there
          cdlRegions.forEach(cdlRegion => {
            const { name, display, value } = cdlRegion  // must be there
            result.push({
              ...appRegion,
              name, display, value,
            })
          })
          return result
        }, [])
        return list
      }

      const addons = toBeActivatedApp.reduce((acc, app) => (isNonemptyArray(app.addons) ? acc.concat(app.addons) : acc), [])
      const addonRegions = indexArray(addons.reduce((acc, addon) => {
        // if addon is not in manifest or global region, addonRegion will be []
        const addonRegionList = getAnyApp(addon.app_id)?.regions?.filter(r => {
          // logic to support same cdl region to map two different IoT regions, which is required during IoT prod testing
          if (addon.app_id === ZINGBOX && hasMultiRegion) {
            return r.name !== 'us_ca'
          }

          if (isMultiRegion && (isNonemptyArray(r.platform_regions) || r?.extra?.easy_onboarding_only)) {
            return !(r?.extra?.easy_onboarding_only) && r.platform_regions.filter(pr => cdlRegions.has(pr))
          }
          return cdlRegions.has(r.logging_service_region || r.region)
        }) || []
        return acc.concat(addonRegionList)
      }, []).filter(Boolean), 'logging_service_region') // keyBy CDL region field
      if (isNonemptyArray(addonRegions)) {
        // has addon that defined regions need to be respect
        const filteredAppRegions = appRegions.filter((r) => {
          if (isMultiRegion && (isNonemptyArray(r.platform_regions) || r?.extra?.easy_onboarding_only)) {
            return !(r?.extra?.easy_onboarding_only) && r.platform_regions.filter(pr => {
              return addonRegions?.platform_regions?.some(each => each === pr) || addonRegions.has(pr) //addonRegions indexed on logging_service_region
            })
          }
          return addonRegions.some(addonRegion => addonRegion.platform_regions?.some(each => each === r.logging_service_region)) || addonRegions.has(r.logging_service_region)
        })
        return constructCdlRegionList(filteredAppRegions)
      }

      return constructCdlRegionList(appRegions)
    }
    return appRegions || cdlRegions
  }, [getAnyApp, showAllCdlRegions, primaryAppId, useDataLakeRegion, hasMultiRegion, hasMSP, isMultiRegion, toBeActivatedApp])
  const avaiRegions = useMemo(() => filterRegions(regions, accountIsFederal), [accountIsFederal, regions])
  // const defaultRegion = avaiRegions[0].name // stop using defaultRegion
  const auth_code = useMemo(() => toBeActivatedApp.find(lic => lic.checked && lic.app_id === LGS_ID && lic.auth_code)?.auth_code, [toBeActivatedApp])
  // const clcsTenant = useMemo(() => getAnyApp(LGS_ID)?.tenants?.find(t => t.is_clcs && t.region === defaultRegion), [getAnyApp, defaultRegion])
  // const defaultValue = useMemo(() => {
  //   if (clcsTenant) {
  //     const { tenant_id, serial_number, region } = clcsTenant
  //     return `${tenant_id}|${serial_number || ''}|${region}|${auth_code}`
  //   }
  //   return auth_code && `${SETUP_CREATE_NEW}|||${auth_code}`
  // }, [auth_code, clcsTenant])

  const renderedInstances = useMemo(() => {
    const singleAssoIdSet = new Set()
    if (!skipSingleAssoIdSet && contextAppId) {
      singleAssoIdSet.add(contextAppId)
      if (contextAppId === PRISMA_ACCESS) {
        singleAssoIdSet.add(PA_PANORAMA)
      }
    }
    if (!skipSingleAssoIdSet && primaryAppId) {
      singleAssoIdSet.add(primaryAppId)
    }
    const instanceFilterFn = (t) => {
      const filterFn = isTelemetryAllowed ? isClcsInstance : isClcsOrTelemetryInstance
      if (auth_code) {
        return filterFn(t) || !t.no_access
      }
      return !t.no_access && !filterFn(t)
    }

    // only if support account has no paid/telemetry cdl tenants
    // const canActivateTelemetry = isTelemetryAllowed && _.isEmpty(getAnyApp(LGS_ID)?.tenants?.filter(tenant => !tenant.is_clcs))
    const canActivateTelemetry = isTelemetryAllowed && _.isEmpty(getAnyApp(LGS_ID)?.tenants)

    const instancesPropsFn = (auth_code || canActivateTelemetry) ? ({ filtered, regions, optionsGroups, optionsProps }) => {
      const regionNameSet = new Set(_.map(regions, 'name'))
      const clcsOrTelemetryInstances = filtered.filter(isClcsOrTelemetryInstance)
      const clcsOrTelemetryTenantIdSet = new Set(clcsOrTelemetryInstances.map(t => t.tenant_id))
      const upgradableRegionMapping = _.keyBy(clcsOrTelemetryInstances.filter(t => regionNameSet.has(t.region)), 'region')
      if (Object.keys(upgradableRegionMapping).length) {
        for (const region of regions) {
          const tele = upgradableRegionMapping[region.name]
          const key = (region.display || region.name).toUpperCase()
          const group = optionsGroups[key] || (optionsGroups[key] = [])
          const found = tele && group.find(op => op.key === tele.tenant_id)
          if (found) {
            _.remove(group, each => clcsOrTelemetryTenantIdSet.has(each.key)) // remove all clcs or telemetry it will added back below
            const { tenant_id, serial_number, tenant_display_name = serial_number, region, is_clcs } = tele
            const newProps = {
              key: tenant_id,
              ...found,
              value: `${tenant_id}|${serial_number}|${region}|${auth_code}`,
              display: is_clcs ? ACTIVATE_NEW : `Upgrade Telemetry Instance ${found?.display || tenant_display_name}`,
              disabled: false,
              extra: undefined,
            }
            Object.assign(found, newProps)
            group.unshift(newProps)
          }
          else {
            const value = `${SETUP_CREATE_NEW}||${region.name}|${auth_code}`
            const newProps = {
              key: value,
              value,
              display: ACTIVATE_NEW,
            }
            optionsProps.unshift(newProps)
            group.unshift(newProps)
          }
        }
        return
      }

      let auth_code_value = SETUP_CREATE_NEW
      if (auth_code) {
        auth_code_value += `|||${auth_code}`
      }
      else if (canActivateTelemetry) {
        auth_code_value += '|||telemetry'
      }

      const newProps = {
        key: auth_code_value,
        value: auth_code_value,
        display: ACTIVATE_NEW,
      }
      optionsProps.unshift(newProps)
      optionsGroups[''] = [newProps]
    } : undefined
    const instancesRenderedFn = ({ rendered, availables }) => {
      if (!auth_code) {
        rendered.defaultOption = rendered.onlyAvailableOptionValue
      }
      else {
        const defaultValue = `${SETUP_CREATE_NEW}|||${auth_code}`
        if (availables.some(o => o.value === defaultValue)) {
          rendered.defaultOption = defaultValue
        }
      }
    }
    const rendered = renderInstances({
      config: {
        app_id: LGS_ID,
        isDisabledFn: () => hasMSP,
        single_association: Array.from(singleAssoIdSet),
        instanceFilterFn,
        instancesPropsFn,
        instancesRenderedFn,
        isEasyActivation: true,
      },
      regions: avaiRegions,
      appInfoMap: { // no need the full version from activate utils
        getAnyApp,
        getInstances: appId => getAnyApp(appId)?.tenants || [],
      },
      renderOptions,
      renderOptionGroups,
    })
    return rendered
  }, [skipSingleAssoIdSet, contextAppId, primaryAppId, auth_code, avaiRegions, getAnyApp, isTelemetryAllowed, hasMSP])

  const {
    // tenant_id,
    region, // = defaultRegion, // stop using defaultRegion
    value: cdlInitValue = renderedInstances.defaultOption,
    fromInstanceAssociation = false,
  } = cdlInstance || {}

  const finalRenderedCdlOptions = useMemo(() => {
    if (!cdlInitValue || !fromInstanceAssociation) {
      return renderedInstances
    }
    if (cdlInstance.is_clcs && !auth_code) {
      // for now cdlInitValue is not reset but we block user to use next button
      return <Select.Option key={cdlInitValue} value={cdlInitValue}> </Select.Option>
    }
    const notAvailable = cdlInstance.notAvailable ? ' (not available)' : ''
    return <Select.Option key={cdlInitValue} value={cdlInitValue}>{cdlInstance.tenant_display_name}{notAvailable}</Select.Option>
  }, [cdlInitValue, fromInstanceAssociation, cdlInstance, auth_code, renderedInstances])

  const instanceDecorator = useMemoFieldDecorator(CDL_INSTANCE_FIELD, {
    initialValue: cdlInitValue,
    validateFirst: true,
    rules: useMemo(() => {
      return [
        { required: true, message: 'Data Lake instance is required' },
        {
          validator(rule, value, callback) {
            if (stateRef.current.cdlInstance?.notAvailable) {
              const displayName = stateRef.current.cdlInstance?.fromAppDisplayName || 'instance'
              callback(`The Data Lake used by the selected ${displayName} is not available`)
            }
            if (stateRef.current.cdlInstance?.is_clcs && !auth_code) {
              callback('Data lake subscription is required')
            }
            /**
             * MSP customer can only use new CDL. Can not use existing datalake.
             */
            if (hasMSP && !value.includes('new')) {
              callback('MSP customer can not use existing Data Lake.')
            }
            else {
              callback()
            }
          }
        }
      ]
    }, [stateRef, auth_code, hasMSP]),
  }, getFieldDecorator)
  const regionDecorator = useMemoFieldDecorator(REGION_FIELD, {
    initialValue: region,
    validateFirst: true,
    rules: useMemo(() => [
      { required: true, message: 'Data Lake region is required' },
      // {
      //   validator(rule, value, callback) {
      //     if (stateRef.current.cdlInstance?.notAvailable) {
      //       const displayRegionName = stateRef.current.cdlInstance?.region_display
      //       callback(`The Data Lake region selected ${displayRegionName} is not available`)
      //     }
      //     else {
      //       callback()
      //     }
      //   }
      // }
    ], []),
  }, getFieldDecorator) // TODO: fedramp support?

  useLayoutEffect(() => { // only once onmount for default value
    const { value: cdlInitValue, fromInstanceAssociation } = stateRef.current.cdlInstance || {}
    const cdlValue = getFieldValue(CDL_INSTANCE_FIELD)
    if (!cdlInitValue && !fromInstanceAssociation && renderedInstances.defaultOption && (!cdlValue || cdlValue === renderedInstances.defaultOption)) {
      const [tenant_id, serial_number, region, auth_code] = renderedInstances.defaultOption.split('|')
      const regionValue = region || getFieldValue(REGION_FIELD) // stop using defaultRegion
      onCDLChange({ region: regionValue, tenant_id, serial_number, auth_code, value: renderedInstances.defaultOption })
    }
  }, [stateRef, onCDLChange, renderedInstances.defaultOption, getFieldValue])

  // IOT-222 on change of IOT instance update CDL region
  useEffect(() => {
    setFieldsValue({
      [REGION_FIELD]: cdlInstance?.region // stop using defaultRegion
    })
    const cdlFieldValue = getFieldValue([CDL_INSTANCE_FIELD])
    if (cdlFieldValue !== cdlFieldValue?.value) {
      setFieldsValue({ [CDL_INSTANCE_FIELD]: cdlInstance?.value })
    }
    validateFields([REGION_FIELD, CDL_INSTANCE_FIELD], { force: true })
  }, [cdlInstance, setFieldsValue, getFieldValue, validateFields])

  const regionDisabled = useMemo(() => {
    if (cdlInstance?.tenant_id !== SETUP_CREATE_NEW) {
      return true
    }
    const cdlValue = getFieldValue(CDL_INSTANCE_FIELD)
    const [, , region] = cdlValue?.split('|', 3) || []
    return region ? true : false
  }, [cdlInstance, getFieldValue])

  const cdlSelectHandler = useCallback((value) => {
    if (!value || fromInstanceAssociation) {
      return
    }
    const [tenant_id, serial_number, region, auth_code] = value.split('|', 4)
    const regionValue = region || getFieldValue(REGION_FIELD) // stop using defaultRegion
    onCDLChange({ tenant_id, serial_number, value, region: regionValue, auth_code })
    setFieldsValue({
      [REGION_FIELD]: regionValue
    })
  }, [fromInstanceAssociation, getFieldValue, onCDLChange, setFieldsValue])
  const regionHandler = useCallback((region) => {
    onCDLChange({ ...stateRef.current.cdlInstance, region })
  }, [stateRef, onCDLChange])

  const descField = useMemo(() => {
    if (FT_SETUP_WITH_DESC_FIELD && contextAppId === PRISMA_ACCESS) {
      return <DescriptionField getFieldDecorator={getFieldDecorator} initialValues={{}} />
    }
    return null
  }, [contextAppId, getFieldDecorator])

  const warningMsg = useMemo(() => {
    const warning = avaiRegions.find(each => each.value === region)?.extra?.warning
    return warning ? <p className='region-warning'>{warning}</p> : null
  }, [avaiRegions, region])

  const contextAppRegion = getFieldValue(APP_REGION_FIELD)
  const cdlRegion = region || getFieldValue(REGION_FIELD)

  const isCdlCrossRegion = useMemo(() => {
    if (FT('AIOPS_PREMIUM') && contextAppId === STRATA_INSIGHTS && cdlRegion) { // for now only for aiops
      return cdlRegion !== contextAppRegion
    }
  }, [cdlRegion, contextAppId, contextAppRegion])

  return (
    <div className={'vbox setup-data-lake'}>
      <div className={'hbox middle space-between setup-item'}>
        <label>{dataLakeLabel}</label>
        {instanceDecorator(
          <Select
            {...dropDownProps}
            onSelect={cdlSelectHandler}
            disabled={fromInstanceAssociation}
          >
            {finalRenderedCdlOptions}
          </Select>
        )}
      </div>
      <div className={'hbox middle space-between setup-item'}>
        <label>{dataLakeRegionLabel}</label>
        {regionDecorator(
          <Select
            {...dropDownProps}
            placeholder='Select Region'
            disabled={regionDisabled}
            onSelect={regionHandler}
          >
            {avaiRegions.map(({ display, value }) => {
              return <Select.Option key={value} value={value}>{display}</Select.Option>
            })}
          </Select>
        )}
      </div>
      {warningMsg}
      {isCdlCrossRegion && <CrossRegionWarning
        getAnyApp={getAnyApp}
        contextApp={contextApp}
        primaryApp={{ primaryAppId: contextAppId, primaryAppRegion: contextAppRegion }}
        associationApp={{ appId: LGS_ID, region: cdlRegion }}
      />}
      {descField}
    </div>
  )
}

DataLake.propTypes = {
  supportAccounts: PropTypes.array,
  selectedAccount: PropTypes.number,
  getAnyApp: PropTypes.func,
  toBeActivatedApp: PropTypes.array,
  onCDLChange: PropTypes.func,
  stateRef: PropTypes.any,
  cdlInstance: PropTypes.object,
  region: PropTypes.string,
  primaryAppId: PropTypes.string,
  contextApp: PropTypes.object,
  form: PropTypes.object,
  dataLakeLabel: PropTypes.string,
  dataLakeRegionLabel: PropTypes.string,
  useDataLakeRegion: PropTypes.bool,
  dropDownProps: PropTypes.object,
  entitledAppsList: PropTypes.array,
  skipSingleAssoIdSet: PropTypes.bool,
  hasMSP: PropTypes.bool,
  showAllCdlRegions: PropTypes.bool,
  setupSelectedAccount: PropTypes.string,
}

DataLake.defaultProps = {
  getAnyApp: () => undefined,
  useDataLakeRegion: false,
  supportAccountIds: [],
  toBeActivatedApp: [],
  onCDLChange: () => { },
  cdlInstance: {},
  dropDownProps: {
    style: {
      width: 200,
    },
  },
  hasMSP: false,
  /* determines to opt-in/out for cdl single association check */
  skipSingleAssoIdSet: false,
  showAllCdlRegions: false,
}

export default DataLake
