/**
 * Created by vsuthar on 6/4/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import SetupMiscItem from './SetupMiscItem'

const SetUpSupportAccounts = ({ supportAccounts, setupSelectedAccount, wrapperClass = 'hbox', ...rest }) => {
  const cspAcc = useMemo(() => {
    return (supportAccounts && setupSelectedAccount >= 0 && supportAccounts.get(setupSelectedAccount)?.support_account_name) || null
  }, [setupSelectedAccount, supportAccounts])
  return (
    cspAcc && <SetupMiscItem wrapperClass={wrapperClass} label={'Support Account'} value={cspAcc} {...rest} />
  )
}

SetUpSupportAccounts.propTypes = {
  supportAccounts: PropTypes.array,
  setupSelectedAccount: PropTypes.number,
  wrapperClass: PropTypes.string,
}

export default SetUpSupportAccounts
