import React from 'react'
import PropTypes from 'prop-types'
import StatusCard from '../iot/StatusCard'
import BatchError from '../common/BatchError'
const style = {
  borderLeft: '3px solid rgb(235, 87, 87)',
  background: 'linear-gradient(90deg, rgba(235, 87, 87,0.08) 0.74%, rgba(235, 87, 87,0) 100%)',
}


const BatchErrorCard = ({
  title,
  description,
  actionPanel,
  instances,
  request_instances,
  hash_context,
}) => {
  return <StatusCard
    title={title}
    description={description}
    actionPanel={actionPanel}
    style={style}
    tenantName={
      <BatchError
        instances={instances}
        request_instances={request_instances}
        hash_context={hash_context}
      />
    }
  />
}

BatchErrorCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  actionPanel: PropTypes.node,
  instances: PropTypes.array,
  request_instances: PropTypes.array,
  hash_context: PropTypes.string.isRequired,
}

export default BatchErrorCard

