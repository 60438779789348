/**
 * Created by vsuthar on 5/14/20
 * Project: App Portal ©Palo Alto Networks
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@pan/cloud-base'

const AdditionalProductActivated = ({ visible, title, btnProps = {} }) => {
  const { label = 'Activate More Products', ...restBtnProps } = btnProps
  return visible ? (
    <div className={'hbox middle'}>
      <label style={{ margin: '0 5px' }}>{title}</label>
      <Button v2={false} {...restBtnProps}>{label}</Button>
    </div>
  ) : null
}



AdditionalProductActivated.propTypes = {
  /**
   * Make component visible
   */
  visible: PropTypes.bool,
  /**
   * Antd button props
   */
  btnProps: PropTypes.object,

  /**
   * Show title for this compnent
   */
  title: PropTypes.string
}

AdditionalProductActivated.defaultProps = {
  title: ' You have additional products to activate!',
}

export default AdditionalProductActivated
