import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { Progress } from '@pan/cloud-base'
import { isNonemptyArray } from '../../../utils/common'

const LONGER_THAN_USUAL = 'It may take more time than usual'
const MIN_CALC_TASK_ESTIMATES = 3000 // 3s

const useCurrentTimestamp = (initTs, adjust = 0, enable) => {
  const [ts, setTs] = useState(initTs)

  useEffect(() => {
    if (enable) {
      const updateTs = () => setTs(_.floor(Date.now() - adjust, -3))
      const id = setInterval(updateTs, 1000)
      updateTs()
      return () => {
        clearInterval(id)
      }
    }
  }, [enable, adjust])

  return ts
}

const displayDurationInMinOrSec = (estRemainingMs) => {
  const estDuration = moment.duration(estRemainingMs, 'ms')
  const estMin = Math.floor(estDuration.asMinutes())
  const estS = estDuration.seconds()
  if (estMin > 0) {
    return `${estMin} ${estMin > 1 ? 'minutes' : 'minute'}`
  }
  else {
    return `${estS} ${estS > 1 ? 'seconds' : 'second'}`
  }
}

const SetupInProgress = ({
  session,
  progress,
  progressStep,
  throttledRefreshSession,
}) => {
  const noError = !session.error
  const ts = useCurrentTimestamp(session.error?.timestamp, session.ts_adjustment, noError)
  const {
    email_note = 'We will email you when we’re finished'
  } = progressStep

  const [adjustedProgress, estRemaining, currentStepDisplay, isLongerThanUsual] = useMemo(() => {
    const steps = session.progress_steps
    if (!isNonemptyArray(steps)) {
      return [progress, null, progress > 0 ? `${progress}%` : null]
    }
    const currentStep = steps.find(step => !step.ended)
    let estRemaining = null

    if (noError) {
      if (currentStep?.retry) {
        estRemaining = LONGER_THAN_USUAL
      }
      else {
        const estRemainingMs = _.sum(steps.map((step) => {
          if (step.ended || !step.estimates) {
            return 0
          }
          if (step.started) {
            const timeSpend = Math.max(ts - step.started, 0)
            if (step.est_warning !== false && timeSpend > step.estimates) {
              const maxEst = _.max([step.estimates, step.est_warning])
              if (timeSpend > maxEst) {
                step.warning = LONGER_THAN_USUAL
              }
              if (step.retry || timeSpend > (step.est_stuck || maxEst * 2)) {
                step.stuck = true
              }
              return 0
            }
            return step.estimates - timeSpend
          }
          return step.estimates
        }))

        estRemaining = `${displayDurationInMinOrSec(estRemainingMs)} remaining`

        if (currentStep?.warning) {
          if (!currentStep.stuck && estRemainingMs > 60000) { // TODO: may need rewording instead of direct concat
            estRemaining = <>{estRemaining}  <span className='progress-warn'>{currentStep.warning}</span></>
          }
          else { // if less than 1 min remaining, just show the warning without seconds count down
            estRemaining = currentStep.warning
          }
        }
      }
    }

    let adjustedProgress = progress
    if (currentStep?.estimates > MIN_CALC_TASK_ESTIMATES && ts > currentStep.started) {
      const reservedStepEstPercentage = _.clamp(_.round(currentStep.percentage * 0.05, 1), 0.5, 5)
      const maxStepEstPercentage = currentStep.percentage - reservedStepEstPercentage
      const timeSpend = ts - currentStep.started
      const localStepEstPercentage = timeSpend > currentStep.estimates ? maxStepEstPercentage :
        _.round(maxStepEstPercentage * timeSpend / currentStep.estimates, 1)
      if (localStepEstPercentage > 0) {
        const maxLocalEstProgress = localStepEstPercentage +
          _.sum(steps.filter(step => step.ended).map(step => step.percentage))
        if (progress < maxLocalEstProgress) {
          adjustedProgress = maxLocalEstProgress
        }
      }
    }
    const currentStepDisplay = currentStep?.display || (progress > 0 ? steps[0].display || 'Initializing ...' : `${adjustedProgress}%`)
    // console.info(adjustedProgress, progress, currentStepDisplay, estRemaining, currentStep?.underest && !currentStep.no_est_warning && LONGER_THAN_USUAL)
    return [
      _.clamp(adjustedProgress, 0, 100),
      estRemaining,
      currentStepDisplay,
      Boolean(currentStep && (currentStep.retry || currentStep.warning)),
    ]
  }, [session.progress_steps, progress, noError, ts])

  useEffect(() => {
    if (isLongerThanUsual) {
      throttledRefreshSession()
    }
  }, [isLongerThanUsual, throttledRefreshSession])

  return <div className='setup-inprogress'>
    <div className='progress-tooltip ant-tooltip ant-tooltip-placement-top' style={{
      left: `${adjustedProgress}%`,
      opacity: adjustedProgress > 0 && adjustedProgress < 100 ? 1 : 0,
    }}>
      <div className='ant-tooltip-content'>
        <div className='ant-tooltip-arrow'></div>
        <div className='ant-tooltip-inner' role='tooltip'>
          <span>{currentStepDisplay}</span>
        </div>
      </div>
    </div>
    <Progress percent={adjustedProgress} size='small' showInfo={false} />
    {noError && <>
      <div className='progress-est-remaining'>{estRemaining}</div>
      <div className='email-note'>{email_note}</div>
    </>}
  </div>
}

SetupInProgress.propTypes = {
  session: PropTypes.object,
  progress: PropTypes.number,
  progressStep: PropTypes.object,
  throttledRefreshSession: PropTypes.func,
  // stateRef: PropTypes.any,
}

export default SetupInProgress
