/**
 * Created by vsuthar on 4/12/20
 * Project: App Portal ©Palo Alto Networks
 */

import React from 'react'
import PropTypes from 'prop-types'
import './acivate-menu-item.scss'
import Tooltip from '../common/Tooltip'
import Skeleton from '../Skeleton'

const ActivateMenuItem = ({ children, leftComp, icon, disabled, tooltip, isLoading }) => {
  return isLoading ? <Skeleton style={{ width: 400 }} /> : <div style={{ height: 'auto' }} className={'hbox select-product-wrapper '}>
    {
      icon ? <div style={disabled ? { opacity: 0.2 } : {}} className={'select-logo'}>
        <img src={icon} alt={''} />
      </div> : null
    }
    <div className={'select-right'}>
      {children}
    </div>
    <div style={{ margin: 'auto 10px' }}>
      <Tooltip {...tooltip}>
        {leftComp}
      </Tooltip>
    </div>

  </div>
}

ActivateMenuItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  disabled: PropTypes.bool,
  tooltip: PropTypes.object,
  isLoading: PropTypes.bool,
  leftComp: PropTypes.node,
  children: PropTypes.node,
}

export default ActivateMenuItem
