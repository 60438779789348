import React from 'react'
import PropTypes from 'prop-types'
import SetupMiscItem from '../../../components/Setup/SetupMiscItem'
import { ZINGBOX, SETUP_CAPACITY_LABEL_ENUM } from '../../../constants/AppConstants'

const getAddonPlan = (sku) => {
  if (sku?.toUpperCase()?.includes('3P-ADDON-BASIC')) {
    return SETUP_CAPACITY_LABEL_ENUM['IOTBASIC']
  }
  if (sku?.toUpperCase()?.includes('3P-ADDON-ADV')) {
    return SETUP_CAPACITY_LABEL_ENUM['IOTADV']
  }
}
const IoTThirdPartyAddOn = ({ instances = [], ...rest }) => {
  const zingboxThirdPartyAddon = instances.find(instance => instance.app_id === ZINGBOX)?.add_on
  return <>
    <SetupMiscItem label={'Add-on'} value={zingboxThirdPartyAddon?.display_name} wrapperClass={'hbox'} {...rest} />
    <SetupMiscItem label={'Plan'} value={getAddonPlan(zingboxThirdPartyAddon?.sku)} wrapperClass={'hbox'} {...rest} />
  </>
}

IoTThirdPartyAddOn.propTypes = {
  instances: PropTypes.array,
}
export default IoTThirdPartyAddOn

