/**
 * Created by vsuthar on 5/28/20
 * Project: App Portal ©Palo Alto Networks
 */
/**
 * @TODO this file needs to be moved in app manifest file
 */
import {
  SETUP_CTX_CDL,
  SETUP_CTX_IOT,
  SETUP_CTX_CGX,
  SETUP_CTX_PRISMA_ACCESS,
  SETUP_CTX_PRISMA_SAAS,
  SETUP_CTX_AIOPS,
} from '../../constants/AppConstants'
import { FT } from '../../utils/activateUtils'

import { PA } from './prisma_access/PrismaAccessSetup'
import { IoT } from './iot/IoTSetup'
import { FLS } from './fls/FLSSetup'
import { CGX } from './cgx/CGXSetup'
import { SAAS } from './prisma_saas/PrismaSaasSetup'
import { AIOPS } from './aiops/AiopsSetup'

export const SetupSteps = {
  [SETUP_CTX_PRISMA_ACCESS]: PA,
  [SETUP_CTX_IOT]: IoT,
  [SETUP_CTX_CDL]: FLS,
  [SETUP_CTX_CGX]: CGX,
  [SETUP_CTX_PRISMA_SAAS]: SAAS,
  ...(FT('AIOPS_PREMIUM') && { [SETUP_CTX_AIOPS]: AIOPS }),
}
