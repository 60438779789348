/* if value format changed or new props added, please remember to update schema validation in server/src/routes/activate/index.js#33 */
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Table, Checkbox, Icon, Tooltip } from '@pan/cloud-base'
import InputSearchBar from '../../../components/common/InputSearchBar'
import ControlledToggle from './ControlledToggle'
import { SEARCH_PLACE_HOLDER, NGFW, FLS_DEVICES_FIELD, INGEST_AND_STORAGE, INGEST, LGS_ID } from '../../../constants/AppConstants'
import {
  firewallDeviceSearch,
  useMemoFieldDecorator,
  useDebouncedCallback,
  useValueRef,
  isNonemptyArray,
  indexArray,
} from '../../../utils/activateUtils'
import ListItem from '../../../components/ListItem'
import { InfoCircle } from '../../../images/svg-icons'
import './FirewallSelectionStep.scss'

const EMPTY_ARRAY = []
const SN_FIELD = 'serial_number'
const device_type = NGFW.toUpperCase()

const theadTh = {
  textTransform: 'none',
  color: '#000000',
  fontSize: '12px',
  fontWeight: 'bold',
  backgroundColor: '#fff',
}

const trGroup = {
  minHeight: '30px',
  borderBottom: 'none',
  flex: '0 0 auto',
}

const thead = {
  backgroundColor: '#fff'
}

const td = {
  fontSize: '12px',
}

const searchIconStyle = {
  color: '#a3a3a3',
  fontWeight: 900,
  fontSize: '16px'
}

const noFlsRegistered = d => !(d.platform_id || d.fls_tenant_id)

/* eslint-disable react/prop-types */
const renderWithTooltip = (value) => <ListItem toolTipProps={{ placement: 'right' }}>{value || '-'}</ListItem>
const renderToggle = ({ value: { checked, disabled }, column: { onToggle }, row: { serial_number } }) => (
  <>
    <ControlledToggle
      checked={checked}
      disabled={disabled}
      onClick={(checked) => serial_number && onToggle(serial_number, checked)}
    />
    <span className='toggle-text'>{checked ? 'On' : 'Off'}</span>
  </>
)
const certStatuses = {
  ACTIVE: { className: 'cert-status dot-success', children: 'Active' },
  MISSING: { className: 'cert-status dot-warning', children: 'Needs Certificate' },
  EXPIRED: { className: 'cert-status dot-error', children: 'Expired' },
  EXPIRING: { className: 'cert-status dot-warning', children: 'Expiring (in the next 7 days)' },
}
const renderCertStatus = ({ value }) => <span {
  ...(certStatuses[value || 'MISSING'] || { children: _.capitalize(value) })
} />
/* eslint-enable react/prop-types */
const staticColums = [
  {
    Header: 'Serial Number',
    accessor: SN_FIELD,
    width: 150,
    Cell: ({ value }) => renderWithTooltip(value)
  },
  {
    Header: 'Model',
    accessor: 'model',
    width: 100,
  },
  {
    Header: 'Device Name',
    accessor: 'device_name',
    width: 175,
    Cell: ({ value }) => renderWithTooltip(value),
  },
]

const InfoIcon = ({ children, className }) => (
  <Tooltip
    placement='top'
    title={children}
    getPopupContainer={e => e.parentNode.closest?.('.fls-firewalls') || document.body}
  ><InfoCircle className={className} /></Tooltip>
)
InfoIcon.defaultProps = {
  className: 'fls-info-icon',
}
InfoIcon.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

const FirewallSelectionField = React.forwardRef(({
  firewalls,
  value = EMPTY_ARRAY,
  devicesLimit,
  onChange,
}, ref) => {
  const selected = useMemo(() => indexArray(value, SN_FIELD), [value])
  const selectedRef = useValueRef(selected)
  const selectableSet = useMemo(() => new Set(firewalls.filter(noFlsRegistered).map(each => each.serial_number)), [firewalls])

  const [search, setSearch] = useState('')
  const filteredList = useMemo(() => (
    search ? firewallDeviceSearch(search, firewalls) : firewalls
  ), [search, firewalls])
  const filteredListRef = useValueRef(filteredList)

  const handleInputSearch = useDebouncedCallback(setSearch, 300, [])

  const columns = useMemo(() => {
    const selectOne = ({ checked, serial_number, subscription_type }) => {
      const newSelected = checked ?
        selectedRef.current.filter(v => v[SN_FIELD] !== serial_number) :
        selectedRef.current.concat({ serial_number, device_type, subscription_type })
      onChange(newSelected)
    }
    const selectAll = (checked) => {
      onChange(checked ? [] : filteredListRef.current.filter(noFlsRegistered).map(({ serial_number }) => ({
        serial_number,
        device_type,
        subscription_type: INGEST_AND_STORAGE,
      })))
    }
    const checkBox = ({ onClick, id, value, row, ...rest }) => {
      return <Checkbox {...rest}
        id={id}
        checked={value.checked}
        disabled={value.disabled}
        onClick={(e) => {
          e.stopPropagation()
          selectOne({ checked: value.checked, serial_number: row.serial_number, subscription_type: INGEST_AND_STORAGE })
          // onClick(id, e.shiftKey, row)
        }}
      />
    }
    const selectAllCheckBox = ({ onClick, id, row, ...rest }) => {
      const checked = selectableSet.size === selected.length
      return <Checkbox {...rest}
        id={id}
        checked={checked}
        indeterminate={!checked && selected.length > 0}
        onClick={(e) => {
          e.stopPropagation()
          // onClick(id, e.shiftKey, row)
          selectAll(checked)
        }}
      />
    }

    return [{
      id: 'checkbox',
      width: 36,
      sortable: false,
      Header: selectAllCheckBox,
      Cell: checkBox,
      accessor: (data) => ({
        checked: selected.has(data.serial_number),
        disabled: !selectableSet.has(data.serial_number),
      }),
    }, ...staticColums, {
      Header: 'Store Log Data',
      id: 'selector',
      width: 115,
      Cell: renderToggle,
      accessor: (data) => ({
        checked: (data.subscription_type || selected.get(data.serial_number)?.subscription_type) === INGEST_AND_STORAGE,
        disabled: !selectableSet.has(data.serial_number),
      }),
      onToggle: (serial_number, checked) => {
        const newSelected = selected.map((v) => {
          if (v.serial_number !== serial_number) {
            return v
          }
          return {
            serial_number,
            device_type,
            subscription_type: checked ? INGEST_AND_STORAGE : INGEST,
          }
        })
        onChange(newSelected)
      },
    }, {
      Header: 'Certificate Status',
      accessor: 'cert_status',
      Cell: renderCertStatus,
    }]
  }, [onChange, filteredListRef, selectableSet, selected, selectedRef])

  useEffect(() => { // clear search after unmount
    return () => {
      setSearch('')
    }
  }, [])

  const onDeviceSelect = useCallback((selected) => {
    const selectedSet = new Set(selected)
    onChange(firewalls.filter(dev => selectedSet.has(dev[SN_FIELD])))
  }, [firewalls, onChange])

  return (
    <div className={'setup-card-body fls-setup fls-firewalls vbox'}>
      <div className='fls-fw-counter'>{selected.length} of {selectableSet.size} {selectableSet.size > 1 ? 'Firewalls' : 'Firewall'} selected {selectableSet.size > devicesLimit && <>(maximum of {devicesLimit} firewalls can be selected at once)</>}</div>
      <div className='fls-fw-wrapper vbox'>
        <InputSearchBar
          className='fls-fw-search'
          handleInputSearch={handleInputSearch}
          placeholder={SEARCH_PLACE_HOLDER}
          prefix={<Icon type='search' style={searchIconStyle} />}
        />
        <div className='fls-fw-table-wrapper'>
          <Table
            keyField={SN_FIELD}
            styles={{ thead, theadTh, trGroup, td }}
            sortable={true}
            autoScrollbar={true}
            noDataText='No Firewall Found'
            columns={columns}
            data={filteredList}
            onSelect={onDeviceSelect}
            ref={ref}
          />
        </div>
      </div>
    </div>
  )
})
FirewallSelectionField.displayName = 'FirewallSelectionField'
FirewallSelectionField.propTypes = {
  value: PropTypes.array,
  firewalls: PropTypes.array,
  devicesLimit: PropTypes.number,
  onChange: PropTypes.func,
}

const FirewallSelectionStep = ({
  devices,
  getAnyApp,
  form: {
    getFieldDecorator,
    getFieldValue,
    resetFields,
  },
}) => {
  const devicesLimit = useMemo(() => {
    return +getAnyApp(LGS_ID)?.setup?.max_devices || 100
  }, [getAnyApp])
  const [firewalls, defaultValue, hasInvalidSelection] = useMemo(() => {
    const [selectableFw, disabledFw] = _.partition(
      devices?.filter(d => d.app_id === NGFW),
      noFlsRegistered,
    )
    const sorted = selectableFw.concat(disabledFw)
    const selectableFwSn = indexArray(selectableFw, SN_FIELD)
    const hasInvalidSelection = (selected) => { // e.g. when account changed
      if (!isNonemptyArray(selected)) {
        return false
      }
      return !selected.every(selectableFwSn.has)
    }
    const defaultValue = selectableFwSn.slice(0, devicesLimit).map(({ serial_number }) => ({
      serial_number,
      device_type,
      subscription_type: INGEST_AND_STORAGE,
    }))
    return [sorted, defaultValue, hasInvalidSelection]
  }, [devices, devicesLimit])
  const fieldDecorator = useMemoFieldDecorator(FLS_DEVICES_FIELD, {
    preserve: true,
    initialValue: defaultValue,
    // validateFirst: true,
    rules: useMemo(() => [
      {
        validator(rule, value, callback) {
          if (value?.length > devicesLimit) {
            callback(`Maximum of ${devicesLimit} firewalls can be selected at once.`)
          }
          else {
            callback()
          }
        }
      }
    ], [devicesLimit]),
  }, getFieldDecorator)
  useEffect(() => { // reset value when firewalls changed
    if (hasInvalidSelection(getFieldValue(FLS_DEVICES_FIELD))) {
      resetFields([FLS_DEVICES_FIELD])
    }
  }, [hasInvalidSelection, getFieldValue, resetFields])

  return fieldDecorator(<FirewallSelectionField firewalls={firewalls} devicesLimit={devicesLimit} />)
}

FirewallSelectionStep.propTypes = {
  form: PropTypes.object,
  formValues: PropTypes.object,
  session: PropTypes.shape({
    licenses: PropTypes.array.isRequired
  }).isRequired,
  devices: PropTypes.array.isRequired,
  getAnyApp: PropTypes.func,
  entitledAppsList: PropTypes.array.isRequired,
  selectedFirewallDevices: PropTypes.array.isRequired,
  setSelectedFirewallDevices: PropTypes.func,
  supportAccounts: PropTypes.array.isRequired,
  setupSelectedAccount: PropTypes.number.isRequired,
}

export default FirewallSelectionStep
