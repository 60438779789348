/**
 * Created by vsuthar on 6/9/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import SetupMiscItem from '../../../components/Setup/SetupMiscItem'

const AppUrl = ({ instances = [], entitledAppsList, appContext, ...rest }) => {
  const domain = useMemo(() => {
    const iotInstance = instances.find(app => app?.app_id === appContext)
    const { url } = iotInstance || {}
    return url?.replace(/^https?:\/\//i, '') || ''
  }, [instances, appContext])
  return <SetupMiscItem value={domain || rest?.value } wrapperClass={'hbox'} {...rest} />
}

AppUrl.propTypes = {
  instances: PropTypes.array,
  entitledAppsList: PropTypes.array,
  appContext: PropTypes.string.isRequired,
}
export default AppUrl

