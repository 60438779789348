import React from 'react'
import PropTypes from 'prop-types'
import { Table, Popover, Icon } from '@pan/cloud-base'
import ListItem from '../../../../components/ListItem'
import { firewallModelMatch, renderAssetType } from '../../../../utils/activateUtils'
import { indexArray } from '../../../../utils/common'
import LicenseSelection from './LicenseSelection'
import {
  LICENSE_GRACE_PERIOD,
  NFR,
  PANW_GRACE_PERIOD,
  PANW_GRACE_PERIOD_LINK,
  PREVIOUSLY_ACTIVATED_NOTE,
  TRIAL,
  CLEAR,
  RESET,
  MONTHS,
  NEW_LABEL,
  PREV_LABEL,
  DEVICE_LICENSE_MATRIX,
} from '../../../../constants/AppConstants'
import { isFeatureEnabled as FT } from '@pan/cloud-appframework'

const theadTh = {
  textTransform: 'none',
  color: '#000000',
  fontSize: '12px',
  fontWeight: 'normal',
  backgroundColor: '#FFFFFF'
}

const trGroup = {
  minHeight: '10px',
  borderBottom: '1px solid #EAEAEA',
  width: '98%',
}

const thead = {
  backgroundColor: '#FFFFFF'
}

const td = {
  border: 'none',
  color: '#333333',
  fontWeight: 'normal',
}

const renderWithTooltip = (value) => <ListItem toolTipProps={{ placement: 'right' }}>{value || '-'}</ListItem>

const purchaseTermHeader = (selectedDevices, deviceModel) => {
  const selectedModelCount = selectedDevices.filter(device => device?.model === deviceModel)?.length
  const title = <>
    <span>{LICENSE_GRACE_PERIOD}</span>
    <br />
    <a rel='external noopener noreferrer' target='_blank' href={`${PANW_GRACE_PERIOD_LINK}`}>{PANW_GRACE_PERIOD}</a>
    <br />
    <span>{PREVIOUSLY_ACTIVATED_NOTE}</span>
  </>

  return <>
    <span>{`Purchased Term(${selectedModelCount})`}</span>
    <Popover content={title} placement="top" trigger="hover" overlayClassName='custom-popup-over'>
      <Icon type='info-circle-o' style={{ padding: '3px' }} />
    </Popover>
  </>

}



const renderCDLIcon = (value, cdlList, currentStepObj) => {
  const {
    serial_number,
    tenant_instance_name,
    tenant_display_name = tenant_instance_name || serial_number,
    is_clcs
  } = cdlList?.get(value) || {}
  const title = value ? <><span>Associated with </span> <p>{tenant_display_name}</p></> : <>
    This devices needs to be associated with Data Lake <a href={currentStepObj?.help} target='_blank' rel='noopener noreferrer' >Go to details</a>
  </>
  if (is_clcs) {
    return '-'
  }
  return value ? <>
    <Popover content={title} placement="top" trigger="hover" overlayClassName='cdl-column-tooltip'>
      <Icon style={{ color: 'green', fontSize: '16px' }} type="check-circle" theme="filled" />
    </Popover>
  </> : <Popover content={title} placement="top" trigger="hover" overlayClassName='cdl-column-tooltip'>
    <Icon style={{ color: 'blue', fontSize: '16px' }} color='blue' type="question-circle" theme="filled" />
  </Popover>
}

const FirewallDeviceTable = ({ data, selectedDevices, selectedRow, isAgnosticLicense, licenses, handleSelectOption, handleUnselect, context, cdlList, currentStepObj }) => {

  indexArray(cdlList, 'tenant_id', { cached: true })
  const renderLink = (serialNumber) => {
    const selectedDevice = selectedDevices.find(device => device.serial_number === serialNumber)
    if (selectedDevice) {
      const linkText = selectedDevice.tenant_id ? RESET : CLEAR
      return <a href='#' onClick={() => handleUnselect(serialNumber)}>{linkText}</a>
    }
  }

  const isOptionDisabled = (license, list) => {
    const listCount = list.filter(
      lic => lic.asset_type === license.asset_type &&
        lic.license_unit === license.license_unit &&
        lic.license_term === license.license_term &&
        lic.license_version === license.license_version
    )?.length || 0

    const selectedCount = selectedDevices.filter(
      obj => obj.license_asset_type === license.asset_type &&
        obj.license_unit === license.license_unit &&
        obj.license_term === license.license_term &&
        obj.license_version === license.license_version &&
        (isAgnosticLicense || firewallModelMatch(obj.model, license?.sku))
    )?.length || 0
    return selectedCount >= listCount
  }

  /*
    eg:  {
      prod: {
        new: [{ term: 60, isDisable: false}, { term: 12, isDisable: true }],
        prev: [{ term: 19, isDisable: false}]
      }
    }
  */
  const constructResult = (result, license, list) => {
    const licenseUnit = license.license_unit === MONTHS ? NEW_LABEL : PREV_LABEL
    result[license?.license_version][license?.asset_type][licenseUnit] = result[license?.license_version][license?.asset_type][licenseUnit] || []
    if (!result[license?.license_version][license?.asset_type][licenseUnit].some(obj => obj.term === license.license_term)) { //duplicate check
      result[license?.license_version][license?.asset_type][licenseUnit].push({
        term: license.license_term,
        isDisable: isOptionDisabled(license, list)
      })
    }
  }

  const deviceLicenseMatrix = DEVICE_LICENSE_MATRIX[context]

  const getSelectionOptions = (rowInfo) => {
    if (rowInfo.isDisable) {
      return {} // for disable device no need to construct select options
    }

    const deviceType = rowInfo.asset_type
    if (isAgnosticLicense) {
      const selectionOptions = licenses.filter(license => !license.activated).reduce((result, license) => {
        result[license?.license_version] = result[license?.license_version] || {}
        result[license?.license_version][license?.asset_type] = result[license?.license_version][license?.asset_type] || {}
        constructResult(result, license, licenses)
        return result
      }, {})
      return selectionOptions
    }
    else {
      const sortedUnusedLicenses = selectedRow?.licenses?.unused.sort((obj1, obj2) => (obj1.licenseUnit > obj2.licenseUnit ? -1 : 1))
      const combinations = deviceLicenseMatrix[deviceType]
      const selectionOptions = sortedUnusedLicenses.reduce((result, license) => {
        const setResult = () => {
          result[license?.license_version] = result[license?.license_version] || {}
          result[license?.license_version][license?.asset_type] = result[license?.license_version][license?.asset_type] || {}
          constructResult(result, license, sortedUnusedLicenses)
        }
        if (deviceType === NFR && combinations.includes(license?.asset_type)) {
          // exceptional case: NFR device activated with TRIAL license cannot overwrite with NFR license
          const skipOption = rowInfo?.license_asset_type === TRIAL && license?.asset_type === NFR
          !skipOption && setResult(skipOption)
        }
        else if (combinations.includes(license?.asset_type)) {
          setResult()
        }
        return result
      }, {})
      return selectionOptions
    }
  }

  const renderSelection = (original) => {
    return <LicenseSelection data={getSelectionOptions(original)} rowInfo={original} selectedDevices={selectedDevices} handleSelectOption={handleSelectOption} />
  }

  const deviceColumn = [
    {
      Header: 'Serial Number',
      accessor: 'serial_number',
      Cell: ({ value }) => renderWithTooltip(value),
      width: 200,
    },
    {
      Header: 'Device',
      accessor: 'device_name',
      Cell: ({ value }) => renderWithTooltip(value),
      width: 120,
    },
    {
      Header: 'Type',
      accessor: 'asset_type',
      Cell: ({ value }) => renderAssetType(value),
      width: 50
    },
    {
      Header: purchaseTermHeader(selectedDevices, selectedRow?.model),
      Cell: ({ original }) => renderSelection(original),
      width: 230
    },
    {
      accessor: 'serial_number',
      Cell: ({ value }) => renderLink(value),
      width: 70
    },
  ]
  if (FT('SHOW_CLD_COL_FW')) {
    deviceColumn.splice(3, 0, {
      Header: 'Logging Service',
      accessor: 'platform_id',
      Cell: ({ value }) => {
        return renderCDLIcon(value, cdlList, currentStepObj)
      },
      width: 50
    })
  }

  return <Table
    className='fw-device-tbl'
    columns={deviceColumn}
    data={data}
    styles={{ thead, theadTh, trGroup, td }}
    keyField='serial_number'
    autoScrollbar={true}
    noDataText='No Devices Found' />
}

FirewallDeviceTable.propTypes = {
  data: PropTypes.array.isRequired,
  handleSelectOption: PropTypes.func.isRequired,
  handleUnselect: PropTypes.func.isRequired,
  selectedDevices: PropTypes.array.isRequired,
  selectedRow: PropTypes.object,
  isAgnosticLicense: PropTypes.bool.isRequired,
  licenses: PropTypes.array.isRequired,
  context: PropTypes.string.isRequired,
  cdlList: PropTypes.array,
  currentStepObj: PropTypes.object,
}

export { renderCDLIcon }

export default FirewallDeviceTable
