
/**
 * Created by vsuthar on 10/8/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import SetupBorder from '../../components/Setup/SetupBorder'
import ActivateMenuItemText from '../../components/Setup/ActivateMenuItemText'
import CapacityRender from './CapacityRender'
import SetupMiscItem from '../../components/Setup/SetupMiscItem'
import { isNonemptyArray } from '../../utils/common'
import DateRender from '../../components/Setup/DateRender'
import { isActivatingSaasCasbApi, isActivatingSaasCasbInline } from '../../utils/activateUtils'



const getAddons = (addons) => {
  let addonVal = ''
  addonVal = addons.map((each, idx) => {
    const { ui_display_name } = each
    return <Fragment key={idx}>
      {ui_display_name} {idx === addons.length - 1 ? '' : ','}
      <br/>
    </Fragment>
  })
  return <>
    <SetupMiscItem wrapperClass={'hbox middle'}
      label={'Add-on: '}
      labelProps={{ style: { minWidth: 50, alignSelf: 'baseline', fontWeight: 400 } }}
      value={isNonemptyArray(addonVal) ? addonVal : 'None'}
    />
  </>
}

/***
 *
 * @param additionalBaseProduct
 * @returns {*}
 * @constructor
 * Additional base product must contain dispaly_name, purchased_size_render, license_display_name
 */


const SetupRenderProduct = ({ purchasedSize }) => (<CapacityRender purchased_size_render={purchasedSize}/>)

SetupRenderProduct.propTypes = {
  purchasedSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ])
}

const isSaasAddOn = (app) => {
  if (isActivatingSaasCasbInline([app])) {
    return true
  }
  if (isActivatingSaasCasbApi([app])) {
    return true
  }

  const { extra = {}, sku } = app
  return extra.saas_api_skus?.some(apiSku => sku?.includes(apiSku))
}

const SetupAdditionalProduct = ({ app }) => {
  const { additionalBaseProduct = [], sku, license_expiration, addons = [] } = app

  return <Fragment>
    {
      additionalBaseProduct.map((each, idx) => {
        const { display_name, purchased_size_render, license_display_name, app_id } = each
        return <div key={idx}>
          <SetupBorder/>
          <ActivateMenuItemText
            title={license_display_name || display_name}
            subTitle={<CapacityRender purchased_size_render={purchased_size_render} app_id={app_id}/>}
          />
          <SetupBorder/>
        </div>
      })
    }
    {(sku?.includes('PRISMA-ACCESS') || isSaasAddOn(app)) ? getAddons(addons, license_expiration) : null}
    {<DateRender labelProps={{ style: { marginRight: 5, fontWeight: 400 } }} label='Expiration:' date={license_expiration}/>}
  </Fragment>

}

SetupAdditionalProduct.propTypes = {
  app: PropTypes.shape({
    additionalBaseProduct: PropTypes.arrayOf(PropTypes.shape({
      display_name: PropTypes.string,
      purchased_size_render: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      license_display_name: PropTypes.string,
    })),
    sku: PropTypes.string,
    addons: PropTypes.array,
    license_expiration: PropTypes.string,
    extra: PropTypes.object,
    licenses: PropTypes.arrayOf(PropTypes.shape({
      addons: PropTypes.array
    }))
  }),
}

export default SetupAdditionalProduct
