import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ErrorHandler from './ErrorHandler'
import DataLake from './DataLake'
import ActivateProduct, { ActivateProductLoadingPlaceholder } from './common/ActivateProduct'
import CustomerAccountSelection from './common/CustomerAccountSelection'
import ConfirmActivation from './common/ConfirmActivation'
import ChooseHowToManage from './prisma_access/ChooseHowToManage'
import RegisterNewPanorama from './prisma_access/RegisterNewPanorama'
import SetUpErrorCard from '../../components/Setup/SetUpErrorCard'
import FirewallStep from './iot/FirewallStep'
import IoTSecuritySetup from './iot/IoTSecuritySetup'
import FlsConfigStep from './fls/ConfigStep'
import FlsFirewallSelectionStep from './fls/FirewallSelectionStep'
import PrismaSaasFirewallStep from './prisma_saas/PrismaSaasFirewallStep'
import PrismaSaasSecuritySetup from './prisma_saas/PrismaSaasSecuritySetup'
import AiopsFirewallStep from './aiops/AiopsFirewallStep'
import AiopsSecuritySetup from './aiops/AiopsSecuritySetup'

const EMPTY_STEPS = []
const EMPTY_STEP_OBJ = {}

const FormStepComponent = ({
  stateRef,
  states,
  form,
  isLoading,
  onProductChecked,
  onAccountSelect,
  onCDLChange,
  chooseHowToManage,
  setNewPanoramaSn,
  setSelectedFirewallDevices,
  setFirewallListVisible,
  setClcsTenant,
  keyByMsspAccounts,
  msspAccounts,
  hasMSP,
  sgGroups
}) => {
  const {
    currentStep,
    currentStepObj = EMPTY_STEP_OBJ,
    toBeActivatedApp,
    entitledAppsList,
    cdlInstance,
    productToBeActivated,
    steps = EMPTY_STEPS,
    supportAccounts,
    setupSelectedAccount,
    getAppInstances,
  } = states

  const stepForm = useMemo(() => {
    const { getFieldDecorator } = form
    if (!currentStepObj.formFields) {
      currentStepObj.formFields = []
    }
    return {
      ...form,
      getFieldDecorator: (fieldName, options) => {
        const decorator = getFieldDecorator(fieldName, options)
        if (!currentStepObj.formFields.includes(fieldName)) {
          currentStepObj.formFields.push(fieldName)
        }
        return decorator
      },
    }
  }, [form, currentStepObj])

  const comp_enum = currentStepObj.comp_enum
  switch (comp_enum) {
    case 'SETUP_ACTIVATE_PRODUCT': {
      return <ActivateProduct
        stateRef={stateRef}
        isLoading={isLoading}
        form={stepForm}
        toBeActivatedApp={toBeActivatedApp}
        onProductChecked={onProductChecked}
        hasMSP={hasMSP}
      />
    }
    case 'SETUP_ACCOUNT_SELECTION': {
      return <CustomerAccountSelection
        form={stepForm}
        stateRef={stateRef}
        toBeActivatedApp={toBeActivatedApp}
        supportAccounts={supportAccounts}
        setupSelectedAccount={setupSelectedAccount}
        onAccountSelect={onAccountSelect}
        getAppInstances={getAppInstances}
        isLoading={isLoading}
        keyByMsspAccounts={keyByMsspAccounts}
      />
    }
    case 'FLS_CONFIG': {
      return <FlsConfigStep
        form={stepForm}
        {...states}
        stateRef={stateRef}
        onCDLChange={onCDLChange}
        setNewPanoramaSn={setNewPanoramaSn}
      />
    }
    case 'FLS_FIREWALL_SELECTION': {
      return <FlsFirewallSelectionStep
        form={stepForm}
        {...states}
        stateRef={stateRef}
      />
    }
    case 'CHOOSE_HOW_TO_MANAGE_PRISMA_ACCESS': {
      return <ChooseHowToManage
        form={stepForm}
        hasMSP={hasMSP}
        {...states}
        stateRef={stateRef}
        entitledAppsList={entitledAppsList}
        chooseHowToManage={chooseHowToManage}
      />
    }
    case 'IOT_SECURITY_SETUP': {
      return <IoTSecuritySetup
        form={stepForm}
        {...states}
        stateRef={stateRef}
        cdlInstance={cdlInstance}
        onCDLChange={onCDLChange}
        chooseHowToManage={chooseHowToManage}
        setSelectedFirewallDevices={setSelectedFirewallDevices}
        setNewPanoramaSn={setNewPanoramaSn}
        setClcsTenant={setClcsTenant}
      />
    }
    case 'SETUP_CORTEX_DATA_LAKE': {
      return <DataLake
        stateRef={stateRef}
        form={stepForm}
        cdlInstance={cdlInstance}
        onCDLChange={onCDLChange}
        toBeActivatedApp={toBeActivatedApp}
        entitledAppsList={entitledAppsList}
        supportAccounts={supportAccounts}
        selectedAccount={setupSelectedAccount}
      />
    }
    case 'REGISTER_NEW_PANORAMA': {
      return <RegisterNewPanorama
        form={stepForm}
        stateRef={stateRef}
        {...states}
        supportAccounts={supportAccounts}
        selectedAccount={setupSelectedAccount}
        setNewPanoramaSn={setNewPanoramaSn}
      />
    }
    case 'CONFIRMATION': {
      return <ConfirmActivation
        {...states}
        form={stepForm}
        keyByMsspAccounts={keyByMsspAccounts}
        msspAccounts={msspAccounts}
        hasMSP={hasMSP}
        sgGroups={sgGroups}
        stateRef={stateRef}
        supportAccounts={supportAccounts}
        selectedAccount={setupSelectedAccount}
        productToBeActivated={productToBeActivated}
        entitledAppsList={entitledAppsList}
        cdlInstance={cdlInstance}
        onCDLChange={onCDLChange}
        chooseHowToManage={chooseHowToManage}
        setNewPanoramaSn={setNewPanoramaSn}
        setSelectedFirewallDevices={setSelectedFirewallDevices}
        toBeActivatedApp={toBeActivatedApp}
      />
    }
    case 'SELECT_FIREWALL': {
      return <FirewallStep
        {...states}
        stateRef={stateRef}
        form={stepForm}
        supportAccounts={supportAccounts}
        setFirewallModalVisible={setFirewallListVisible}
        setSelectedFirewallDevices={setSelectedFirewallDevices}
      />
    }
    case 'PRISMA_SAAS_SECURITY_SETUP': {
      return <PrismaSaasSecuritySetup
        form={stepForm}
        {...states}
        stateRef={stateRef}
        cdlInstance={cdlInstance}
        onCDLChange={onCDLChange}
        chooseHowToManage={chooseHowToManage}
        setSelectedFirewallDevices={setSelectedFirewallDevices}
        setClcsTenant={setClcsTenant}
        setNewPanoramaSn={setNewPanoramaSn}
      />
    }
    case 'SELECT_PRISMA_SAAS_FIREWALL': {
      return <PrismaSaasFirewallStep
        {...states}
        form={stepForm}
        stateRef={stateRef}
        supportAccounts={supportAccounts}
        setFirewallModalVisible={setFirewallListVisible}
        setSelectedFirewallDevices={setSelectedFirewallDevices}
      />
    }
    case 'STRATA_INSIGHTS_SECURITY_SETUP': {
      return <AiopsSecuritySetup
        form={stepForm}
        {...states}
        stateRef={stateRef}
        cdlInstance={cdlInstance}
        onCDLChange={onCDLChange}
        chooseHowToManage={chooseHowToManage}
        setSelectedFirewallDevices={setSelectedFirewallDevices}
      />
    }
    case 'SELECT_STRATA_INSIGHTS_FIREWALL': {
      return <AiopsFirewallStep
        {...states}
        stateRef={stateRef}
        form={stepForm}
        supportAccounts={supportAccounts}
        setFirewallModalVisible={setFirewallListVisible}
        setSelectedFirewallDevices={setSelectedFirewallDevices}
      />
    }
    case 'ERROR': {
      return <ErrorHandler errorData={currentStepObj?.errorData} />
    }
    default: {
      const children = steps[currentStep]?.children
      if (children) {
        return children
      }
      if (isLoading || !steps.length) {
        return <ActivateProductLoadingPlaceholder toBeActivatedApp={[]} isLoading={isLoading} />
      }
      return <SetUpErrorCard />
    }
  }
}

FormStepComponent.propTypes = {
  stateRef: PropTypes.any,
  states: PropTypes.object,
  form: PropTypes.object,
  keyByMsspAccounts: PropTypes.object,
  msspAccounts: PropTypes.array,
  sgGroups: PropTypes.array,
  hasMSP: PropTypes.bool,
  isLoading: PropTypes.bool,
  onProductChecked: PropTypes.func,
  onAccountSelect: PropTypes.func,
  onCDLChange: PropTypes.func,
  setClcsTenant: PropTypes.func,
  chooseHowToManage: PropTypes.func,
  setNewPanoramaSn: PropTypes.func,
  setSelectedFirewallDevices: PropTypes.func,
  setFirewallListVisible: PropTypes.func,
}

export default FormStepComponent
