/**
 * Created by vsuthar on 4/20/20
 * Project: App Portal ©Palo Alto Networks
 */
import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Input } from '@pan/cloud-base'
import { PANORAMA, PANORAMA_INSTANCE_FIELD } from '../../../constants/AppConstants'
import { useMemoFieldDecorator } from '../../../utils/hooks'
import { validatePanoramaSerial } from '../../../utils/activateUtils'
import './registerPanorama.scss'

const NewPanoramaInstruction = () => <>
  <label className={'setup-help-label'}>Panorama virtual appliance:</label>
  <ol className={'help-list'}>
    <li>Review the <a target='_blank' rel='noopener noreferrer' href={'https://docs.paloaltonetworks.com/panorama/9-0/panorama-admin/set-up-panorama/set-up-the-panorama-virtual-appliance/setup-prerequisites-for-the-panorama-virtual-appliance.html'}>prerequisites</a>.</li>
    <li><a target='_blank' rel='noopener noreferrer' href={'https://docs.paloaltonetworks.com/panorama/9-0/panorama-admin/set-up-panorama/set-up-the-panorama-virtual-appliance/install-the-panorama-virtual-appliance.html'}>Install</a> Panorama.</li>
    <li>Perform initial <a target='_blank' rel='noopener noreferrer' href={'https://docs.paloaltonetworks.com/panorama/9-0/panorama-admin/set-up-panorama/set-up-the-panorama-virtual-appliance/perform-initial-configuration-of-the-panorama-virtual-appliance.html'}>configuration</a>.</li>
    <li>Upgrade your Panorama. Refer to the <a target='_blank' rel='noopener noreferrer' href={'https://docs.paloaltonetworks.com/compatibility-matrix/panorama/plugins.html#id17C8A0XB0V8'}>compatibility matrix</a> for the minimum Panorama version.</li>
  </ol>
  <label className={'setup-help-label'}>Panorama hardware appliance:</label>
  <ol className={'help-list'}>
    <li>Complete <a target='_blank' rel='noopener noreferrer' href={'https://docs.paloaltonetworks.com/panorama/9-0/panorama-admin/set-up-panorama/set-up-the-m-series-appliance/m-series-setup-overview/set-up-an-m-series-appliance-in-panorama-mode.html'}>steps 1 through 6</a> to set up the Panorama hardware appliance.</li>
    <li>Refer to the <a target='_blank' rel='noopener noreferrer' href={'https://docs.paloaltonetworks.com/compatibility-matrix/panorama/plugins.html#id17C8A0XB0V8'}>compatibility matrix </a>for the minimum Panorama version.</li>
  </ol>
</>

const DebouncedInput = React.forwardRef(({ onChanged, onChange, onBlur, wait, ...props }, ref) => {
  const debouncedOnChanged = useMemo(() => _.debounce(onChanged, wait), [onChanged, wait])
  const triggerChange = useCallback((e) => {
    const value = e.target.value
    onChange?.(value)
    debouncedOnChanged(value)
  }, [onChange, debouncedOnChanged])
  const triggerBlur = useCallback((e) => {
    debouncedOnChanged.cancel()
    onChanged(e.target.value)
    return onBlur?.(e)
  }, [debouncedOnChanged, onBlur, onChanged])
  return <Input ref={ref} {...props} onChange={triggerChange} onBlur={triggerBlur} />
})
DebouncedInput.displayName = 'DebouncedInput'
DebouncedInput.propTypes = {
  wait: PropTypes.number.isRequired,
  onChanged: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
}

export const NewPanoramaSnField = ({
  fieldName,
  setNewPanoramaSn,
  getFieldDecorator,
  stateRef,
  toBeActivatedApp,
  style,
}) => {
  const { new_panorama_sn } = stateRef.current
  const defaultPanoramaSerial = useMemo(() => {
    const panorama = toBeActivatedApp.find(lic => lic.checked && lic.app_id.endsWith(PANORAMA) && lic.serial_number)
    return panorama?.serial_number || ''
  }, [toBeActivatedApp])

  const saveHandler = useCallback((value) => {
    setNewPanoramaSn(value || defaultPanoramaSerial)
  }, [defaultPanoramaSerial, setNewPanoramaSn])

  const fieldDecorator = useMemoFieldDecorator(fieldName, {
    initialValue: new_panorama_sn ?? defaultPanoramaSerial,
    validateFirst: true,
    validateTrigger: 'onChanged',
    rules: useMemo(() => [
      { required: true, message: 'Panorama Serial Number is required' },
      { pattern: /^[a-zA-Z0-9-]+$/, message: 'Allowing only alphanumeric characters' },
      {
        validator(rule, value, callback) {
          const { devices } = stateRef.current
          const found = devices?.get?.(value)?.platform_id > 0
          if (found) {
            callback('This Panorama Serial Number has been registered')
          }
          else {
            callback()
          }
        }
      },
      {
        validator(rule, value) {
          return validatePanoramaSerial(value, stateRef.current, {
            register_new: true,
          })
        }
      }
    ], [stateRef]),
  }, getFieldDecorator)

  return fieldDecorator(
    <DebouncedInput
      style={style}
      onChanged={saveHandler}
      maxLength={15}
      wait={300}
    />
  )
}

NewPanoramaSnField.propTypes = {
  new_panorama_sn: PropTypes.string,
  toBeActivatedApp: PropTypes.array,
  stateRef: PropTypes.any,
  style: PropTypes.object,
  setNewPanoramaSn: PropTypes.func,
  getFieldDecorator: PropTypes.func,
}

const RegisterNewPanorama = ({ setNewPanoramaSn, form: { getFieldDecorator }, stateRef, toBeActivatedApp }) => {
  return (
    <div className={'vbox setup-card-body register-panorama'}>
      <NewPanoramaInstruction />
      <div className={'hbox space-between setup-item middle'}>
        <label>Enter Panorama Serial#</label>
        <NewPanoramaSnField
          fieldName={PANORAMA_INSTANCE_FIELD}
          toBeActivatedApp={toBeActivatedApp}
          setNewPanoramaSn={setNewPanoramaSn}
          getFieldDecorator={getFieldDecorator}
          stateRef={stateRef}
          style={{ width: 200 }}
        />
      </div>
    </div>
  )
}

RegisterNewPanorama.propTypes = {
  form: PropTypes.object,
  new_panorama_sn: PropTypes.string,
  toBeActivatedApp: PropTypes.array,
  stateRef: PropTypes.any,
  setNewPanoramaSn: PropTypes.func,
  getFieldDecorator: PropTypes.func,
}

export default RegisterNewPanorama
