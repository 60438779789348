/**
 * Created by vsuthar on 6/4/20
 * Project: App Portal ©Palo Alto Networks
 */
import React from 'react'
import PropTypes from 'prop-types'
import ListItem from '../ListItem'
import classNames from 'classnames'
import './setupMiscItem.scss'

const SetupMiscItem = ({ label, value, labelProps = {}, valueProps, wrapperClass = 'hbox center space-around', suffixReactNode }) => {
  const clsNames = classNames('setup-label', labelProps?.classNames)
  wrapperClass = classNames('setup-misc-items', wrapperClass)
  return value ? (
    <div className={wrapperClass}>
      <div {...labelProps} className={clsNames}><b>{label}</b></div>
      <div className={'misc-value'} {...valueProps} >{<ListItem>{value}{suffixReactNode}</ListItem>}</div>
    </div>
  ) : null
}
SetupMiscItem.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  labelProps: PropTypes.object,
  valueProps: PropTypes.object,
  wrapperClass: PropTypes.string,
  suffixReactNode: PropTypes.object,
}
export default SetupMiscItem
