/**
 * Created by vsuthar on 4/13/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, {
  useState,
  useMemo,
  useImperativeHandle,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react'
import { Radio, Select } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import {
  renderInstances,
  useMemoFieldDecorator,
  isRunningOrProvisioningInstance,
  useValueRef,
  hasAddon,
  validatePanoramaSerial,
  FT,
} from '../../../utils/activateUtils'
import './chooseHowToManage.scss'
import {
  SETUP_NEW_ADELPHI,
  SETUP_USE_EXISTING_ADELPHI,
  SETUP_NEW_PANORAMA,
  SETUP_USE_EXISTING_PANORAMA,
  SETUP_CREATE_NEW,
  LGS_ID,
  PRISMA_ACCESS,
  PA_EXT_APPS,
  PRISMA_ACCESS_PANORAMA,
  PRISMA_ACCESS_EDITION,
  PRISMA_ACCESS_FOR_CLEAN_PIPE,
  SETUP_ADDON_APP_DLP,
  DLP,
  RUNNING,
  SETUP_ADDON_APP_IOT,
  SETUP_DONT_SUPPORT_IoT,
  SETUP_NGPA,
  SETUP_FEDRAMP_PAE,
  CHOOSE_HOW_TO_MANAGE_FIELD,
} from '../../../constants/AppConstants'
import ListItem from '../../../components/ListItem'

const radioStyle = {
  height: 50,
  lineHeight: '50px'
}
const border = {
  border: '1px dashed #D4D4D4'
}

const PAE_ONPREM = `${PRISMA_ACCESS_EDITION}_onprem`
const NEW_AUTHCODE = SETUP_CREATE_NEW

const renderOptions = tenants => tenants.map(({ key, group, display, extra, ...props }) => (
  <Select.Option {...props} key={key}>
    <ListItem toolTipProps={{ placement: 'right' }}>{display} {extra}</ListItem>
  </Select.Option>
))
const renderOptionGroups = optionsGroups => optionsGroups.map(([region, tenants]) => {
  return !region ? renderOptions(tenants) : <Select.OptGroup key={region} label={region}>
    {renderOptions(tenants)}
  </Select.OptGroup>
})


/**
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{readonly panorama_options?: *,
 * readonly hasDLPAddon?: *,
 * readonly onChange?: *,
 * readonly value?: *,
 * readonly prisma_access_options?: *,
 * readonly hasIoTAddon?: *,
 * readonly name?: *,
 * readonly onSelect?: *,
 * readonly lockedPAETenantId?: *}> & React.RefAttributes<unknown>>}
 */

const ChooseHowToManageRadioGroup = React.forwardRef(({
  name,
  value,
  lockedPAETenantId,
  onChange,
  onSelect,
  prisma_access_options,
  panorama_options,
  hasIoTAddon,
  getAnyApp,
  isNGPA,
  hasDLPAddon,
  hasMSP,
  isFederalLicense,
  currentSupportAccount,
}, ref) => {

  const [radioValue, adelpiInitValue, panoramaInitValue] = useMemo(() => {
    if (!value) {
      return [value, '', '']
    }
    const [choice, option] = value.split(':', 2)
    if (option === SETUP_CREATE_NEW) {
      return [value, '', '']
    }
    const radioValue = `${choice}:`
    const adelpiInitValue = option && radioValue === SETUP_USE_EXISTING_ADELPHI ? option : ''
    const panoramaInitValue = option && radioValue === SETUP_USE_EXISTING_PANORAMA ? option : ''
    return [radioValue, adelpiInitValue, panoramaInitValue]
  }, [value])

  const FT_BY_CSP_FEDRAMP_FAWKES = useMemo(() => {
    return FT.bySupportAccount('FEDRAMP_FAWKES', currentSupportAccount)
  }, [currentSupportAccount])

  const [adelpiValue, setAdelphiValue] = useState(adelpiInitValue)
  const [panoramaValue, setPanoramaValue] = useState(panoramaInitValue)
  const [manageMethod, setManageMethod] = useState('ANY')


  const getValue = useCallback((selected) => {
    switch (selected) {
      case SETUP_USE_EXISTING_ADELPHI:
        return `${selected}${adelpiValue}`
      case SETUP_USE_EXISTING_PANORAMA:
        return `${selected}${panoramaValue}`
      default:
        return selected
    }
  }, [adelpiValue, panoramaValue])

  const handleRadioChange = useCallback((e) => {
    const value = getValue(e.target.value)
    onChange(value)
    if (onSelect && !value.endsWith(':')) {
      onSelect(value)
    }
  }, [onChange, onSelect, getValue])

  const handleAdelphiChange = (v) => {
    setAdelphiValue(v)
    const val = `${SETUP_USE_EXISTING_ADELPHI}${v}`
    onChange(val)
    if (onSelect) {
      onSelect(val)
    }
  }
  const handlePanoramaChange = (v) => {
    setPanoramaValue(v)
    const val = `${SETUP_USE_EXISTING_PANORAMA}${v}`
    onChange(val)
    if (onSelect) {
      onSelect(val)
    }
  }
  /**
   * find prisma_access_panorama or prisma_access tenant, based on license tenant_id
   */
  const findShellTenant = (licenseTid) => {
    const pae_onprem = `${PRISMA_ACCESS_EDITION}_onprem`
    const prisma_access_panorama = getAnyApp(pae_onprem)?.tenants?.find(each => each.tenant_id === lockedPAETenantId)?.associations?.[PRISMA_ACCESS_PANORAMA]
    const fawkesTenant = getAnyApp(PRISMA_ACCESS_EDITION)?.tenants?.find(each => each.tenant_id === lockedPAETenantId)?.associations?.[PRISMA_ACCESS]
    return (prisma_access_panorama ?? fawkesTenant) || {}
  }
  useLayoutEffect(() => {
    if (hasMSP) {
      handleRadioChange({ target: { value: SETUP_NEW_ADELPHI } })
    }
  }, [hasMSP, handleRadioChange])

  /**
   * LST-4058
   */
  useLayoutEffect(() => {
    if (Boolean(lockedPAETenantId)) {
      const { app_id, tenant_id, region } = findShellTenant(lockedPAETenantId)
      if (!tenant_id) {
        return
      }
      // Panorama managed
      if (app_id === PRISMA_ACCESS_PANORAMA && value !== `prisma_access_panorama:${tenant_id}|${tenant_id}|${region}`) {
        // format: tenant_id|serial_number|region
        value = `${tenant_id}|${tenant_id}|${region}` // eslint-disable-line react-hooks/exhaustive-deps
        handlePanoramaChange(value)
        setManageMethod(PRISMA_ACCESS_PANORAMA)
      }
      // Fawkes managed
      else if (app_id === PRISMA_ACCESS && value !== `prisma_access:${tenant_id}||${region}`) {
        value = `${tenant_id}||${region}` // eslint-disable-line react-hooks/exhaustive-deps
        handleAdelphiChange(value)
        setManageMethod(PRISMA_ACCESS)
      }
    }
  }, [lockedPAETenantId, getAnyApp, handlePanoramaChange, value])

  useImperativeHandle(ref, () => ({}), [])

  return (
    <Radio.Group name={name}
      className={'vbox choose-how-manage setup-card-body'}
      value={radioValue} onChange={handleRadioChange}>
      { prisma_access_options.length > 0 &&
        <div className={'vbox'}>
          <Radio disabled={hasIoTAddon || manageMethod === PRISMA_ACCESS_PANORAMA || Boolean(hasMSP) || (!FT_BY_CSP_FEDRAMP_FAWKES && isFederalLicense)}
            style={radioStyle} value={SETUP_USE_EXISTING_ADELPHI} className={'hbox middle'}>
            Use existing Cloud-Based Management Console
            <Select
              style={{ width: 230, marginLeft: 10 }}
              disabled={radioValue !== SETUP_USE_EXISTING_ADELPHI || Boolean(lockedPAETenantId)}
              value={adelpiValue}
              onChange={handleAdelphiChange}>
              {prisma_access_options}
            </Select>
          </Radio>
        </div>

      }
      { <div className={'vbox'}>
        <Radio style={radioStyle}
          disabled={hasIoTAddon || lockedPAETenantId || (!FT_BY_CSP_FEDRAMP_FAWKES && isFederalLicense)}
          value={SETUP_NEW_ADELPHI}>
          Create new Cloud-Based Management Console
        </Radio>
        <div style={border}/>
      </div>
      }
      {hasIoTAddon && <p style={{ paddingLeft: 24, opacity: 0.6, fontSize: 12 }}>{SETUP_DONT_SUPPORT_IoT}</p>}
      {(!FT_BY_CSP_FEDRAMP_FAWKES && isFederalLicense) && <p style={{ paddingLeft: 24, opacity: 0.6, fontSize: 12 }}>{SETUP_FEDRAMP_PAE}</p>}
      {isNGPA ? <p style={{ paddingLeft: 24, opacity: 0.6, fontSize: 12 }}>{SETUP_NGPA}</p> : <></>}

      {panorama_options.length > 0 &&
        <Radio style={radioStyle} value={SETUP_USE_EXISTING_PANORAMA} disabled={manageMethod === PRISMA_ACCESS || hasMSP || isNGPA}>
          Use existing Panorama
          <Select
            style={{ width: 300, marginLeft: 10 }}
            disabled={radioValue !== SETUP_USE_EXISTING_PANORAMA || Boolean(lockedPAETenantId) || Boolean(hasMSP)}
            value={panoramaValue}
            onChange={handlePanoramaChange}
          >
            {panorama_options}
          </Select>
        </Radio>
      }
      <Radio style={radioStyle} value={SETUP_NEW_PANORAMA} disabled={Boolean(lockedPAETenantId) || Boolean(hasMSP) || isNGPA}>
        Set up new Panorama
      </Radio>
    </Radio.Group>
  )
})
ChooseHowToManageRadioGroup.displayName = 'ChooseHowToManageRadioGroup'

/**
 *
 * @type {{
 * hasIoTAddon: *,
 * prisma_access_options,
 * onChange,
 * hasMSP: *,
 * name,
 * hasDLPAddon: *,
 * getAnyApp,
 * panorama_options,
 * lockedPAETenantId,
 * value,
 * onSelect,
 * isNGPA, check if this activation is Next Gen Prisma Access
 * onSelect
 * }}
 */
ChooseHowToManageRadioGroup.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  prisma_access_options: PropTypes.array,
  panorama_options: PropTypes.array,
  hasIoTAddon: PropTypes.bool,
  hasDLPAddon: PropTypes.bool,
  lockedPAETenantId: PropTypes.string,
  getAnyApp: PropTypes.func,
  isNGPA: PropTypes.bool,
  hasMSP: PropTypes.bool,
  isFederalLicense: PropTypes.bool,
  currentSupportAccount: PropTypes.number,
}

const fieldName = CHOOSE_HOW_TO_MANAGE_FIELD
const ChooseHowToManage = ({
  getAnyApp,
  devices,
  toBeActivatedApp,
  chooseHowToManage,
  currentStepObj,
  stateRef,
  form: { setFieldsValue, getFieldDecorator },
  form,
  hasMSP,
}) => {
  const { setupValues: { value } = {} } = currentStepObj
  //keeping for debug
  /*toBeActivatedApp.find(each => each.app_id === PRISMA_ACCESS_EDITION).lockedInfo = {
    support_account_id: '211652',
    tenant_id: '4664374095988441088'
  }*/

  /**
   * For now fawkes do not support IoT or DLP as addon
   * @type {boolean}
   */
  const { hasDLPAddon, hasIoTAddon, lockedPAETenantId } = useMemo(() => {
    const hasDLPAddon = hasAddon(toBeActivatedApp, PRISMA_ACCESS_EDITION, SETUP_ADDON_APP_DLP)
    const hasIoTAddon = hasAddon(toBeActivatedApp, PRISMA_ACCESS_EDITION, SETUP_ADDON_APP_IOT)
    /**
     * For now only pae tenant allowed. @lockedInfo coming from extractLicense. Please visit extractLicense server code
     * @type {*}
     */
    const lockedPAETenantId = toBeActivatedApp?.find(each => each.app_id === PRISMA_ACCESS_EDITION && each.checked && each?.lockedInfo)?.lockedInfo?.tenant_id
    return { hasDLPAddon: Boolean(hasDLPAddon), hasIoTAddon: Boolean(hasIoTAddon), lockedPAETenantId }
  }, [toBeActivatedApp])

  /**
   *  APPORTAL-4827 read NGPA hashes from Fawkes App Manifest and block panorama activation
   */
  const isNGPA = useMemo(() => {
    const { context, licenseHash } = stateRef.current
    if (context === PRISMA_ACCESS) {
      // TODO: read from toBeActivatedApp once IT sends
      const paeAppDef = toBeActivatedApp?.find(app => app.app_id === PRISMA_ACCESS_EDITION)
      const ngpaHashList = paeAppDef?.extra?.ngpa_hash_list || []
      return ngpaHashList.includes(licenseHash)
    }
    return false
  }, [toBeActivatedApp, stateRef])

  // APPORTAL-4849 check if magiclink is for fedramp
  const isFederalLicense = useMemo(() => {
    return toBeActivatedApp.some(app => app.is_federal_license)
  }, [toBeActivatedApp])

  const currentSupportAccount = useMemo(() => {
    return Number(stateRef.current.supportAccounts.get(stateRef.current.setupSelectedAccount)?.support_account_id)
  }, [stateRef])

  const handleSelect = useCallback((value) => {
    chooseHowToManage(value)
  }, [chooseHowToManage])
  const [selectedAdelphiExtApps, selectedPanoramaExtApps] = useMemo(() => {
    const appIdSet = new Set(toBeActivatedApp.filter(app => app.checked).map(app => app.app_id))
    const hasAppId = appIdSet.has.bind(appIdSet)
    // APPORTAL-3679 LST-3236
    if (hasAppId(PRISMA_ACCESS_EDITION)) { // if has edition, not allow others
      for (const appId of PA_EXT_APPS) {
        appIdSet.add(appId)
      }
    }
    else if (PA_EXT_APPS.some(hasAppId)) { // if has others, not allow edition
      appIdSet.add(PRISMA_ACCESS_EDITION)
    } // but allow the exts not selected
    const filteredExtApps = PA_EXT_APPS.filter(hasAppId)
    return [
      filteredExtApps.filter(appId => appId !== PRISMA_ACCESS_FOR_CLEAN_PIPE).join(','), // CPIPE is not supported for cloud
      filteredExtApps.map(n => `${n}_onprem`).join(','),
    ]
  }, [toBeActivatedApp])
  const renderedAdelphiInstances = useMemo(() => {
    const rendered = renderInstances({
      config: {
        app_id: PRISMA_ACCESS,
        single_association: selectedAdelphiExtApps.split(','),
        isDisabledFn: (t) => t.tenant_id.endsWith('_onprem'),
      },
      appInfoMap: { // no need the full version from activate utils
        getAnyApp,
        getInstances: appId => getAnyApp(appId)?.tenants || [],
      },
      renderOptions,
      renderOptionGroups,
    })
    return rendered
  }, [getAnyApp, selectedAdelphiExtApps])

  const renderedPanoramaDevices = useMemo(() => {
    const getInstances = (appId) => {
      const instances = getAnyApp(appId)?.tenants || []
      if (appId === PRISMA_ACCESS_PANORAMA) {
        // the entitlements will includes panoarama not available in cob yet which does not have region or status is registered
        // those should be treated as existing panorama devices but not pa-panorama
        const paPanoramaInstances = instances.filter(inst => inst.region).map(({
          tenant_id, tenant_instance_name, serial_number, region, instance_status, associations, extra, no_access, used_by,
        }) => {
          const platform_id = associations?.[LGS_ID]?.tenant_id || extra?.platform_id
          if (instance_status === 'registered') { // not in cob or not as pa-panorama yet
            return {
              tenant_id,
              serial_number,
              region,
              instance_status: RUNNING, // show it will be available to select
              auth_code: NEW_AUTHCODE,
              platform_id,
              // no_access, // always allow to select since it is from csp only
              value: `${tenant_id}|${serial_number}|${region}|${NEW_AUTHCODE}`, // override
            }
          }
          return {
            tenant_id,
            tenant_display_name: tenant_instance_name && !tenant_instance_name?.includes(serial_number) ?
              `${tenant_instance_name} (${serial_number})` : tenant_instance_name || serial_number,
            serial_number,
            region,
            platform_id,
            instance_status, // non-running/provisioning will be disabled
            no_access,
            ...(used_by && { used_by }),
          }
        })
        const paSnSet = new Set(paPanoramaInstances.map(t => t.serial_number).filter(Boolean))
        const paPanoCdlSet = new Set(paPanoramaInstances.map(dev => dev.platform_id).filter(Boolean))
        const notActivatedYetPanorama = devices.filter(dev =>
          dev.serial_number && !paSnSet.has(dev.serial_number) && // skip activated pa-panorama
          !(dev.platform_id && paPanoCdlSet.has(dev.platform_id)) // skip sec-panorama use same cdl as activated
        ).map(({ serial_number }) => ({ // including registed panorama with or without cdl
          tenant_id: SETUP_CREATE_NEW, // as long as panorama not yet in cob, we treat it as new
          serial_number,
          instance_status: RUNNING, // so it will always showing up
        }))

        const combinedFiltered = paPanoramaInstances.concat(notActivatedYetPanorama).filter(t => !t.no_access)
        return combinedFiltered
      }
      return instances
    }
    const extraTenantInfoRenderFn = (inst) => (
      inst.region && !isRunningOrProvisioningInstance(inst) && '(n/a)'
    )
    const rendered = renderInstances({
      config: {
        app_id: PRISMA_ACCESS_PANORAMA,
        single_association: selectedPanoramaExtApps.split(','),
        extraTenantInfoRenderFn,
      },
      appInfoMap: { // no need the full version from activate utils
        getAnyApp,
        getInstances,
      },
      renderOptions,
      renderOptionGroups,
    })
    return rendered
  }, [devices, getAnyApp, selectedPanoramaExtApps])


  /**
   * APPORTAL-3711
   *  if DLP is already associated with panorama then dont allow user to click next. Currently DLP + Prisma Access only allow one association
   *  within CSP account.
   */
  const [accountRegisteredPAESN, accountRegisteredPAETid] = useMemo(() => {
    if (!hasDLPAddon) {
      return [undefined, undefined]
    }
    const PAEInstance = getAnyApp(PAE_ONPREM)?.tenants?.find(
      ({ associations }) => associations?.[DLP]?.tenant_id
    ) ?? getAnyApp(PRISMA_ACCESS_EDITION)?.tenants?.find(
      ({ associations }) => associations?.[DLP]?.tenant_id
    )
    return [PAEInstance?.serial_number, PAEInstance?.tenant_id]
  }, [hasDLPAddon, getAnyApp])

  const dlpRef = useValueRef(accountRegisteredPAESN)
  const fieldDecorator = useMemoFieldDecorator(fieldName, {
    initialValue: value, // default to Fawkes if it's NGPA
    validateFirst: true,
    rules: useMemo(() => {
      const requiredRule = { required: true, message: 'Choose how to manage prisma access' }
      const selectRequiredRule = {
        validator(rule, value, callback) {
          if (FT('MULTI_DLP')) {
            if (dlpRef.current && hasDLPAddon && !lockedPAETenantId && accountRegisteredPAETid) {
              // for new activation case, if found a PAE already associated with DLP, block
              callback(`There's already a PAE: ${accountRegisteredPAETid} with DLP association existed in this account`)
            }
            if (dlpRef.current && hasDLPAddon && lockedPAETenantId && accountRegisteredPAETid !== lockedPAETenantId) {
              // amend case, if the lockedTenantId is not same as the DLP's consuemr PAE, block proceed
              callback(`DLP already associated with another Prisma Access ${accountRegisteredPAETid} for this account, cannot associated with PAE ${lockedPAETenantId}`)
            }
          }
          else {
            if (dlpRef.current && hasDLPAddon && accountRegisteredPAETid !== lockedPAETenantId) {
              // only dlp associated PAE is not same as the current locked PAE, block proceed
              callback(`DLP already associated with Prisma Access ${dlpRef.current} for this account.`)
            }
          }
          if (value === SETUP_USE_EXISTING_ADELPHI) {
            callback('Prisma Access instance is required')
          }
          if (value === SETUP_USE_EXISTING_PANORAMA) {
            callback('Panorama is required')
          }
          else {
            const [mgmtAppId] = value.split(':', 1)
            const { getAnyApp, toBeActivatedApp } = stateRef.current
            const notActivatableApp = toBeActivatedApp?.filter(app =>
              app.checked && app.extension_to
            ).find((app) => {
              switch (mgmtAppId) {
                case app.extension_to:
                  return !app.activatable
                case PRISMA_ACCESS:
                  return !getAnyApp(app.app_id.replace('_onprem'))?.activatable
                case PRISMA_ACCESS_PANORAMA:
                  return !getAnyApp(`${app.app_id}_onprem`)?.activatable
                default:
                  return false
              }
            })
            if (notActivatableApp) {
              callback(`${notActivatableApp.display_name} is not available for your choice`)
            }
            else {
              callback()
            }
          }
        }
      }
      const checkSupportRule = {
        validator(rule, value) {
          if (value === SETUP_NEW_PANORAMA || !value.startsWith(SETUP_USE_EXISTING_PANORAMA)) {
            return Promise.resolve()
          }
          const [, serial_number] = value.split('|', 2)
          return validatePanoramaSerial(serial_number, stateRef.current, {
            register_new: false,
            panorama_app_id: PRISMA_ACCESS_PANORAMA,
          })
        }
      }
      return [requiredRule, selectRequiredRule, checkSupportRule]
    }, [dlpRef, hasDLPAddon, accountRegisteredPAETid, lockedPAETenantId, stateRef])
  }, getFieldDecorator)

  useEffect(() => {
    if (isNGPA) {
      setFieldsValue({ [fieldName]: SETUP_NEW_ADELPHI })
      chooseHowToManage(SETUP_NEW_ADELPHI)
    }
  }, [setFieldsValue, chooseHowToManage, isNGPA])

  return (
    fieldDecorator(
      <ChooseHowToManageRadioGroup
        hasIoTAddon={hasIoTAddon}
        lockedPAETenantId={lockedPAETenantId}
        hasDLPAddon={hasDLPAddon}
        toBeActivatedApp={toBeActivatedApp}
        name={fieldName}
        onSelect={handleSelect}
        prisma_access_options={renderedAdelphiInstances}
        panorama_options={renderedPanoramaDevices}
        getAnyApp={getAnyApp}
        isNGPA={isNGPA}
        hasMSP={hasMSP}
        isFederalLicense={isFederalLicense}
        currentSupportAccount={currentSupportAccount}
      />
    )
  )
}

ChooseHowToManage.propTypes = {
  getAnyApp: PropTypes.func,
  toBeActivatedApp: PropTypes.array,
  devices: PropTypes.array,
  currentStepObj: PropTypes.object,
  stateRef: PropTypes.any,
  form: PropTypes.object,
  chooseHowToManage: PropTypes.func,
  hasMSP: PropTypes.bool,
}

ChooseHowToManage.defaultProps = {
  getAnyApp: () => undefined,
  devices: [],
  toBeActivatedApp: [],
}

export default ChooseHowToManage
