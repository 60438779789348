/**
 * Created by vsuthar on 4/21/20
 * Project: App Portal ©Palo Alto Networks
 */

import React from 'react'
import PropTypes from 'prop-types'
import './setupFooter.scss'
import { PANWLogo } from '../../images/svg-icons'


export const footerLabel = [
  {
    0: [
      {
        label: 'Company',
        href: 'https://www.paloaltonetworks.com/company'
      },
      {
        label: 'Careers',
        href: 'https://www.paloaltonetworks.com/company/careers'
      }
    ]
  },
  {
    1: [
      {
        label: 'Privacy Policy',
        href: 'https://www.paloaltonetworks.com/legal-notices/privacy'
      },
      {
        label: 'Terms of Use',
        href: 'https://www.paloaltonetworks.com/legal-notices/terms-of-use'
      }
    ]
  },
  {
    2: [
      {
        label: 'Manage Subscription',
        href: 'https://www.paloaltonetworks.com/company/subscriptions'
      }
    ]
  },
  {
    3: [
      {
        label: 'LinkedIn',
        href: 'https://www.linkedin.com/company/palo-alto-networks'
      },
      {
        label: 'Facebook',
        href: 'https://www.facebook.com/PaloAltoNetworks/'
      },
      {
        label: 'Twitter',
        href: 'https://twitter.com/PaloAltoNtwks'
      }
    ]
  }

]

const eachCol = (arr, idx) => {
  return <div key={idx} className={'vbox footer-col'}>
    {
      arr.map((each, idx) => {
        const { label, href } = each
        return <a key={idx} href={href} rel='noopener noreferrer' target='_blank'>{label}</a>
      })
    }
  </div>

}

const SetupFooter = ({ footerLink, showLogo, showSignature }) => {
  return (
    <div className={'setup-footer vbox center'} >
      <div className={'hbox'}>
        {
          footerLink.map((each, idx) => eachCol(each[idx], idx))
        }
      </div>
      {showLogo ? <div>
        <PANWLogo className={'panw-logo'} />
      </div> : null}
      {
        showSignature ? <div className={'footer-col'}>© {new Date().getFullYear()} Palo Alto Networks, Inc. All rights reserved.</div> : null
      }

    </div>
  )
}

SetupFooter.propTypes = {
  footerLink: PropTypes.array,
  showLogo: PropTypes.bool,
  showSignature: PropTypes.bool,
}

SetupFooter.defaultProps = {
  showLogo: true,
  showSignature: true,
  footerLink: [],
}

export default SetupFooter
