import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Table, Icon } from '@pan/cloud-base'
import InputSearchBar from '../../../../components/common/InputSearchBar'
import './firewallListTable.scss'
import { SEARCH_PLACE_HOLDER } from '../../../../constants/AppConstants'
import { firewallDeviceSearch, renderAssetType } from '../../../../utils/activateUtils'
import { isNonemptyArray, indexArray } from '../../../../utils/common'
import ListItem from '../../../../components/ListItem'
import { renderCDLIcon } from './FirewallDeviceTable'
import { isFeatureEnabled as FT } from '@pan/cloud-appframework'
const theadTh = {
  textTransform: 'none',
  color: '#000000',
  fontSize: '12px',
  fontWeight: 'bold',
  backgroundColor: '#fff',
}

const trGroup = {
  minHeight: '30px',
  borderBottom: 'none',
}

const thead = {
  borderBottom: 'none',
  backgroundColor: '#fff'
}

const td = {
  fontSize: '12px',
}

const searchIconStyle = {
  color: '#a3a3a3',
  fontWeight: 900,
  fontSize: '16px'
}

const renderWithTooltip = (value) => <ListItem toolTipProps={{ placement: 'right' }}>{value || '-'}</ListItem>

const cdlDisplayHeader = () => <div>Logging Service <br/> Associated with</div>

const FirewallListTable = ({ firewallData, cdlList }) => {

  const columns = useMemo(() => [
    {
      Header: 'Model',
      accessor: 'model',
      width: 150,
    },
    {
      Header: 'Serial Number',
      accessor: 'serial_number',
      width: 200,
      Cell: ({ value }) => renderWithTooltip(value)
    },
    {
      Header: 'Type',
      accessor: 'asset_type',
      Cell: ({ value }) => renderAssetType(value),
      width: 80
    },
    {
      Header: 'Device Name',
      accessor: 'device_name',
      width: 100,
      Cell: ({ value }) => renderWithTooltip(value),
    },
    {
      Header: cdlDisplayHeader(),
      accessor: 'cdl_display_name',
      Cell: ({ value }) => {
        if (!value && FT('SHOW_CLD_COL_FW')) {
          return renderCDLIcon(value, cdlList)
        }
        return renderWithTooltip(value)
      },
      width: 50
    },
  ], [cdlList])
  const list = useMemo(() => {
    if (!isNonemptyArray(cdlList)) {
      return firewallData
    }
    indexArray(cdlList, 'tenant_id', { cached: true })
    const list = firewallData.map((fw) => {
      const {
        serial_number,
        tenant_instance_name,
        tenant_display_name = tenant_instance_name || serial_number,
      } = (fw.platform_id && cdlList.get(fw.platform_id)) || {}
      const cdl_display_name = tenant_display_name && !tenant_display_name.includes(serial_number) ?
        `${tenant_display_name} (${serial_number})` : (tenant_display_name || fw.platform_id)
      if (cdl_display_name) {
        return { ...fw, cdl_display_name }
      }
      return fw
    })
    return list
  }, [firewallData, cdlList])

  const [firewallList, setFirewallList] = useState(list)

  const handleInputSearch = (value) => {
    const searchData = value ? firewallDeviceSearch(value, list) : list
    setFirewallList(searchData)
  }

  return <>
    <InputSearchBar
      className={'fw-list-search'}
      handleInputSearch={handleInputSearch}
      placeholder={SEARCH_PLACE_HOLDER}
      prefix={<Icon type='search' style={searchIconStyle} />}
    />
    <Table
      className='fw-list-tbl'
      styles={{ thead, theadTh, trGroup, td }}
      sortable={true}
      autoScrollbar={true}
      columns={columns}
      data={firewallList}
    />
  </>
}

FirewallListTable.propTypes = {
  firewallData: PropTypes.array.isRequired,
  cdlList: PropTypes.array,
}

export default FirewallListTable
