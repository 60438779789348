import React from 'react'
import PropTypes from 'prop-types'
import { Select } from '@pan/cloud-base'
import { getRemainingTime, renderAssetType } from '../../../../utils/activateUtils'
import { SELECT, NEW_LABEL, MONTHS, DAYS, LICENSE_GROUP_LABEL, DEFAULT_NGFW_VERSION, NA } from '../../../../constants/AppConstants'

const DELIMITER = ':-:' //NTB-2263
const OptGroup = Select.OptGroup
const Option = Select.Option

const LicenseSelection = ({ data, rowInfo, selectedDevices, handleSelectOption }) => {

  let currentValue
  let isOptionExist
  const selectedDevice = selectedDevices?.find(each => each.serial_number === rowInfo.serial_number) || {}
  const isLicensePrevActivated = !(selectedDevice.license_asset_type && selectedDevice.license_term) && rowInfo.license_asset_type && rowInfo.license_term //previously activated license

  const setCurrentValue = () => {
    const { serial_number, license_asset_type, license_term, license_version } = isLicensePrevActivated ? rowInfo : selectedDevice
    return license_asset_type && license_term ? `${serial_number}${DELIMITER}${license_version}${DELIMITER}${license_asset_type}${DELIMITER}${license_term}` : (rowInfo.isDisable ? NA : SELECT)
  }

  if (isLicensePrevActivated) {
    Object.keys(data).forEach(licenseVersion => {
      Object.keys(data[licenseVersion]).forEach(asset_type => {
        if (rowInfo.license_asset_type === asset_type) {
          Object.keys(data[licenseVersion][asset_type]).forEach((key) => {
            isOptionExist = data[licenseVersion][asset_type][key].some(obj => rowInfo.license_term === obj.term)
          })
        }
      })
    })
    //for eval/trial to non-eval/trial upgrade, user will have new magic link which will not have previously activated license info, hence add default ghost option value for display
    currentValue = isOptionExist ? setCurrentValue() : getRemainingTime(rowInfo.license_term, rowInfo.license_unit)
  }
  else {
    currentValue = setCurrentValue()
  }

  const handleChange = (value) => {
    const [serialNumber, licenseVersion, licenseAssetType, licenseTerm] = value.split(DELIMITER)
    handleSelectOption(serialNumber, licenseVersion, licenseAssetType, licenseTerm)
  }

  const optionGroupLabel = (asset_type, key, licenseVersion) => {
    const prefix = licenseVersion !== DEFAULT_NGFW_VERSION ? `${LICENSE_GROUP_LABEL[licenseVersion]} - ` : ''
    return `${prefix}${renderAssetType(asset_type)} - ${(key)}`
  }

  const selectionGroup = () => {
    const template = []
    Object.keys(data).forEach(licenseVersion => {
      Object.keys(data[licenseVersion]).forEach(asset_type => {
        Object.keys(data[licenseVersion][asset_type]).forEach((key) => {
          template.push(
            <OptGroup key={`${licenseVersion}${DELIMITER}${asset_type}${DELIMITER}${key}`} label={optionGroupLabel(asset_type, key, licenseVersion)} >
              {
                key === NEW_LABEL ? data[licenseVersion][asset_type][key].map(obj => {
                  return <Option key={`${licenseVersion}${DELIMITER}${key}${DELIMITER}${obj.term}`} value={`${rowInfo.serial_number}${DELIMITER}${licenseVersion}${DELIMITER}${asset_type}${DELIMITER}${obj.term}`} disabled={obj.isDisable}>{getRemainingTime(obj.term, MONTHS)}</Option>
                }) : data[licenseVersion][asset_type][key].map(obj => {
                  return <Option key={`${licenseVersion}${DELIMITER}${asset_type}${DELIMITER}${key}${DELIMITER}${obj.term}`} value={`${rowInfo.serial_number}${DELIMITER}${licenseVersion}${DELIMITER}${asset_type}${DELIMITER}${obj.term}`} disabled={obj.isDisable}>{getRemainingTime(obj.term, DAYS)}</Option>
                })
              }
            </OptGroup>
          )
        })
      })
    })
    return template
  }

  return (
    <Select onChange={handleChange} disabled={rowInfo.isDisable} value={currentValue} dropdownClassName='license-select'>
      {selectionGroup()}
    </Select>
  )
}

LicenseSelection.propTypes = {
  data: PropTypes.object.isRequired,
  rowInfo: PropTypes.PropTypes.shape({
    isDisable: PropTypes.bool,
    serial_number: PropTypes.string.isRequired,
    license_asset_type: PropTypes.string,
    license_unit: PropTypes.string,
    license_term: PropTypes.number,
  }),
  selectedDevices: PropTypes.array,
  handleSelectOption: PropTypes.func,
}

export default LicenseSelection
