/**
 * Created by vsuthar on 6/8/20
 * Project: App Portal ©Palo Alto Networks
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from '@pan/cloud-base'
import { ROLES } from '../../constants/RouteConstants'
import { UserEdit } from '../../images/svg-icons'

const SetUpManageRoleBtn = ({ pushHistoryWithRefresh, cssClass }) => {

  const manageRole = (e) => {
    e.preventDefault()
    pushHistoryWithRefresh(ROLES)
  }
  return (
    <Button v2={false} type={'primary'} onClick={manageRole}>
      <div className={`hbox middle space-between ${cssClass}`}>
        <span className={'middle'}>
          <UserEdit style={{ height: 18, margin: 10 }} />
          Assign Role-based Access Control
        </span>
        <Icon type='right-circle' theme='filled' />
      </div>
    </Button>
  )
}

SetUpManageRoleBtn.propTypes = {
  pushHistoryWithRefresh: PropTypes.func,
  cssClass: PropTypes.string
}

export default SetUpManageRoleBtn
