import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  useMemoFieldDecorator,
  indexArray,
  FT,
  hasOnlyIoTPackageTwoActivation,
} from '../../../utils/activateUtils'
import FirewallDetails from '../common/firewall_selection/FirewallDetails'
import FirewallModal from '../common/firewall_selection/FirewallModal'
import FirewallListTable from '../common/firewall_selection/FirewallListTable'
import {
  LGS_ID,
  NGFW,
  IOT_FIREWALL_LIST_TITLE,
  IOT_FIREWALL_LIST_SUBTITLE,
  ZINGBOX_NGFW,
  SETUP_CREATE_NEW,
  ZINGBOX_FIELD,
  ELA,
  ZINGBOX,
} from '../../../constants/AppConstants'
// import { licenseData, deviceData } from '../iot/mockData'
import FirewallSelection from '../common/ela/FirewallSelection'

const CDL_DOCS_LINK = 'https://docs.paloaltonetworks.com/cortex/cortex-data-lake/cortex-data-lake-getting-started.html'

const FirewallStep = ({
  form,
  formValues,
  session,
  devices,
  getAnyApp,
  setFirewallModalVisible,
  isFirewallListVisible,
  cdlInstance,
  entitledAppsList,
  setSelectedFirewallDevices,
  selectedFirewallDevices,
  supportAccounts,
  setupSelectedAccount,
  toBeActivatedApp,
  context,
  mode,
  stateRef,
}) => {
  const zingboxNgfwLicenseData = useMemo(() => session?.licenses?.find(license => license.app_id === ZINGBOX_NGFW), [session])
  const zingboxNgfwEntitlements = useMemo(() => indexArray(entitledAppsList.get(ZINGBOX_NGFW)?.tenants, 'serial_number'), [entitledAppsList]) // TODO: may need to use devices[0]
  const cdlList = useMemo(() => getAnyApp(LGS_ID)?.tenants, [getAnyApp])

  const zingboxTenantId = useMemo(() => formValues[ZINGBOX_FIELD]?.split(/[:|]/, 2)[1] || SETUP_CREATE_NEW, [formValues])
  // check if devices are already associated with zingbox, if associated - add to deviceData only if selected zingbox tenant matches with association

  const filteredDevices = useMemo(() => {
    const { tenant_id: platformId, region } = cdlInstance || {}
    let filterDevices = devices.filter(device => device.app_id === NGFW)
    if (!hasOnlyIoTPackageTwoActivation(toBeActivatedApp)) { // for non IoT 2.0 flow device and cdl filtration - APPORTAL-4569
      filterDevices = filterDevices.filter(device => {
        const deviceCdl = device.fls_tenant_id || device.platform_id
        return !deviceCdl || platformId === deviceCdl
      })
    }
    else if (FT('IOT_2_DEVICE_FILTER') && hasOnlyIoTPackageTwoActivation(toBeActivatedApp)) { // for IoT 2.0 flow filter devices with no region or matching to ingestion region only
      // IoT 2.0 flow filter devices with no region or matching to ingestion region only
      filterDevices = filterDevices.filter(device => !device?.region || device?.region === region)
    }
    return filterDevices.reduce((result, device) => {
      const tenant = zingboxNgfwEntitlements.get(device.serial_number)
      const zingbox = tenant?.associations?.zingbox?.tenant_id
      if (!zingbox || zingbox === zingboxTenantId) {
        result.push(device)
      }
      return result
    }, [])
  }, [devices, cdlInstance, zingboxNgfwEntitlements, zingboxTenantId, toBeActivatedApp])

  const renderModalSubTitle = useMemo(() => {
    const cspAccName = supportAccounts.get(+setupSelectedAccount)?.support_account_name || ''
    const [str1, str2] = IOT_FIREWALL_LIST_SUBTITLE.split('__ACCOUNT_NAME__')
    const [str3, str4] = str2.split('__STEPS_LINK__')
    return <>
      <span>{str1}</span>
      <span style={{ fontWeight: 'bold' }}>{cspAccName}</span>
      <span>{str3}</span>
      < a href={CDL_DOCS_LINK} target='_blank' rel='noopener noreferrer' ><b>steps</b></a>
      <span>{str4}</span>
    </>
  }, [supportAccounts, setupSelectedAccount])

  const fieldDecorator = useMemoFieldDecorator('firewalls', {
    initialValue: selectedFirewallDevices,
    valuePropName: 'value',
    rules: useMemo(() => [{
      validator(rule, value, callback) {
        if (!value.length) {
          callback('Please select a device')
        }
        else if (value.length > FT('FIREWALL_BATCH_LIMIT', 5)) { //APPORTAL-4069
          callback(`IoT Security subscriptions can be activated on a maximum of ${FT('FIREWALL_BATCH_LIMIT')} firewalls at once`)
        }
        else if (value.some(item => !item.auth_code)) {
          callback('Unable to apply firewall license')
        }
        else {
          callback()
        }
      }
    }], [])
  }, form)

  return <>
    {mode === ELA ? fieldDecorator(
      <FirewallSelection
        licenseData={zingboxNgfwLicenseData}
        deviceData={filteredDevices}
        entitlementTenants={zingboxNgfwEntitlements}
        selectedFirewallDevices={selectedFirewallDevices}
        setSelectedFirewallDevices={setSelectedFirewallDevices}
        context={ZINGBOX}
      />
    ) : fieldDecorator(
      <FirewallDetails
        licenseData={zingboxNgfwLicenseData}
        deviceData={filteredDevices}
        entitlementTenants={zingboxNgfwEntitlements}
        selectedFirewallDevices={selectedFirewallDevices}
        setSelectedFirewallDevices={setSelectedFirewallDevices}
        zingboxTenantId={zingboxTenantId}
        toBeActivatedApp={toBeActivatedApp}
        context={context}
        currentStepObj={stateRef.currentStepObj}
      />
    )}
    <FirewallModal
      headers={{
        title: IOT_FIREWALL_LIST_TITLE,
        subTitle: renderModalSubTitle,
      }}
      body={<FirewallListTable firewallData={devices} cdlList={cdlList} />}
      visible={isFirewallListVisible}
      setFirewallModalVisible={setFirewallModalVisible}
    />
  </>
}

FirewallStep.defaultProps = {
  isFirewallListVisible: false
}

FirewallStep.propTypes = {
  form: PropTypes.object,
  formValues: PropTypes.object,
  session: PropTypes.shape({
    licenses: PropTypes.array.isRequired
  }).isRequired,
  devices: PropTypes.array.isRequired,
  setFirewallModalVisible: PropTypes.func,
  isFirewallListVisible: PropTypes.bool,
  getAnyApp: PropTypes.func,
  cdlInstance: PropTypes.object,
  entitledAppsList: PropTypes.array.isRequired,
  selectedFirewallDevices: PropTypes.array.isRequired,
  setSelectedFirewallDevices: PropTypes.func,
  supportAccounts: PropTypes.array.isRequired,
  setupSelectedAccount: PropTypes.number.isRequired,
  toBeActivatedApp: PropTypes.array.isRequired,
  context: PropTypes.string.isRequired,
  mode: PropTypes.string,
  stateRef: PropTypes.any,
}

export default FirewallStep

