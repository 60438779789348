import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { duration } from 'moment'
import Tooltip from '../../../components/common/Tooltip'

const TIMER_INTERVAL = 1000

const displayDuration = (ms, suffix) => {
  const dur = duration(ms, 'ms')
  const estS = dur.seconds()
  const estH = Math.floor(dur.asHours())
  if (estH > 0) {
    return `${estH} ${estH > 1 ? 'hours' : 'hour'} ${suffix}`
  }
  return `${dur.minutes() || 0}:${String(estS).padStart(2, '0')} ${suffix}`
}

const useCurrentTimestamp = (interval) => {
  const [ts, setTs] = useState(Date.now())

  useEffect(() => {
    if (interval > 0) {
      const id = setInterval(() => {
        setTs(Date.now())
      }, interval)
      return () => clearInterval(id)
    }
  }, [interval])

  return ts
}

const TimeRemaining = ({ expiration, setExpired }) => {
  const [expTs, expToolTip] = useMemo(() => {
    const date = expiration ? new Date(expiration) : null
    const ts = +date
    if (!ts) {
      return [null, null]
    }
    const expToolTip = `Valid before ${ts - Date.now() > 3600000 ? date.toLocaleString() : date.toLocaleTimeString()}`
    return [ts, expToolTip]
  }, [expiration])
  const ts = useCurrentTimestamp(TIMER_INTERVAL)
  const [expTimer, expired] = useMemo(() => {
    if (expTs && expTs - ts >= TIMER_INTERVAL) {
      return [displayDuration(expTs - ts - TIMER_INTERVAL, 'left'), false]
    }
    return ['Expired', true]
  }, [ts, expTs])
  useEffect(() => { // call only when expired changed
    setExpired(expired)
  }, [expired, setExpired])

  if (expired) {
    return <div className='otp-psk-expiration'>expired</div>
  }
  return <Tooltip title={expToolTip} placement={'top'}>
    <div className='otp-psk-expiration'>{expTimer}</div>
  </Tooltip>
}
TimeRemaining.propTypes = {
  expiration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setExpired: PropTypes.func,
}

export default TimeRemaining
