/**
 * Created by vsuthar on 4/8/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, Steps, Button, Form, Message, Tooltip } from '@pan/cloud-base'
import { utils } from '@pan/cloud-appframework'
import _ from 'lodash'
import SetupWrapper from './SetupForm'
import { KeyIcon } from '../../images/svg-icons'
import './setupbase.scss'
import SetUpErrorCard from '../../components/Setup/SetUpErrorCard'
import SetupHelp from '../../components/Setup/SetupHelp'
import FormStepComponent from './FormStepComponent'
import NextButton from './NextButton'
import {
  activate,
  indexArray,
  isNonemptyArray,
  useAxiosRequest,
  useMemoForm,
  hasAddon,
  FT,
  appDomainPostfix,
  omitEmptyValues, DSS_ID, hasAccountAccess,
} from '../../utils/activateUtils'
import {
  SETUP_CREATE_NEW,
  PA_PANORAMA,
  PA_EXT_APPS,
  LGS_ID,
  SETUP_CTX_CDL,
  ZINGBOX,
  NOT_ALLOWED_ACTIVATE_ALONE_APPS_SET,
  CHOOSE_LICENSES_FIELD,
  PRISMA_ACCESS_EDITION,
  PRISMA_ACCESS,
  PRISMA_SAAS,
  REGION_FIELD,
  APP_REGION_FIELD,
  ELA,
  SETUP_ADDON_APP_IOT,
  SETUP_ADDON_APP_SAAS_INLINE, PRISMA_ACCESS_PANORAMA, SETUP_ADDON_APP_DLP, SETUP_ADDON_OKYO, SETUP_CTX_PRIMARY_APPS,
} from '../../constants/AppConstants'
import { SERVICE_UNAVAILABLE } from '../../constants/ErrorCode'
import { CDL } from '../../../../server/src/constants'

const { showLoginDialog } = utils
const { Step } = Steps
const EMPTY_DEVICES = Object.freeze(indexArray())
const EMPTY_STEPS = []
const EMPTY_FIELDS = []
const EMPTY_STEP_OBJ = {}
const EMPTY_STYLE = {}
const EMPTY_INSTANCES = {}
const DEFAULT_BACK_BTN_PROPS = { label: 'Back', v2: false }
const DEFAULT_NEXT_BTN_PROPS = { label: 'Next', v2: false }

const SetupBase = ({
  form: rawForm,
  hash,
  context,
  mode,
  states,
  stateRef,
  error,
  // session,
  activateAuthCode,
  supportAccounts,
  selectedAccount,
  onNext,
  onBack,
  onProductChecked,
  onAccountSelect,
  setLoading,
  isLoading,
  onCDLChange,
  onActivateStarted,
  chooseHowToManage,
  setDevices,
  setNewPanoramaSn,
  setFirewallListVisible,
  setSelectedFirewallDevices,
  overrideContext,
  setError,
  setClcsTenant,
  keyByMsspAccounts,
  msspAccounts,
  hasMSP,
  prisma31Release,
  sgGroups
}) => {
  const {
    currentStepObj = EMPTY_STEP_OBJ,
    currentStep,
    steps = EMPTY_STEPS,
    toBeActivatedApp,
    isDrawerOpen,
    stepsHelp,
    context: currentContext = context,
  } = states
  const form = useMemoForm(rawForm)
  const { getFieldsError, validateFields } = form
  const {
    title = '',
    backBtnProps = DEFAULT_BACK_BTN_PROPS,
    nextBtnProps = DEFAULT_NEXT_BTN_PROPS,
    icon = KeyIcon,
    responsiveConfig = '',
    style = EMPTY_STYLE,
    formFields = EMPTY_FIELDS,
  } = currentStepObj || EMPTY_STEP_OBJ

  const isStandaloneCdl = FT('STANDALONE_CDL') && context === CDL

  const description = useMemo(() => {
    if (FT('ELA_IOT_SAAS') && mode === ELA) {
      return currentStepObj?.ela_description || currentStepObj?.description || ''
    }
    return currentStepObj?.description || ''
  }, [currentStepObj, mode])

  /**
  *  APPORTAL-4827 read NGPA hashes from Fawkes App Manifest
  */
  const isNGPA = useMemo(() => {
    const { context, licenseHash, toBeActivatedApp } = states
    if (context === PRISMA_ACCESS) {
      // TODO: read from toBeActivatedApp once IT sends
      const paeAppDef = toBeActivatedApp?.find(app => app.app_id === PRISMA_ACCESS_EDITION)
      const ngpaHashList = paeAppDef?.extra?.ngpa_hash_list || []
      return ngpaHashList.includes(licenseHash)
    }
    return false
  }, [states])

  const isLastStep = currentStep >= steps.length - 1
  const activateHandler = useCallback(() => {
    const {
      session,
      productToBeActivated,
      formValues: {
        subdomain,
        description,
        region: regionValue,
        instances = EMPTY_INSTANCES,
        extra,
        [APP_REGION_FIELD]: appRegion,
        [REGION_FIELD]: dataLakeRegion,
        tsg_id
      },
      contextApp,
      context: hash_context = contextApp?.setup?.context,
      setupSelectedAccount,
    } = stateRef.current
    const support_account_id = supportAccounts.get(+setupSelectedAccount)?.support_account_id
    if (!support_account_id) { // TODO: remove if we can eliminate the root cause
      // eslint-disable-next-line no-console
      console.error('failed to select account', setupSelectedAccount, supportAccounts)
      Message.error('Selected account is not available', 3)
      return
    }
    for (const {
      value,
      app_id,
      tenant_id = '',
      region = '',
      auth_code = '',
      serial_number = '',
    } of productToBeActivated) {
      if (value && serial_number && value.startsWith(`${serial_number}|`) && value.endsWith(`|${SETUP_CREATE_NEW}`)) { // for panorama already has CDL but not in cob
        instances[app_id] = `${SETUP_CREATE_NEW}|${serial_number}|${region}` // authcode not needed here, since cdl already mapped
      }
      else if (value && value !== SETUP_CREATE_NEW && !value.endsWith('|')) {
        instances[app_id] = value
      }
      else {
        instances[app_id] = `${tenant_id}|${serial_number}|${region}|${auth_code}`
      }
    }
    const region = regionValue || productToBeActivated.find(({ region }) => region)?.region
    if (!region) { // just in case
      Message.error('Region is required', 3)
      return
    }
    const domain_postfix = appDomainPostfix(contextApp, appRegion)

    if (FT('HAS_MSP') && hasMSP) {
      instances[DSS_ID] = `new||${region}|`
    }

    // add domain_postfix into extra.app
    const ADDON_APPS = [SETUP_ADDON_APP_IOT, SETUP_ADDON_APP_SAAS_INLINE]
    for (const appId of ADDON_APPS) {
      if (hasAddon(toBeActivatedApp, PRISMA_ACCESS_EDITION, appId) &&
        productToBeActivated.find(({ app_id, tenant_id }) => app_id === appId && tenant_id === SETUP_CREATE_NEW)) {
        extra[appId].domain_postfix = stateRef.current.getAnyApp(appId)?.domain_postfix
      }
    }

    // TODO: remove PRISMA_SAAS check once existing aperture onboarding is retired
    const hasDomain = subdomain && (contextApp.customizable_subdomain || contextApp.app_id === PRISMA_SAAS) && productToBeActivated.some(({ app_id, tenant_id }) =>
      tenant_id === SETUP_CREATE_NEW && app_id === contextApp.app_id
    )
    if (instances[PA_PANORAMA]) { // transform app_id for panorama
      for (const app_id of PA_EXT_APPS) {
        if (instances[app_id]) {
          instances[`${app_id}_onprem`] = instances[app_id]
          delete instances[app_id]
        }
      }
      delete instances[PRISMA_ACCESS] // ensure not send conflicted prisma access if pa-panorama selected
    }
    const payload = {
      hash,
      support_account_id,
      region_data: region.includes('|') ? region : `${region}|${dataLakeRegion}`,
      instances: omitEmptyValues(instances),
      session_id: session.uuid,
      hash_context,
      is_eula_accepted: true,
      ...(hasDomain && {
        subdomain,
        domain_postfix,
      }),
      ...(!_.isEmpty(extra) && { extra }),
      ...(description && { description }), // FT SETUP_WITH_DESC_FIELD
      ...(mode && { mode }), //ela
      ...hasMSP ? { tsg_id } : {}
    }
    // APPORTAL-4827
    if (isNGPA) {
      payload.extra = {
        ...payload.extra,
        prisma_access: { is_nextgenpa: true, nextgenpa_cloud_provider: 'azure' },
        prisma_access_edition: { is_nextgenpa: true, nextgenpa_cloud_provider: 'azure' },
      }
    }
    // eslint-disable-next-line no-console
    // return console.info('activate', payload)
    // eslint-disable-next-line no-unreachable
    setLoading(true)
    activate(payload).then((data) => {
      setLoading(false)
      if (data.uuid) {
        onActivateStarted(data.uuid)
      }
    }, (err) => {
      setLoading(false)
      const status = err.response?.status || err.code
      const data = err.response?.data
      // note: starting error page handling with zingbox, we should remove below app_id check and enable it for all other apps too(eg: PA)
      if (_.inRange(status, 400, 404) && (data?.code === 'G0002' || data?.message?.includes('G0002'))) {
        showLoginDialog({
          title: 'Session Expired',
          content: data.message,
        }).catch(_.noop)
      }
      else if (contextApp.app_id === ZINGBOX) {
        setError({ errorData: data, currentStep: currentStepObj.current })
      }
      else {
        const { message = SERVICE_UNAVAILABLE } = data || err
        Message.error(message, 5) // TODO: use tooltip instead
        // eslint-disable-next-line no-console
        console.error(err) // TODO: show error
      }
    })
  }, [stateRef, supportAccounts, hash, setLoading, onActivateStarted, setError, currentStepObj, toBeActivatedApp, mode, hasMSP, isNGPA])

  const { loading: isDeviceLoading, load: fetch } = useAxiosRequest()
  useEffect(() => {
    fetch({
      method: 'POST',
      url: '/hub/v2/devices',
      data: {
        selectedAccount,
        context: currentContext,
        hash,
        ...(mode && { mode })
      }
    }).then((devices) => {
      const devicesData = devices?.data
      if (isNonemptyArray(devicesData)) {
        indexArray(devicesData, 'serial_number')
        setDevices(devicesData)
        return devicesData
      }
      setDevices(EMPTY_DEVICES)
      return EMPTY_DEVICES
    }, (err) => {
      setDevices(EMPTY_DEVICES)
      throw err
    })
  }, [fetch, selectedAccount, setDevices, hash, currentContext, mode])

  const stepLoading = currentStep === 1 ? isLoading || isDeviceLoading : isLoading

  const errors = getFieldsError()
  const validateError = useMemo(() => {
    const validateError = []
    // flatten errors key
    /**
     * @param ob Object                 The object to flatten
     * @param prefix String (Optional)  The prefix to add before each key, also used for recursion
     **/
    function flattenObject(ob, prefix = false, result = null) {
      result = result || {}

      // Preserve empty objects and arrays, they are lost otherwise
      if (prefix && typeof ob === 'object' && ob !== null && Object.keys(ob).length === 0) {
        result[prefix] = Array.isArray(ob) ? [] : {}
        return result
      }

      prefix = prefix ? `${prefix}.` : ''

      for (const i in ob) {
        if (Object.prototype.hasOwnProperty.call(ob, i)) {
          if (typeof ob[i] === 'object' && !Array.isArray(ob[i]) && ob[i] !== null) {
            // Recursion on deeper objects
            flattenObject(ob[i], prefix + i, result)
          }
          else {
            result[prefix + i] = ob[i]
          }
        }
      }
      return result
    }
    const flattenErrors = flattenObject(errors)
    const errorFields = isLastStep ? Object.keys(flattenErrors) : formFields
    for (const fieldName of errorFields) {
      const err = _.get(errors, fieldName)
      if (Array.isArray(err)) {
        validateError.push(...err)
      }
    }
    return validateError
  }, [errors, formFields, isLastStep])
  const haveErr = validateError.length > 0

  const isLicenseLoadingOrError = Boolean(error) || (isLoading && currentStep === 0 && !toBeActivatedApp.length)
  const isConfirmation = currentStep === steps.length - 1
  const primaryButton = useMemo(() => {
    return <NextButton
      theme={states?.setupSteps[currentContext]?.theme}
      haveError={haveErr}
      validateErrorArr={validateError}
      htmlType={'submit'}
      buttonProps={nextBtnProps}
      needsLoading={stepLoading}
      isLoadingBlocking={isConfirmation}
      disabled={isLicenseLoadingOrError}
    >{nextBtnProps.label}</NextButton>
  }, [haveErr, isConfirmation, isLicenseLoadingOrError, nextBtnProps, stepLoading, validateError, states.setupSteps, currentContext])

  const redirectButton = useMemo(() => {
    return <Button v2={false} type={'danger'} style={{ marginLeft: 5 }}>
      <a href={nextBtnProps?.redirectUrl} target="_blank" rel="noopener noreferrer">{nextBtnProps?.label}</a>
    </Button>
  }, [nextBtnProps])

  useEffect(() => {
    if (currentStep >= 0) {
      validateFields()
    }
  }, [currentStep, stepLoading, validateFields])

  const drawerClassName = isDrawerOpen ? 'setup-drawer open' : 'setup-drawer close'


  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    const isCdlOnly = (values) => {
      const list = values[CHOOSE_LICENSES_FIELD]
      return list?.every((key) => {
        const app_id = key.split('-', 1)[0]
        return app_id === LGS_ID || NOT_ALLOWED_ACTIVATE_ALONE_APPS_SET.has(app_id)
      })
    }
    const validatingFields = !isLastStep && formFields.length ? formFields : undefined
    validateFields(validatingFields, (err, values) => {
      if (!err) { // context is from url, currentContext from states
        if (currentStep === 0 && context !== SETUP_CTX_CDL) {
          const cdlOnly = isCdlOnly(values)
          if (cdlOnly) {
            overrideContext(SETUP_CTX_CDL) // override currentContext
          }
          else {
            overrideContext(context)
          }
          onNext(currentStep, values)
        }
        else if (isLastStep) {
          onNext(currentStep, values)
          setTimeout(activateHandler, 200) // need to wait for reducer
        }
        else {
          onNext(currentStep, values)
        }
        // console.log('Received values of form: ', values)
      }
    })
  }, [isLastStep, formFields, validateFields, currentStep, context, onNext, overrideContext, activateHandler])


  const redirectToSASE = useCallback(() => {
    const appInfo = states.getAnyApp(SETUP_CTX_PRIMARY_APPS[context]?.[0])
    if (appInfo?.extra?.sase_portal_url) {
      window.open(`${appInfo?.extra?.sase_portal_url}/setup/${context}/${hash}`, '_self')
    }
  }, [states, hash, context])

  const indexToBeActivatedApp = indexArray(toBeActivatedApp, 'app_id', { clone: true })

  const popupOverContent = useCallback((allRequiredAppCheck) => {
    if (!allRequiredAppCheck) {
      return <span>These products are not currently supported in the new MSP multi-tenant cloud management environment</span>
    }
    return <span>Select this option if you are an MSP and want to activate your products in the new MSP multi-tenant cloud management environment</span>
  }, [])

  const onHubSetupClick = useCallback(() => {
    window.open(`/hub/setup/${context}/${hash}`, '_self')
  }, [hash, context])

  const renderActivateMSPButton = useMemo(() => {
    // const indexedAddon = indexArray(indexToBeActivatedApp.get(PRISMA_ACCESS_EDITION)?.addons, 'app_id', { clone: true })
    const MSP_NOT_SUPPORTED_ADDON = [
      FT('MSP_FORK_DLP') ? undefined : SETUP_ADDON_APP_DLP,
      FT('MSP_FORK_IOT') ? undefined : SETUP_ADDON_APP_IOT,
      FT('MSP_FORK_SAAS') ? undefined : PRISMA_SAAS,
      FT('MSP_FORK_OKYO') ? undefined : SETUP_ADDON_OKYO,
    ].filter(Boolean)

    const hasNotSupportedAddon = indexToBeActivatedApp.get(PRISMA_ACCESS_EDITION)?.addons?.some(obj => MSP_NOT_SUPPORTED_ADDON.includes(obj?.app_id))

    // const isOkyoSupported = indexedAddon.get(SETUP_ADDON_OKYO) && FT('ALLOW_OKYO_MSSP')
    const isNotAllowedApp = indexToBeActivatedApp.has(PRISMA_ACCESS_PANORAMA)

    const isEVAL = indexToBeActivatedApp.get(PRISMA_ACCESS_EDITION)?.license_type?.toUpperCase()?.includes('EVAL')

    const allRequiredAppCheck = indexToBeActivatedApp.get(PRISMA_ACCESS_EDITION)?.checked && indexToBeActivatedApp.get(LGS_ID)?.checked
    return !hasNotSupportedAddon && (!isNotAllowedApp || isEVAL) && !isLoading && FT('MSP_FORK') && (currentStepObj.comp_enum === 'SETUP_ACTIVATE_PRODUCT' || error) &&
      <Tooltip title={popupOverContent(allRequiredAppCheck)} placement={'left'}>
        <div>
          <Button disabled={!allRequiredAppCheck && !Boolean(error)} style={{ marginRight: 5 }} v2={false} onClick={redirectToSASE}>MSP Cloud Management</Button>
        </div>
      </Tooltip>

  }, [indexToBeActivatedApp, isLoading, currentStepObj.comp_enum, error, popupOverContent, redirectToSASE])

  const renderBuckbeakButton = useMemo(() => {
    const appInfo = states.getAnyApp(SETUP_CTX_PRIMARY_APPS[context]?.[0])
    const allowedAccounts = appInfo?.extra?.buckbeak_beta_accounts || []
    const hasAccess = hasAccountAccess(supportAccounts, allowedAccounts)
    const allRequiredAppCheck = indexToBeActivatedApp.get(LGS_ID)?.checked
    return hasAccess && (currentStepObj.comp_enum === 'SETUP_ACTIVATE_PRODUCT' || error) && (
      <Tooltip placement={'left'}>
        <div>
          <Button disabled={!allRequiredAppCheck || Boolean(error)} style={{ marginRight: 5 }} v2={false} onClick={onHubSetupClick}>Cloud Management Trial</Button>
        </div>
      </Tooltip>)

  }, [error, currentStepObj, indexToBeActivatedApp, onHubSetupClick, context, states, supportAccounts])

  const renderMSPButton = useMemo(() => {
    return isStandaloneCdl ? renderBuckbeakButton : renderActivateMSPButton
  }, [renderActivateMSPButton, renderBuckbeakButton, isStandaloneCdl])

  return (
    <Row className={`setup-base setup-context-${currentContext}`} type={'flex'} justify={'center'} align={'middle'}>
      <Col {...responsiveConfig} style={{ transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms', justifyContent: 'center', ...style }} >
        <Row type={'flex'} style={{ flex: 1 }}>
          <Col xs={24}>
            <Row type={'flex'} justify={'center'}>
              <Steps current={currentStep} progressDot={dot => dot}>
                {steps.map((item, idx) => (
                  <Step key={idx} />
                ))}
              </Steps>
              <Col xs={24}>
                <Form onSubmit={handleSubmit} data-step={currentStep} className='setup-form'>
                  <SetupWrapper icon={icon} title={title}
                    description={description}
                    comp_enum={currentStepObj.comp_enum}
                    setFirewallListVisible={setFirewallListVisible}>
                    {error ?
                      <SetUpErrorCard errorMsg={error} /> :
                      <FormStepComponent
                        keyByMsspAccounts={keyByMsspAccounts}
                        msspAccounts={msspAccounts}
                        hasMSP={hasMSP}
                        sgGroups={sgGroups}
                        stateRef={stateRef}
                        states={states}
                        form={form}
                        isLoading={isLoading}
                        onProductChecked={onProductChecked}
                        onAccountSelect={onAccountSelect}
                        onCDLChange={onCDLChange}
                        chooseHowToManage={chooseHowToManage}
                        setNewPanoramaSn={setNewPanoramaSn}
                        setClcsTenant={setClcsTenant}
                        setSelectedFirewallDevices={setSelectedFirewallDevices}
                        setFirewallListVisible={setFirewallListVisible}
                      />
                    }
                    <div className='setup-base-footer hbox middle space-between'>
                      <Button onClick={onBack.bind(null, currentStep)} {...backBtnProps}>{backBtnProps.label}</Button>
                      <div className={'hbox '}>
                        {renderMSPButton}
                        {nextBtnProps?.redirectUrl ? redirectButton : primaryButton}
                      </div>

                    </div>
                  </SetupWrapper>
                </Form>
                <div className={drawerClassName}
                  style={{ transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms' }}>
                  <h4>Placeholder for drawer</h4>
                </div>
              </Col>
              {/*<a className={'setup-help'} href={help}>Help?</a>*/}
            </Row>
          </Col>
        </Row>
      </Col>
      <Col>
        {stepsHelp ? <SetupHelp
          popupOverProps={{
            title: 'Activation Support',
            trigger: 'click',
            placement: 'topRight',
            style: { padding: 0 },
            arrowPointAtCenter: true,
          }}
          helpVideoLink={stepsHelp?.helpVideo || undefined}
          helpLinks={stepsHelp?.helpLinks || undefined}
          buttonProps={{
            className: 'setup-help vbox center',
            shape: 'circle',
            size: 'large'
          }} /> :
          null}
      </Col>
    </Row>
  )
}

SetupBase.propTypes = {
  hash: PropTypes.string,
  context: PropTypes.string,
  mode: PropTypes.string,
  apps: PropTypes.array,
  entitledAppsList: PropTypes.array,
  session: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setupReducer: PropTypes.object,
  supportAccounts: PropTypes.array,
  selectedAccount: PropTypes.number,
  onNext: PropTypes.func,
  onBack: PropTypes.func,
  onProductChecked: PropTypes.func,
  onAccountSelect: PropTypes.func,
  onCDLChange: PropTypes.func,
  onActivateStarted: PropTypes.func,
  setLoading: PropTypes.func,
  states: PropTypes.object,
  stateRef: PropTypes.any,
  isLoading: PropTypes.bool,
  chooseHowToManage: PropTypes.func,
  setDevices: PropTypes.func,
  setNewPanoramaSn: PropTypes.func,
  activateAuthCode: PropTypes.func,
  history: PropTypes.object,
  form: PropTypes.object,
  helpLinks: PropTypes.array,
  setFirewallListVisible: PropTypes.func,
  setSelectedFirewallDevices: PropTypes.func,
  setError: PropTypes.func,
  overrideContext: PropTypes.func,
  setClcsTenant: PropTypes.func,
  msspAccounts: PropTypes.array,
  sgGroups: PropTypes.array,
  keyByMsspAccounts: PropTypes.object,
  hasMSP: PropTypes.bool,
  prisma31Release: PropTypes.bool,
}

export default Form.create()(SetupBase)
