import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Table } from '@pan/cloud-base'
import ListItem from '../../../../components/ListItem'

const theadTh = {
  textTransform: 'none',
  color: '#000000',
  fontSize: '12px',
  fontWeight: 'normal',
  backgroundColor: '#FFFFFF',
}

const trGroup = {
  minHeight: '30px',
  borderBottom: 'none',
}

const thead = {
  borderBottom: 'none',
  backgroundColor: '#FFFFFF'
}

const boldRedFont = {
  color: '#c83a4b',
  fontWeight: 'bold'
}

const licenseHeader = (totalSelectedDevices, totalAvailableAuthCodes) => {
  const style = {
    paddingRight: '5px',
    textAlign: 'right',
  }

  return <p style={style}>
    <span>License</span>
    <span style={totalSelectedDevices > totalAvailableAuthCodes ? boldRedFont : {}}></span> <br />
    <span>{`(${totalSelectedDevices}/${totalAvailableAuthCodes})`}</span>
  </p>

}

const renderModel = (value) => <ListItem toolTipProps={{ placement: 'right' }}>{value}</ListItem>

//for prod license type
const renderModelAuthCodes = (value) => {
  const { selected, unused } = value
  const totalAuthCodes = unused?.length
  let style = {
    paddingRight: '5px',
  }
  if (totalAuthCodes === 0) {
    return <span style={style}>-</span>
  }

  style = {
    ...style,
    ...(selected > totalAuthCodes && boldRedFont)
  }

  return <span style={style}>{selected}/{totalAuthCodes}</span>
}

// for eval or trial license types
const renderAllAuthCodes = (value, totalAvailableAuthCodes) => {
  const { selected } = value

  const style = {
    paddingRight: '5px',
    ...(selected > totalAvailableAuthCodes && boldRedFont)
  }

  return <span style={style}>{selected}</span>

}

const renderRightArrow = () => <span className='right-arrow-hidden' />

const renderDirtyState = (model, selectedDevices) => {
  if (selectedDevices.find(item => item.model === model)) {
    return <div className='dirty-state' />
  }
  return
}

const modelColumns = (isAgnosticLicense, selectedDevices, totalAvailableAuthCodes) => {
  return [
    {
      Header: '',
      accessor: 'dirty_state',
      width: 5,
      Cell: ({ original }) => renderDirtyState(original.model, selectedDevices),
    },
    {
      Header: 'Model',
      accessor: 'model',
      width: 100,
      Cell: ({ value }) => renderModel(value),
    },
    {
      Header: licenseHeader(selectedDevices?.length, totalAvailableAuthCodes),
      accessor: 'licenses',
      width: 100,
      Cell: ({ value }) => (isAgnosticLicense ? renderAllAuthCodes(value, totalAvailableAuthCodes) : renderModelAuthCodes(value)),
      style: { textAlign: 'right' },
    },
    {
      Header: '',
      accessor: 'right_arrow',
      width: 20,
      cell: () => renderRightArrow(),
    }
  ]
}
const FirewallModelTable = ({ data, handleModelSelected, isAgnosticLicense, selectedDevices, totalAvailableAuthCodes }) => {
  const [selectedRow, setSelectedRow] = useState(null)

  useEffect(() => {
    if (!!selectedRow) {
      handleModelSelected(selectedRow?.original)
    }
  }, [selectedRow, handleModelSelected])

  const getTrProps = (state, rowInfo) => {
    if (rowInfo && rowInfo.row) {

      if (rowInfo.index === 0 && !selectedRow) {
        setSelectedRow(rowInfo) //default select first row
      }

      const rowStyle = rowInfo.index === selectedRow?.index ?
        {
          background: '#EFF7FF',
          borderLeft: '3px solid #42A5ED',
        } :
        {
          borderLeft: '3px solid #FFFFFF'
        }

      return {
        onClick: () => setSelectedRow(rowInfo),
        style: rowStyle
      }
    }
    return {}
  }

  const getTdProps = (state, rowInfo, column) => {
    if (rowInfo && rowInfo.row && rowInfo.index === selectedRow?.index && column?.id === 'right_arrow') {
      return { className: 'right-arrow-visible' }
    }
    return {}
  }

  return <Table
    className='fw-model-tbl'
    styles={{ thead, theadTh, trGroup }}
    getTrProps={getTrProps}
    getTdProps={getTdProps}
    columns={modelColumns(isAgnosticLicense, selectedDevices, totalAvailableAuthCodes)}
    data={data}
    handleModelSelected={handleModelSelected}
    autoScrollbar={true}
    noDataText='No Models Found'
  />
}

FirewallModelTable.defaultProps = {
  isAgnosticLicense: false
}

FirewallModelTable.propTypes = {
  data: PropTypes.array.isRequired,
  handleModelSelected: PropTypes.func.isRequired,
  isAgnosticLicense: PropTypes.bool,
  selectedDevices: PropTypes.array,
  totalAvailableAuthCodes: PropTypes.number,
}


export default FirewallModelTable
