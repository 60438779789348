/**
 * Created by vsuthar on 6/10/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { LGS_ID, NGFW } from '../../../constants/AppConstants'
import SetupDataLakeView from '../../../components/Setup/SetupDataLakeView'
import SetupMiscItem from '../../../components/Setup/SetupMiscItem'

const emptyObj = {}

const DataLakeDomain = ({ instances = [], entitledAppsList, appContext, getAnyApp, ...rest }) => {
  const { cdlInstance, displayRegion } = useMemo(() => {
    const appInstance = instances.find(app => [appContext, `${appContext}_${NGFW}`].includes(app?.app_id))
    const { platform_id } = appInstance || {}
    const getInstances = entitledAppsList?.get ? app_id => entitledAppsList.get(app_id)?.tenants : () => undefined
    const cdlInstance = getInstances(LGS_ID)?.find(tenant => tenant?.tenant_id === platform_id) || emptyObj
    const displayRegion = getAnyApp(appContext)?.regions?.find(r => r.name === appInstance?.region)?.display
    return { cdlInstance, displayRegion }
  }, [entitledAppsList, instances, appContext, getAnyApp])

  return (
    <div>
      <SetupMiscItem label={'App Region'} value={displayRegion} wrapperClass='hbox' labelProps={{ style: { minWidth: 140 } }} />
      <SetupDataLakeView cdlInstance={cdlInstance} {...rest} />
    </div>
  )
}

DataLakeDomain.propTypes = {
  instances: PropTypes.array,
  entitledAppsList: PropTypes.array,
  appContext: PropTypes.string,
  getAnyApp: PropTypes.func,
}
export default DataLakeDomain

