/**
 * Created by vsuthar on 12/10/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import SubdomainField from './SubdomainField'
import { SETUP_CREATE_NEW, APP_REGION_FIELD, REGION_FIELD, SUBDOMAIN_FIELD } from '../../../constants/AppConstants'
import { getSubdomainFromEmail, appDomainPostfix } from '../../../utils/activateUtils'

// subdomainFieldName will be default to 'subdomain', but should be override if it's not main app
const SetupSubdomainField = ({ form, app, getAnyApp, subdomainFieldName = SUBDOMAIN_FIELD, tenant_selected_value, subdomainProps, user_email }) => {
  const { getFieldValue, setFieldsValue, getFieldDecorator, validateFields } = form
  const appRegion = getFieldValue(APP_REGION_FIELD) || getFieldValue(REGION_FIELD)
  const tenant_id = useMemo(() => tenant_selected_value?.split(/[:|]/, 2)?.[1], [tenant_selected_value])
  const selectedExistingTenantSubdomain = useMemo(() => {
    if (tenant_id && tenant_id !== SETUP_CREATE_NEW) {
      const instance = getAnyApp(app.app_id).tenants?.find(x => x.tenant_id === tenant_id)
      if (instance?.url) {
        const [subdomain] = instance.url.replace(/^https?:\/\//i, '').split('.', 1)
        if (subdomain) {
          return { [subdomainFieldName]: subdomain, isEmpty: false }
        }
      }
    }
    return { isEmpty: true }
  }, [tenant_id, getAnyApp, app.app_id, subdomainFieldName])

  const appInfoMap = useMemo(() => { // just a mock one
    return Object.assign(new Map([[app.app_id, app]]), {
      subdomainMap: new Map([[app.app_id, app.domain_postfix]]),
      hasSubdomainField: true,
      customizable_subdomain: true,
      user_email,
      domainPostfix: appDomainPostfix(app, appRegion),
    })
  }, [app, user_email, appRegion])

  const getPreservedFieldDecorator = useCallback((id, opt) => getFieldDecorator(id, { preserve: true, ...opt }), [getFieldDecorator])

  useEffect(() => {
    if (tenant_id === SETUP_CREATE_NEW) {
      setFieldsValue({ [subdomainFieldName]: getSubdomainFromEmail(user_email) }, () => {
        validateFields([subdomainFieldName], { force: true })
      })
    }
    else if (selectedExistingTenantSubdomain[subdomainFieldName]) {
      setFieldsValue({ [subdomainFieldName]: selectedExistingTenantSubdomain[subdomainFieldName] }, () => {
        validateFields([subdomainFieldName], { force: true })
      })
    }

  }, [tenant_id, setFieldsValue, selectedExistingTenantSubdomain.subdomain, user_email, subdomainFieldName, validateFields, selectedExistingTenantSubdomain])


  return (<SubdomainField
    {...subdomainProps}
    {...form}
    getFieldDecorator={getPreservedFieldDecorator}
    domainPostfix={appInfoMap.domainPostfix}
    appInfoMap={appInfoMap}
    fieldName={subdomainFieldName}
    initialValues={selectedExistingTenantSubdomain} />)
}



SetupSubdomainField.propTypes = {
  /**
   * @form object antd form that pass down from SetupBase file for Setup
   */
  form: PropTypes.object,
  /**
   * @app app object that you trying to get domain for
   */
  app: PropTypes.object,
  /**
   * @setupSelectedAccount: User selected account. Its index
   */
  setupSelectedAccount: PropTypes.number,
  getAnyApp: () => undefined,
  tenant_selected_value: PropTypes.string,
  subdomainProps: PropTypes.object,
  app_id: PropTypes.string,
  user_email: PropTypes.string,
  subdomainFieldName: PropTypes.string,
}
export default SetupSubdomainField

