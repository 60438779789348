/**
 * Created by vsuthar on 5/21/20
 * Project: App Portal ©Palo Alto Networks
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Popover, Button, Icon } from '@pan/cloud-base'
import { PANWWhiteIconOnly } from '../../images/svg-icons'
import SetupBorder from './SetupBorder'



const SetupHelp = ({ icon, helpVideoLink, helpLinks, buttonLabel, buttonProps, popupOverProps }) => {

  const content = () => {
    return <div className={'vbox'} >
      {/*TODO move to the manifest*/}
      {helpVideoLink ? <>
        <iframe title={'prisma-help'} width="315" height="178" src={helpVideoLink} frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        <span style={{ lineHeight: '40px' }}>Click to watch the help video</span>
        <SetupBorder />
      </> : null}

      <div className={''}>
        {helpLinks.map(({ href, label, border }, idx) => {
          return <div key={idx}>
            <div key={idx} className={'hbox space-between middle'}>
              <a style={{ lineHeight: '25px' }} href={href}>{label}</a>
              <Icon type="right-circle" style={{ color: '#1F75B2', margin: '0 5px' }} />
            </div>
            {border ? <SetupBorder /> : null}
          </div>
        })}
      </div>
    </div>
  }
  return <Popover {...popupOverProps} content={content()}>
    <Button {...buttonProps}>
      {icon}
      {buttonLabel}
    </Button>
  </Popover>
}

SetupHelp.propTypes = {
  buttonProps: PropTypes.object,
  popupOverProps: PropTypes.object,
  buttonLabel: PropTypes.string,
  icon: PropTypes.node,
  helpLinks: PropTypes.array,
  helpVideoLink: PropTypes.string,
}

SetupHelp.defaultProps = {
  buttonLabel: 'Help',
  buttonProps: {
    size: 'large',
    shape: 'circle'
  },
  helpVideoLink: '',
  icon: <Icon>
    <PANWWhiteIconOnly />
  </Icon>,
  helpLinks: []
}

export default SetupHelp

