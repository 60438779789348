import React, { useMemo, useCallback, useLayoutEffect, useEffect } from 'react'
import PropTypes from 'prop-types'
import DataLake from '../DataLake'
import {
  STRATA_INSIGHTS_NGFW,
  SUBDOMAIN_FIELD,
  CDL_INSTANCE_FIELD,
  STRATA_INSIGHTS,
} from '../../../constants/AppConstants'
import {
  useMemoFieldDecorator,
  selectedDevicesAndLicenses,
  FT,
} from '../../../utils/activateUtils'
import AiopsTenant from './AiopsTenant'
import DirectorySync from '../common/DirectorySync'
import DirectorySyncRegion from '../common/DirectorySyncRegion'
import AiopsAppRegion from './AiopsAppRegion'
import './aiops.scss'

const AiopsSecuritySetup = (props) => {
  const {
    form,
    getAnyApp,
    chooseHowToManage,
    currentStepObj,
    cdlInstance,
    stateRef,
    onCDLChange,
    setSelectedFirewallDevices,
    toBeActivatedApp,
    session,
    selectedFirewallDevices,
    entitledAppsList,
  } = props
  const hasFirewallBundle = session?.has_firewall_bundle
  const aiops = useMemo(() => AiopsTenant.getAiopsInstanceValue(
    currentStepObj,
    toBeActivatedApp,
    getAnyApp,
    chooseHowToManage
  ), [currentStepObj, toBeActivatedApp, getAnyApp, chooseHowToManage])
  const { validateFields, setFieldsValue, resetFields, getFieldValue } = form

  useLayoutEffect(() => { // apply value from state/default to form field
    const curValue = getFieldValue(CDL_INSTANCE_FIELD)
    const { value, fromInstanceAssociation } = cdlInstance || {}
    const newValue = fromInstanceAssociation ? value : null //IOT-222 setting to null
    if (newValue !== curValue) {
      if (newValue) {
        setFieldsValue({ [CDL_INSTANCE_FIELD]: newValue })
      }
      else {
        resetFields([CDL_INSTANCE_FIELD]) // so it will use default value
      }
    }
  }, [cdlInstance, getFieldValue, resetFields, setFieldsValue, validateFields])


  useEffect(() => {
    if (hasFirewallBundle) {
      const { licenses, devices } = selectedDevicesAndLicenses(entitledAppsList, session?.licenses, STRATA_INSIGHTS_NGFW)
      setSelectedFirewallDevices({ devices, licenses })
    }
  }, [session, hasFirewallBundle, setSelectedFirewallDevices, entitledAppsList])

  const onCDLChangeOverride = useCallback((cdlInstance) => {
    const { cdlInstance: currentCdl } = stateRef.current
    if (currentCdl?.tenant_id !== cdlInstance?.tenant_id) {
      if (!hasFirewallBundle) {
        setSelectedFirewallDevices({}) //reset firewall device selection
      }
    }
    onCDLChange(cdlInstance)
  }, [stateRef, onCDLChange, setSelectedFirewallDevices, hasFirewallBundle])

  const handleIoTSelection = useCallback((value) => {
    if (!hasFirewallBundle) {
      setSelectedFirewallDevices({}) //reset firewall device selection
    }
    chooseHowToManage(value)
    setTimeout(() => validateFields([SUBDOMAIN_FIELD, CDL_INSTANCE_FIELD], { force: true }), 100)
  }, [setSelectedFirewallDevices, chooseHowToManage, validateFields, hasFirewallBundle])

  //required for raptor device activations
  const fieldDecorator = useMemoFieldDecorator('firewalls', {
    initialValue: selectedFirewallDevices,
  }, form)

  return (
    <div className={'aiops setup-card-body'}>
      <AiopsTenant {...props}
        initialValue={aiops}
        selectProps={{ style: { width: '70%', marginLeft: 10 }, onSelect: handleIoTSelection }} />
      {!FT('AIOPS_MULTI_REGION') && <AiopsAppRegion {...props} />}
      <DataLake
        {...props}
        onCDLChange={onCDLChangeOverride}
        dropDownProps={{ style: { width: '70%' }, placeholder: 'Select DataLake' }}
        dataLakeLabel={'Data Lake'}
        dataLakeRegionLabel={'Data Lake Region'}
        useDataLakeRegion={true}
        showAllCdlRegions={true}
        showDataLake={false}
      />
      {FT('AIOPS_MULTI_REGION') && <AiopsAppRegion {...props} />}
      <DirectorySync {...props } selectedPrimaryAppValue={aiops} primaryAppId={STRATA_INSIGHTS}/>
      <DirectorySyncRegion {...props } primaryAppId={STRATA_INSIGHTS}/>
      {hasFirewallBundle && fieldDecorator(<div />)}
    </div>
  )
}

AiopsSecuritySetup.propTypes = {
  form: PropTypes.object,
  stateRef: PropTypes.any,
  contextApp: PropTypes.object,
  cdlInstance: PropTypes.object,
  setupSelectedAccount: PropTypes.number,
  currentStepObj: PropTypes.object,
  getAnyApp: () => undefined,
  chooseHowToManage: () => undefined,
  onCDLChange: PropTypes.func,
  setSelectedFirewallDevices: PropTypes.func,
  toBeActivatedApp: PropTypes.array,
  session: PropTypes.object,
  selectedFirewallDevices: PropTypes.array,
  entitledAppsList: PropTypes.array,
  formValues: PropTypes.object,
}

export default AiopsSecuritySetup
