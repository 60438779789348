/**
 * Created by vsuthar on 6/5/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@pan/cloud-base'
import { useMemoFieldDecorator } from '../../utils/hooks'

export const fieldName = 'confirmation-agree-term'

const SetupTermCondition = ({ form }) => {

  const agreeDecorator = useMemoFieldDecorator(fieldName, useMemo(() => ({
    rules: [{
      validator(rule, value, callback) {
        if (!value) {
          callback('You must agree to the terms and conditions.')
        }
        else {
          callback()
        }
      }
    }]
  }), []), form)

  return (
    <div className={'hbox space-between setup-item'}>
      <label className='agreement'>Agree to the <span><b><a href='https://www.paloaltonetworks.com/legal/eula' target='_blank' rel='noopener noreferrer'>Terms and Conditions</a></b></span></label>
      {agreeDecorator(<Checkbox />)}
    </div>
  )
}

SetupTermCondition.propTypes = {
  form: PropTypes.object,
}
export default SetupTermCondition
