import React, { useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Select, Icon } from '@pan/cloud-base'
import ListItem from '../../../components/ListItem'
import {
  filterRegions,
  isClcsOrTelemetryInstance,
  isRunningOrProvisioningInstance,
  isTelemetryInstance,
  renderInstances,
  useMemoFieldDecorator,
  indexArray,
  validatePanoramaSerial,
} from '../../../utils/activateUtils'
import {
  LGS_ID,
  PANORAMA,
  PRISMA_ACCESS_PANORAMA,
  SETUP_CREATE_NEW,
  RUNNING,
  CDL_INSTANCE_FIELD,
  REGION_FIELD,
  PANORAMA_INSTANCE_FIELD as NEW_PANORAMA_SN_FIELD,
  CHOOSE_HOW_TO_MANAGE_FIELD as PANORAMA_FIELD,
  NONE,
} from '../../../constants/AppConstants'
import FakeField from '../common/FakeField'
import { NewPanoramaSnField } from '../prisma_access/RegisterNewPanorama'
import './ConfigStep.scss'

const PANORAMA_NEW = `${PANORAMA}:${SETUP_CREATE_NEW}`
const PANORAMA_NONE = `${PANORAMA}:${NONE}`
const EMPTY_DEVICES = []

const renderOptions = tenants => _.sortBy(tenants, (t) => `${t.disabled ? 1 : 0}${t.display}`).map(({ key, value, group, display, extra, ...props }) => (
  <Select.Option {...props} key={key} value={`${PANORAMA}:${value}`}>
    <ListItem toolTipProps={{ placement: 'right' }}>{display} {extra}</ListItem>
  </Select.Option>
))
const renderOptionGroups = optionsGroups => optionsGroups.map(([region, tenants]) => {
  return !region ? renderOptions(tenants) : <Select.OptGroup key={region} label={region}>
    {renderOptions(tenants)}
  </Select.OptGroup>
})

const FlsConfigStep = (props) => {
  const {
    devices = EMPTY_DEVICES,
    toBeActivatedApp,
    stateRef,
    getAnyApp,
    // onCDLChange,
    accountIsFederal,
    form: {
      getFieldDecorator,
      getFieldValue,
      setFieldsValue,
      // resetFields,
      validateFields,
    },
  } = props

  const getPreservedFieldDecorator = useCallback((id, opt) => getFieldDecorator(id, { preserve: true, ...opt }), [getFieldDecorator])

  const {
    regions,
    display_name: lsAppDisplayName,
    tenants: lsTenants,
  } = getAnyApp(LGS_ID) || {}
  const avaiRegions = useMemo(() => {
    const avaiRegions = filterRegions(regions || [], accountIsFederal)
    avaiRegions.defaultRegionName = regions[0]?.name
    return avaiRegions
  }, [regions, accountIsFederal])

  const [hasPanoramaLicense, lsAuthCode] = useMemo(() => [
    toBeActivatedApp.some(lic => lic.checked && lic.app_id.endsWith(PANORAMA)),
    toBeActivatedApp.find(lic => lic.checked && lic.app_id === LGS_ID && lic.auth_code)?.auth_code,
  ], [toBeActivatedApp])

  const regionDecorator = useMemoFieldDecorator(REGION_FIELD, {
    initialValue: avaiRegions.defaultRegionName, // TODO: restore from state
    validateFirst: true,
    rules: [{ required: true, message: 'Region is required' }],
  }, getPreservedFieldDecorator)

  const lsUpgradeDecorator = useMemoFieldDecorator(CDL_INSTANCE_FIELD, {
    rules: useMemo(() => [{
      validator: (rule, value, callback) => {
        const [tenant_id, serial_number] = value?.split('|', 2) || []
        if (tenant_id === SETUP_CREATE_NEW) {
          return callback()
        }
        if (!tenant_id || !serial_number) {
          return callback(`Found an invalid ${lsAppDisplayName} instance in the specified region`)
        }
        const instance = lsTenants?.find(t => t.tenant_id === tenant_id)
        if (instance && !isRunningOrProvisioningInstance(instance)) {
          return callback(`Found a ${lsAppDisplayName} instance in the specified region is not in provisioning or running`)
        }
        callback()
      }
    }], [lsAppDisplayName, lsTenants]),
  }, getPreservedFieldDecorator)

  const region = getFieldValue(REGION_FIELD)
  const [freeLs, freeLsTid, cdlExtra] = useMemo(() => {
    const freeLsInstances = lsTenants?.filter(t => t.region === region && isClcsOrTelemetryInstance(t))
    const freeLs = freeLsInstances?.find(isRunningOrProvisioningInstance) || freeLsInstances?.[0]
    if (!freeLs) {
      return [undefined, undefined, null]
    }
    const extra = isTelemetryInstance(freeLs) ? `Upgrade Telemetry Instance ${freeLs.tenant_display_name || freeLs.serial_number}` : (
      <span style={{ visibility: 'hidden' }}>Upgrade CLCS {freeLs.tenant_id}</span>
    )
    return [freeLs, freeLs?.tenant_id, extra]
  }, [lsTenants, region])
  useEffect(() => { // when region inited/changed
    if (!freeLs) {
      // onCDLChange({ region })
      setFieldsValue({
        [CDL_INSTANCE_FIELD]: `${SETUP_CREATE_NEW}||${region}|${lsAuthCode}`
      })
    }
    else {
      const {
        tenant_id = '',
        serial_number = '',
      } = freeLs || {}
      // onCDLChange({ ...freeLs, region })
      setFieldsValue({
        [CDL_INSTANCE_FIELD]: `${tenant_id}|${serial_number}|${region}|${lsAuthCode}`
      })
    }
    validateFields([CDL_INSTANCE_FIELD], { force: true })
  }, [freeLs, region, lsAuthCode, setFieldsValue, validateFields])

  const panoramas = useMemo(() => {
    const paWithCdl = getAnyApp(PRISMA_ACCESS_PANORAMA)?.tenants?.filter(({ serial_number, associations, extra }) =>
      serial_number && (associations?.[LGS_ID]?.tenant_id || extra?.platform_id)
    )
    const paWithCdlSnMap = new Map(paWithCdl?.map(t => [t.serial_number, t]))
    const notActivatedYetPanorama = devices.filter(dev =>
      dev.serial_number && dev.app_id === PANORAMA
    ).map(({ serial_number, platform_id, device_name }) => {
      if (!platform_id) {
        return { // including registed panorama with or without cdl
          tenant_id: SETUP_CREATE_NEW, // as long as panorama not yet in cob, we treat it as new
          serial_number,
          tenant_display_name: device_name ? `${device_name} (${serial_number})` : serial_number,
          instance_status: RUNNING, // so it will always showing up
        }
      }
      if (paWithCdlSnMap.has(serial_number)) {
        const { tenant_instance_name, region } = paWithCdlSnMap.get(serial_number)
        return { // including registed panorama with or without cdl
          tenant_id: serial_number, // as long as panorama not yet in cob, we treat it as new
          tenant_display_name: tenant_instance_name && !tenant_instance_name?.includes(serial_number) ?
            `${tenant_instance_name} (${serial_number})` : tenant_instance_name || serial_number,
          serial_number,
          region,
          instance_status: RUNNING, // so it will always showing up
        }
      } // else if platform_id
      return { // including registed panorama with or without cdl
        tenant_id: serial_number, // as long as panorama not yet in cob, we treat it as new
        serial_number,
        tenant_display_name: device_name ? `${device_name} (${serial_number})` : serial_number,
        instance_status: RUNNING, // so it will always showing up
      }
    })
    return indexArray(notActivatedYetPanorama, 'serial_number')
  }, [devices, getAnyApp])
  const renderedPanoramaDevices = useMemo(() => {
    const getInstances = () => panoramas
    const rendered = renderInstances({
      config: {
        app_id: PANORAMA,
        isDisabledFn: t => t.tenant_id !== SETUP_CREATE_NEW && !(freeLsTid && t.tenant_id === freeLsTid),
        extraTenantInfoRenderFn: t => {
          if (freeLsTid && t.tenant_id === freeLsTid) {
            return '(associated)'
          }
          if (t.tenant_id !== SETUP_CREATE_NEW) {
            return '(used)'
          }
        },
      },
      appInfoMap: { // no need the full version from activate utils
        getAnyApp: () => ({ // fake app manifest for panorama
          enabled: true,
          display_name: 'Panorama',
        }),
        getInstances,
      },
      renderOptions,
      renderOptionGroups,
    })
    const fixedOpitons = [
      <Select.Option
        key={PANORAMA_NONE}
        value={PANORAMA_NONE}
      >None</Select.Option>
    ]
    if (hasPanoramaLicense) {
      fixedOpitons.unshift(
        <Select.Option
          key={PANORAMA_NEW}
          value={PANORAMA_NEW}
        >New Panorama</Select.Option>
      )
    }
    return fixedOpitons.concat(rendered)
  }, [panoramas, hasPanoramaLicense, freeLsTid])

  const initialValue = hasPanoramaLicense ? PANORAMA_NEW : PANORAMA_NONE
  useEffect(() => { // reset panorama selection if current value not in panorama list
    const panoSn = getFieldValue(PANORAMA_FIELD)?.split('|', 2)[1]
    if (panoSn && !panoramas.has(panoSn)) {
      setFieldsValue({
        [PANORAMA_FIELD]: initialValue
      }, () => {
        validateFields([PANORAMA_FIELD], { force: true })
      })
    }
  }, [initialValue, panoramas, getFieldValue, setFieldsValue, validateFields])

  const panoramaDecorator = useMemoFieldDecorator(PANORAMA_FIELD, {
    validateFirst: true,
    rules: useMemo(() => [
      {
        validator(rule, value = '') {
          if (value === PANORAMA_NEW || value === PANORAMA_NONE) {
            return Promise.resolve()
          }
          const [, serial_number] = value.split('|', 2)
          return validatePanoramaSerial(serial_number, stateRef.current, {
            register_new: true,
          })
        }
      }
    ], [stateRef]),
  }, getPreservedFieldDecorator)

  const isNewPanorama = getFieldValue(PANORAMA_FIELD) === PANORAMA_NEW

  return (
    <div className={'setup-card-body fls-setup fls-config'}>
      <div className={'setup-item'}>
        <label className='setup-required-field-label'>Region</label>
        {regionDecorator(
          <Select>
            {avaiRegions.map(({ display, value }) => {
              return <Select.Option key={value} value={value}>{display}</Select.Option>
            })}
          </Select>
        )}
        {lsUpgradeDecorator(<FakeField>{cdlExtra}</FakeField>)}
      </div>
      <div className={'setup-item'}>
        <label>Panorama</label>
        {panoramaDecorator(
          <Select
            placeholder='Select one'
          // onChange={handlePanoramaChange} // TODO: save selection
          >
            {renderedPanoramaDevices}
          </Select>
        )}
      </div>
      {isNewPanorama && <div className={'setup-item'}>
        <label className='setup-required-field-label'>Panorama Serial Number</label>
        <NewPanoramaSnField
          fieldName={NEW_PANORAMA_SN_FIELD}
          toBeActivatedApp={toBeActivatedApp}
          getFieldDecorator={getPreservedFieldDecorator}
          setNewPanoramaSn={_.noop}
          stateRef={stateRef}
        />
      </div>}
      <div className={'hbox multi-panorama-info'}>
        <Icon type='info-circle' theme={'filled'} style={{ 'vertical-align': 'initial', padding: '4px', marginRight: '8px', color: '#006FCC' }} />
        <p>You will be able to connect additional Panorama appliances after activation.</p>
      </div>
    </div>
  )
}

FlsConfigStep.propTypes = {
  form: PropTypes.object,
  stateRef: PropTypes.any,
  toBeActivatedApp: PropTypes.array,
  contextApp: PropTypes.object,
  formValues: PropTypes.object,
  accountIsFederal: PropTypes.bool,
  setupSelectedAccount: PropTypes.number,
  devices: PropTypes.array,
  user_email: PropTypes.string,
  currentStepObj: PropTypes.object,
  getAnyApp: PropTypes.func,
  onCDLChange: PropTypes.func,
  setNewPanoramaSn: PropTypes.func,
}

export default FlsConfigStep
