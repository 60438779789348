/**
 * Created by vsuthar on 4/9/20
 * Project: App Portal ©Palo Alto Networks
 */
import React, { useMemo } from 'react'
import { Button, Checkbox, Icon } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import './selectcheckbox.scss'
import ActivateMenuItem from './ActivateMenuItem'
import ActivateMenuItemText from './ActivateMenuItemText'
import Tooltip from '../common/Tooltip'

const activatedStyle = {
  backgroundColor: '#C6E08D',
  color: 'white',
  border: 'none',
  textTransform: 'uppercase'
}

const SelectCheckBox = (props) => {
  const {
    title,
    subtitle,
    imageUrl,
    sessionUrl,
    disabled,
    tooltip,
    tipIcon,
    isLoading,
    isActivated,
    children,
    history,
    ...checkBoxProps
  } = props
  const className = checkBoxProps.checked ? 'select-checkbox select-checkbox-border effect-9' : 'select-checkbox'
  const isActivatedWithSessionUrl = isActivated && sessionUrl

  const activatedSessionUrlProps = useMemo(() => {
    if (isActivatedWithSessionUrl) {
      return {
        onClick() {
          history.push(sessionUrl)
        }
      }
    }
  }, [isActivatedWithSessionUrl, history, sessionUrl])


  const getLeftSideCmp = useMemo(() => {
    if (isActivated) {
      const props = {
        style: activatedStyle,
        ...(!sessionUrl && { disabled: false })
      }
      return <Button {...props}>Activated</Button>
    }
    else if (disabled) {
      return <Icon type="info-circle" theme="filled" color={'#808080'} />
    }
  }, [isActivated, disabled, sessionUrl])

  const activatedSessionUrlTooltip = useMemo(() => (isActivatedWithSessionUrl ? tooltip : {}), [isActivatedWithSessionUrl, tooltip])

  return (
    <Tooltip { ...activatedSessionUrlTooltip }>
      <div {...activatedSessionUrlProps}>
        <Checkbox className={className} disabled={disabled} {...checkBoxProps}>
          <ActivateMenuItem icon={imageUrl}
            isLoading={isLoading}
            title={title}
            tipIcon={tipIcon}
            tooltip={!isActivatedWithSessionUrl ? tooltip : {}}
            leftComp={getLeftSideCmp}
          >
            <ActivateMenuItemText title={title} subTitle={subtitle} />
            {children}
          </ActivateMenuItem>
        </Checkbox>
      </div>
    </Tooltip>
  )
}

SelectCheckBox.propTypes = {
  checked: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  checkBoxProps: PropTypes.object,
  imageUrl: PropTypes.string,
  sessionUrl: PropTypes.string,
  disabled: PropTypes.bool,
  tipIcon: PropTypes.string,
  tooltip: PropTypes.object,
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  isActivated: PropTypes.bool,
  children: PropTypes.node,
}

export default SelectCheckBox
