import React, { useMemo, useCallback, useEffect } from 'react'
import axios from 'axios'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Select } from '@pan/cloud-base'
import ListItem from '../../../components/ListItem'
import {
  renderInstances,
  useMemoFieldDecorator,
} from '../../../utils/activateUtils'
import {
  PANORAMA,
  PANORAMA_INSTANCE_FIELD as PANORAMA_FIELD,
} from '../../../constants/AppConstants'

const renderOptions = deviceList => deviceList.map(({ key, display, extra, value, ...props }) => (
  <Select.Option {...props} key={key} value={`${value}`}>
    <ListItem toolTipProps={{ placement: 'right' }}>{display} {extra}</ListItem>
  </Select.Option>
))
const renderOptionGroups = optionsGroups => optionsGroups.map(([region, deviceList]) => {
  return renderOptions(deviceList)
})

const PrismaSaaSPanorama = (props) => {
  const {
    devices,
    cdlInstance,
    setNewPanoramaSn,
    new_panorama_sn,
    form: {
      getFieldDecorator,
      setFieldsValue,
    },
    stateRef,
  } = props

  const orderedPanoramaDevices = useMemo(() => {
    const filterDevices = devices?.filter(dev => dev?.serial_number && dev.app_id === PANORAMA)
    return _.orderBy(filterDevices, [dev => (!dev.platform_id ? 1 : 0)], ['desc'])
  }, [devices])

  const handlePanoramaChange = useCallback((value) => {
    setNewPanoramaSn(value)
  }, [setNewPanoramaSn])

  useEffect(() => {
    const selectedCdlPanorama = cdlInstance && orderedPanoramaDevices?.find(device => device.platform_id === cdlInstance?.tenant_id)
    if (selectedCdlPanorama && !new_panorama_sn) {
      setNewPanoramaSn(selectedCdlPanorama?.serial_number) // if selected cdl associated with panorama, default it to panorama
    }
    else {
      setFieldsValue({
        [PANORAMA_FIELD]: new_panorama_sn || ''
      })
    }

  }, [orderedPanoramaDevices, cdlInstance, new_panorama_sn, setNewPanoramaSn, setFieldsValue])

  const renderedPanoramaDevices = useMemo(() => {
    const isCdlHasPanorama = orderedPanoramaDevices.some(device => device.platform_id === cdlInstance?.tenant_id)

    const getInstances = () => {
      const list = orderedPanoramaDevices.map(({ serial_number, platform_id }) => {
        return {
          platform_id,
          serial_number,
          value: serial_number,
          instance_status: 'running', // so it will always showing up
        }
      })
      return list
    }

    const isDisabledFn = t => (isCdlHasPanorama ? (t.platform_id !== cdlInstance?.tenant_id) : t.platform_id)
    return renderInstances({
      config: {
        app_id: PANORAMA,
        isDisabledFn,
        extraTenantInfoRenderFn: t => isDisabledFn(t) && '(used)'
      },
      appInfoMap: { // no need the full version from activate utils
        getAnyApp: () => ({ // fake app manifest for panorama
          enabled: true,
          display_name: 'Panorama',
        }),
        getInstances,
      },
      renderOptions,
      renderOptionGroups,
    })
  }, [cdlInstance, orderedPanoramaDevices])

  const panoramaDecorator = useMemoFieldDecorator(PANORAMA_FIELD, {
    initialValue: new_panorama_sn,
    validateFirst: true,
    rules: useMemo(() => {
      return [
        { required: true, message: 'Panorama selection is required' },
        {
          validator(rule, value) {
            const { setupSelectedAccount: selectedAccount } = stateRef.current
            return axios.post('/hub/v2/validate/panorama_support', {
              serial_number: value,
              selectedAccount,
              register_new: false,
              has_support_license: false,
            }).catch((error) => {
              if (!error?.response || error?.response?.status !== 404) {
                const message = error.response?.data?.message || error.message || error
                throw new Error(message)
              }
            })
          },
        },
      ]
    }, [stateRef])
  }, getFieldDecorator)

  return (
    <div className={'hbox middle space-between setup-item'}>
      <label>Panorama</label>
      {panoramaDecorator(
        <Select
          style={{ width: '70%', marginLeft: 10 }}
          onChange={handlePanoramaChange}
          placeholder='Select panorama'>
          {renderedPanoramaDevices}
        </Select>
      )}
    </div>
  )
}

PrismaSaaSPanorama.propTypes = {
  form: PropTypes.object,
  devices: PropTypes.array,
  getAnyApp: PropTypes.func,
  cdlInstance: PropTypes.object,
  setNewPanoramaSn: PropTypes.func,
  new_panorama_sn: PropTypes.string,
  stateRef: PropTypes.any,
  supportAccounts: PropTypes.array.isRequired,
  toBeActivatedApp: PropTypes.array,
  clcsTenant: PropTypes.string,
  setClcsTenant: PropTypes.func
}

export default PrismaSaaSPanorama
