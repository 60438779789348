
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import './crossRegionWarning.scss'

const CrossRegionWarning = ({
  getAnyApp,
  contextApp,
  primaryApp: {
    primaryAppId,
    primaryAppRegion,
  },
  associationApp: {
    appId: associationAppId,
    region: associationAppRegion
  },
}) => {

  const warningMessage = useMemo(() => {
    const primaryAppRegionDisplay = getAnyApp(primaryAppId)?.regions?.find(each => each.name === primaryAppRegion)?.display
    const associationAppRegionDisplay = getAnyApp(associationAppId)?.regions?.find(each => each.name === associationAppRegion)?.display
    return contextApp?.associations?.find(app => app?.app_id === associationAppId)?.cross_region_warning?.replace('{region_display}', primaryAppRegionDisplay)?.replace('{asso_region_display}', associationAppRegionDisplay)
  }, [associationAppId, associationAppRegion, contextApp?.associations, getAnyApp, primaryAppId, primaryAppRegion])

  return <p className='cross-region-warning'>{warningMessage}</p>
}

CrossRegionWarning.propTypes = {
  getAnyApp: PropTypes.func.isRequired,
  contextApp: PropTypes.object.isRequired,
  primaryApp: PropTypes.object.isRequired,
  associationApp: PropTypes.object.isRequired,

}

export default CrossRegionWarning

