/**
 * Created by vsuthar on 7/6/20
 * Project: App Portal ©Palo Alto Networks
 */

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import SetupMiscItem from './SetupMiscItem'

const DateRender = ({ date, label = 'Expiration', wrapperClass = 'hbox middle', format = 'MMMM Do, YYYY', ...rest }) => {
  if (date) {
    return <SetupMiscItem wrapperClass={wrapperClass}
      label={label}
      value={moment(date).format(format)}
      {...rest}
    />
  }
  return null

}

DateRender.propTypes = {
  label: PropTypes.string,
  date: PropTypes.string,
  wrapperClass: PropTypes.string,
  format: PropTypes.string,
}
export default DateRender

