/**
 * Created by vsuthar on 12/10/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { useMemo, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@pan/cloud-base'
import {
  hasAddon,
  hasAppActivation,
  renderInstances,
  useMemoFieldDecorator,
} from '../../../utils/activateUtils'
import {
  ZINGBOX,
  ZINGBOX_3P_ADDON,
  ZINGBOX_NGFW,
  ZINGBOX_NGFW_DRDL,
  ZINGBOX_FIELD,
  PRISMA_ACCESS_EDITION,
  PRISMA_ACCESS_PANORAMA,
  CHOOSE_HOW_TO_MANAGE_FIELD,
  PRISMA_ACCESS,
  SETUP_CREATE_NEW
} from '../../../constants/AppConstants'
import ListItem from '../../../components/ListItem'

const ZINGBOX_NEW = `${ZINGBOX}:${SETUP_CREATE_NEW}`

const renderOptions = tenants => tenants.map(({
  key,
  group,
  display,
  extra,
  value,
  tenant_id,
  ...props
}) => {
  return <Select.Option {...props} key={key} value={`${ZINGBOX}:${value}`}>
    <ListItem toolTipProps={{ placement: 'right' }}>{display} {extra}</ListItem>
  </Select.Option>
})

const renderOptionGroups = optionsGroups => optionsGroups.map(([region, tenants]) => {
  return !region ? renderOptions(tenants) : <Select.OptGroup key={region} label={region}>
    {renderOptions(tenants)}
  </Select.OptGroup>
})

const getIoTInstanceValue = (currentStepObj,
  toBeActivatedApp,
  getAnyApp,
  chooseHowToManage) => {
  const hasNoIoTNgfwActivate = !toBeActivatedApp.some(app => app.checked && [ZINGBOX_NGFW, ZINGBOX_NGFW_DRDL].includes(app.app_id))
  if (hasPreviousAddonIoTSelection(currentStepObj, toBeActivatedApp, getAnyApp)) {
    chooseHowToManage(undefined) //reset CDL tenant to default
    return undefined //reset IoT tenant to default
  }
  else if (hasNoIoTNgfwActivate && currentStepObj?.setupValues?.value === ZINGBOX_NEW) {
    return undefined //reset IoT tenant to default
  }
  return currentStepObj?.setupValues?.value
}


const hasPreviousAddonIoTSelection = (currentStepObj, toBeActivatedApp, getAnyApp) => {
  if (hasAppActivation(toBeActivatedApp, ZINGBOX_NGFW) && hasAppActivation(toBeActivatedApp, ZINGBOX_3P_ADDON)) {
    const value = currentStepObj?.setupValues?.value
    const tenantId = value?.substring(value.lastIndexOf(':') + 1, value.lastIndexOf('||'))
    return getAnyApp(ZINGBOX)?.tenants?.find(tenant => tenant.tenant_id === tenantId && tenant.has_addon)
  }
}

const IoTSecurityTenant = ({
  getAnyApp,
  toBeActivatedApp,
  chooseHowToManage,
  form,
  selectProps,
  currentStepObj,
  fieldName,
  initialValue,
  formValues,
  region,
  isDisabledFn,
  extraTenantInfoRenderFn,
}) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form
  useLayoutEffect(() => {
    const existingValue = getFieldValue(fieldName)
    if (existingValue !== initialValue) {
      setFieldsValue({ [fieldName]: initialValue })
    }
  }, [getFieldValue, setFieldsValue, initialValue, fieldName])

  const zingbox = useMemo(() => getIoTInstanceValue(
    currentStepObj,
    toBeActivatedApp,
    getAnyApp,
    chooseHowToManage,
  ), [currentStepObj, toBeActivatedApp, getAnyApp, chooseHowToManage])
  const renderedIoTInstances = useMemo(() => {
    const rendered = renderInstances({
      config: {
        app_id: ZINGBOX,
        isDisabledFn,
        extraTenantInfoRenderFn,
        /**
         * Make region_matching true when region is true
         */
        region_matching: Boolean(region),
      },
      appInfoMap: { // no need the full version from activate utils
        getAnyApp,
        getInstances: appId => getAnyApp(appId)?.tenants || [],
      },
      renderOptions,
      renderOptionGroups,
      instanceRegionFilter: (instance) => {
        /**
         * If region dont pass by default then just show all tenant.
         */
        if (Boolean(region)) {
          return instance.region === region
        }
        return true
      },
    })
    /**
     * List of app_id allowed to make sure that we default set IoT tenant to new as default option
     */
    if (toBeActivatedApp.some(app => app.checked && [ZINGBOX_NGFW, ZINGBOX_NGFW_DRDL, PRISMA_ACCESS_EDITION].includes(app.app_id))) {
      const newOption = <Select.Option
        key={ZINGBOX_NEW}
        value={ZINGBOX_NEW}
      >Activate New</Select.Option>
      rendered.unshift(newOption)
    }
    return rendered
  }, [getAnyApp, toBeActivatedApp, region, isDisabledFn, extraTenantInfoRenderFn])

  const getIoTDecorator = useMemoFieldDecorator(fieldName, {
    initialValue: initialValue || zingbox,
    rules: useMemo(() => {
      return [
        {
          validator(rule, value, callback) {
            //This needs to be move out side.
            //This validation is only happen for pae with IoT addon
            if (value && Boolean(hasAddon(toBeActivatedApp, PRISMA_ACCESS_EDITION, ZINGBOX))) {
              const chooseHowToManage = formValues?.[CHOOSE_HOW_TO_MANAGE_FIELD]?.split(/[:|]/, 3)?.[1]
              const t_id = value.split(/[:|]/, 2)?.[1]
              const cdl_t_id = getAnyApp(ZINGBOX)?.tenants?.find(each => each.tenant_id === t_id)?.associations?.logging_service?.tenant_id
              let foundCDLAssociation
              const isUsed = [PRISMA_ACCESS, PRISMA_ACCESS_PANORAMA].some(app => {
                return getAnyApp(app)?.tenants?.some(({ associations = {}, platform_id, ...rest }) => {
                  const assoicatedCDLTid = associations?.logging_service?.tenant_id || (platform_id)
                  if (assoicatedCDLTid && cdl_t_id && assoicatedCDLTid === cdl_t_id) {
                    foundCDLAssociation = rest
                    return assoicatedCDLTid === cdl_t_id
                  }
                  return false
                })
              })
              const { tenant_instance_name, tenant_display_name, tenant_id } = foundCDLAssociation || {}
              return isUsed && chooseHowToManage === 'new' ? callback(`Cortex Datalake is already associated with
               ${tenant_instance_name || tenant_display_name || tenant_id} tenant`) : callback()
            }
            callback()
          },
        },
        { required: true, message: 'IoT tenant is required' },
      ]
    }, [getAnyApp, toBeActivatedApp, formValues]),
  }, getFieldDecorator)

  return (
    <div className={'hbox middle space-between setup-item'}>
      <label>Select IoT tenant</label>
      {getIoTDecorator(
        <Select {...selectProps} placeholder='Select IoT tenant'>
          {renderedIoTInstances}
        </Select>,
      )}
    </div>

  )
}

IoTSecurityTenant.getIoTInstanceValue = getIoTInstanceValue
IoTSecurityTenant.hasPreviousAddonIoTSelection = hasPreviousAddonIoTSelection

/**
 *
 * @type {{setClcsTenant, formValues, user_email, fieldName, getAnyApp, chooseHowToManage, currentStepObj, setSelectedFirewallDevices, form, selectProps, toBeActivatedApp, region, initialValue, setNewPanoramaSn}}
 */
IoTSecurityTenant.propTypes = {
  getAnyApp: PropTypes.func,
  toBeActivatedApp: PropTypes.array,
  setSelectedFirewallDevices: PropTypes.func,
  chooseHowToManage: PropTypes.func,
  setNewPanoramaSn: PropTypes.func,
  form: PropTypes.object,
  user_email: PropTypes.string,
  selectProps: PropTypes.object,
  currentStepObj: PropTypes.object,
  /**
   * fieldName Dynamic form field. Default to ZINGBOX_FIELD
   */
  fieldName: PropTypes.string.isRequired,
  /**
   * @initialValue set initial value tenantid|serail_number|region|authcode
   */
  initialValue: PropTypes.string,
  formValues: PropTypes.object,
  /**
   * @region Props passed from the parent to determine region of the iot tenant that needs to be filterd upon
   */
  region: PropTypes.string,
  isDisabledFn: PropTypes.func,
  extraTenantInfoRenderFn: PropTypes.func,

}

IoTSecurityTenant.defaultProps = {
  fieldName: ZINGBOX_FIELD,
  chooseHowToManage: () => undefined,
  isDisabledFn: () => false,
  extraTenantInfoRenderFn: () => '',
}

export default IoTSecurityTenant
