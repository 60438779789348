/**
 * Created by vsuthar on 12/15/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { APP_REGION_FIELD, ZINGBOX, NEW, ZINGBOX_FIELD, IOT_APP_REGION } from '../../../constants/AppConstants'
import { isIoTMultiCdlRegionAllowed, useMemoFieldDecorator } from '../../../utils/activateUtils'

const IoTAppRegion = ({
  cdlInstance,
  getAnyApp,
  form: {
    getFieldValue,
    getFieldDecorator,
    setFieldsValue,
  },
  supportAccounts,
  setupSelectedAccount

}) => {
  const cdlRegion = cdlInstance?.region
  const [, zingboxTenantId] = getFieldValue(ZINGBOX_FIELD)?.split(/[:|]/, 2) || []
  const appRegion = useMemo(() => {
    let appRegions = getAnyApp(ZINGBOX)?.regions
    // logic to support same cdl region to map two different IoT regions, which is required during IoT prod testing
    if (isIoTMultiCdlRegionAllowed(supportAccounts, setupSelectedAccount)) {
      appRegions = appRegions.filter(e => e.name !== 'us_ca')
    }


    if (zingboxTenantId && zingboxTenantId !== NEW) { // APPORTAL-4917 if existing zingbox tenant selected, pick region from it
      const selectedZingbox = getAnyApp(ZINGBOX).tenants?.find(x => x.tenant_id === zingboxTenantId)
      if (selectedZingbox) {
        return appRegions?.find(each => each.name === selectedZingbox.region)
      }
    }
    else if (cdlRegion) {
      return appRegions?.find(each => each.logging_service_region === cdlRegion)
    }
  }, [cdlRegion, getAnyApp, setupSelectedAccount, supportAccounts, zingboxTenantId])

  useEffect(() => {
    if (appRegion) {
      setFieldsValue({
        [APP_REGION_FIELD]: appRegion?.name
      })
    }
  }, [appRegion, setFieldsValue])

  const appRegionDecorator = useMemoFieldDecorator(APP_REGION_FIELD, {
    initialValue: appRegion?.name,
    validateFirst: true,
    rules: useMemo(() => ([{
      validator(rule, value, callback) {
        if (!value) {
          callback(`${IOT_APP_REGION} is required`)
        }
        else {
          callback()
        }
      }
    }]), []),
  }, getFieldDecorator)

  return <div className={'hbox middle space-between setup-item'}>
    <label>IoT App Region</label>
    {
      appRegionDecorator(
        <span>{appRegion?.display}</span>
      )
    }
  </div>
}

IoTAppRegion.propTypes = {
  cdlInstance: PropTypes.object,
  getAnyApp: PropTypes.func,
  form: PropTypes.object,
  supportAccounts: PropTypes.array,
  setupSelectedAccount: PropTypes.string,
}
export default IoTAppRegion

