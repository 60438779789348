/**
 * Created by vsuthar on 6/9/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { DATALAKE_STATUS_COLOR, ZINGBOX_NGFW, LGS_ID } from '../../../constants/AppConstants'
import StatusCard from './StatusCard'

const STATUS_PRIORITY = [
  'error',
  'pending',
  'warning',
  'expired',
  'stopped',
  'running',
  'good',
  'ready',
  'provisioning',
  'default',
].reduce((o, s, i) => {
  const p = i + 1
  o[s] = p
  o[p] = s
  return o
}, {})

const IoTNGFStatus = ({
  label,
  sub_title,
  description,
  actionPanel,
  instances = [],
  statuses = instances,
  entitledAppsList,
}) => {
  const cdlInstance = useMemo(() => {
    const platform_id = instances.find(app => app?.app_id === ZINGBOX_NGFW)?.platform_id
    return platform_id && entitledAppsList?.get(LGS_ID)?.tenants?.find(tenant => tenant.tenant_id === platform_id)
  }, [entitledAppsList, instances])

  const status = useMemo(() => {
    const statusPriortyArr = statuses.filter(inst => inst.app_id === ZINGBOX_NGFW)
      .map(each => STATUS_PRIORITY[each.status] || STATUS_PRIORITY.error)
    const aggStatus = _.min(statusPriortyArr)
    return STATUS_PRIORITY[aggStatus] || 'error'
  }, [statuses])

  const { tenant_display_name, tenant_instance_name } = cdlInstance || {}
  const style = useMemo(() => {
    return {
      borderLeft: `3px solid rgb(${DATALAKE_STATUS_COLOR[status]})`,
      background: `linear-gradient(90deg, rgba(${DATALAKE_STATUS_COLOR[status]},0.08) 0.74%, rgba(${DATALAKE_STATUS_COLOR[status]},0) 100%)`,
    }
  }, [status])

  return <StatusCard title={label} description={description} actionPanel={actionPanel} style={style} tenantName={tenant_display_name || tenant_instance_name}/>
}

IoTNGFStatus.propTypes = {
  label: PropTypes.string,
  sub_title: PropTypes.string,
  description: PropTypes.string,
  actionPanel: PropTypes.node,
  instances: PropTypes.array,
  statuses: PropTypes.array,
  entitledAppsList: PropTypes.array
}
export default IoTNGFStatus

