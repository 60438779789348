import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  useMemoFieldDecorator,
  indexArray,
  FT,
} from '../../../utils/activateUtils'
import FirewallDetails from '../common/firewall_selection/FirewallDetails'
import FirewallModal from '../common/firewall_selection/FirewallModal'
import FirewallListTable from '../common/firewall_selection/FirewallListTable'
import {
  LGS_ID,
  NGFW,
  PRISMA_SAAS_FIREWALL_LIST_TITLE,
  PRISMA_SAAS_FIREWALL_LIST_SUBTITLE,
  PRISMA_SAAS,
  PRISMA_SAAS_NGFW,
  SETUP_CREATE_NEW,
  CHOOSE_HOW_TO_MANAGE_FIELD,
  ELA,
} from '../../../constants/AppConstants'
// import { licenseData, deviceData } from '../prisma_saas/mockData'
import FirewallSelection from '../common/ela/FirewallSelection'

const CDL_DOCS_LINK = 'https://docs.paloaltonetworks.com/cortex/cortex-data-lake/cortex-data-lake-getting-started.html'

const PrismaSaasFirewallStep = ({
  form,
  formValues,
  session,
  devices,
  getAnyApp,
  setFirewallModalVisible,
  isFirewallListVisible,
  cdlInstance,
  entitledAppsList,
  setSelectedFirewallDevices,
  selectedFirewallDevices,
  supportAccounts,
  setupSelectedAccount,
  toBeActivatedApp,
  context,
  mode,
  stateRef,
}) => {
  // ngfw list can exists in aperture app(API license) or aperture_ngfw app(inline license)
  const prismaSaasNgfwLicenseData = useMemo(() => session?.licenses?.find(license => [PRISMA_SAAS, PRISMA_SAAS_NGFW].includes(license.app_id) && !license.activated), [session])
  const prismaSaasNgfwEntitlements = useMemo(() => indexArray(entitledAppsList.get(PRISMA_SAAS_NGFW)?.tenants, 'serial_number'), [entitledAppsList]) // TODO: may need to use devices[0]
  const cdlList = useMemo(() => getAnyApp(LGS_ID)?.tenants, [getAnyApp])
  const platformId = cdlInstance?.tenant_id
  const prismaSaaSTenantId = useMemo(() => formValues[CHOOSE_HOW_TO_MANAGE_FIELD]?.split(/[:|]/, 2)[1] || SETUP_CREATE_NEW, [formValues])

  // check if devices are already associated with prisma saas, if associated - add to deviceData only if selected prisma saas tenant matches with association
  const filteredDevices = useMemo(() => {
    return devices.filter(device => {
      const deviceCdl = device.fls_tenant_id || device.platform_id
      return device.app_id === NGFW && (!deviceCdl || platformId === deviceCdl)
    }).reduce((result, device) => {
      const tenant = prismaSaasNgfwEntitlements.get(device.serial_number)
      const prismaSaas = tenant?.associations?.aperture?.tenant_id
      if (!prismaSaas || prismaSaas === prismaSaaSTenantId) {
        result.push(device)
      }
      return result
    }, [])
  }, [devices, platformId, prismaSaasNgfwEntitlements, prismaSaaSTenantId])

  const renderModalSubTitle = useMemo(() => {
    const cspAccName = supportAccounts.get(+setupSelectedAccount)?.support_account_name || ''
    const [str1, str2] = PRISMA_SAAS_FIREWALL_LIST_SUBTITLE.split('__ACCOUNT_NAME__')
    const [str3, str4] = str2.split('__STEPS_LINK__')
    return <>
      <span>{str1}</span>
      <span style={{ fontWeight: 'bold' }}>{cspAccName}</span>
      <span>{str3}</span>
      <a href={CDL_DOCS_LINK} target='_blank' rel='noopener noreferrer'><b>steps</b></a>
      <span>{str4}</span>
    </>
  }, [supportAccounts, setupSelectedAccount])

  const fieldDecorator = useMemoFieldDecorator('firewalls', {
    initialValue: selectedFirewallDevices,
    valuePropName: 'value',
    rules: useMemo(() => [{
      validator(rule, value, callback) {
        if (!value.length) {
          callback('Please select a device')
        }
        else if (value.length > FT('FIREWALL_BATCH_LIMIT', 5)) { //APPORTAL-4069
          callback(`SaaS Security Inline subscriptions can be activated on a maximum of ${FT('FIREWALL_BATCH_LIMIT')} firewalls at once`)
        }
        else if (value.some(item => !item.auth_code)) {
          callback('Unable to apply firewall license')
        }
        else {
          callback()
        }
      }
    }], [])
  }, form)

  return <>
    {mode === ELA ? fieldDecorator(
      <FirewallSelection
        licenseData={prismaSaasNgfwLicenseData}
        deviceData={filteredDevices}
        entitlementTenants={prismaSaasNgfwEntitlements}
        selectedFirewallDevices={selectedFirewallDevices}
        setSelectedFirewallDevices={setSelectedFirewallDevices}
        context={PRISMA_SAAS}
      />
    ) : fieldDecorator(
      <FirewallDetails
        licenseData={prismaSaasNgfwLicenseData}
        deviceData={filteredDevices}
        entitlementTenants={prismaSaasNgfwEntitlements}
        selectedFirewallDevices={selectedFirewallDevices}
        setSelectedFirewallDevices={setSelectedFirewallDevices}
        toBeActivatedApp={toBeActivatedApp}
        context={context}
        currentStepObj={stateRef.currentStepObj}
      />
    )}
    <FirewallModal
      headers={{
        title: PRISMA_SAAS_FIREWALL_LIST_TITLE,
        subTitle: renderModalSubTitle,
      }}
      body={<FirewallListTable firewallData={devices} cdlList={cdlList} />}
      visible={isFirewallListVisible}
      setFirewallModalVisible={setFirewallModalVisible}
    />
  </>
}

PrismaSaasFirewallStep.defaultProps = {
  isFirewallListVisible: false
}

PrismaSaasFirewallStep.propTypes = {
  form: PropTypes.object,
  formValues: PropTypes.object,
  session: PropTypes.shape({
    licenses: PropTypes.array.isRequired
  }).isRequired,
  devices: PropTypes.array.isRequired,
  setFirewallModalVisible: PropTypes.func,
  isFirewallListVisible: PropTypes.bool,
  getAnyApp: PropTypes.func,
  cdlInstance: PropTypes.object,
  entitledAppsList: PropTypes.array.isRequired,
  selectedFirewallDevices: PropTypes.array.isRequired,
  setSelectedFirewallDevices: PropTypes.func,
  supportAccounts: PropTypes.array.isRequired,
  setupSelectedAccount: PropTypes.number.isRequired,
  toBeActivatedApp: PropTypes.array.isRequired,
  context: PropTypes.string.isRequired,
  mode: PropTypes.string,
  stateRef: PropTypes.any,
}

export default PrismaSaasFirewallStep

