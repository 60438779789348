import React from 'react'
import {
  SETUP_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
  LABEL_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
  LABEL_SELECT_PRODUCTS,
  SETUP_SELECT_PRODUCTS,
  LGS_ID,
  ZINGBOX,
  ZINGBOX_NGFW,
  ZINGBOX_NGFW_DRDL,
  ZINGBOX_3P_ADDON,
} from '../../../constants/AppConstants'
import { CheckLight, CogsLight, KeyIcon, UserShieldLight } from '../../../images/svg-icons'

/**
 * Created by vsuthar on 6/5/20
 * Project: App Portal ©Palo Alto Networks
 */

const backBtnProps = {
  v2: false,
  label: 'Back',
}

const nextBtnProps = {
  v2: false,
  label: 'Next'
}

const progressStep = {
  title: {
    intermediate_title: 'Activating IoT Security Portal...',
    final_title: 'IoT Security Portal Activated.',
    sub_header: 'After the firewalls begin forwarding logs to the logging service, IoT Security will begin monitoring your network'
  },
  miscComponent: [
    'CMP_SUPPORT_ACCOUNT',
    'IOT_PORTAL',
    'CMP_DOMAIN',
    'CMP_REGION',
    'CMP_DATALAKE_READ',
    'FIREWALL_SUBSCRIPTION',
    'CMP_EXPIRATION',
    'IOT_3P_ADD_ON'
  ],
  recommendedSteps: {
    showOTP: true,
    showPSK: true,
    showDataLakeStatus: true,
    showDeviceCertificate: true,
    buttons: [
      'REC_ASSIGN_ROLE',
      'REC_MANAGE_APPS'
    ]
  },
  usefulLinks: [
    {
      component_type: 'SET_UP_LINK',
      href: 'https://docs.paloaltonetworks.com/iot/iot-security-admin.html',
      label: 'IoT Security Administrator Guide'
    },
    {
      component_type: 'SET_UP_LINK',
      href: 'https://docs.paloaltonetworks.com/iot/iot-security-best-practices.html',
      label: 'IoT Security Best Practices'
    },
    {
      component_type: 'SET_UP_LINK',
      href: 'https://docs.paloaltonetworks.com/panorama/10-1/panorama-admin/manage-firewalls/install-the-device-certificate-for-managed-firewalls.html',
      label: 'Device certificate configuration (Panorama)'
    },
    {
      component_type: 'SET_UP_LINK',
      href: 'https://docs.paloaltonetworks.com/pan-os/10-1/pan-os-admin/certificate-management/obtain-certificates/device-certificate.html',
      label: 'Device certificate configuration (PAN-OS)'
    }
  ],
  keyFeature: [
    {
      title: <a target={'_blank'} rel={'noopener noreferrer'} href={'https://live.paloaltonetworks.com/t5/5g-iot-onboarding/iot-security-solution-overview-video/ta-p/337021'}>Introduction to IoT Security</a>,
      content: 'Provide an overview of the challenges with securing the IoT devices, how the IoT Security solution addresses these challenges, key values it provides.'
    },
    {
      title: <a target={'_blank'} rel={'noopener noreferrer'} href={'https://live.paloaltonetworks.com/t5/5g-iot-onboarding/iot-security-product-demo-part-1-and-2/ta-p/337023'}>IoT Security Demo</a>,
      content: 'Provides a quick demonstration of the IoT Security covering key features.'
    },
    {
      title: <a target={'_blank'} rel={'noopener noreferrer'} href="https://docs.paloaltonetworks.com/iot/iot-security-best-practices.html">IoT Security Best Practices</a>,
      subTitle: '',
      content: 'The Best Practices guide provides a list of best practices for planning, onboarding, and monitoring your IoT Security deployment'
    },
    {
      title: <a target={'_blank'} rel={'noopener noreferrer'} href={'https://docs.paloaltonetworks.com/panorama/10-1/panorama-admin/manage-firewalls/install-the-device-certificate-for-managed-firewalls.html'}>Device certificate configuration (using the Panorama management server)</a>,
      subTitle: '',
      content: 'Read how to use the Panorama management server to install device certificates on your firewalls'
    },
    {
      title: <a target={'_blank'} rel={'noopener noreferrer'} href={'https://docs.paloaltonetworks.com/pan-os/10-1/pan-os-admin/certificate-management/obtain-certificates/device-certificate.html'}>Device certificate configuration (next-generation firewall)</a>,
      subTitle: '',
      content: 'Read how to use the Panorama management server to install device certificates on your firewalls'
    },
  ],
}
export const IoT = {
  theme: {
    primaryColor: '#5482B7',
    secondaryColor: ''
  },
  steps: [
    {
      title: LABEL_SELECT_PRODUCTS,
      icon: KeyIcon,
      style: {
        maxWidth: 820,
        minWidth: 820,
      },
      key: 0,
      allowedApps: [LGS_ID, ZINGBOX, ZINGBOX_NGFW, ZINGBOX_NGFW_DRDL, ZINGBOX_3P_ADDON],
      comp_enum: 'SETUP_ACTIVATE_PRODUCT',
      setup_enum: SETUP_SELECT_PRODUCTS,
      type: ZINGBOX,
      description: 'The following products are available for activation. Please select which products you would like to activate now:',
      backBtnProps: { ...backBtnProps, label: 'Quit', href: '/apps' },
      nextBtnProps: { ...nextBtnProps, label: 'Start Activation' },
      help: '',
      current: '0',
      prev: null,
      next: '1',
    },
    {
      title: LABEL_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
      icon: UserShieldLight,
      style: {
        maxWidth: 820,
        minWidth: 820,
      },
      responsiveConfig: { xl: 12, xxl: 10, lg: 16, md: 18, sm: 23 },
      key: 1,
      comp_enum: 'SETUP_ACCOUNT_SELECTION',
      setup_enum: SETUP_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
      type: ZINGBOX,
      description: 'We have detected you have multiple support accounts. Please assign your new products to a support account. If you would like to assign these new products to different support accounts, you may do so.',
      backBtnProps,
      nextBtnProps,
      help: '',
      current: '1',
      prev: '0',
      next: '2',
      fields: false
    },
    {
      title: 'IoT Security Setup',
      icon: CogsLight,
      responsiveConfig: { xl: 10, xxl: 9, lg: 14, md: 18, sm: 23 },
      style: {
        maxWidth: 820,
        minWidth: 820,
      },
      key: 2,
      comp_enum: 'IOT_SECURITY_SETUP',
      setup_enum: 'IOT_SECURITY_SETUP',
      type: ZINGBOX,
      description: 'Complete the IoT Security app URL and make tenant, logging, and management selections.',
      backBtnProps,
      nextBtnProps,
      help: '',
      current: '2',
      prev: '1',
      next: '3'
    },
    {
      title: 'Select Firewalls to enable IoT Security subscription',
      icon: CogsLight,
      style: {
        maxWidth: 1000,
        minWidth: 1000,
      },
      responsiveConfig: { xl: 12, xxl: 10, lg: 16, md: 16, sm: 18 },
      key: 3,
      comp_enum: 'SELECT_FIREWALL',
      setup_enum: 'SELECT_FIREWALL',
      type: ZINGBOX,
      description: 'Select a model and then select subscriptions for individual devices: IoT=IoT Security; IoT-DRDL=IoT Security-Doesn’t Require Data Lake. Firewalls associated with other data lakes are listed in the __LINK__. To activate more firewalls later, click Activate in the original email.',
      ela_description: 'Enterprise License Agreement (ELA): Select up to 200 firewalls per activation session. Firewalls associated with other data lakes are listed in the __LINK__. To activate more, return to ELA Activations in the Hub.',
      backBtnProps,
      nextBtnProps,
      help: 'https://docs.paloaltonetworks.com/iot/iot-security-admin/get-started-with-iot-security/onboard-iot-security',
      current: '3',
      prev: '2',
      next: '4',
    },
    {
      title: 'Activate Subscriptions',
      icon: CheckLight,
      style: {
        maxWidth: 820,
        minWidth: 820,
      },
      show_data_lake: false,
      show_cmp: ['CMP_SUPPORT_ACCOUNT', 'IOT_PORTAL', 'CMP_REGION', 'CMP_DATALAKE_READ', 'CMP_EXPIRATION', 'CMP_TERM_CONDITION', 'FIREWALL_SUBSCRIPTION', 'IOT_PANORAMA'],
      comp_enum: 'CONFIRMATION',
      type: ZINGBOX,
      responsiveConfig: { xl: 8, xxl: 6, lg: 12, md: 18, sm: 18 },
      key: 4,
      description: 'Activate your subscriptions to complete the setup',
      backBtnProps,
      nextBtnProps: { ...nextBtnProps, label: 'Activate Subscription' },
      prev: '3',
      current: '4',
    }
  ],
  progressStep
}
