import React, { useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@pan/cloud-base'
import {
  renderInstances,
  useMemoFieldDecorator,
} from '../../../utils/activateUtils'
import {
  PRISMA_SAAS,
  SETUP_PRISMA_SAAS_NEW,
  LGS_ID,
  SETUP_CREATE_NEW,
  RUNNING,
  PROVISIONING,
  PENDING,
  WARNING,
  FAILED,
  NEW,
  UPGRADE,
  SAAS_REST,
  SAAS_INLINE,
} from '../../../constants/AppConstants'
import ListItem from '../../../components/ListItem'

//custom license-types
const PRISMA_SAAS_INLINE = 'saas_inline'
const PRISMA_SAAS_API_INLINE = 'saas_api_inline'

const allowedInstanceStatuses = new Set([RUNNING, PROVISIONING, PENDING, FAILED, WARNING])

const renderOptions = tenants => tenants.map(({
  key,
  group,
  display,
  extra,
  value,
  tenant_id,
  ...props
}) => {
  return <Select.Option {...props} key={key} value={`${PRISMA_SAAS}:${value}`}>
    <ListItem toolTipProps={{ placement: 'right' }}>{display} {extra}</ListItem>
  </Select.Option>
})

const renderOptionGroups = optionsGroups => optionsGroups.map(([region, tenants]) => {
  return !region ? renderOptions(tenants) : <Select.OptGroup key={region} label={region}>
    {renderOptions(tenants)}
  </Select.OptGroup>
})

const PrismaSaasTenant = ({
  getAnyApp,
  getAppInstances,
  form,
  selectProps,
  fieldName,
  initialValue,
  region,
  cdlInstance,
  isDisabled,
  tenantUpgradeContext,
  prismaSaasRegion,
  toBeActivatedApp,
  isOnlyRestActivation,
}) => {
  const { getFieldDecorator, setFieldsValue, validateFields, getFieldValue } = form
  const renderedPrismaSaasInstances = useMemo(() => {
    // if activation has inline and tenant already has rest+inline or inline license, it cannot be used for other inline activation
    const isTenantNotSelectable = (tenant) => !isOnlyRestActivation && [PRISMA_SAAS_API_INLINE, PRISMA_SAAS_INLINE].includes(tenant.license_type)

    const isCdlAssociatedSaasTenant = (tenant) => {
      const associatedCdl = tenant?.associations[LGS_ID]
      return associatedCdl && associatedCdl?.tenant_id === cdlInstance?.tenant_id
    }
    const getInstancesPropsFn = () => {
      if (tenantUpgradeContext === SAAS_REST) {
        return ({ optionsProps }) => {
          optionsProps?.forEach(each => each.value += `||${UPGRADE}`)
        }
      }
      else if (tenantUpgradeContext === SAAS_INLINE) {
        const restAuthCode = toBeActivatedApp.find(app => app.app_id === PRISMA_SAAS)?.auth_code
        return ({ optionsProps }) => {
          optionsProps?.forEach(each => each.value += `|${restAuthCode}|${UPGRADE}`)
        }
      }
      return undefined
    }

    const rendered = renderInstances({
      config: {
        app_id: PRISMA_SAAS,
        isDisabledFn: (tenant) => isTenantNotSelectable(tenant),
        extraTenantInfoRenderFn: (tenant) => (isTenantNotSelectable(tenant) && !isCdlAssociatedSaasTenant(tenant) ? '(used)' : ''),
        instancesPropsFn: getInstancesPropsFn(),

        /**
         * Make region_matching true when region is true
         */
        region_matching: Boolean(region),
        allowedInstanceStatuses,
      },
      appInfoMap: { // no need the full version from activate utils
        getAnyApp,
        getInstances: appId => {
          if (prismaSaasRegion && !isOnlyRestActivation) {
            return getAnyApp(appId)?.tenants?.filter(tenant => tenant?.region === prismaSaasRegion)
          }
          else if (isOnlyRestActivation) {
            return getAnyApp(appId)?.tenants
          }
          else {
            return []
          }
        },
      },
      renderOptions,
      renderOptionGroups,
      instanceRegionFilter: (instance) => {
        /**
         * If region dont pass by default then just show all tenant.
         */
        if (Boolean(region)) {
          return instance.region === region
        }
        return true
      },
    })
    // Skip "Activate new" option if saas-inline activating on csp account that has SaaS REST tenant without existing aperture ngfw tenant
    // or show "Activate new" option when SaaS Rest activation only
    if (initialValue === `${PRISMA_SAAS}:${SETUP_CREATE_NEW}` || isOnlyRestActivation) {
      const newOption = <Select.Option
        key={SETUP_PRISMA_SAAS_NEW}
        value={SETUP_PRISMA_SAAS_NEW}
      >Activate New</Select.Option>
      rendered.unshift(newOption)
    }
    return rendered
  }, [tenantUpgradeContext, region, getAnyApp, cdlInstance?.tenant_id, prismaSaasRegion, initialValue, toBeActivatedApp, isOnlyRestActivation])

  const isInstanceNotAvailable = useCallback((tenantId) => getAppInstances(PRISMA_SAAS)?.find(tenant => tenant?.tenant_id === tenantId)?.instance_status !== RUNNING, [getAppInstances])

  const getPrismaSaasDecorator = useMemoFieldDecorator(fieldName, {
    initialValue,
    rules: useMemo(() => [
      { required: true, message: 'SaaS Security tenant is required' },
      {
        validator(rule, value, callback) {
          const tenantId = value?.split(/[:|]/, 2)?.[1]
          if (tenantId && tenantId !== NEW && isInstanceNotAvailable(tenantId)) {
            callback('SaaS Security tenant is not in running state')
          }
          else {
            callback()
          }
        }
      },
    ], [isInstanceNotAvailable]),
  }, getFieldDecorator)

  // const handlePrismaSaasSelection = useCallback((value) => {
  //   setSelectedFirewallDevices({}) //reset firewall device selection
  //   setFieldsValue({ [fieldName]: value })
  //   setTimeout(() => validateFields([SUBDOMAIN_FIELD], { force: true }), 100)
  // }, [validateFields, setSelectedFirewallDevices, fieldName, setFieldsValue])

  useEffect(() => {
    if (cdlInstance || (isOnlyRestActivation && getFieldValue(fieldName) !== initialValue)) {
      setFieldsValue({
        [fieldName]: initialValue
      })
    }

  }, [cdlInstance, setFieldsValue, fieldName, initialValue, renderedPrismaSaasInstances, getFieldValue, isOnlyRestActivation])

  useEffect(() => {
    if (initialValue) {
      validateFields([fieldName], { force: true })
    }
  }, [initialValue, fieldName, validateFields])


  return (
    <div className={'hbox middle space-between setup-item'}>
      <label>SaaS Tenant</label>
      {getPrismaSaasDecorator(
        <Select
          {...selectProps}
          // onSelect={handlePrismaSaasSelection}
          placeholder='Select SaaS Security tenant'
          disabled={isDisabled}>
          {renderedPrismaSaasInstances}
        </Select>
      )}
    </div>

  )
}

/**
 *
 * @type {{user_email, fieldName, getAnyApp, currentStepObj, form, selectProps, region, initialValue}}
 */
PrismaSaasTenant.propTypes = {
  getAnyApp: PropTypes.func,
  getAppInstances: PropTypes.func,
  form: PropTypes.object,
  user_email: PropTypes.string,
  selectProps: PropTypes.object,
  currentStepObj: PropTypes.object,
  cdlInstance: PropTypes.object,
  isDisabled: PropTypes.bool,
  fieldName: PropTypes.string,
  /**
   * @initialValue set initial value tenantid|serail_number|region|authcode
   */
  initialValue: PropTypes.string,
  /**
   * @region Props passed from the parent to determine region of the saas security tenant that needs to be filtered upon
   */
  region: PropTypes.string,
  tenantUpgradeContext: PropTypes.string,
  prismaSaasRegion: PropTypes.string,
  toBeActivatedApp: PropTypes.array,
  isOnlyRestActivation: PropTypes.bool,
}

export default PrismaSaasTenant

