import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Table, Tooltip } from '@pan/cloud-base'
import './firewallSubscribedTable.scss'
import { FT } from '../../../../utils/activateUtils'
import { IOT_FIREWALL_SUBSCRIBED_COLOR_CODES, ZINGBOX_NGFW, NA, LCAAS_CONFIG_ERROR, LCAAS_UNAVAILABLE_STATUS, INSTALLED, ABSENT, LCAAS_CONFIG_ERROR_TOOLTIP } from '../../../../constants/AppConstants'
import { useInterval } from '../../../../utils/hooks'
import ListItem from '../../../../components/ListItem'

const theadTh = {
  textTransform: 'none',
  color: '#000000',
  fontSize: '12px',
  fontWeight: 'bold',
  backgroundColor: '#fff',
}

const trGroup = {
  minHeight: '30px',
  borderBottom: 'none',
}

const thead = {
  borderBottom: 'none',
  backgroundColor: '#fff'
}

const dotStyle = {
  height: '10px',
  width: '10px',
  borderRadius: '50%',
  display: 'inline-block',
  marginRight: '5px',
  minWidth: '10px'
}

const renderWithTooltip = (value) => <ListItem toolTipProps={{ placement: 'right' }}>{value || '-'}</ListItem>

const renderDotElement = (message, dotStyle) => {
  return <div className='hbox middle'>
    <div style={{ ...dotStyle }}></div>
    <ListItem toolTipProps={{ placement: 'right' }}>
      {message || NA}
    </ListItem>
  </div>
}

const renderWithColorCode = (original) => {
  const { certs, status, message } = original

  if (certs?.lcaas || certs?.thermite) {
    dotStyle.backgroundColor = IOT_FIREWALL_SUBSCRIBED_COLOR_CODES[status?.toLowerCase()] || IOT_FIREWALL_SUBSCRIBED_COLOR_CODES.default
    return renderDotElement(message, dotStyle)
  }

  dotStyle.backgroundColor = IOT_FIREWALL_SUBSCRIBED_COLOR_CODES[LCAAS_UNAVAILABLE_STATUS]
  return <div className='hbox middle'>
    <div style={{ ...dotStyle }}></div>
    <Tooltip placement='right' title={LCAAS_CONFIG_ERROR_TOOLTIP}>
      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{LCAAS_CONFIG_ERROR}</div>
    </Tooltip>
  </div>
}

const renderCertStatus = (value) => {
  const message = value ? INSTALLED : ABSENT
  dotStyle.backgroundColor = value ? IOT_FIREWALL_SUBSCRIBED_COLOR_CODES.thermite_available : IOT_FIREWALL_SUBSCRIBED_COLOR_CODES.thermite_unavailable
  return renderDotElement(message, dotStyle)
}

export const columns = [
  {
    Header: 'Model',
    accessor: 'model',
    Cell: ({ value }) => renderWithTooltip(value),
  },
  {
    Header: 'Serial Number',
    accessor: 'serial_number',
    Cell: ({ value }) => renderWithTooltip(value),
  },
  // {//TODO enable when HUB BE enables it
  //   Header: 'Device Name',
  //   accessor: 'device',
  //   width: 120,
  //   Cell: ({ value }) => hyphenOnEmptyValue(value),
  // },
  {
    Header: 'Expiration',
    accessor: 'license_expiration',
    Cell: ({ value }) => renderWithTooltip(moment(value).format('MMM-DD-YYYY')),
  },
  {
    Header: 'Logging Service Status',
    accessor: 'status',
    Cell: ({ original }) => renderWithColorCode(original),
    width: 150,
  },
  {
    Header: 'Device Certificate',  //thermite cert.
    accessor: 'certs',
    Cell: ({ value }) => renderCertStatus(value?.thermite),
  },
]

const FirewallSubscribedTable = ({ session, refreshSession }) => {
  useEffect(refreshSession, [refreshSession]) // initial poll

  const data = useMemo(() => {
    const fwInstances = session?.statuses || session?.instances?.filter(inst => inst.app_id === ZINGBOX_NGFW) || []
    return fwInstances
  }, [session])

  return <>
    <Table
      className='fw-subscribed-tbl'
      styles={{ thead, theadTh, trGroup }}
      sortable={true}
      autoScrollbar={true}
      columns={columns}
      data={data}
      defaultSorted={[{
        id: 'model',
        desc: true
      }]}
    />
  </>
}

FirewallSubscribedTable.propTypes = {
  session: PropTypes.object.isRequired,
  refreshSession: PropTypes.func.isRequired,
}

const FirewallSubscribedTableRefresh = ({ session, pollTime, refreshSession }) => {
  useInterval(refreshSession, pollTime) // polling
  return <FirewallSubscribedTable session={session} />
}

FirewallSubscribedTableRefresh.propTypes = {
  session: PropTypes.object.isRequired,
  pollTime: PropTypes.number,
  refreshSession: PropTypes.func.isRequired,
}

const exported = FT('NO_PUSH_EVENTS') ? FirewallSubscribedTable : FirewallSubscribedTableRefresh
export default exported
