import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  useMemoFieldDecorator,
  indexArray,
  FT,
} from '../../../utils/activateUtils'
import FirewallDetails from '../common/firewall_selection/FirewallDetails'
import FirewallModal from '../common/firewall_selection/FirewallModal'
import FirewallListTable from '../common/firewall_selection/FirewallListTable'
import {
  LGS_ID,
  AIOPS_FIREWALL_LIST_TITLE,
  AIOPS_FIREWALL_LIST_SUBTITLE,
  STRATA_INSIGHTS_NGFW,
  SETUP_CREATE_NEW,
  ELA,
  CHOOSE_HOW_TO_MANAGE_FIELD,
} from '../../../constants/AppConstants'
// import { licenseData, deviceData } from '../aiops/mockData'
import FirewallSelection from '../common/ela/FirewallSelection'

const CDL_DOCS_LINK = 'https://docs.paloaltonetworks.com/cortex/cortex-data-lake/cortex-data-lake-getting-started.html'

const AiopsFirewallStep = ({
  form,
  formValues,
  session,
  devices,
  getAnyApp,
  setFirewallModalVisible,
  isFirewallListVisible,
  cdlInstance,
  entitledAppsList,
  setSelectedFirewallDevices,
  selectedFirewallDevices,
  supportAccounts,
  setupSelectedAccount,
  toBeActivatedApp,
  context,
  mode,
  stateRef,
}) => {
  const aiopsNgfwLicenseData = useMemo(() => session?.licenses?.find(license => license.app_id === STRATA_INSIGHTS_NGFW), [session])
  const aiopsNgfwEntitlements = useMemo(() => indexArray(entitledAppsList.get(STRATA_INSIGHTS_NGFW)?.tenants, 'serial_number'), [entitledAppsList])
  const cdlList = useMemo(() => getAnyApp(LGS_ID)?.tenants, [getAnyApp])

  const aiopsTenantId = useMemo(() => formValues[CHOOSE_HOW_TO_MANAGE_FIELD]?.split(/[:|]/, 2)[1] || SETUP_CREATE_NEW, [formValues])
  // check if devices are already associated with aiops, if associated - add to deviceData only if selected aiops tenant matches with association
  const filteredDevices = useMemo(() => {
    const { tenant_id: platformId } = cdlInstance || {}
    // let filterDevices = devices.filter(device => device.app_id === NGFW)
    const filterDevices = devices.filter(device => {
      const deviceCdl = device.fls_tenant_id || device.platform_id
      return !deviceCdl || platformId === deviceCdl
    })
    return filterDevices.reduce((result, device) => {
      const tenant = aiopsNgfwEntitlements.get(device.serial_number)
      const aiops = tenant?.associations?.strata_insights?.tenant_id
      if (!aiops || aiops === aiopsTenantId) {
        result.push(device)
      }
      return result
    }, [])
  }, [devices, cdlInstance, aiopsNgfwEntitlements, aiopsTenantId])

  const renderModalSubTitle = useMemo(() => {
    const cspAccName = supportAccounts.get(+setupSelectedAccount)?.support_account_name || ''
    const [str1, str2] = AIOPS_FIREWALL_LIST_SUBTITLE.split('__ACCOUNT_NAME__')
    const [str3, str4] = str2.split('__STEPS_LINK__')
    return <>
      <span>{str1}</span>
      <span style={{ fontWeight: 'bold' }}>{cspAccName}</span>
      <span>{str3}</span>
      <a href={CDL_DOCS_LINK} target='_blank' rel='noopener noreferrer' ><b>steps</b></a>
      <span>{str4}</span>
    </>
  }, [supportAccounts, setupSelectedAccount])

  const fieldDecorator = useMemoFieldDecorator('firewalls', {
    initialValue: selectedFirewallDevices,
    valuePropName: 'value',
    rules: useMemo(() => [{
      validator(rule, value, callback) {
        if (!value.length) {
          callback('Please select a device')
        }
        else if (value.length > FT('FIREWALL_BATCH_LIMIT', 5)) { //APPORTAL-4069
          callback(`AIOps for NGFW subscriptions can be activated on a maximum of ${FT('FIREWALL_BATCH_LIMIT')} firewalls at once`)
        }
        else if (value.some(item => !item.auth_code)) {
          callback('Unable to apply firewall license')
        }
        else {
          callback()
        }
      }
    }], [])
  }, form)

  return <>
    {mode === ELA ? fieldDecorator(
      <FirewallSelection
        licenseData={aiopsNgfwLicenseData}
        deviceData={filteredDevices}
        entitlementTenants={aiopsNgfwEntitlements}
        selectedFirewallDevices={selectedFirewallDevices}
        setSelectedFirewallDevices={setSelectedFirewallDevices}
        context={context}
      />
    ) : fieldDecorator(
      <FirewallDetails
        currentStepObj={stateRef.current.currentStepObj}
        licenseData={aiopsNgfwLicenseData}
        deviceData={filteredDevices}
        cdlList={cdlList}
        entitlementTenants={aiopsNgfwEntitlements}
        selectedFirewallDevices={selectedFirewallDevices}
        setSelectedFirewallDevices={setSelectedFirewallDevices}
        aiopsTenantId={aiopsTenantId}
        toBeActivatedApp={toBeActivatedApp}
        context={context}
      />
    )}
    <FirewallModal
      headers={{
        title: AIOPS_FIREWALL_LIST_TITLE,
        subTitle: renderModalSubTitle,
      }}
      body={<FirewallListTable firewallData={devices} cdlList={cdlList} />}
      visible={isFirewallListVisible}
      setFirewallModalVisible={setFirewallModalVisible}
    />
  </>
}

AiopsFirewallStep.defaultProps = {
  isFirewallListVisible: false
}

AiopsFirewallStep.propTypes = {
  form: PropTypes.object,
  formValues: PropTypes.object,
  session: PropTypes.shape({
    licenses: PropTypes.array.isRequired
  }).isRequired,
  devices: PropTypes.array.isRequired,
  setFirewallModalVisible: PropTypes.func,
  isFirewallListVisible: PropTypes.bool,
  getAnyApp: PropTypes.func,
  cdlInstance: PropTypes.object,
  entitledAppsList: PropTypes.array.isRequired,
  selectedFirewallDevices: PropTypes.array.isRequired,
  setSelectedFirewallDevices: PropTypes.func,
  supportAccounts: PropTypes.array.isRequired,
  setupSelectedAccount: PropTypes.number.isRequired,
  toBeActivatedApp: PropTypes.array.isRequired,
  context: PropTypes.string.isRequired,
  mode: PropTypes.string,
  stateRef: PropTypes.any,
}

export default AiopsFirewallStep

