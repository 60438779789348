/**
 * Created by vsuthar on 4/9/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Checkbox } from '@pan/cloud-base'
import { useMemoFieldDecorator, useDeepMemo, isNonemptyArray, FT } from '../../../utils/activateUtils'
import SelectCheckBox from '../../../components/Setup/SelectCheckBox'
import {
  LGS_ID,
  CHOOSE_LICENSES_FIELD,
  NOT_ALLOWED_ACTIVATE_ALONE_APPS_SET, ZINGBOX, PRISMA_ACCESS_EDITION,
} from '../../../constants/AppConstants'
import './activateProduct.scss'
import CapacityRender from '../CapacityRender'
import SetupAdditionalProduct from '../SetupAdditionalProduct'

export const fieldName = CHOOSE_LICENSES_FIELD
const ActivateProduct = ({
  hasMSP,
  toBeActivatedApp = [],
  onProductChecked,
  isLoading,
  stateRef,
  form: { getFieldDecorator, validateFields }
}) => {
  const history = useHistory()
  const [checkedIds, isCDLRequired] = useDeepMemo(() => {
    const ids = toBeActivatedApp.reduce((ids, app, idx) => {
      if (app?.checked && app.app_id) {
        ids.add(`${app.app_id}-${idx}`)
      }
      return ids
    }, new Set())

    const findCDLFromAssociation = (arr) => {
      return Array.isArray(arr) && arr.find(eachApp => eachApp.app_id === LGS_ID)
    }
    const isCDLRequired = toBeActivatedApp.some(eachObj => {
      return findCDLFromAssociation(eachObj.associations)
    })
    return [Array.from(ids), isCDLRequired]
  }, [toBeActivatedApp])

  // APPORTAL-4849 if license is fedral but user doesn't have federal account, block process
  const noFedAccountWithFedLicense = useMemo(() => {
    const accounts = stateRef.current.supportAccounts
    const isCustomerHasFedralAccount = accounts?.some(account => account.is_federal)
    const isFederalLicense = toBeActivatedApp?.some(app => app.is_federal_license)
    return isFederalLicense && !isCustomerHasFedralAccount
  }, [stateRef, toBeActivatedApp])

  const fieldDecorator = useMemoFieldDecorator(fieldName, {
    initialValue: checkedIds,
    rules: useMemo(() => [{
      required: true,
      message: 'You have to select at-least one of the product to activate',
    },
    {
      validator: (rule, value, callback) => {
        if (toBeActivatedApp.find(each => value.includes(each.key))?.lockedInfo?.doNotMoveFurther) {
          callback('MSP Activated Tenant can not be amend here.')
        }
        if (isNonemptyArray(value) && value.every((val) => NOT_ALLOWED_ACTIVATE_ALONE_APPS_SET.has(val.split('-', 1)[0]))) {
          callback('You can not activate Prisma Access Panorama alone.')
        }
        if (isNonemptyArray(value) && FT('DOD_ENV') && (!value?.join(',').includes(LGS_ID) || !value?.join(',').includes(PRISMA_ACCESS_EDITION))) {
          callback('We can not activate Logging Service or Prisma Access service alone in this environment')
        }
        if (isNonemptyArray(value) && Boolean(hasMSP)) {
          const isIOT = value?.some(val => {
            const selectedProd = toBeActivatedApp.find(each => each.key === val)
            return selectedProd?.addons?.some(each => each.app_id === ZINGBOX)
          })
          if (isIOT) {
            callback('MSP: Prisma Access Edition with IoT is not supported. Please contact support!')
          }
          callback()
        }
        if (noFedAccountWithFedLicense) {
          callback('You cannot activate because you don\'t have a federal account')
        }
        else {
          callback()
        }
      },
    }], [hasMSP, toBeActivatedApp, noFedAccountWithFedLicense]),
  }, getFieldDecorator)

  const { context } = stateRef.current

  const items = toBeActivatedApp.length > 0 && toBeActivatedApp.map((eachApp, idx) => {
    const {
      logo,
      display_name,
      app_id,
      checked,
      allowed,
      activated,
      session_id,
      selectable,
      no_support_license,
      purchased_size_render,
    } = eachApp
    const title = (activated && session_id && 'Product has been activated, click to view the progress') ||
      (activated && 'Product has been activated') ||
      (allowed === false && 'Products need to be activated separately') ||
      (no_support_license && 'A support license is required to activate it with other products') ||
      'Duplicate products need to be activated individually'

    return (
      <SelectCheckBox
        isLoading={isLoading}
        tooltip={{
          title,
          placement: 'left',
          trigger: 'hover',
        }}
        tipIcon={'info-circle'}
        value={`${app_id}-${idx}`}
        key={`${app_id}-${idx}`}
        checked={checked}
        disabled={!selectable || activated}
        isActivated={activated}
        sessionUrl={session_id ? `/${context}/${session_id}` : undefined}
        imageUrl={logo}
        title={display_name}
        subtitle={
          <div>
            <CapacityRender purchased_size_render={purchased_size_render} app_id={app_id} />
            {/*{!additional_base_product.length && !addons.length && dateRender(license_expiration)}*/}
          </div>
        }
        history={history}
      >
        {<SetupAdditionalProduct app={eachApp} />}
      </SelectCheckBox>
    )
  })

  useEffect(() => { // just once
    if (!isLoading) {
      validateFields([fieldName])
    }
  }, [isLoading, validateFields])

  return <>
    {fieldDecorator(
      <Checkbox.Group className={'activate-product'} onChange={onProductChecked}>
        {items}
        {isCDLRequired &&
          <span className={'data-lake-note'}>
            <b>Note:</b> A valid Cortex Data Lake instance is required for the selected products and can be activated as part of this activation
          </span>
        }
      </Checkbox.Group>,
    )}
  </>
}

ActivateProduct.propTypes = {
  toBeActivatedApp: PropTypes.array,
  onProductChecked: PropTypes.func,
  getFieldDecorator: PropTypes.func,
  stateRef: PropTypes.any,
  form: PropTypes.any,
  isLoading: PropTypes.bool,
  hasMSP: PropTypes.bool,
}


ActivateProduct.defaultProps = {
  onProductChecked: () => {
  },
  onActivateProduct: () => {
  },
}

// use this to avoid create field with empty value
export const ActivateProductLoadingPlaceholder = (props) => {
  if (!props.toBeActivatedApp.length) {
    if (!props.isLoading) {
      return <Checkbox.Group className={'activate-product'} />
    }
    return (
      <Checkbox.Group className={'activate-product'}>
        <SelectCheckBox
          isLoading={true}
          tipIcon={'info-circle'}
          value={'placeholder1'}
          key={'placeholder1'}
          checked={false}
          disabled={true}
        />
      </Checkbox.Group>
    )
  }

  return <ActivateProduct {...props} />
}

ActivateProductLoadingPlaceholder.propTypes = {
  toBeActivatedApp: PropTypes.array,
  isLoading: PropTypes.bool,
}

export default ActivateProductLoadingPlaceholder
