import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './ControlledToggle.scss'

const prefixCls = 'ctrl-toggle'
const ControlledToggle = ({
  className,
  checked,
  disabled,
  onClick,
  ...restProps
}) => {
  const propsRef = useRef({})
  propsRef.current.checked = checked
  propsRef.current.onClick = onClick
  const onInternalClick = useCallback((e) => {
    const { onClick, checked } = propsRef.current
    onClick?.(!checked, e)
  }, [])

  const classname = classNames(prefixCls, className, {
    [`${prefixCls}-checked`]: checked,
    [`${prefixCls}-disabled`]: disabled,
  })

  return (
    <button
      {...restProps}
      type='button'
      role='switch'
      aria-checked={checked}
      disabled={disabled}
      className={classname}
      onClick={onInternalClick}
    >
      <svg className={`${prefixCls}-svg`} xmlns="http://www.w3.org/2000/svg" fill="#fff" viewBox="0 0 512 512">
        <path className={`${prefixCls}-svg-checked`} d="M504 256a248 248 0 11-496 0 248 248 0 01496 0zM227.3 387.3l184-184a16 16 0 000-22.6L388.7 158a16 16 0 00-22.6 0L216 308l-70-70a16 16 0 00-22.7 0l-22.6 22.6a16 16 0 000 22.6l104 104a16 16 0 0022.6 0z" />
        <path className={`${prefixCls}-svg-unchecked`} d="M256 8a248 248 0 100 496 248 248 0 000-496zM124 296a12 12 0 01-12-12v-56a12 12 0 0112-12h264a12 12 0 0112 12v56a12 12 0 01-12 12H124z"/>
      </svg>
    </button>
  )
}

ControlledToggle.displayName = 'ControlledToggle'

ControlledToggle.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  // onChange,
}

export default ControlledToggle
