import React, { useMemo, useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@pan/cloud-base'
import { DIRECTORY_SYNC_APP_REGION_FIELD, DSS_ID, NEW, DIRECTORY_SYNC_REGION, DIRECTORY_SYNC_FIELD, APP_REGION_FIELD, STRATA_INSIGHTS } from '../../../constants/AppConstants'
import { filterRegions, useMemoFieldDecorator } from '../../../utils/activateUtils'
import CrossRegionWarning from './CrossRegionWarning'
import { FT } from '@pan/cloud-appframework'

const DirectorySyncRegion = (props) => {
  const {
    getAnyApp,
    form: {
      getFieldValue,
      getFieldDecorator,
      setFieldsValue,
    },
    dropDownProps,
    stateRef,
    getAppInstances,
    fieldName,
    primaryAppId,
    cdlInstance
  } = props

  const primaryAppRegion = getFieldValue(APP_REGION_FIELD)
  const dssTenantId = getFieldValue(fieldName)?.split(/[:|]/, 2)?.[1]
  const [dssRegion, setDssRegion] = useState(undefined)
  const cdlRegion = cdlInstance?.region
  const isAIopsMultiRegion = FT('AIOPS_MULTI_REGION') && primaryAppId === STRATA_INSIGHTS

  useEffect(() => {
    if (dssTenantId && dssTenantId !== NEW) {
      const dssTenantRegion = getAppInstances(DSS_ID)?.find(each => each?.tenant_id === dssTenantId)?.region
      setDssRegion(dssTenantRegion)
      setFieldsValue({
        [DIRECTORY_SYNC_APP_REGION_FIELD]: dssTenantRegion
      })
    }
    else {
      const dssTenantRegion = isAIopsMultiRegion && cdlRegion && dssTenantId ? cdlRegion : undefined
      setFieldsValue({
        [DIRECTORY_SYNC_APP_REGION_FIELD]: dssTenantRegion
      })
      setDssRegion(dssTenantRegion)
    }
  }, [isAIopsMultiRegion, cdlRegion, dssTenantId, getAppInstances, primaryAppRegion, setFieldsValue])
  const { accountIsFederal } = stateRef.current
  const dssRegions = useMemo(() => getAnyApp(DSS_ID)?.regions, [getAnyApp])
  const availableRegions = useMemo(() => filterRegions(dssRegions, accountIsFederal), [dssRegions, accountIsFederal])

  const regionHandler = useCallback((value) => {
    setDssRegion(value)
    setFieldsValue({
      [DIRECTORY_SYNC_APP_REGION_FIELD]: value
    })
  }, [setFieldsValue])

  const isDssCrossRegion = primaryAppRegion && dssRegion && primaryAppRegion !== dssRegion

  const appRegionDecorator = useMemoFieldDecorator(DIRECTORY_SYNC_APP_REGION_FIELD, {
    initialValue: dssRegion,
    validateFirst: true,
    rules: useMemo(() => [{
      validator(rule, value, callback) {
        if (dssTenantId && !value) {
          callback(`${DIRECTORY_SYNC_REGION} is required`)
        }
        else {
          callback()
        }
      }
    }], [dssTenantId])
  }, getFieldDecorator)
  return <>
    <div className={'hbox middle space-between setup-item'}>
      <label>{DIRECTORY_SYNC_REGION}</label>
      {appRegionDecorator(
        <Select
          {...dropDownProps}
          placeholder={'Select Region'}
          disabled={dssTenantId !== NEW || isAIopsMultiRegion}
          onSelect={regionHandler}
        >
          {availableRegions.map(({ display, value }) => {
            return <Select.Option key={value} value={value}>{display}</Select.Option>
          })}
        </Select>
      )}
    </div>
    {isDssCrossRegion && <CrossRegionWarning
      {...props}
      primaryApp={{ primaryAppId, primaryAppRegion }}
      associationApp={{ appId: DSS_ID, region: dssRegion }}
    />}
  </>

}

DirectorySyncRegion.propTypes = {
  getAnyApp: PropTypes.func,
  form: PropTypes.object,
  formValues: PropTypes.object,
  dropDownProps: PropTypes.object,
  stateRef: PropTypes.any,
  getAppInstances: PropTypes.func,
  fieldName: PropTypes.string.isRequired,
  contextApp: PropTypes.object,
  primaryAppId: PropTypes.string,
  cdlInstance: PropTypes.object,
}

DirectorySyncRegion.defaultProps = {
  getAnyApp: () => undefined,
  fieldName: DIRECTORY_SYNC_FIELD,
  dropDownProps: {
    style: {
      width: '70%'
    },
  },
}

export default DirectorySyncRegion

