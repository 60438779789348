/**
 * Created by vsuthar on 8/25/20
 * Project: App Portal ©Palo Alto Networks
 */
import React from 'react'
import PropTypes from 'prop-types'
import './ActivateMenuItemText.scss'

const ActivateMenuItemText = ({ title, subTitle }) => {
  return <div className={'activate-menu-item-text'}>
    <span className={'activate-title'}>{title}</span>
    <span className={'activate-subtitle'}>{subTitle}</span>
  </div>
}

ActivateMenuItemText.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.node,
}

export default ActivateMenuItemText
