/**
 * Created by vsuthar on 8/25/20
 * Project: App Portal ©Palo Alto Networks
 */
import React from 'react'
import PropTypes from 'prop-types'
import { SETUP_PAE_LABEL, SETUP_CAPACITY_LABEL_ENUM, SETUP_CAPACITY } from '../../constants/AppConstants'
import { Tooltip } from '@pan/cloud-base'
import SetupMiscItem from '../../components/Setup/SetupMiscItem'
import { isNonemptyArray } from '../../utils/common'

const createToolTip = (tooltips) => {
  return tooltips.map((each, idx) => {
    const { label, value } = each
    return <SetupMiscItem key={idx} labelProps={{ style: { marginRight: 2 } }} label={`${label}:`} value={value} wrapperClass={'hbox middle'} />
  })
}

const renderPurchasedSizes = (purchased_size_render) => {
  return purchased_size_render.map(({ label, value, isToolTip, tooltips }, idx) => {
    let labelRender = label
    if (isToolTip && isNonemptyArray(tooltips)) {
      labelRender = <Tooltip title={createToolTip(tooltips)} >
        <div style={{ textDecoration: 'underline' }}>{`${label}`}</div>
      </Tooltip>
    }
    if (value && labelRender) {
      return <SetupMiscItem key={idx} labelProps={{ style: { whiteSpace: 'nowrap' } }}
        label={labelRender} value={`: ${value}`} valueProps={{ style: { whiteSpace: 'nowrap' } }} wrapperClass={'hbox middle'} />
    }
    return null
  })
}

const CapacityRender = ({ purchased_size_render, app_id }) => {
  const label = SETUP_PAE_LABEL[app_id] || SETUP_CAPACITY
  if (purchased_size_render) {
    return <div className={'vbox'}>
      {Array.isArray(purchased_size_render) ? renderPurchasedSizes(purchased_size_render) : <SetupMiscItem label={`${label}:`}
        labelProps={{ style: { marginRight: 5 } }}
        wrapperClass={'hbox middle'}
        value={SETUP_CAPACITY_LABEL_ENUM[purchased_size_render] || purchased_size_render} />}
    </div>
  }
  return null

}

CapacityRender.propTypes = {
  purchased_size_render: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  app_id: PropTypes.string,
}

export default CapacityRender
