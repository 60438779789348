/**
 * Created by vsuthar on 4/16/20
 * Project: App Portal ©Palo Alto Networks
 */

import React from 'react'
import PropTypes from 'prop-types'
import SetUpSupportAccounts from './SetUpSupportAccount'
import SetupMiscItem from './SetupMiscItem'
import SetupTermCondition from './SetupTermCondition'
import AppUrl from '../../containers/Setup/common/AppUrl'
import DataLakeDomain from '../../containers/Setup/common/DataLakeDomain'
import IoTThirdPartyAddOn from '../../containers/Setup/iot/IoTThirdPartyAddOn'
import DateRender from './DateRender'
import { SET_UP_TYPE_LINK, SET_UP_TYPE_SIMPLE_TEXT, ZINGBOX, PRISMA_SAAS } from '../../constants/AppConstants'
import { SetupSimpleLink, SetupSimpleText } from './SetupSimpleComponents'

const ComponentFactory = (cmp, props) => {
  switch (cmp) {
    case 'CMP_EXPIRATION':
      return <DateRender {...props} />
    case 'CMP_SUPPORT_ACCOUNT':
      return <SetUpSupportAccounts {...props} />
    case 'CMP_DOMAIN':
      return <SetupMiscItem {...props} />
    case 'CMP_TERM_CONDITION':
      return <SetupTermCondition {...props} />
    case 'IOT_PORTAL':
      return <AppUrl {...props} appContext={ZINGBOX} label={'IoT Security Portal'}/>
    case 'CMP_DATALAKE_READ':
      return <DataLakeDomain {...props} appContext={ZINGBOX}/>
    case 'IOT_3P_ADD_ON':
      return <IoTThirdPartyAddOn {...props} />
    case SET_UP_TYPE_LINK:
      return <SetupSimpleLink {...props} />
    case SET_UP_TYPE_SIMPLE_TEXT:
      return <SetupSimpleText {...props} />
    case 'PRISMA_SAAS_URL':
      return <AppUrl {...props} appContext={PRISMA_SAAS} label={'SaaS Subdomain'} />
    default:
      return null
  }
}

ComponentFactory.propTypes = {
  prop: PropTypes.object
}

export default ComponentFactory
