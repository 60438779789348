/**
 * Created by vsuthar on 4/9/20
 * Project: App Portal ©Palo Alto Networks
 */
import _ from 'lodash'
import {
  SET_UP_INIT_LICENSE,
  PRODUCT_SELECT,
  SET_CDL_SELECTION,
  SET_UP_BACK_STEP,
  SET_UP_NEXT_STEP,
  SETUP_ACCOUNT_SELECT,
  HELP_DRAWER,
  CHOOSE_HOW_TO_MANAGE,
  APPS_LIST_UPDATE,
  SET_NEW_PANORAMA_SN,
  SET_DEVIECS,
  OVERRIDE_CONTEXT,
  FIREWALL_LIST_VISIBLE,
  SET_SUBSCRIBED_FIREWALL_POLL_TIME,
  SET_SELECTED_FIREWALL_DEVICES,
  SET_ERROR,
  SET_NEW_CLCS_TENANT,
} from '../../config/actions/ActionConstants'
import {
  LGS_ID,
  PRISMA_ACCESS,
  PRISMA_ACCESS_PANORAMA,
  SETUP_CREATE_NEW,
  SETUP_NEW_PANORAMA,
  PRISMA_ACCESS_FOR_CLEAN_PIPE,
  PRISMA_ACCESS_MOBILE_USERS,
  PRISMA_ACCESS_REMOTE_NETWORKS,
  ZINGBOX_NGFW,
  ZINGBOX_NGFW_DRDL,
  ZINGBOX,
  ZINGBOX_3P_ADDON,
  CONTACT_SUPPORT_URL,
  SETUP_PAE_LABEL,
  SETUP_CAPACITY,
  SETUP_CAPACITY_LABEL_USE_LICENSE_DISPLAY,
  SETUP_SERVICE_LOCATION,
  SETUP_SERVICE_LOCATION_VAL_LCL,
  SETUP_SERVICE_LOCATION_VAL_GBL,
  IOT_PACKAGE_2,
  IOT_PACKAGE_1,
  PRISMA_ACCESS_EDITION,
  _ONPREM,
  PANORAMA,
  SETUP_LICENSED_QUANTITY,
  CHOOSE_HOW_TO_MANAGE_FIELD,
  PANORAMA_INSTANCE_FIELD,
  CHOOSE_LICENSES_FIELD,
  CDL_INSTANCE_FIELD,
  REGION_FIELD,
  APP_REGION_FIELD,
  PAE_ADDON_ZINGBOX_TNT,
  PAE_ADDON_SAAS_TNT,
  PAE_ADDON_DLP_TNT,
  SETUP_ADDON_APP_IOT,
  SETUP_ADDON_APP_SAAS_INLINE,
  SETUP_ADDON_APP_DLP,
  NONE,
  AUTONOMOUS_DEM,
  PRISMA_SAAS,
  DLP,
  PRISMA_SAAS_NGFW,
  ELA,
  STRATA_INSIGHTS_NGFW,
  STRATA_INSIGHTS,
  DIRECTORY_SYNC_FIELD,
  DSS_ID,
  DIRECTORY_SYNC_APP_REGION_FIELD
} from '../../constants/AppConstants'
import { SetupSteps } from './SetupSteps'
import { UNHANDLED_EXCEPTION } from '../../constants/ErrorCode'
import { FT, hasAddon, indexArray, isNonemptyArray, getContextApp, hasOnlyIoTThirdPartyActivation, isActivatingOnlySaasRest, isActivatingSaasInlineAndRest, isMultiRegionAllowed } from '../../utils/activateUtils'

/**
 * @TODO move to app manifest along with steps
 * @type {({border: boolean, label: string, href: string}|{border: boolean, label: string, href: string}|{label: string, href: string}|{border: boolean, label: string, href: string}|{label: string, href: string})[]}
 */
//TODO move to app def
const sizeMapping = {
  [PRISMA_ACCESS_REMOTE_NETWORKS]: 'Mbps',
  [PRISMA_ACCESS_FOR_CLEAN_PIPE]: 'Mbps',
  [PRISMA_ACCESS_MOBILE_USERS]: 'Users',
  [SETUP_NEW_PANORAMA]: '',
  [LGS_ID]: 'TB',
  [ZINGBOX_NGFW]: 'Firewalls',
  [ZINGBOX_NGFW_DRDL]: 'Firewalls',
  [PRISMA_SAAS]: 'Users',
  [PRISMA_SAAS_NGFW]: 'Firewalls',
  [STRATA_INSIGHTS_NGFW]: 'Firewalls',
  'MU': 'Mobile Users',
  'RN': 'Remote Networks',
  'MURN': 'Mobile Users & Remote Networks'
}

const SHOW_ADDON_PURCHASED_SIZE = [AUTONOMOUS_DEM]
/**
 *
 * @type {{currentStep: number, toBeActivatedApp: [], currentStepObj: {nextBtnProps: {label: string, v2: boolean}, backBtnProps: {label: string, v2: boolean}, icon, description: string, title: string, key: number}, steps: [{nextBtnProps: {label: string, v2: boolean}, backBtnProps: {label: string, v2: boolean}, icon: *, description: string, title: string, key: number}, {nextBtnProps: {label: string, v2: boolean}, backBtnProps: {label: string, v2: boolean}, icon: *, description: string, title: string, key: number}, {nextBtnProps: {label: string, v2: boolean}, backBtnProps: {label: string, v2: boolean}, icon: *, description: string, title: string, key: number}, {nextBtnProps: {label: string, v2: boolean}, backBtnProps: {label: string, v2: boolean}, icon: *, description: string, title: string, key: number}, {nextBtnProps: {label: string, v2: boolean}, backBtnProps: {label: string, v2: boolean}, icon: *, description: string, title: string, key: number}]}}
 */
const setup = {
  currentStep: 0, // if u want to jump different state define here instead of jump 0
  setupSteps: SetupSteps,
  steps: [], // number of stage to activate app
  currentStepObj: {}, // if u want to jump some default state define here
  toBeActivatedApp: [], // app needs to be activated,
  setupSelectedAccount: undefined, // selected setup account,
  cdlInstance: {},
  productToBeActivated: [],
  isDrawerOpen: false,
  stepsHelp: {},
  formValues: {},
  selectedFirewallDevices: [],
  isFirewallListVisible: false,
  isFirewallSubscribedVisible: false,
  mode: undefined, //ela
  getAnyApp: _.noop,
  getAppInstance: _.noop,
  getAppInstances: _.noop,
}


const findCurrentSteps = (array, index) => {
  return array.findIndex(eachObj => {
    const { current } = eachObj
    if (current === index) {
      return true
    }
    return eachObj.hasOwnProperty(index)

  })
}

const hasOnlyIoTAddon = (toBeActivatedApp) => {
  return !toBeActivatedApp.some(app => app.checked && [ZINGBOX_NGFW, ZINGBOX_NGFW_DRDL].includes(app.app_id))
}

// determines if step needs to skip
const getNextStep = (steps, currentStep, toBeActivatedApp, has_firewall_bundle) => {
  const { type, comp_enum, next } = steps[currentStep] || {}
  if (type === ZINGBOX && comp_enum === 'IOT_SECURITY_SETUP' && (hasOnlyIoTAddon(toBeActivatedApp) || has_firewall_bundle)) {
    return (+next + 1).toString()
  }
  if (type === PRISMA_SAAS && comp_enum === 'PRISMA_SAAS_SECURITY_SETUP' && (isActivatingOnlySaasRest(toBeActivatedApp) || has_firewall_bundle)) {
    return (+next + 1).toString()
  }
  if (type === STRATA_INSIGHTS && comp_enum === 'STRATA_INSIGHTS_SECURITY_SETUP' && has_firewall_bundle) {
    return (+next + 1).toString()
  }
  return next
}

const getNextObj = ({ steps, toBeActivatedApp, session: { has_firewall_bundle }, getAnyApp, supportAccounts, setupSelectedAccount }, currentStep, formValues) => {
  const next = getNextStep(steps, currentStep, toBeActivatedApp, has_firewall_bundle)
  const current = steps[currentStep]?.current
  const prev = steps[currentStep]?.prev
  const nextStepIndex = findCurrentSteps(steps, next)
  // dont modify object if its -1
  let currentStepObj = steps[nextStepIndex] ? steps[nextStepIndex] : steps[steps.length - 1]
  if (currentStepObj?.current.includes('.')) {
    currentStepObj = steps[nextStepIndex][next]
    currentStepObj.prev = current
  }
  else {
    currentStepObj.prev = nextStepIndex === -1 ? prev : current
  }
  // keep adding formValues for each steps.
  // we can collect all values for each step when we submit form on last steps
  const findCurrentIndex = findCurrentSteps(steps, current)
  steps[findCurrentIndex]._formValues = formValues
  const allFormValues = Object.assign({}, ...steps.map(step => step?._formValues))
  const productToBeActivated = findCurrentIndex === steps.length - 1 ? buildProductToBeActivated(allFormValues, toBeActivatedApp, getAnyApp, supportAccounts, setupSelectedAccount) : []
  if (!currentStepObj.setupValues && currentStepObj.defaultSetupValues) {
    currentStepObj.setupValues = { ...currentStepObj.defaultSetupValues }
  }
  return {
    formValues: allFormValues,
    currentStep: currentStepObj.key,
    next,
    currentStepObj,
    productToBeActivated
  }
}

const getBackObj = ({ steps }, currentStep) => {
  const prev = steps[currentStep]?.prev || []
  currentStep = findCurrentSteps(steps, prev)
  const currentStepObj = steps[currentStep] ? steps[currentStep] : steps[steps.length - 1]
  return {
    // formValues: Object.assign({}, ...steps.map(step => step?._formValues)),
    currentStep: steps[currentStep] ? currentStep : ++currentStep,
    currentStepObj
  }
}

const getPurchasedSizeRender = (each, licenseQuantity) => {
  const { purchased_size, purchased_sizes = [], app_id: master_app_id, license_type = '', addons } = each
  //Default to purchased_size from base product of all magic link payload is not backward compatible
  let purchased_size_render = purchased_size ? (sizeMapping[master_app_id] ? `${purchased_size} ${sizeMapping[master_app_id]}` : `${purchased_size}`) : [] // dont render capacity if u dont have it
  // We will remove whole block when PAE 2.0 is done.
  if (!FT('PAE_IOT') && isNonemptyArray(purchased_sizes)) {
    purchased_size_render = purchased_sizes.map((each, idx) => {
      const { app_id, license_display_name, purchased_size } = each
      const label = SETUP_PAE_LABEL[app_id] ? `${SETUP_PAE_LABEL[app_id]}` :
        license_display_name && SETUP_CAPACITY_LABEL_USE_LICENSE_DISPLAY.includes(app_id) ?
          license_display_name : SETUP_CAPACITY
      // check if have PAE label available for Licensed Quantity
      if (SETUP_PAE_LABEL[app_id] && purchased_sizes.length) {
        licenseQuantity += `${purchased_size} ${SETUP_PAE_LABEL[app_id]}`
        if (purchased_sizes.length > 1 && idx < purchased_sizes.length - 1) {
          licenseQuantity += ' & '
        }
        else if (purchased_sizes.length === 1) {
          licenseQuantity += ' only'
        }
      }
      return {
        ...each,
        isToolTip: master_app_id === PRISMA_ACCESS_EDITION,
        label,
        value: purchased_size ? `${purchased_size} ${sizeMapping[app_id] || ''}` : null
      }
    })
    if (license_type.includes('LCL')) {
      purchased_size_render.unshift({
        label: SETUP_SERVICE_LOCATION,
        value: SETUP_SERVICE_LOCATION_VAL_LCL
      })
    }
    else if (license_type.includes('GBL')) {
      purchased_size_render.unshift({
        label: SETUP_SERVICE_LOCATION,
        value: SETUP_SERVICE_LOCATION_VAL_GBL
      })
    }
    if (purchased_sizes && Array.isArray(purchased_sizes) && purchased_sizes.length && licenseQuantity) {
      const label = 'Licensed Quantity'
      const value = licenseQuantity
      purchased_size_render.unshift({
        label, value
      })
    }
  }

  if (FT('PAE_IOT') && isNonemptyArray(purchased_sizes)) {
    /**
     * PAE needs the tooltip
     * https://www.figma.com/file/p7KNmqKKQt8URLXJHJocd9/Prisma-Access-%7C-Ideal-CX-%E2%80%93-Activation-%2F-Eval?node-id=24141%3A83&viewport=-3189%2C33963%2C1.061875343322754
     */
    if (master_app_id === PRISMA_ACCESS_EDITION) {
      let value = ''
      const tooltips = []
      purchased_sizes.forEach((each, idx) => {
        const { app_id, purchased_size } = each
        if (SETUP_PAE_LABEL[app_id] && purchased_sizes.length) {
          value += `${purchased_size} ${SETUP_PAE_LABEL[app_id]}`
          if (purchased_sizes.length > 1 && idx < purchased_sizes.length - 1) {
            value += ' & '
          }
          else if (purchased_sizes.length === 1) {
            value += ' only'
          }
        }
        tooltips.push({
          label: `${SETUP_PAE_LABEL[app_id]} ${SETUP_CAPACITY}`,
          value: purchased_size ? `${purchased_size} ${sizeMapping[app_id] || ''}` : purchased_size,
        })
      })
      /*addons.forEach(each => {
        if (each.license_display_name && each.purchased_size) {
          tooltips.push({
            label: each?.license_display_name,
            value: each?.purchased_size
          })
        }

      })*/
      purchased_size_render.push({
        label: SETUP_LICENSED_QUANTITY,
        value,
        isToolTip: tooltips.length > 0,
        tooltips,
      })
      if (license_type.includes('LCL')) {
        purchased_size_render.push({
          label: SETUP_SERVICE_LOCATION,
          value: SETUP_SERVICE_LOCATION_VAL_LCL
        })
      }
      else if (license_type.includes('GBL')) {
        purchased_size_render.push({
          label: SETUP_SERVICE_LOCATION,
          value: SETUP_SERVICE_LOCATION_VAL_GBL
        })
      }
    }
    else {
      purchased_size_render = purchased_sizes.map(each => {
        const { app_id, license_display_name, purchased_size } = each
        const label = SETUP_PAE_LABEL[app_id] ? `${SETUP_PAE_LABEL[app_id]}` :
          license_display_name && SETUP_CAPACITY_LABEL_USE_LICENSE_DISPLAY.includes(app_id) ?
            license_display_name : SETUP_CAPACITY
        return {
          label,
          value: `${purchased_size} ${sizeMapping[app_id] || ''}`
        }
      })
    }
  }

  if (isNonemptyArray(addons)) {
    const generateDisplayName = ({ app_id, license_display_name, purchased_size, license_unit }) => {
      // TODO: make generic
      if (isNonemptyArray(license_display_name)) {
        let displayName = ''
        for (const dName of license_display_name) {
          displayName += `${dName}, `
        }
        displayName = displayName.replace(/,\s$/, '')
        return displayName
      }
      else {
        let displayName = license_display_name
        if (SHOW_ADDON_PURCHASED_SIZE.includes(app_id)) {
          if (isNonemptyArray(license_unit) && isNonemptyArray(purchased_size)) {
            // expect license_unit and purchased_size length is the same
            displayName += ' ('
            for (let i = 0; i < purchased_size.length; i++) {
              displayName += `${purchased_size[i]} ${sizeMapping[license_unit[i]]} & `
            }
            displayName = displayName.replace(/\s&\s$/, '')
            displayName += ')'
          }
          return displayName
        }
        else {
          return displayName
        }
      }
    }
    each.addons = addons.map((obj) => {
      const { license_display_name, app_id, license_unit, purchased_size } = obj
      if (FT('GROUP_ADDONS') && obj.is_grouped) {
        return {
          ui_display_name: generateDisplayName({ app_id, license_display_name, purchased_size, license_unit }),
          ...obj,
        }
      }
      if (SHOW_ADDON_PURCHASED_SIZE.includes(app_id)) {
        return {
          ui_display_name: `${license_display_name} (${purchased_size} ${sizeMapping[license_unit]})`,
          ...obj
        }
      }
      return {
        ui_display_name: license_display_name,
        ...obj
      }
    })
  }

  return purchased_size_render
}


//TODO we can have it app manifest
const hasAppSpecificRegion = {
  [PRISMA_SAAS]: true,
  [PRISMA_SAAS_NGFW]: true,
  [ZINGBOX]: true,
  [ZINGBOX_NGFW]: true,
  [ZINGBOX_3P_ADDON]: true,
  [STRATA_INSIGHTS]: true,
  [STRATA_INSIGHTS_NGFW]: true
}
/**
 * defines if an app needs to lookup it's own region value,
 * based on selected CDL region
 */
const needRegionLookUp = {
  [PRISMA_ACCESS]: true,
  [PRISMA_ACCESS_EDITION]: true,
  [PRISMA_ACCESS_PANORAMA]: true,
  [`${PRISMA_ACCESS_EDITION}${_ONPREM}`]: true,
  [PRISMA_ACCESS_FOR_CLEAN_PIPE]: true,
  [PRISMA_ACCESS_MOBILE_USERS]: true,
  [PRISMA_ACCESS_REMOTE_NETWORKS]: true,
}


const modifyApps = (apps = [], mode) => {
  const constructZingboxNgfwDrdl = (app, licenseV2) => {
    const zingbox_ngfw_drdl = _.cloneDeep(app)
    const licenses = licenseV2.map(license => {
      license.app_id = ZINGBOX_NGFW_DRDL
      license.display_name = license.license_display_name
      return license
    })
    return {
      ...zingbox_ngfw_drdl,
      licenses,
      app_id: ZINGBOX_NGFW_DRDL,
      display_name: licenses?.[0]?.display_name,
      ...(mode !== ELA && { purchased_size: licenses.length }), // ignore purchase_size for ela
    }
  }

  return apps.reduce((result, app) => {
    if ([PRISMA_SAAS, STRATA_INSIGHTS].includes(app.app_id)) {
      app.display_name = app.license_display_name
    }

    if ([PRISMA_SAAS_NGFW, STRATA_INSIGHTS_NGFW].includes(app.app_id) && isNonemptyArray(app?.licenses)) {
      app.display_name = app?.licenses[0].license_display_name // overwrite display name
      if (isNonemptyArray(app?.licenses[0].addons)) { // copy first index addons to root
        app.addons = app?.licenses[0].addons.map(each => {
          return {
            ...each,
            ui_display_name: each?.license_display_name
          }
        })
      }
    }

    if (app?.app_id && app.app_id !== ZINGBOX_NGFW) {
      result.push(app)
      return result
    }
    //zingbox_ngfw
    app.is_federal_license = app?.licenses?.some(each => each.is_federal_license) // fedramp flag

    const licenseVersionGroup = _.groupBy(app?.licenses, 'license_version')
    const licenseV1 = licenseVersionGroup[IOT_PACKAGE_1]
    const licenseV2 = licenseVersionGroup[IOT_PACKAGE_2]

    if (isNonemptyArray(licenseV1) && isNonemptyArray(licenseV2)) { // v1 & v2 - push zingbox_ngfw, zingbox_ngfw_drdl to list
      app = {
        ...app,
        licenses: licenseV1,
        purchased_size: licenseV1.length
      }
      const drdlApp = constructZingboxNgfwDrdl(app, licenseV2)
      result.push(app, drdlApp)
    }
    else if (_.isEmpty(licenseV1) && isNonemptyArray(licenseV2)) { // v2 overwrite zingbox_ngfw with zingbox_ngfw_drdl app
      app = constructZingboxNgfwDrdl(app, licenseV2)
      result.push(app)
    }
    else if (isNonemptyArray(licenseV1)) { // v1 push zingbox_ngfw to list
      result.push(app)
    }
    return result
  }, [])
}

/**
 *
 * @param toBeActivatedApp
 * @param apps
 * @returns {*}
 */

const productToBeActivated = ({ toBeActivatedApp, disabledAppSet, mode }, apps = [], allowedApps) => {
  const checkedSet = new Set()
  const filterApps = apps.filter(a => a?.app_id)
  const existingMap = _.keyBy(toBeActivatedApp, 'key')
  const appList = modifyApps(filterApps, mode).map((eachApp, idx) => {
    const { app_id, license_expiration, no_support_license } = eachApp
    const key = `${app_id}-${idx}`
    const existing = existingMap[key]
    const allowed = allowedApps ? allowedApps.has(app_id) : true
    const precondition = allowed && !eachApp.activated && !no_support_license // for checked or selectable
    // Checked if duplicate product is already checked
    const checked = precondition && (existing ? existing.checked : !checkedSet.has(app_id))
    // make it disabled when product is already checked and already activated
    const selectable = precondition && !checkedSet.has(app_id) && !disabledAppSet?.has(`${app_id}-${idx}`)

    if (allowed && !eachApp.activated) {
      checkedSet.add(app_id)
    }

    const purchased_size_render = getPurchasedSizeRender(eachApp, '')
    eachApp.additional_base_product = (eachApp.additional_base_product || []).map(each => {
      return {
        ...each,
        purchased_size_render: getPurchasedSizeRender(each, '')
      }
    })
    return {
      ...eachApp,
      key,
      allowed,
      display_name: app_id === PRISMA_ACCESS_EDITION ? eachApp.license_display_name || eachApp.display_name : eachApp.display_name,
      purchased_size_render,
      license_expiration,
      checked,
      selectable,
    }
  })
  const sortedApps = _.sortBy(appList, [app => (app.allowed !== false ? 0 : 1)])
  return sortedApps // keeping for debug
}

const serializeFirewallLicenseDataFn = (data, { toBeActivatedObj: { licenses }, formValues: { firewalls } }) => {
  if (Array.isArray(licenses)) {
    const instances = firewalls.map((fw) => {
      const { serial_number, auth_code, tenant_id } = fw
      const instance = tenant_id || SETUP_CREATE_NEW
      return auth_code && `${instance}|${serial_number}|${data.region}|${auth_code}`
    }).filter(Boolean)
    data.value = instances.join('\n')
  }
  return data
}
const buildProductFnMap = {
  [ZINGBOX_NGFW]: serializeFirewallLicenseDataFn,
  [PRISMA_SAAS_NGFW]: serializeFirewallLicenseDataFn,
  [STRATA_INSIGHTS_NGFW]: serializeFirewallLicenseDataFn,
}

const buildProductToBeActivated = (formValues, toBeActivatedApp, getAnyApp, supportAccounts, setupSelectedAccount) => {
  let productActivatedAppIds = formValues[CHOOSE_LICENSES_FIELD]?.map(key => key.replace(/-\d+$/, '')) || []
  const productIndexMap = new Map(formValues[CHOOSE_LICENSES_FIELD]?.map(key => key.split('-')))
  const dataLake = formValues[CDL_INSTANCE_FIELD] || ''
  const region = formValues[REGION_FIELD] || ''
  const appRegion = formValues[APP_REGION_FIELD] || ''
  const panorama_sn = formValues[PANORAMA_INSTANCE_FIELD]
  const management = formValues[CHOOSE_HOW_TO_MANAGE_FIELD] || ''
  const pae_iot_addon = formValues[PAE_ADDON_ZINGBOX_TNT]
  const pae_saas_addon = formValues[PAE_ADDON_SAAS_TNT]
  const pae_dlp_addon = formValues[PAE_ADDON_DLP_TNT]
  const dssTenant = formValues[DIRECTORY_SYNC_FIELD]
  const dssRegion = formValues[DIRECTORY_SYNC_APP_REGION_FIELD]

  const [cdlTenatnId, cdlSerial, cdlRegion, cdlAuthCode] = dataLake.split('|')
  // TODO add addon ZINGBOX app id instead iot
  const hasPAEIoTAddon = hasAddon(toBeActivatedApp, PRISMA_ACCESS_EDITION, SETUP_ADDON_APP_IOT)
  const [
    mgmtAppId,
    mgmtTenantId,
    mgmtSerial = mgmtAppId.endsWith(PANORAMA) && mgmtTenantId === SETUP_CREATE_NEW ? panorama_sn : undefined,
  ] = management.split(/[:|]/, 3)
  const appValueMap = {}
  const lockedInfo = toBeActivatedApp.find(x => x.app_id === 'prisma_access_edition')?.lockedInfo
  const getTenantId = (app_id) => {
    switch (app_id) {
      case LGS_ID:
        return cdlTenatnId
      case PRISMA_ACCESS:
      case PRISMA_ACCESS_PANORAMA:
      case ZINGBOX:
      case PRISMA_SAAS:
        return management.split(/[:|]/, 2)?.[1]
      case PRISMA_ACCESS_EDITION:
        /**
         * LST-4058 Customer want to upgrade existing PAE with IoT that time we need to pass PAE tenant
         */
        return Boolean(lockedInfo?.tenant_id) ? lockedInfo.tenant_id : SETUP_CREATE_NEW
      default:
        return SETUP_CREATE_NEW
    }
  }
  const upsertReplace = (from, to) => {
    const idx = productActivatedAppIds.indexOf(from)
    if (idx >= 0) {
      productActivatedAppIds.splice(idx, 1, to)
    }
    else if (!productActivatedAppIds.includes(to)) {
      productActivatedAppIds.push(to)
    }
  }

  if (dataLake) {
    appValueMap[LGS_ID] = cdlRegion ? dataLake : `${cdlTenatnId}|${cdlSerial}|${region}|${cdlAuthCode}`
    if (!productActivatedAppIds.includes(LGS_ID)) {
      productActivatedAppIds.push(LGS_ID)
    }
  }
  if (mgmtAppId) {
    appValueMap[mgmtAppId] = management.split(':', 2)?.[1]
    if (mgmtAppId === PRISMA_ACCESS) {
      upsertReplace(PRISMA_ACCESS_PANORAMA, mgmtAppId)
    }
    else if (mgmtAppId === PRISMA_ACCESS_PANORAMA) {
      upsertReplace(PRISMA_ACCESS, mgmtAppId)
    }
    else if (!productActivatedAppIds.includes(mgmtAppId)) {
      productActivatedAppIds.push(mgmtAppId)
    }
  }

  if (productActivatedAppIds.includes(ZINGBOX_NGFW_DRDL) && productActivatedAppIds.includes(ZINGBOX_NGFW)) { // IoT mixed case (1.0 and 2.0)
    _.remove(productActivatedAppIds, app => app === ZINGBOX_NGFW_DRDL)//remove drdl app if activation has both ngfw and drdl
  }
  else if (productActivatedAppIds.includes(ZINGBOX_NGFW_DRDL)) { //IoT 2.0 flow
    productActivatedAppIds = productActivatedAppIds.reduce((result, app) => {
      if (app === ZINGBOX_NGFW_DRDL) {
        result.push(ZINGBOX_NGFW) // update drdl with ngwf
      }
      else if (app !== LGS_ID) { // filter out CDL
        result.push(app)
      }
      return result
    }, [])

    toBeActivatedApp = toBeActivatedApp.map(app => {
      if (app.app_id === ZINGBOX_NGFW_DRDL) {
        app.app_id = ZINGBOX_NGFW
      }
      return app
    })

    if (productIndexMap.has(ZINGBOX_NGFW_DRDL)) {
      productIndexMap.set(ZINGBOX_NGFW, productIndexMap.get(ZINGBOX_NGFW_DRDL))
      productIndexMap.delete(ZINGBOX_NGFW_DRDL)
    }
  }

  if (hasOnlyIoTThirdPartyActivation(toBeActivatedApp) || (isActivatingOnlySaasRest(toBeActivatedApp))) { // IoT 3p or SaaS Rest activation
    productActivatedAppIds = productActivatedAppIds.reduce((result, app) => {
      if (app !== LGS_ID) { // filter out CDL
        result.push(app)
      }
      return result
    }, [])
  }

  if (mgmtAppId === PRISMA_SAAS && isActivatingSaasInlineAndRest(toBeActivatedApp) && formValues?.firewalls?.length > 0) {
    productActivatedAppIds.push(PRISMA_SAAS_NGFW)

  }
  /**
   * lookup region from app manifest, based on selected cdl region A
   * find the region where the logging_service_region === A
   */
  const regionLookup = (app_id, region) => {
    const { regions } = getAnyApp(app_id)
    if (isMultiRegionAllowed(supportAccounts, setupSelectedAccount) && regions.some(e => isNonemptyArray(e?.platform_regions))) {
      return regions.filter(region => !(region?.extra?.easy_onboarding_only)
      ).find(each => each.platform_regions.find(pr => pr === region))?.name || region
    }
    return regions.find(r => r.logging_service_region === region)?.name || region
  }

  const productToBeActivated = productActivatedAppIds.map((app_id) => {
    const idx = productIndexMap.get(app_id)
    const toBeActivatedObj = toBeActivatedApp[idx] || {}
    const serial_number = (app_id === mgmtAppId && mgmtSerial) ||
      (app_id === LGS_ID && cdlSerial) || toBeActivatedObj?.serial_number
    const value = appValueMap[app_id]
    const data = {
      app_id,
      serial_number,
      region: hasAppSpecificRegion[app_id] ? appRegion : (needRegionLookUp[app_id] ? regionLookup(app_id, region) : region),
      cdl_tenant_id: cdlTenatnId,
      tenant_id: value === NONE ? value : getTenantId(app_id),
      value,
      auth_code: toBeActivatedObj?.auth_code
    }
    const fn = buildProductFnMap[app_id]
    return fn ? fn(data, { formValues, toBeActivatedObj, idx }) : data
  })

  /**
   * Not need FT here because pae_iot_addon_tenant wont come if addon is not there
   * PAE + IOT addon if addon is present add part of instance
   */
  if (productActivatedAppIds.includes(PRISMA_ACCESS_EDITION) && Boolean(hasPAEIoTAddon) && pae_iot_addon) {
    const data = {
      'app_id': ZINGBOX,
      region: regionLookup(ZINGBOX, region),
      cdlTenatnId,
      tenant_id: pae_iot_addon.split(/[:|]/, 2)?.[1],
      value: pae_iot_addon.split(/[:]/)?.[1]
    }
    productToBeActivated.push(data)
  }
  // add PAE + SaaS addon
  const hasPAESaaSAddon = hasAddon(toBeActivatedApp, PRISMA_ACCESS_EDITION, SETUP_ADDON_APP_SAAS_INLINE)
  if (productActivatedAppIds.includes(PRISMA_ACCESS_EDITION) && Boolean(hasPAESaaSAddon) && pae_saas_addon) {
    const data = {
      'app_id': PRISMA_SAAS,
      region: regionLookup(PRISMA_SAAS, region),
      cdlTenatnId,
      tenant_id: pae_saas_addon.split(/[:|]/, 2)?.[1],
      value: pae_saas_addon.split(/[:]/)?.[1]
    }
    productToBeActivated.push(data)
  }
  // PAE + DLP addon
  const hasDLPAddon = hasAddon(toBeActivatedApp, PRISMA_ACCESS_EDITION, SETUP_ADDON_APP_DLP)
  if (FT('MULTI_DLP') && productActivatedAppIds.includes(PRISMA_ACCESS_EDITION) && Boolean(hasDLPAddon) && pae_dlp_addon) {
    const data = {
      app_id: DLP,
      region: 'global',
      cdlTenatnId,
      tenant_id: pae_dlp_addon.split(/[:|]/, 2)?.[1],
      value: pae_dlp_addon.split(/[:]/)?.[1]
    }
    productToBeActivated.push(data)
  }

  if ((mgmtAppId === ZINGBOX || (FT('SAAS_CASB') && mgmtAppId === PRISMA_SAAS)) && panorama_sn) { //for now zingbox 2.0 app only
    productToBeActivated.push({
      region,
      app_id: PANORAMA,
      serial_number: panorama_sn,
      cdl_tenant_id: cdlTenatnId,
      value: `${SETUP_CREATE_NEW}|${panorama_sn}|${region}`
    })
  }

  if (FT('AIOPS_PREMIUM') && dssTenant) {
    productToBeActivated.push({
      app_id: DSS_ID,
      cdlTenatnId,
      region: dssRegion,
      tenant_id: dssTenant.split(/[:|]/, 2)?.[1],
      value: dssTenant.split(/[:]/)?.[1],
    })
  }
  return productToBeActivated
}

const buildProductActivation = ({ toBeActivatedApp }, cdlInstance) => {
  const activationProduct = toBeActivatedApp.filter(eachProd =>
    eachProd.checked && (
      eachProd.app_id !== LGS_ID ||
      cdlInstance.tenant_id === SETUP_CREATE_NEW ||
      (eachProd.auth_code && cdlInstance.value?.endsWith(`${eachProd.auth_code}`))
    )
  )
  return activationProduct.map(eachApp => {
    return {
      ..._.pick(eachApp, ['app_id', 'name', 'role', 'display_name', 'logo', 'auth_code']),
      ..._.pick(cdlInstance, 'region'),
      region_display: cdlInstance.region_display
    }
  })
}

const changeGetState = ({ currentStep, steps, context }, value = '') => {
  const { setupValues, defaultSetupValues } = steps[currentStep]
  const newValues = { ...defaultSetupValues, ...setupValues, value }
  const _steps = steps.map((eachItem, idx) => {
    if (idx === currentStep) {
      return {
        ...eachItem,
        setupValues: newValues,
        ...(context === PRISMA_ACCESS && {
          next: value === SETUP_NEW_PANORAMA ? '3' : '4'
        })
      }
    }
    return eachItem
  })
  return {
    steps: _steps,
    currentStepObj: _steps[currentStep],
  }
}

const getToBeActivatedPerCheck = ({ toBeActivatedApp, cdlInstance }, checkedValues) => {
  const uniqueApp = new Map()
  const appIdxMap = new Map()
  const newArray = []
  const checkedValueSet = new Set(checkedValues)
  const disabledAppSet = new Set()
  toBeActivatedApp.forEach((eachApp, idx) => {
    const { app_id, allowed = true, no_support_license } = eachApp
    if (!appIdxMap.has(app_id)) {
      appIdxMap.set(app_id, idx)
    }
    const checked = allowed && !no_support_license && checkedValueSet.has(`${app_id}-${idx}`)
    if (!allowed || no_support_license || uniqueApp.has(app_id)) {
      eachApp.selectable = false
    }
    else if (!checked) {
      eachApp.selectable = true
    }

    if (checked) {
      const findIdx = appIdxMap.get(app_id)
      if (findIdx !== idx) {
        const obj = newArray[findIdx]
        obj.selectable = false
        disabledAppSet.add(`${obj.app_id}-${findIdx}`)
      }
      uniqueApp.set(app_id, idx)
    }
    /**
     * APPORTAL-3853 CDL field shows garbage when onboarding PA with Telemetry CDL
     * When user select CDL on the last page and come back all the way to first step and uncheck CDL
     * that time lets clean up cdlInstance selection value
     */
    else if (!checked && app_id === LGS_ID && !_.isEmpty(cdlInstance)) {
      cdlInstance = {}
    }

    newArray.push({
      ...eachApp,
      checked,
    })
  })
  return { disabledAppSet, toBeActivatedApp: newArray, cdlInstance }
}

const overrideStepsIfSkipAccountSelect = (orgSteps, newState) => {
  const steps = orgSteps.slice()
  if (steps && (newState?.supportAccounts?.length === 1 || newState?.session?.has_firewall_bundle || newState?.mode === ELA)) {
    const accountSelectStepIdx = steps[0].next
    const accountSelectNextIdx = steps[accountSelectStepIdx].next
    if (accountSelectNextIdx > 1) {
      steps[0] = { ...steps[0], next: accountSelectNextIdx }
      steps[accountSelectNextIdx] = { ...steps[accountSelectNextIdx], prev: 0 }
    }
  }
  return steps
}

const applyAllowedApps = (newState, context) => {
  const setupSteps = SetupSteps[context]
  if (setupSteps) {
    const { steps = [], stepsHelp, steps: [currentStepObj = {}] = [] } = setupSteps
    Object.assign(newState, {
      steps: overrideStepsIfSkipAccountSelect(steps, newState),
      stepsHelp,
      currentStepObj,
    })
    newState.allowedApps = Array.isArray(currentStepObj.allowedApps) ?
      indexArray(currentStepObj.allowedApps, n => n) : undefined
  }
}

const resetFormValues = (steps) => {
  steps.forEach((step, i) => {
    if (i > 1) { // revert values
      delete step._formValues
      step.setupValues = { ...step.defaultSetupValues }
    }
    return step
  })
}

const setupReducer = (state = setup, action) => {
  switch (action.type) {
    case SET_UP_NEXT_STEP: {
      return { ...state, ...getNextObj(state, action.currentStep, action.formValue) }
    }
    case SET_UP_BACK_STEP: {
      return { ...state, ...getBackObj(state, action.currentStep) }
    }
    case SET_UP_INIT_LICENSE: {
      if (state.licenseHash === action.hash) {
        return state // skip if hash not changed
      }
      const newState = {
        ...state,
        toBeActivatedApp: [],
        licenseHash: action.hash,
        session: action.session,
        context: action.context,
        contextApp: action.contextApp,
        user_email: action.user_email,
        mode: action.mode
      }
      if (state.context !== action.context) {
        applyAllowedApps(newState, action.context)
      }
      newState.toBeActivatedApp = productToBeActivated(newState, action.apps, newState.allowedApps)
      return newState
    }
    case OVERRIDE_CONTEXT: {
      if (state.context !== action.context) {
        const newState = {
          ...state,
          context: action.context,
          contextApp: action.contextApp || getContextApp(action.context, state.entitledAppsList),
        }
        applyAllowedApps(newState, action.context)
        resetFormValues(newState.steps)
        return newState
      }
      return state
    }
    case SET_DEVIECS: {
      if (action.devices === state.devices) {
        return state
      }
      return { ...state, devices: action.devices }
    }
    case APPS_LIST_UPDATE: {
      if (state.entitledAppsList === action.entitledAppsList) {
        return state
      }
      return {
        ...state,
        entitledAppsList: action.entitledAppsList,
        getAnyApp: action.getAnyApp,
        getAppInstances: action.getAppInstances,
        getAppInstance: action.getAppInstance,
        contextApp: state.context ? getContextApp(state.context, action.entitledAppsList) : state.contextApp,
      }
    }
    case PRODUCT_SELECT: {
      const { toBeActivatedApp, disabledAppSet, cdlInstance } = getToBeActivatedPerCheck(state, action.checkedValues)
      return { ...state, toBeActivatedApp, disabledAppSet, cdlInstance }
    }
    case SETUP_ACCOUNT_SELECT: {
      const sameAccount = state.setupSelectedAccount === action.setupSelectedAccount
      if (sameAccount && !action.supportAccounts) {
        return state
      }
      const newState = { ...state, setupSelectedAccount: +action.setupSelectedAccount, cdlInstance: undefined, selectedFirewallDevices: [] }
      if (isNonemptyArray(action.supportAccounts)) {
        newState.supportAccounts = action.supportAccounts
      }
      if (newState.supportAccounts && action.setupSelectedAccount >= 0) {
        const currentAccount = newState.supportAccounts.get(+action.setupSelectedAccount)
        newState.accountIsFederal = Boolean(currentAccount?.is_federal)
        const steps = newState.context && SetupSteps[newState.context]?.steps
        if (steps) {
          newState.steps = overrideStepsIfSkipAccountSelect(steps, newState)
        }
      }
      // clear form values
      if (!sameAccount) {
        resetFormValues(newState.steps)
      }
      return newState
    }
    case SET_CDL_SELECTION: {
      if (_.isEqual(action.cdlInstance, state.cdlInstance)) {
        return state
      }
      const productToBeActivated = buildProductActivation(state, action.cdlInstance)
      return { ...state, cdlInstance: action.cdlInstance, productToBeActivated }
    }
    case SET_NEW_PANORAMA_SN: {
      if (state.new_panorama_sn === action.serial_number) {
        return state
      }
      return { ...state, new_panorama_sn: action.serial_number }
    }
    case CHOOSE_HOW_TO_MANAGE: {
      return { ...state, ...changeGetState(state, action.value), cdlInstance: action.cdlInstance }
    }
    case HELP_DRAWER: {
      return { ...state, isDrawerOpen: action.isOpen }
    }
    case FIREWALL_LIST_VISIBLE: {
      return { ...state, isFirewallListVisible: action.isFirewallListVisible }
    }
    case SET_SUBSCRIBED_FIREWALL_POLL_TIME: {
      return {
        ...state,
        isFirewallSubscribedVisible: action.isFirewallSubscribedVisible
      }
    }
    case SET_NEW_CLCS_TENANT: {
      if (state.clcsTenant === action.clcsTenant) {
        return state
      }
      return { ...state, clcsTenant: action.clcsTenant }
    }
    case SET_ERROR: {
      const obj = state?.steps?.find(each => each.comp_enum === 'ERROR') || {}
      const { currentStep, errorData } = action
      return {
        ...state,
        currentStepObj: {
          ...obj,
          comp_enum: 'ERROR',
          prev: currentStep - 1,
          current: currentStep,
          title: errorData?.errorType ? _.startCase(`${errorData?.errorType}`.replace(/\W+/g, '_')) : UNHANDLED_EXCEPTION,
          style: { maxWidth: 800, minWidth: 800 },
          responsiveConfig: { xl: 8, xxl: 6, lg: 12, md: 18, sm: 18 },
          backBtnProps: { v2: false, label: 'Back' },
          nextBtnProps: { label: 'Contact Support', redirectUrl: CONTACT_SUPPORT_URL },
          errorData,
        }
      }
    }
    case SET_SELECTED_FIREWALL_DEVICES: {
      const { selectedFirewallDevices = [], totalLicenses } = action
      if (selectedFirewallDevices && totalLicenses) {
        const groupTotalLicenses = _.groupBy(totalLicenses, 'app_id')
        const groupSelectedFirewallDevices = _.groupBy(selectedFirewallDevices, 'app_id')
        const constructPurchasedSizeRender = (app) => {
          const totalLicenseCount = groupTotalLicenses[app]?.length || 0
          const selectedDevicesCount = groupSelectedFirewallDevices[app]?.length || 0
          const purchasedSizeRender = selectedDevicesCount === totalLicenseCount ? totalLicenseCount : `${selectedDevicesCount}/${totalLicenseCount}`
          return `${purchasedSizeRender} Firewalls`
        }
        const toBeActivatedApp = (state.toBeActivatedApp || []).map(each => {
          if (each.app_id.match('ngfw')) { // ngfw apps
            return {
              ...each,
              ...(each?.purchased_size_render && { purchased_size_render: constructPurchasedSizeRender(each.app_id) })
            }
          }
          else if (each.app_id === PRISMA_SAAS) {
            const additionalBaseProduct = each?.additional_base_product?.map(product => {
              if (product.app_id === PRISMA_SAAS_NGFW) {
                product.purchased_size_render = constructPurchasedSizeRender(PRISMA_SAAS_NGFW) // look for aperture_ngfw
              }
              return product
            })
            return {
              ...each,
              ...(each?.additional_base_product && { additional_base_product: additionalBaseProduct })
            }
          }
          return each
        })
        return {
          ...state,
          toBeActivatedApp,
          selectedFirewallDevices,
        }
      }
      return {
        ...state,
        selectedFirewallDevices
      }
    }
    default: {
      return state
    }
  }
}

setupReducer.initialState = setup

export default setupReducer
