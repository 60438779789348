import React from 'react'
import PropTypes from 'prop-types'
//test
const SubscriptionDetails = ({ totalPurchased, currentActivation, previouslyActivated }) => {
  const remaining = totalPurchased - (currentActivation + previouslyActivated)
  return (
    <span style={{ padding: '3px' }}>
      <div>Subscriptions purchased: {totalPurchased}</div>
      <hr style={{ borderColor: '#d3dde0' }}/>
      <div style={{ paddingBottom: '3px' }}>Activate in this session: {currentActivation}</div>
      <div style={{ paddingBottom: '3px' }} >Previously activated: {previouslyActivated}</div>
      <div>Remaining: {remaining}</div>
    </span>
  )
}

SubscriptionDetails.propTypes = {
  totalPurchased: PropTypes.number,
  currentActivation: PropTypes.number,
  previouslyActivated: PropTypes.number,
}
export default SubscriptionDetails
