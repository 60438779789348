import React, { useMemo, useCallback, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import DataLake from '../DataLake'
import { PRISMA_SAAS, CDL_INSTANCE_FIELD, PRISMA_SAAS_REST_ACCOUNT_MESSAGE, CHOOSE_HOW_TO_MANAGE_FIELD } from '../../../constants/AppConstants'
import { isActivatingOnlySaasRest, useMemoFieldDecorator, hasPrismaSaasRestTenant, FT, isActivatingSaasCasbInline } from '../../../utils/activateUtils'
import PrismaSaasSecurityFields from './PrismaSaasSecurityFields'
import PrismaSaaSPanorama from './PrismaSaaSPanorama'
import './prismaSaas.scss'

export const fieldName = 'support-account-saas-rest'

const PrismaSaasSecuritySetup = (props) => {
  const { form, getAppInstances, cdlInstance, stateRef, onCDLChange, setSelectedFirewallDevices, toBeActivatedApp, supportAccounts, session, chooseHowToManage, setNewPanoramaSn } = props
  const { validateFields, setFieldsValue, resetFields, getFieldValue, getFieldDecorator } = form
  const hasFirewallBundle = session?.has_firewall_bundle

  useLayoutEffect(() => { // apply value from state/default to form field
    if (isActivatingOnlySaasRest(toBeActivatedApp)) {
      chooseHowToManage(undefined) //reset CDL tenant to default
    }
    const curValue = getFieldValue(CDL_INSTANCE_FIELD)
    const { value, fromInstanceAssociation } = cdlInstance || {}
    const newValue = fromInstanceAssociation ? value : null //IOT-222 setting to null
    if (newValue !== curValue) {
      if (newValue) {
        setFieldsValue({ [CDL_INSTANCE_FIELD]: newValue })
      }
      else {
        resetFields([CDL_INSTANCE_FIELD]) // so it will use default value
      }
    }

  }, [cdlInstance, getFieldValue, resetFields, setFieldsValue, validateFields, toBeActivatedApp, chooseHowToManage])

  const onCDLChangeOverride = useCallback((cdlInstance) => {
    const { cdlInstance: currentCdl } = stateRef.current
    if (currentCdl?.tenant_id !== cdlInstance?.tenant_id) {
      if (!hasFirewallBundle) {
        setSelectedFirewallDevices({}) //reset firewall device selection
      }
      setNewPanoramaSn(null) //reset selected panorama
      resetFields([CHOOSE_HOW_TO_MANAGE_FIELD])
      validateFields([fieldName])
    }
    else if (currentCdl?.region !== cdlInstance?.region) {
      if (!hasFirewallBundle) {
        setSelectedFirewallDevices({}) //reset firewall device selection
      }
      setFieldsValue({ [CHOOSE_HOW_TO_MANAGE_FIELD]: undefined })
      validateFields([fieldName])
    }
    onCDLChange(cdlInstance)
  }, [stateRef, onCDLChange, setSelectedFirewallDevices, resetFields, setFieldsValue, validateFields, hasFirewallBundle, setNewPanoramaSn])

  const isSingleCspHasSaasRest = useMemo(() => {
    return supportAccounts?.length <= 1 &&
      toBeActivatedApp?.some(app => app.app_id === PRISMA_SAAS && app.checked) &&
      hasPrismaSaasRestTenant(getAppInstances(PRISMA_SAAS))
  }, [supportAccounts, getAppInstances, toBeActivatedApp])

  const fieldDecorator = useMemoFieldDecorator(fieldName, {
    validateFirst: true,
    rules: useMemo(() => [{
      validator(rule, value, callback) {
        if (isSingleCspHasSaasRest) {
          callback(PRISMA_SAAS_REST_ACCOUNT_MESSAGE)
        }
        else {
          callback()
        }
      }
    }], [isSingleCspHasSaasRest])
  }, getFieldDecorator)

  return (
    fieldDecorator(
      <div className={'prisma-saas setup-card-body'}>
        {!isActivatingOnlySaasRest(toBeActivatedApp) && <DataLake
          {...props}
          skipSingleAssoIdSet={true}
          onCDLChange={onCDLChangeOverride}
          dropDownProps={{ style: { width: '70%' }, placeholder: 'Select DataLake' }}
          dataLakeLabel={'Data Lake'}
          dataLakeRegionLabel={'Data Lake Region'}
          useDataLakeRegion={true}
          showDataLake={false}
        />
        }
        <PrismaSaasSecurityFields
          {...props}
          cdlInstance={!isActivatingOnlySaasRest(toBeActivatedApp) ? cdlInstance : undefined}
          fieldName={CHOOSE_HOW_TO_MANAGE_FIELD}
        />
        {FT('SAAS_CASB') && isActivatingSaasCasbInline(toBeActivatedApp) && <PrismaSaaSPanorama {...props}/> }
      </div>
    )
  )
}

PrismaSaasSecuritySetup.propTypes = {
  form: PropTypes.object,
  stateRef: PropTypes.any,
  contextApp: PropTypes.object,
  formValues: PropTypes.object,
  cdlInstance: PropTypes.object,
  setupSelectedAccount: PropTypes.number,
  user_email: PropTypes.string,
  currentStepObj: PropTypes.object,
  getAnyApp: () => undefined,
  chooseHowToManage: () => undefined,
  onCDLChange: PropTypes.func,
  setSelectedFirewallDevices: PropTypes.func,
  toBeActivatedApp: PropTypes.array,
  supportAccounts: PropTypes.array,
  getAppInstances: PropTypes.func,
}

export default PrismaSaasSecuritySetup
