import React, { useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
// import { isEmpty } from 'lodash'
import { Select } from '@pan/cloud-base'
import { LGS_ID, SETUP_CREATE_NEW, REGION_FIELD } from '../../../constants/AppConstants'
import { useMemoFieldDecorator, isNonemptyArray, filterRegions } from '../../../utils/activateUtils'

const IoTIngestionRegion = ({
  getAnyApp,
  contextApp,
  primaryAppId = contextApp?.app_id,
  onCDLChange,
  stateRef,
  cdlInstance,
  dataLakeRegionLabel = 'Ingestion Region',
  form: {
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
  },
  dropDownProps,
}) => {
  const regions = useMemo(() => {
    const appRegions = primaryAppId && getAnyApp(primaryAppId)?.regions
    const cdlRegions = getAnyApp(LGS_ID)?.regions
    if (isNonemptyArray(appRegions)) {
      const supportedDataLakeRegions = new Set(appRegions.map(r => r.logging_service_region || r.name))
      return cdlRegions.filter(r => supportedDataLakeRegions.has(r.name))
    }
    return appRegions || cdlRegions
  }, [getAnyApp, primaryAppId])

  const { accountIsFederal } = stateRef.current
  const avaiRegions = useMemo(() => filterRegions(regions, accountIsFederal), [regions, accountIsFederal])
  // const defaultRegion = avaiRegions[0].name // stop using defaultRegion

  const {
    region, // = defaultRegion, // stop using defaultRegion
  } = cdlInstance || {}

  const regionDecorator = useMemoFieldDecorator(REGION_FIELD, {
    initialValue: region,
    rules: useMemo(() => [{
      required: true,
      message: 'Ingestion region is required',
    }], [])
  }, getFieldDecorator) // TODO: fedramp support?

  // useEffect(() => { // stop using defaultRegion
  //   if (isEmpty(cdlInstance)) {
  //     onCDLChange({ ...stateRef.current.cdlInstance, region: defaultRegion })
  //   }
  // }, [cdlInstance, onCDLChange, stateRef, defaultRegion])

  // IOT-222 on change of IOT instance update CDL region
  useEffect(() => {
    setFieldsValue({
      [REGION_FIELD]: cdlInstance?.region, // stop using defaultRegion
    })
  }, [cdlInstance, setFieldsValue])

  const regionDisabled = useMemo(() => {
    if (cdlInstance && cdlInstance.tenant_id && cdlInstance.tenant_id !== SETUP_CREATE_NEW) {
      return true
    }
    return false
  }, [cdlInstance])

  const regionHandler = useCallback((region) => {
    onCDLChange({ ...stateRef.current.cdlInstance, region })
  }, [stateRef, onCDLChange])

  const warningMsg = useMemo(() => {
    const warning = avaiRegions.find(each => (each.name || each.value) === region)?.extra?.warning
    return warning ? <p className='region-warning'>{warning}</p> : null
  }, [avaiRegions, region])

  return (
    <>
      <div className={'hbox middle space-between setup-item'}>
        <label>{dataLakeRegionLabel}</label>
        {regionDecorator(
          <Select
            {...dropDownProps}
            placeholder={`Select ${dataLakeRegionLabel}`}
            disabled={regionDisabled}
            onSelect={regionHandler}
          >
            {avaiRegions.map(({ display, value }) => {
              return <Select.Option key={value} value={value}>{display}</Select.Option>
            })}
          </Select>
        )}
      </div>
      {warningMsg}
    </>
  )
}

IoTIngestionRegion.propTypes = {
  supportAccounts: PropTypes.array,
  selectedAccount: PropTypes.number,
  getAnyApp: PropTypes.func,
  toBeActivatedApp: PropTypes.array,
  onCDLChange: PropTypes.func,
  stateRef: PropTypes.any,
  cdlInstance: PropTypes.object,
  region: PropTypes.string,
  primaryAppId: PropTypes.string,
  contextApp: PropTypes.object,
  form: PropTypes.object,
  dataLakeLabel: PropTypes.string,
  dataLakeRegionLabel: PropTypes.string,
  useDataLakeRegion: PropTypes.bool,
  dropDownProps: PropTypes.object,
}

IoTIngestionRegion.defaultProps = {
  getAnyApp: () => undefined,
  useDataLakeRegion: false,
  supportAccountIds: [],
  toBeActivatedApp: [],
  onCDLChange: () => { },
  cdlInstance: {},
  dropDownProps: {
    style: {
      width: '70%'
    },
  }
}

export default IoTIngestionRegion
