import React, { useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Row, Col, Collapse, Icon } from '@pan/cloud-base'
import {
  SETUP_CTX_PRIMARY_APPS,
  LGS_ID,
  NOT_ALLOWED_ACTIVATE_ALONE_APPS_SET,
  IOT_FIREWALL_SUBSCRIBED_TITLE,
  IOT_FIREWALL_SUBSCRIBED_SUBTITLE,
  PRISMA_ACCESS_PANORAMA,
  SETUP_CTX_IOT,
  ZINGBOX_NGFW,
  ZINGBOX,
  SETUP_CTX_PRISMA_ACCESS,
  PRISMA_ACCESS_INSIGHTS,
  PRISMA_ACCESS,
  RUNNING,
  ELA,
  CONTACT_SUPPORT_URL,
} from '../../constants/AppConstants'
import { SETTINGS } from '../../constants/RouteConstants'
import { isNonemptyArray } from '../../utils/common'
import PrismaHelp from './prisma_access/PrismaHelp'
import SetupFooter, { footerLabel } from './SetupFooter'
import InstanceRecordModal from '../../components/Settings/InstanceRecordModal'
import AdditionalProductActivated from '../../components/Setup/AdditionalProductActivated'
import OTP from '../../components/Setup/OTP'
import PSK from '../../components/Setup/PSK'
import SetUpManageRoleBtn from '../../components/Setup/SetUpManageRoleBtn'
import ComponentFactory from '../../components/Setup/ComponentFactory'
import FirewallModal from './common/firewall_selection/FirewallModal'
import FirewallSubscribedTable from './common/firewall_selection/FirewallSubscribedTable'
import IoTNGFStatus from './iot/IoTNGFStatus'
import BatchErrorCard from './iot/BatchErrorCard'
import Subscription from './common/firewall_subscription/Subscription'
import {
  CloudUpload,
  DataBaseLight,
  IoTSecurityIcon,
  ExternalLinkIcon,
  PAInsightIcon,
} from '../../images/svg-icons'
import DateRender from '../../components/Setup/DateRender'
import StatusCard from './iot/StatusCard'
import PAInsightsLearnmore from './prisma_access/PAInsightsLearnmore'
import { RecommendedBtn } from '../../components/Setup/SetupSimpleComponents'
import SetupProgressBar from './SetupProgressBar'
import SetupCompleteIcon from './SetupCompleteIcon'
import './setupProgressPage.scss'
import { FT } from '../../utils/activateUtils'

const { Panel } = Collapse
const DEM_PATH = '/dem/dem-summary'

const isRunningInstance = (inst) => inst &&
  (inst.instance_status === RUNNING || inst.status === RUNNING)
const findAppInstance = (instances, appIds, getAppInstance, sessionOnly) => {
  if (!appIds || !isNonemptyArray(instances)) {
    return undefined
  }
  let targetInst = instances?.find(inst => appIds.includes(inst.app_id))
  if (!sessionOnly) {
    if (!targetInst) { // looking in associations
      for (const inst of instances) {
        if (inst.asso) {
          const [asso] = Object.values(_.pick(inst.asso, appIds))
          if (asso?.tenant_id && asso.app_id) {
            targetInst = asso
            break
          }
        }
      }
    }
    if (targetInst?.tenant_id && targetInst.app_id) {
      const found = getAppInstance(targetInst)
      if (isRunningInstance(found)) {
        return found
      }
    }
  }
  return targetInst
}

const EMPTY_ARRAY = []
const EMPTY_OBJ = {}
const POLL_TIME = 5000 // 5s
const FORCE_REFRESH_INTERVAL = FT('NO_PUSH_EVENTS') ? POLL_TIME : 300000 // 5min
const SetupProgressPage = ({
  session = {},
  progress = +session.progress || 0,
  progressStep = EMPTY_OBJ,
  selectedAccount,
  switchAccount,
  context,
  contextApp,
  history,
  fetching,
  stateRef,
  showEditInstanceModal,
  setFirewallSubscribedVisible,
  refreshSession,
  isFirewallSubscribedVisible,
  refreshEntitlementsAndCredentials,
  ...rest
}) => {
  const {
    miscComponent = EMPTY_ARRAY,
    usefulLinks = EMPTY_ARRAY,
    keyFeature = EMPTY_ARRAY,
    keyFeatureTitle = 'Key features to explore:',
    extraComponents = EMPTY_ARRAY,
    useLinearLayout,
    maxWidth = 1280,
    title: {
      intermediate_title,
      final_title,
      sub_header,
    } = EMPTY_OBJ,
    recommendedSteps: {
      showOTP,
      showPSK,
      buttons: recommendStepsBtn = EMPTY_ARRAY,
      showDataLakeStatus,
      showDeviceCertificate,
    } = EMPTY_OBJ,
    launchButton: {
      text: launchBtnText,
      disabledByDefault,
    } = EMPTY_OBJ,
  } = progressStep
  const { getAnyApp, mode } = stateRef.current
  rest.getAnyApp = getAnyApp
  const isInitFetching = fetching && !session.uuid
  const isDeviceCertificateAbsent = showDeviceCertificate && session?.statuses?.some(each => !each?.certs?.thermite)
  const isDataLakeStatusRunning = showDataLakeStatus &&
    !(session?.statuses?.every(each => each?.certs?.lcass && isRunningInstance(each)))

  const [
    primaryInstance,
    isPrimaryInstanceRunning,
    cdlInstanceUrl,
    showOtpWithPending,
    showPskWithPending,
    iotUrl,
    autonomousDemUrl,
    paInsightsUrl,
  ] = useMemo(() => {
    const { getAppInstance } = stateRef.current
    const getRunningInstance = (inst) => {
      if (!inst) {
        return undefined
      }
      const entInst = getAppInstance(inst)
      if (entInst?.url && isRunningInstance(entInst)) {
        return entInst
      }
      if (inst.url && isRunningInstance(inst)) {
        return inst
      }
      return undefined
    }
    const primaryInstance = (session.primary_instance?.tenant_id && getRunningInstance(session.primary_instance)) ||
      findAppInstance(session.instances, SETUP_CTX_PRIMARY_APPS[context], getAppInstance, showOTP)
    if (primaryInstance && !primaryInstance.platform_id) {
      primaryInstance.platform_id = session.platform_id
    }
    const cdlInstance = getRunningInstance(primaryInstance?.platform_id ? { app_id: LGS_ID, tenant_id: primaryInstance.platform_id } :
      findAppInstance(session.instances, [LGS_ID], getAppInstance, false))
    const iotUrl = getRunningInstance(primaryInstance?.url && primaryInstance.app_id === ZINGBOX ? primaryInstance :
      findAppInstance(session.instances, ZINGBOX, getAppInstance, true))?.url
    const showOtpWithPending = showOTP && (session.show_panorama_otp ||
      session.instances?.some(inst => inst.app_id === PRISMA_ACCESS_PANORAMA))
    const showPskWithPending = Boolean(showPSK && session.psk)
    const isPrimaryInstanceRunning = Boolean(session.psk) || session.show_panorama_otp || isRunningInstance(primaryInstance)
    const paInst = primaryInstance?.url && primaryInstance.app_id === PRISMA_ACCESS ? primaryInstance :
      findAppInstance(session?.instances, PRISMA_ACCESS, getAppInstance, false)
    let autonomousDemUrl
    if (paInst?.url?.startsWith('http')) {
      autonomousDemUrl = new URL(paInst.url)
      autonomousDemUrl.pathname = DEM_PATH
      autonomousDemUrl = autonomousDemUrl.toString()
    }
    const app = getAnyApp(PRISMA_ACCESS_INSIGHTS)
    let paInsightsUrl
    if (app?.enabled) {
      paInsightsUrl = app.app_base_url
    }
    else if (paInst?.url) {
      paInsightsUrl = paInst?.url
    }
    return [
      primaryInstance,
      isPrimaryInstanceRunning,
      cdlInstance?.url,
      showOtpWithPending,
      showPskWithPending,
      iotUrl,
      autonomousDemUrl,
      paInsightsUrl,
    ]
  }, [stateRef, context, session.primary_instance, session.instances, session.show_panorama_otp, session.psk, session.platform_id, showOTP, showPSK, getAnyApp])

  const launchInstanceBtnProps = useMemo(() => {
    let disabled = false
    if (!session.done || !isNonemptyArray(session.instances)) {
      disabled = true
    }
    const primaryInstanceUrl = primaryInstance?.url
    if (!primaryInstanceUrl) {
      disabled = true
    }
    if (disabled && !disabledByDefault) { // hidden by default
      return null
    }
    let children = launchBtnText
    if (!children) {
      const primaryAppDisplayName = contextApp?.display_name || ''
      children = primaryAppDisplayName ? `Launch ${primaryAppDisplayName}` : 'Launch'
    }
    return disabled ? { disabled, children } : { href: primaryInstanceUrl, children }
  }, [contextApp, primaryInstance, disabledByDefault, launchBtnText, session.done, session.instances])

  const setupSelectedAccount = useMemo(() => {
    const index = session.support_account_id >= 0 && rest.supportAccounts.find(
      acc => +acc.support_account_id === +session.support_account_id
    )?.support_account_index
    return index || undefined
  }, [rest.supportAccounts, session.support_account_id])

  const isCdlRunning = Boolean(cdlInstanceUrl || session.clcs || session.otp || session.psk)

  const otp = useMemo(() => {
    if (!showOtpWithPending) {
      return null
    }
    if (!isPrimaryInstanceRunning || !isCdlRunning) {
      return {}
    }
    return {
      otp: session.otp,
      expiration: session.otp_expiration,
    }
  }, [isPrimaryInstanceRunning, session.otp, session.otp_expiration, showOtpWithPending, isCdlRunning])

  const psk = useMemo(() => {
    if (!showPskWithPending) {
      return null
    }
    if (!isPrimaryInstanceRunning || !isCdlRunning) {
      return {}
    }

    return {
      data: session.psk
    }
  }, [isPrimaryInstanceRunning, session.psk, showPskWithPending, isCdlRunning])

  progress = Math.max(progress, +session.progress || 0) // in case session updated while events missed
  const throttledRefreshSession = useMemo(() => _.throttle(refreshSession, POLL_TIME), [refreshSession]) // 5s
  useEffect(() => {
    if (selectedAccount >= 0 && setupSelectedAccount >= 0 && selectedAccount !== setupSelectedAccount) {
      switchAccount(setupSelectedAccount)
    }
  }, [selectedAccount, setupSelectedAccount, switchAccount])
  useEffect(() => { // refresh session when progress changed with 5s throttling
    if (progress > 0) {
      throttledRefreshSession()
    }
  }, [progress, getAnyApp, throttledRefreshSession]) // when progress update or entitlements loaded
  useEffect(() => {
    const fn = _.debounce(() => {
      if (document.visibilityState === 'visible' && Date.now() - fn.last > 60000) { // > 1min
        throttledRefreshSession()
        fn.last = Date.now()
      }
    }, 500)
    fn.last = Date.now()
    window.addEventListener('focus', fn, false)
    document.addEventListener('visibilitychange', fn, false)
    const internval = setInterval(throttledRefreshSession, FORCE_REFRESH_INTERVAL)
    return () => {
      window.removeEventListener('focus', fn, false)
      document.removeEventListener('visibilitychange', fn, false)
      clearInterval(internval)
    }
  }, [throttledRefreshSession])
  useEffect(() => {
    if (FT('NO_PUSH_EVENTS')) {
      const ts = setTimeout(refreshSession, 1000)
      return () => {
        clearTimeout(ts)
      }
    }
  }, [refreshSession])

  const recommendedSteps = useCallback((errorState) => {
    if (!isNonemptyArray(recommendStepsBtn)) {
      return null
    }
    const cdlBtnProps = cdlInstanceUrl ? {
      disabled: false,
      href: cdlInstanceUrl,
      target: '_blank',
      rel: 'noopener noreferrer',
    } : { disabled: true }
    const edit = ((e) => {
      e.preventDefault()
      showEditInstanceModal(primaryInstance)
    })
    const pushHistoryWithRefresh = (path) => {
      refreshEntitlementsAndCredentials(primaryInstance)
      history.push(path)
    }
    const manageApps = (e) => {
      e.preventDefault()
      pushHistoryWithRefresh(SETTINGS)
    }

    const backgroundColor = errorState ? 'gray-background' : ''
    const textColor = errorState ? 'gray-color' : 'white'

    return (
      <>
        <label className={'card-label'}><i>Recommended Next Steps</i></label>
        <div className={`recommended-btn-wrapper ${backgroundColor} vbox`}>
          {otp && <OTP
            otp={otp.otp}
            inputProps={{ placeholder: 'Pending...' }}
            btnProps={{ disabled: !otp.otp }}
            otp_expiration={otp.expiration}
            hasFormError={Boolean(errorState)}
          />}
          {psk && <PSK
            pskData={psk.data}
            inputProps={{ placeholder: 'Pending...' }}
            btnProps={{ disabled: !psk.data }}
            hasFormError={Boolean(errorState)}
            stateRef={stateRef}
            session={session}
          />}
          {recommendStepsBtn.includes('PA_INSTALL_PLUGIN') ? <RecommendedBtn
            btnProps={{
              style: { color: textColor },
              target: '_blank',
              href: 'https://docs.paloaltonetworks.com/prisma/prisma-access/prisma-access-panorama-admin/license-prisma-access/activate-and-install-prisma-access.html'
            }}
            leftSideIcon={<CloudUpload style={{ height: 18, margin: 10 }} />}
            text={'Install Cloud Services Plugin'}
            rightIconProps={{ type: 'right-circle', theme: 'filled' }}
          /> : null}
          {recommendStepsBtn.includes('REC_ASSIGN_ROLE') ? <SetUpManageRoleBtn
            pushHistoryWithRefresh={pushHistoryWithRefresh}
            cssClass={textColor}
          /> : null}
          {recommendStepsBtn.includes('REC_ASSOCIATE_DSS') ?
            <RecommendedBtn
              btnProps={{
                style: { color: textColor },
                disabled: !isPrimaryInstanceRunning,
                onClick: edit
              }}
              leftSideIcon={<Icon type="sync" style={{ fontSize: 18, margin: 10, fontWeight: 'bold' }} />}
              text={'Associate Directory-Sync Service'}
              rightIconProps={{ type: 'right-circle', theme: 'filled' }}
            /> : null}
          {recommendStepsBtn.includes('REC_CONFIGURE_DATALAKE') ?
            <RecommendedBtn
              btnProps={{
                style: { color: textColor },
                ...cdlBtnProps
              }}
              leftSideIcon={<DataBaseLight style={{ height: 18, margin: 10 }} />}
              text={'Configure Data Lake Quota'}
              rightIconProps={{
                type: 'right-circle', theme: 'filled'
              }}
            /> : null}
          {iotUrl && recommendStepsBtn.includes('REC_LAUNCH_IOT_SECURITY') ?
            <RecommendedBtn
              btnProps={{
                style: { color: textColor },
                target: '_blank',
                href: iotUrl
              }}
              textColor={textColor}
              leftSideIcon={<IoTSecurityIcon fill={textColor} style={{ width: 25, margin: 10 }} />}
              rightSideIcon={<ExternalLinkIcon fill={'white'} style={{ width: 12 }} />}
              text={'Launch IoT Security'}
            /> : null}
          {recommendStepsBtn.includes('REC_LAUNCH_PA_INSIGHTS') ?
            <RecommendedBtn
              btnProps={{
                style: { color: textColor },
                target: '_blank',
                href: paInsightsUrl,
                disabled: !paInsightsUrl,
              }}
              text='Launch Prisma Access Insights'
              leftSideIcon={<PAInsightIcon fill={textColor} style={{ width: 25, margin: 10 }} />}
              rightSideIcon={<ExternalLinkIcon fill={'white'} style={{ width: 12 }} />}
            /> : null}
          {recommendStepsBtn.includes('REC_LAUNCH_A_DEM') ?
            <RecommendedBtn
              btnProps={{
                style: { color: textColor },
                target: '_blank',
                href: autonomousDemUrl,
                disabled: !autonomousDemUrl,
              }}
              text='Launch Autonomous DEM'
              leftSideIcon={<PAInsightIcon fill={textColor} style={{ width: 25, margin: 10 }} />}
              rightSideIcon={<ExternalLinkIcon fill={'white'} style={{ width: 12 }} />}
            /> : null}
          {recommendStepsBtn.includes('REC_MANAGE_APPS') ?
            <RecommendedBtn
              btnProps={{
                style: { color: textColor },
                onClick: manageApps
              }}
              textColor={textColor}
              leftSideIcon={<Icon type="setting" style={{ fontSize: 20, margin: 10 }} />}
              rightIconProps={{ type: 'right-circle', theme: 'filled' }}
              text={'Manage App Instances'} >
            </RecommendedBtn> : null}
        </div>
      </>
    )
  }, [
    cdlInstanceUrl,
    history,
    isPrimaryInstanceRunning,
    otp,
    psk,
    primaryInstance,
    recommendStepsBtn,
    refreshEntitlementsAndCredentials,
    showEditInstanceModal,
    paInsightsUrl,
    iotUrl,
    autonomousDemUrl,
    stateRef,
    session
  ])


  const remainLicensesBtnProps = useMemo(() => {
    if (!context || !session.hash || !session.licenses) {
      return undefined
    }
    const activating = new Set(session.activating)
    const hasRemaining = session.licenses.reduce((all, lic) => (
      all.concat(Array.isArray(lic.licenses) ? lic.licenses : lic)
    ), []).some(l =>
      l.app_id && !l.activated && l.auth_code &&
      !activating.has(l.auth_code || l.serial_number) &&
      !NOT_ALLOWED_ACTIVATE_ALONE_APPS_SET.has(l.app_id)
    )
    if (!hasRemaining) {
      return undefined
    }
    const href = `/setup/${context}/${encodeURIComponent(session.hash)}`
    const remainLicensesBtnProps = {
      href,
      label: 'Activate More Products',
      style: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        minWidth: 190,
      }, // TODO: make entitlement refresh then we can use history.push
      // onClick: (e) => {
      //   e.preventDefault()
      //   history.push(href)
      // }
    }
    return remainLicensesBtnProps
  }, [context, session.activating, session.hash, session.licenses])
  // TODO: temp solution for IoT, prevent user loop back before csp activation complete
  const showRemainLicensesBtn = Boolean(remainLicensesBtnProps) && (progress > 45 || context !== SETUP_CTX_IOT)


  const showFirewallSubscribed = useCallback((e) => {
    e.preventDefault()
    setFirewallSubscribedVisible(true)
  }, [setFirewallSubscribedVisible])

  const renderMiscComponents = useCallback((rest) => {
    if (!isNonemptyArray(miscComponent) || !session) {
      return null
    }
    const factoryObject = { ...session, ...rest, setupSelectedAccount, wrapperClass: 'hbox', labelProps: { style: { minWidth: 140 } } }
    const components = miscComponent.map((eachCmp) => {
      const comp = ComponentFactory(eachCmp, factoryObject)
      return comp && <div key={eachCmp}>{comp}</div>
    })
    if (mode !== ELA && miscComponent.includes('FIREWALL_SUBSCRIPTION')) {
      const zingboxNgfwLicenses = session?.licenses?.filter(inst => inst.app_id === ZINGBOX_NGFW)
      const subscriptionData = {
        totalPurchased: zingboxNgfwLicenses?.map(obj => obj.licenses)?.reduce((res, lic) => [...res, ...lic], [])?.length,
        currentActivation: zingboxNgfwLicenses?.find(obj => obj.session_id === session?.uuid)?.licenses?.filter(obj => obj.activated === true)?.length,
        previouslyActivated: zingboxNgfwLicenses?.filter(obj => obj.session_id !== session?.uuid)?.reduce((res, lic) => [...res, ...lic?.licenses], [])?.filter(obj => obj.activated === true)?.length
      }
      components.push(
        <Subscription
          labelProps={{ style: { minWidth: 140 } }}
          key='FIREWALL_SUBSCRIPTION'
          wrapperClass={'hbox'}
          subscriptionData={subscriptionData}
          showFirewallSubscribed={showFirewallSubscribed}
        />
      )
    }
    if (miscComponent.includes('CMP_EXPIRATION')) {
      // find expiration date from instances
      const date = (session?.instances || []).find(each => each?.license_expiration)?.license_expiration
      components.push(<DateRender key={'CMP_EXPIRATION-1'} date={date} label={'Subscription Expiration'} valueProps={{ style: { color: '#54b79f' } }} labelProps={{ style: { minWidth: 140 } }} />)
    }
    return components
  }, [miscComponent, session, setupSelectedAccount, showFirewallSubscribed, mode])

  const renderIoTCards = useCallback((rest) => {
    return <>
      {FT('ELA_IOT_SAAS') && session?.batch_errors && <BatchErrorCard
        {...session}
        {...rest}
        description={'Please contact support engineers to reactivate it.'}
        title={'Firewall Activation Failure'}
        actionPanel={<a href={CONTACT_SUPPORT_URL} target='_blank' rel='noopener noreferrer'>Customer Support</a>}
      />}
      {isDataLakeStatusRunning && <IoTNGFStatus
        {...session}
        {...rest}
        detail_label={'Details'}
        description={'The logging service is not currently receiving any required logs from the subscribed firewalls.'}
        forward_label={'Forward logs to logging service'}
        label={'Logging Service Status'}
        actionPanel={<a href='#' onClick={showFirewallSubscribed}>Details</a>}
      />}
      {isDeviceCertificateAbsent && <StatusCard
        title={'Device certificate'}
        description={'Some firewalls are missing the Device Certificate.'}
        actionPanel={<a href='#' onClick={showFirewallSubscribed}>Details</a>}
        style={{
          borderLeft: '3px solid rgb(251, 210, 65)',
          background: 'linear-gradient(90deg, rgba(251, 210, 65,0.25) 0.74%, rgba(251, 210, 65,0) 100%)'
        }}
      />}
      {(isDataLakeStatusRunning || isDeviceCertificateAbsent) &&
        <FirewallModal
          headers={{
            title: IOT_FIREWALL_SUBSCRIBED_TITLE,
            subTitle: <span>{IOT_FIREWALL_SUBSCRIBED_SUBTITLE}</span>
          }}
          body={<FirewallSubscribedTable
            session={session}
            pollTime={isFirewallSubscribedVisible ? POLL_TIME : null}
            refreshSession={throttledRefreshSession}
          />}
          visible={isFirewallSubscribedVisible}
          setFirewallModalVisible={setFirewallSubscribedVisible}
        />
      }
    </>
  }, [session, isDataLakeStatusRunning, isDeviceCertificateAbsent, isFirewallSubscribedVisible, setFirewallSubscribedVisible, showFirewallSubscribed, throttledRefreshSession])

  const renderExtraComponents = useCallback((rest) => {
    const props = { ...session, ...rest }
    return extraComponents.map((Cmp, i) =>
      <Cmp key={`extra-${i}`} {...props} />
    )
  }, [extraComponents, session])

  const colCfg = useMemo(() => {
    if (useLinearLayout) {
      const cfg = {
        lg: 24,
        xl: 24,
        xxl: 24,
      }
      return { left: cfg, right: cfg }
    }
    return {
      left: {
        lg: 9,
        xl: 9,
        xxl: 9,
        style: { marginRight: 10 },
      },
      right: {
        lg: 14,
        xl: 14,
        xxl: 14,
      },
    }
  }, [useLinearLayout])

  return (
    <section className={'setup-progress'}>
      <SetupProgressBar progress={progress}
        session={session}
        isInitFetching={isInitFetching}
        launchInstanceBtnProps={launchInstanceBtnProps} />
      <div className={'vbox'} style={{ flex: 1, overflow: 'auto' }} >
        <Row className={`setup-progress-main ${isInitFetching ? 'loading' : ''}`}>
          <Col lg={18} xl={16} xxl={16} md={18} sm={24} xs={24} style={{ maxWidth }}>
            <Row type={'flex'} className={'progress-card'}>
              <Col className={'progress-card-title hbox space-between'}>
                <div className={'hbox middle'}>
                  <SetupCompleteIcon session={session} progress={progress} />
                  <div className={'vbox justify-center'}>
                    <div className={'setup_complete hbox '}>{progress < 100 && !session.done ? intermediate_title : final_title}</div>
                    <div><p className={'user_feedback'}>{sub_header}</p></div>
                  </div>
                </div>
                <AdditionalProductActivated visible={showRemainLicensesBtn} btnProps={remainLicensesBtnProps} />
              </Col>
              <Row type={'flex'} className={'progress-body'} justify={'space-between'}>
                <Col {...colCfg.left}>
                  {renderMiscComponents(rest)}
                  {renderIoTCards(rest)}
                  {recommendedSteps(isDataLakeStatusRunning || isDeviceCertificateAbsent)}
                  {
                    usefulLinks.length > 0 && <div className={'vbox'}>
                      <label className={'card-label'}>Useful Links</label>
                      <PrismaHelp usefulLinks={usefulLinks} />
                    </div>
                  }
                </Col>
                <Col {...colCfg.right}>
                  {context === SETUP_CTX_PRISMA_ACCESS && paInsightsUrl && <PAInsightsLearnmore />}
                  {keyFeature.length > 0 && <>
                    <label className={'card-label'}>{keyFeatureTitle}</label>
                    <Collapse className='progress-cards-wrapper'
                      expandIconPosition={'right'}
                      expandIcon={(isActive) => (isActive ? <Icon type='plus' /> : <Icon type='minus' />)}>
                      {keyFeature.map(({ title, content }, key) => {
                        return <Panel header={title} key={key} >
                          <p>{content}</p>
                        </Panel>
                      })}
                    </Collapse>
                  </>}
                  {extraComponents.length > 0 && renderExtraComponents(rest)}
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
        <SetupFooter footerLink={footerLabel} />
      </div>
      {primaryInstance && <InstanceRecordModal />}
    </section>
  )
}

SetupProgressPage.propTypes = {
  history: PropTypes.object,
  session: PropTypes.object,
  stateRef: PropTypes.any,
  context: PropTypes.string,
  contextApp: PropTypes.object,
  progress: PropTypes.number,
  selectedAccount: PropTypes.number,
  fetching: PropTypes.bool,
  showEditInstanceModal: PropTypes.func,
  progressStep: PropTypes.object,
  refreshSession: PropTypes.func,
  switchAccount: PropTypes.func,
  refreshEntitlementsAndCredentials: PropTypes.func,
  setFirewallSubscribedVisible: PropTypes.func,
  isFirewallSubscribedVisible: PropTypes.bool,
}

export default React.memo(SetupProgressPage)
