import React, { useState, useMemo, useEffect } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { CheckboxTable, Checkbox } from '@pan/cloud-base'
import ListItem from '../../../../components/ListItem'
const theadTh = {
  textTransform: 'none',
  color: '#000000',
  fontSize: '12px',
  fontWeight: 'normal',
  backgroundColor: '#FFFFFF'
}

const trGroup = {
  minHeight: '10px',
  borderBottom: '1px solid #EAEAEA',
  // width: '98%',//TODO
}

const thead = {
  backgroundColor: '#FFFFFF'
}

const td = {
  border: 'none',
  color: '#333333',
  fontWeight: 'normal',
}

const renderWithTooltip = (value) => <ListItem toolTipProps={{ placement: 'right' }}>{value || '-'}</ListItem>

export const deviceColumn = [
  {
    Header: 'Serial Number',
    accessor: 'serial_number',
    Cell: ({ value }) => renderWithTooltip(value),
  },
  {
    Header: 'Device Name',
    accessor: 'device_name',
    Cell: ({ value }) => renderWithTooltip(value),
  },
  {
    Header: 'Model',
    accessor: 'model',
    Cell: ({ value }) => renderWithTooltip(value),
  },
]

const FirewallTable = ({ data, handleDevicesSelected, selectedFirewallDevices }) => {
  const availableDevices = useMemo(() => data.filter(each => !each.disabled).map(each => each.serial_number), [data])
  const selectedDevices = useMemo(() => selectedFirewallDevices.map(each => each.serial_number), [selectedFirewallDevices])

  const [allChecked, setAllChecked] = useState()

  const checkBox = ({ ...rest }) => {
    const { onClick, id, row } = rest
    const { disabled, checked } = row || {}
    const isCheck = checked || selectedFirewallDevices.some(each => each.serial_number === row.serial_number)
    return <Checkbox {...rest} {...isCheck && { checked: true }} disabled={disabled} onClick={(e) => {
      const shiftKey = e.shiftKey
      e.stopPropagation()
      onClick(id, shiftKey, row)
    }} />
  }

  useEffect(() => {
    // preserve select all checked state when "back and next" navigation
    if (_.isEqual(availableDevices, selectedDevices)) {
      setAllChecked(true)
    }
    else {
      setAllChecked(false)
    }
  }, [availableDevices, selectedDevices])

  const selectAllCheckBox = ({ ...rest }) => {
    const { onClick, id, row } = rest
    return <Checkbox {...rest} {...allChecked && { checked: true }}
      onClick={(e) => {
        const shiftKey = e.shiftKey
        e.stopPropagation()
        onClick(id, shiftKey, row)
      }
      } />
  }

  return <CheckboxTable
    className='fw-device-check-tbl'
    columns={deviceColumn}
    data={data}
    styles={{ thead, theadTh, trGroup, td }}
    keyField='serial_number'
    onSelect={(serialNumbers) => handleDevicesSelected(serialNumbers)}
    autoScrollbar={true}
    sortable={true}
    preSelectList={selectedDevices}
    noDataText='No Devices Found'
    checkboxProps={{
      SelectInputComponent: checkBox,
      SelectAllInputComponent: selectAllCheckBox
    }}
  />
}

FirewallTable.propTypes = {
  data: PropTypes.array.isRequired,
  handleDevicesSelected: PropTypes.func.isRequired,
  selectedFirewallDevices: PropTypes.array,
}

export default FirewallTable
