import React, { useCallback, useState, useLayoutEffect, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@pan/cloud-base'
import Tooltip from '../../components/common/Tooltip'
import { useMemoizeValue } from '../../utils/hooks'

const NextButton = ({ theme, buttonProps, needsLoading, isLoadingBlocking, disabled, onClick, haveError, validateErrorArr, ...restBtnProps }) => {
  const [isButtonLoading, setButtonLoading] = useState(false)
  const btnRef = useRef()

  const handler = useCallback((e) => {
    if (needsLoading) {
      e.preventDefault()
      btnRef.current = e.target
      setButtonLoading(needsLoading)
    }
    else if (onClick) {
      const result = onClick(e)
      if (result === false) {
        e.preventDefault()
      }
    }
  }, [needsLoading, onClick])

  useLayoutEffect(() => {
    if (isButtonLoading && !needsLoading) {
      setButtonLoading(needsLoading)
      btnRef.current?.click()
    }
  }, [needsLoading, isButtonLoading])

  const errorTooltip = useMemo(() => {
    if (!validateErrorArr.length) {
      return null
    }
    return <>
      {
        validateErrorArr.map((each, idx) => {
          return <div key={idx}>{each}</div>
        })
      }
    </>
  }, [validateErrorArr])
  const btnProps = useMemoizeValue({
    ...restBtnProps,
    v2: false,
    ...buttonProps,
    loading: isLoadingBlocking ? needsLoading : isButtonLoading,
    onClick: handler,
    style: {
      ...restBtnProps.style,
      color: theme?.textColor || '#fff',
      backgroundColor: theme?.primaryColor || '#2686cb',
      borderColor: theme?.primaryColor || '#2686cb'
    }
  })
  const btnDisabled = disabled || Boolean(errorTooltip)

  /**
   * Custom style for disabled button needs to be fixed in cloud-base for v2=false
   */
  return <Tooltip title={errorTooltip || buttonProps.tooltip} placement={'left'}>
    <div>
      <Button
        {...btnProps}
        style={{ ...btnProps.style, opacity: btnDisabled ? 0.5 : 1 }}
        disabled={btnDisabled}
      >{buttonProps.label}</Button>
    </div>
  </Tooltip>
}

NextButton.propTypes = {
  theme: PropTypes.object,
  buttonProps: PropTypes.object,
  needsLoading: PropTypes.bool,
  isLoadingBlocking: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  haveError: PropTypes.bool,
  validateErrorArr: PropTypes.array,
}

export default React.memo(NextButton)
