/**
 * Created by vsuthar on 4/7/20
 * Project: App Portal ©Palo Alto Networks
 */
import React from 'react'
import PropTypes from 'prop-types'
import './Skeleton.scss'

class Skeleton extends React.PureComponent {
  render() {
    return (
      <div className={'hb-skeleton'} style={this.props.style}>
        <div className={'hb-skeleton-avatar'}></div>
        <div className={'hb-content'}>
          <ul className={'hb-paragraph'}>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>
    )
  }
}
Skeleton.propTypes = {
  style: PropTypes.object,
  header: PropTypes.node
}


export default Skeleton
