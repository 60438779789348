/**
 * Created by vsuthar on 6/15/20
 * Project: App Portal ©Palo Alto Networks
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Tooltip } from '@pan/cloud-base'
import './subscription.scss'
import SubscriptionDetails from './SubscriptionDetails'
import SetupMiscItem from '../../../../components/Setup/SetupMiscItem'

const Subscription = ({ subscriptionData, showFirewallSubscribed, ...rest }) => {
  const { currentActivation } = subscriptionData
  const toolTipTitle = <SubscriptionDetails {...subscriptionData} />
  const reactNode = <span className='subscription'>
    <Tooltip placement='top' title={toolTipTitle} overlayClassName='subscription-tooltip'>
      <Icon type='info-circle-o' style={{ color: '#1f75b2', padding: '3px' }} />
    </Tooltip>
    {showFirewallSubscribed && <a style={{ padding: '3px' }} href='#' onClick={showFirewallSubscribed}>Details</a>}
  </span>
  return <SetupMiscItem
    label={'Activate Subscription on'}
    value={currentActivation ? `${currentActivation} Firewall(s)` : undefined}
    suffixReactNode={reactNode}
    {...rest}
  />
}

Subscription.propTypes = {
  subscriptionData: PropTypes.shape({
    totalPurchased: PropTypes.number,
    currentActivation: PropTypes.number,
    previouslyActivated: PropTypes.number,
  }).isRequired,
  showFirewallSubscribed: PropTypes.func,
}
export default Subscription
