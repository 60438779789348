/**
 * Created by vsuthar on 11/17/20
 * Project: App Portal ©Palo Alto Networks
 */

/**
 * SetupCompleteIcon will show the icon error state or progress state based on session and progress.
 */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@pan/cloud-base'

const SetupCompleteIcon = ({ progress, session }) => {

  const setupCompleteIcon = useMemo(() => {
    const style = {
      height: 79,
      width: 79,
      borderRadius: '50%',
      backgroundColor: 'white',
      border: '2px solid #EAEAEA',
    }
    const icon = () => {
      if (session.error) {
        return <Icon type="exclamation" style={{ fontSize: 40, color: '#808080' }} />
      }
      else if (progress < 100) {
        return <Icon type='loading' style={{ fontSize: '32px' }} />
      }
      else if (session.done) {
        return <Icon type='check-circle' style={{ fontSize: 40, color: '#808080' }} theme='outlined' />
      }
      else {
        return <Icon type='loading' style={{ fontSize: '32px' }} />
      }
    }
    return <div style={style} className={'center'}>
      {icon()}
    </div>
  }, [progress, session])

  return (
    <div style={{ width: 80, height: 80, marginRight: 10 }}>
      {setupCompleteIcon}
    </div>
  )
}

SetupCompleteIcon.propTypes = {
  session: PropTypes.object,
  progress: PropTypes.number,
}
export default SetupCompleteIcon
