import {
  LABEL_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
  LABEL_SELECT_PRODUCTS,
  SETUP_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
  SETUP_SELECT_PRODUCTS,
  CGX as CGX_ID,
} from '../../../constants/AppConstants'
import { CheckLight, KeyIcon, UserShieldLight } from '../../../images/svg-icons'

const backBtnProps = {
  v2: false,
  label: 'Back',
}

const nextBtnProps = {
  v2: false,
  label: 'Next'
}

const progressStep = {
  useLinearLayout: true,
  maxWidth: 1280,
  title: {
    intermediate_title: 'Activating Prisma SD-WAN...',
    final_title: 'Prisma SD-WAN Activated.',
    sub_header: '',
  },
  recommendedSteps: {
    buttons: []
  },
  extraComponents: [({ getAnyApp }) => getAnyApp(CGX_ID)?.description || null]
}

export const CGX = {
  theme: {
    primaryColor: '#5482B7',
    secondaryColor: ''
  },
  steps: [
    {
      title: LABEL_SELECT_PRODUCTS,
      icon: KeyIcon,
      style: {
        maxWidth: 500,
        minWidth: 300,
      },
      key: 0,
      allowedApps: [CGX_ID],
      comp_enum: 'SETUP_ACTIVATE_PRODUCT',
      setup_enum: SETUP_SELECT_PRODUCTS,
      description: 'The following products are available for activation. Please select which products you would like to activate now:',
      backBtnProps: { ...backBtnProps, label: 'Quit', href: '/apps' },
      nextBtnProps: { ...nextBtnProps, label: 'Start Activation' },
      help: '',
      current: '0',
      prev: null,
      next: '1',
    },
    {
      title: LABEL_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
      icon: UserShieldLight,
      responsiveConfig: { xl: 14, xxl: 10, lg: 16, md: 18, sm: 23 },
      key: 1,
      comp_enum: 'SETUP_ACCOUNT_SELECTION',
      setup_enum: SETUP_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
      description: 'We have detected you have multiple support accounts. Please assign your new products to a support account, If you would like to assign these new products to different support accounts, you may do so.',
      backBtnProps,
      nextBtnProps,
      help: '',
      current: '1',
      prev: '0',
      next: '2',
      fields: false
    },
    {
      title: 'Confirm Selections',
      icon: CheckLight,
      style: {
        width: 450,
      },
      show_data_lake: false,
      show_cmp: ['CGX_CFG_FIELDS', 'CMP_SUPPORT_ACCOUNT', 'CMP_TERM_CONDITION'],
      comp_enum: 'CONFIRMATION',
      key: 2,
      description: 'Please finalize your selections below to complete your setup.',
      backBtnProps,
      nextBtnProps: { ...nextBtnProps, label: 'Confirm Selections' },
      prev: '1',
      current: '2',
    }
  ],
  progressStep,
}
