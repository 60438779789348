/**
 * Created by vsuthar on 6/10/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import SetupMiscItem from './SetupMiscItem'
import { LGS_ID } from '../../utils/activateUtils'

const SetupDataLakeView = ({ wrapperClass = 'hbox', cdlInstance = {}, getAnyApp, regionLabel, contextApp, ...rest }) => {
  const { tenant_display_name, tenant_instance_name, serial_number, region_display, is_clcs } = useMemo(() => {
    let cdlInst = cdlInstance
    const lsApp = getAnyApp?.(LGS_ID)
    if (!lsApp) {
      return cdlInst
    }
    if (cdlInstance?.tenant_id && !(cdlInstance.tenant_display_name || cdlInstance.tenant_instance_name)) {
      cdlInst = lsApp.tenants?.find(each => each.tenant_id === cdlInstance.tenant_id) || cdlInstance
    }
    if (!cdlInst.region_display) {
      const region = cdlInst.region || cdlInstance.region
      cdlInst.region_display = lsApp.regions?.find(each => each.name === region)?.display || region
    }
    return cdlInst
  }, [cdlInstance, getAnyApp])

  return Object.keys(cdlInstance).length ? (
    <div>
      <SetupMiscItem
        wrapperClass={wrapperClass}
        label={'Data Lake'}
        value={!is_clcs ? (tenant_display_name || tenant_instance_name || serial_number) : null}
        {...rest}
      />
      <SetupMiscItem
        wrapperClass={wrapperClass}
        label={regionLabel || 'Data Lake Region'}
        value={region_display}
        {...rest}
      />
    </div>
  ) : null
}

SetupDataLakeView.propTypes = {
  wrapperClass: PropTypes.string,
  cdlInstance: PropTypes.object,
  contextApp: PropTypes.object,
  getAnyApp: PropTypes.func,
  regionLabel: PropTypes.string,
}
export default SetupDataLakeView

