import React, { useRef, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CopyIcon, RefreshIcon } from '../../../images/svg-icons'
import { OTP_DESCRIPTION, OTP_TECH_DOC_LABEL, OTP_TECH_DOC_LINK } from '../../../constants/AppConstants'
import Tooltip from '../../../components/common/Tooltip'
import { preventDefault } from '../../../utils/activateUtils'
import TimeRemaining from './TimeRemaining'
import './PanoramaOTP.scss'

const OTP_INPUT_ID = 'panoarma-otp-input'
const PanoramaOTP = ({ otp, otp_expiration, has_psk, refreshSession }) => {
  const [isCopied, setCopied] = useState(false)
  const [expired, setExpired] = useState(false)
  const textAreaRef = useRef(null)

  refreshSession.expired = expired // use as ref
  const handleClick = useMemo(() => {
    const throttledRefresh = _.throttle(refreshSession, 5000, { leading: true, trailing: false })
    return (e) => {
      if (refreshSession.expired) { // expired
        if (!refreshSession.fetching) {
          throttledRefresh()
        }
      }
      else if (e?.target?.id !== OTP_INPUT_ID) { // good and click on btn
        textAreaRef.current.select()
        document.execCommand('copy')
        setCopied(true)
        setTimeout(() => setCopied(false), 1500)
      }
    }
  }, [refreshSession])
  const selectText = useCallback((e) => {
    e.preventDefault()
    e.target.select()
  }, [])

  const icon = useMemo(() => (expired ? <RefreshIcon className='otp-psk-copy-icon' /> : <CopyIcon className='otp-psk-copy-icon' />), [expired])

  return <div className='progress-completed-otp'>
    <p className='otp-caption'>Copy and paste the one-time password (OTP) on your Panorama using the instructions below.</p>
    <div className='otp-input-wrap'>
      <label className='otp-label' htmlFor={OTP_INPUT_ID}>Generate One-time Password</label>
      <TimeRemaining expiration={otp_expiration} setExpired={setExpired} />
      <input
        className='otp-text-input'
        id={OTP_INPUT_ID}
        readOnly
        ref={textAreaRef}
        onChange={preventDefault}
        onFocus={selectText}
        placeholder={expired ? 'Click to generate new OTP' : ''}
        value={expired ? '' : otp}
        onClick={handleClick}
      />
      <Tooltip visible={isCopied} title={'Copied!'} placement={'right'}>
        <button className='otp-copy-btn center' onClick={handleClick}>{icon}</button>
      </Tooltip>
    </div>
    <ol className='otp-instrucations'>
      <li>Log in to your Panorama.</li>
      <li>Download and install the Cloud Services plugin on <strong>Panorama &gt; Plugins</strong>.</li>
      <li>Select <strong>Panorama &gt; Cloud Services &gt; Configuration</strong> and click <strong>Verify</strong>.<br />
        If <strong>Verify</strong> is disabled, check that you have configured a DNS server and NTP server on <strong>Panorama &gt; Setup &gt; Services</strong>.</li>
      <li>Paste the <strong>OTP</strong> and click <strong>OK</strong>.</li>
    </ol>
    {!has_psk &&
      <div className='opt-instruction-note'>
        <strong>Note: </strong>{OTP_DESCRIPTION}
        <a rel='external noopener noreferrer' target='_blank' style={{ padding: '2px' }} href={`${OTP_TECH_DOC_LINK}`}>{OTP_TECH_DOC_LABEL}</a>
      </div>
    }
  </div>
}

PanoramaOTP.propTypes = {
  otp: PropTypes.string,
  otp_expiration: PropTypes.string,
  has_psk: PropTypes.bool,
  refreshSession: PropTypes.func,
}
export default React.memo(PanoramaOTP)
