import React, { useRef, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { CopyIcon, RefreshIcon } from '../../../images/svg-icons'
import { PSK_TECH_DOC_LINK, OTP_TECH_DOC_LINK } from '../../../constants/AppConstants'
import Tooltip from '../../../components/common/Tooltip'
import { useAxiosRequest, preventDefault } from '../../../utils/activateUtils'
import TimeRemaining from './TimeRemaining'
import './FirewallPSK.scss'

const EMPTY_OBJ = {}
const PSK_INPUT_ID = 'panoarma-psk-input'
const PanoramaPSK = ({ psk, session, has_otp }) => {
  const { loading, load, data } = useAxiosRequest()
  const [isCopied, setCopied] = useState(false)
  const [expired, setExpired] = useState(false)
  const textAreaRef = useRef(null)
  const refreshRef = useRef(EMPTY_OBJ)
  refreshRef.current.expired = expired
  refreshRef.current.fetching = loading
  refreshRef.current.session = session

  const handleClick = useMemo(() => {
    const { uuid, session_id = uuid, support_account_id } = refreshRef.current.session || {}
    const throttledRefresh = _.throttle(() => {
      load({
        method: 'post',
        url: '/hub/v2/licenses/psk',
        data: {
          session_id,
          support_account_id,
        }
      })
    }, 5000, { leading: true, trailing: false })
    return (e) => {
      if (refreshRef.current.expired) { // expired
        if (!refreshRef.current.fetching && session_id) {
          throttledRefresh()
        }
      }
      else if (e?.target?.id !== PSK_INPUT_ID) { // good and click on btn
        textAreaRef.current.select()
        document.execCommand('copy')
        setCopied(true)
        setTimeout(() => setCopied(false), 1500)
      }
    }
  }, [load])
  const selectText = useCallback((e) => {
    e.preventDefault()
    e.target.select()
  }, [])

  const { psk: pskValue, expiry: expiration } = data?.psk && data.expiry > psk.expiry ? data : psk
  const icon = useMemo(() => (expired ? <RefreshIcon className='otp-psk-copy-icon' /> : <CopyIcon className='otp-psk-copy-icon' />), [expired])
  return <div className='progress-completed-psk'>
    <div className='psk-instruction-block'>
      <div className='psk-caption'>Firewalls Using PAN-OS 10.1 and Later</div>
      Firewalls running PAN-OS 10.1 and later can use a device certificate to authenticate to Cortex Data Lake and other Palo Alto Networks cloud services.
      <a rel='external noopener noreferrer' target='_blank' style={{ padding: '2px' }} href={`${PSK_TECH_DOC_LINK}`}>Learn more</a>
    </div>
    <div className="psk-instruction-block">
      <div className='psk-caption'>Firewalls Using PAN-OS 10.0 and Earlier</div>
      For firewalls running on PAN-OS 10.0 and earlier, please copy the pre-shared key (PSK) provided below onto the firewall’s interface.
      <a rel='external noopener noreferrer' target='_blank' style={{ padding: '2px' }} href={`${PSK_TECH_DOC_LINK}`}>Learn more</a>
      <div className='psk-input-wrap'>
        <label className='psk-label' htmlFor={PSK_INPUT_ID}>Copy PSK for individually managed firewalls</label>
        <TimeRemaining expiration={expiration} setExpired={setExpired} />
        <input
          className='psk-text-input'
          id={PSK_INPUT_ID}
          readOnly
          ref={textAreaRef}
          onChange={preventDefault}
          onFocus={selectText}
          placeholder={expired ? 'Click to generate new PSK' : ''}
          value={expired ? '' : pskValue}
          onClick={handleClick}
        />
        <Tooltip visible={isCopied} title={'Copied!'} placement={'right'}>
          <button className='psk-copy-btn center' onClick={handleClick}>{icon}</button>
        </Tooltip>
      </div>
      <div className='panorama-fw-instrucation'>
        <div className='psk-caption'>Panorama-Managed Firewalls</div>
        For firewalls managed by Panorama running on PAN-OS versions 10.0 and earlier, please
        <a rel='external noopener noreferrer' target='_blank' style={{ paddingLeft: '3px' }} href={`${OTP_TECH_DOC_LINK}`}>follow these instructions</a>.
      </div>
    </div>
  </div>
}

PanoramaPSK.propTypes = {
  psk: PropTypes.shape({
    psk: PropTypes.string,
    expiry: PropTypes.number,
  }),
  has_otp: PropTypes.bool,
  session: PropTypes.object,
}
export default React.memo(PanoramaPSK)
