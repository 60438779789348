import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Tooltip } from '@pan/cloud-base'
import _ from 'lodash'
import './licenseTypeCounter.scss'
import { getRemainingTime, renderAssetType, firewallModelMatch } from '../../../../utils/activateUtils'
import { MONTHS, DAYS, NEW_LABEL, PREV_LABEL, LICENSE_GROUP_LABEL } from '../../../../constants/AppConstants'

const LicenseTypeCounter = ({ licenses, formValue, currentRowModel, isAgnosticLicense }) => {

  const licenseTermTemplate = (licenseVersion, list, label) => {

    const groupByLicenseTerm = _.groupBy(list, 'license_term')
    const toPairs = _.toPairs(groupByLicenseTerm) // eg: [['12', [{}]], ['15', [{}, {}]]
    const sortedPairs = toPairs.sort((a, b) => b[1].length - a[1].length) // eg: [['15', [{}, {}]], ['12', [{}]]
    return <div className='license-groups'>
      <div className='ghost-label'>{label}</div>
      {
        sortedPairs.map(arr => {
          const unitLabel = label === NEW_LABEL ? MONTHS : DAYS
          const obj = arr[1]?.[0] || {} // just pick first array value since array is already grouped
          const count = formValue.filter(
            each => each.license_asset_type === obj.asset_type &&
              each.license_term === obj.license_term &&
              each.license_unit === unitLabel &&
              each.license_version === licenseVersion &&
              (isAgnosticLicense || firewallModelMatch(each.model, obj?.sku))
          )?.length || 0
          return <div key={arr[0]} className='license-term'>
            <span style={{ paddingRight: '5px' }}>{`${count}/${arr[1]?.length}`}</span>
            <span>{getRemainingTime(arr[0], unitLabel)}</span>
          </div>
        })
      }
    </div>
  }

  const tooltip = (licenseType, assetTypeGroup, licenseVersion) => {
    const list = assetTypeGroup[licenseType]
    const groupByLicenseUnit = _.groupBy(list, 'license_unit')
    const monthBasedLicenses = groupByLicenseUnit[MONTHS]
    const dayBasedLicenses = groupByLicenseUnit[DAYS]

    const titleNode = <div className='license-tooltip-title'>
      <label className='label'>License Quantities</label>
      {monthBasedLicenses && licenseTermTemplate(licenseVersion, monthBasedLicenses, NEW_LABEL)}
      {dayBasedLicenses && licenseTermTemplate(licenseVersion, dayBasedLicenses, PREV_LABEL)}
    </div>

    return <Tooltip placement='right' title={titleNode} overlayClassName='license-type-tooltip'>
      <Icon type='info-circle-o' style={{ color: '#6d757b', padding: '3px', marginTop: '7px', marginRight: '10px' }} />
    </Tooltip>
  }

  const tagWithCounter = (licenseType, assetTypeGroup, licenseVersion) => {
    let filterValues = formValue.filter(each => each.license_asset_type === licenseType && each.license_version === licenseVersion)
    if (currentRowModel) {
      filterValues = filterValues.filter(each => each.model === currentRowModel)
    }
    const quantity = `${filterValues.length}/${assetTypeGroup[licenseType]?.length}`
    return <div className='license-type-counter'>
      <div className='license-type-value'>{renderAssetType(licenseType)}</div>
      <div className='license-quantity'>{quantity}</div>
    </div>
  }

  const renderComponent = (licenseVersion) => {
    const assetTypeGroup = _.groupBy(licenses[licenseVersion], 'asset_type')
    return <div key={licenseVersion} className='license-type-counter-container'>
      {licenseVersion && <div className='license-version'>{LICENSE_GROUP_LABEL[licenseVersion]}</div>}
      {
        Object.keys(assetTypeGroup).map(licenseType => {
          return <span key={licenseType} className='flex-container'>
            {tagWithCounter(licenseType, assetTypeGroup, licenseVersion)}
            {tooltip(licenseType, assetTypeGroup, licenseVersion)}
          </span>
        })
      }
    </div>
  }

  return <div className='flex-container'>
    {Object.keys(licenses).map(licenseVersion => renderComponent(licenseVersion))}
  </div>
}

LicenseTypeCounter.propTypes = {
  licenses: PropTypes.object.isRequired,
  formValue: PropTypes.array.isRequired,
  currentRowModel: PropTypes.string,
  isAgnosticLicense: PropTypes.bool
}

export default LicenseTypeCounter
