import React, { useMemo } from 'react'
import { Select } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import {
  renderInstances,
  useMemoFieldDecorator,
} from '../../../utils/activateUtils'
import { DLP, SETUP_CREATE_NEW } from '../../../constants/AppConstants'
import ListItem from '../../../components/ListItem'
import { convertObjToString } from '../../../utils/activateUtils'

const renderOptions = tenants => tenants.map(({
  key,
  group,
  display,
  extra,
  value,
  tenant_id,
  ...props
}) => {
  return <Select.Option {...props} key={key} value={`${DLP}:${value}`}>
    <ListItem toolTipProps={{ placement: 'right' }}>{display} {extra}</ListItem>
  </Select.Option>
})

const renderOptionGroups = optionsGroups => optionsGroups.map(([region, tenants]) => {
  return !region ? renderOptions(tenants) : <Select.OptGroup key={region} label={region}>
    {renderOptions(tenants)}
  </Select.OptGroup>
})

const DLPFields = (props) => {
  const {
    fieldName,
    getAnyApp,
    form,
  } = props

  const { getFieldDecorator } = form
  const renderedDLPInstances = useMemo(() => {
    const rendered = renderInstances({
      config: {
        app_id: DLP,

      },
      appInfoMap: { // no need the full version from activate utils
        getAnyApp,
        getInstances: appId => {
          return getAnyApp(appId)?.tenants
        },
      },
      renderOptions,
      renderOptionGroups,
    })
    return rendered
  }, [getAnyApp])

  const DLPValue = useMemo(() => {
    // get DLP that doesn't have any tsg_id, only 2 possibilities, 1 or none
    const DLPTenant = getAnyApp(DLP)?.tenants?.filter(inst => !inst.tsg_id)?.[0]
    if (DLPTenant) {
      return `${DLP}:${convertObjToString(DLPTenant)}`
    }
    return `${DLP}:${SETUP_CREATE_NEW}`
  }, [getAnyApp])
  const getDLPDecorator = useMemoFieldDecorator(fieldName, {
    initialValue: DLPValue,
    rules: useMemo(() => [
      { required: true, message: 'DLP tenant is required' },
    ], []),
  }, getFieldDecorator)
  return (
    <div className={'hbox middle space-between setup-item'} style={{ display: 'none' }}>
      <label>DLP Tenant</label>
      {getDLPDecorator(
        <Select
          {...{ style: { width: '70%', marginLeft: 10 } }}
          placeholder='DLP tenant'
          disabled={true}>
          {renderedDLPInstances}
        </Select>
      )}
    </div>
  )
}

DLPFields.propTypes = {
  form: PropTypes.object,
  fieldName: PropTypes.string,
  contextApp: PropTypes.object,
  formValues: PropTypes.object,
  setupSelectedAccount: PropTypes.number,
  getAnyApp: PropTypes.func,
  toBeActivatedApp: PropTypes.array,
  selectProps: PropTypes.object,
  session: PropTypes.object,
  entitledAppsList: PropTypes.array,
}

export default DLPFields
