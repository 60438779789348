import React from 'react'
import PropTypes from 'prop-types'
import SetUpErrorCard from '../../components/Setup/SetUpErrorCard'
import { SERVICE_UNAVAILABLE, ERROR_TYPES } from '../../constants/ErrorCode'

const reactNodeStyle = {
  fontSize: '12px'
}

const ErrorHandler = ({ errorData = {} }) => {
  const { errorType, errorCode, message } = errorData
  let errorMessage = message ? `${message} Error code: ${errorCode}` : message
  let reactNode = ''

  switch (errorType) {
    case ERROR_TYPES.FIREWALL_NOT_FOUND: {
      const { devices } = errorData
      reactNode = <ul>{devices.map(each => <li key={each}>{each}</li>)}</ul>
      break
    }
    case ERROR_TYPES.FIREWALL_LICENSE_INCOMPATIBLE: {
      const { device } = errorData
      reactNode = <div style={reactNodeStyle}>
        <p>Serial Number: {device?.serial_number}</p>
        <p>License Type: {device?.license_type}</p>
      </div>
      break
    }
    case ERROR_TYPES.LICENSE_NOT_FOUND: {
      const { devices } = errorData
      reactNode = <ul>{devices.map(each => <li key={each}>{each}</li>)}</ul>
      break
    }
    default: {
      errorMessage = SERVICE_UNAVAILABLE
      break
    }
  }
  return <SetUpErrorCard errorMsg={errorMessage} reactNode={reactNode} />
}


ErrorHandler.propTypes = {
  errorData: PropTypes.object,
}

export default ErrorHandler
