/**
 * Created by vsuthar on 6/5/20
 * Project: App Portal ©Palo Alto Networks
 */

import { isFeatureEnabled as FT } from '@pan/cloud-appframework'
import {
  LABEL_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
  LABEL_SELECT_PRODUCTS,
  SETUP_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
  SETUP_NEW_PANORAMA,
  SETUP_SELECT_PRODUCTS,
  LGS_ID,
  PRISMA_ACCESS,
  PRISMA_ACCESS_PANORAMA,
  PA_ALL_EXT_APPS, ZINGBOX, SETUP_CREATE_NEW,
  PRISMA_ACCESS_DISPLAY_NAME,
  STRATA_ACCESS_DISPLAY_NAME,
} from '../../../constants/AppConstants'
import { CheckLight, CogsLight, KeyIcon, UserShieldLight } from '../../../images/svg-icons'

const ACCESS_DISPLAY_NAME = FT('STRATA_ACCESS_REBRAND') ? STRATA_ACCESS_DISPLAY_NAME : PRISMA_ACCESS_DISPLAY_NAME

const backBtnProps = {
  v2: false,
  label: 'Back',
}

const nextBtnProps = {
  v2: false,
  label: 'Next',
}


const progressStep = {
  useSimpleProgress: true,
  title: {},
  recommendedSteps: {
    showOTP: true,
    showDataLakeStatus: false,
    buttons: [
      'PA_INSTALL_PLUGIN',
      'REC_ASSIGN_ROLE',
      'REC_ASSOCIATE_DSS',
      'REC_CONFIGURE_DATALAKE',
      'PA_INSIGHT_LAUNCH',
      'REC_MANAGE_APPS',
      'REC_LAUNCH_IOT_SECURITY',
      'REC_LAUNCH_PA_INSIGHTS',
    ],
  },
  usefulLinks: [
    {
      component_type: 'SET_UP_LINK',
      href: 'https://knowledgebase.paloaltonetworks.com/KCSArticleDetail?id=kA10g000000PP3wCAG',
      label: `${ACCESS_DISPLAY_NAME} Useful Resources`,
    },
    {
      component_type: 'SET_UP_LINK',
      href: 'https://knowledgebase.paloaltonetworks.com/KCSArticleDetail?id=kA10g000000PP3wCAG',
      label: `${ACCESS_DISPLAY_NAME} Live Community`,
    },
    {
      component_type: 'SET_UP_LINK',
      href: 'https://status.paloaltonetworks.com/',
      label: 'Palo Alto Networks Status Information ',
    },
    {
      component_type: 'SET_UP_LINK',
      href: 'https://www.paloaltonetworks.com/resources/reference-architectures/prisma-access',
      label: `${ACCESS_DISPLAY_NAME} Architecture Guide`,
    },
    {
      component_type: 'SET_UP_LINK',
      href: 'https://www.paloaltonetworks.com/resources/videos/cloud-enabled-mobile-workforce',
      label: 'Lightboard Series: Cloud-Enabled Mobile Workforce',
    },
    {
      component_type: 'SET_UP_LINK',
      href: 'https://www.paloaltonetworks.com/resources/videos/branch-security',
      label: 'Lightboard Series: Cloud-Connected Branch',
    },
    {
      component_type: 'SET_UP_LINK',
      href: 'https://www.paloaltonetworks.com/resources/datasheets/globalprotect-cloud-service',
      label: `${ACCESS_DISPLAY_NAME} - Datasheet`,
    },
    {
      component_type: 'SET_UP_LINK',
      href: 'https://www.youtube.com/watch?v=fKWvBT-G4_E&list=PLqATPiC_Bcl-8R7t1_kQ-c_BbSQcXjf8C&index=4&t=0s',
      label: 'Zero Touch Cloud Security',
    },
    {
      component_type: 'SET_UP_LINK',
      href: 'https://www.youtube.com/playlist?list=PLqATPiC_Bcl-t8vrzZlnGi3HTurs9Yuf3',
      label: `SASE for Dummies ${ACCESS_DISPLAY_NAME} for Networks Architecture Guide`,
    },
    {
      component_type: 'SET_UP_LINK',
      href: 'https://www.youtube.com/watch?v=UfpvkPuXGk0&list=PLqATPiC_Bcl-8R7t1_kQ-c_BbSQcXjf8C&index=17&t=29s',
      label: 'Jeffries case study Prevent Successful attacks using Wildfire',
    },
    {
      component_type: 'SET_UP_LINK',
      href: 'https://www.youtube.com/playlist?list=PLqATPiC_Bcl-t8vrzZlnGi3HTurs9Yuf3',
      label: 'Lightboard series',
    },
    {
      component_type: 'SET_UP_LINK',
      href: 'https://live.paloaltonetworks.com/t5/prisma-access-insights/ct-p/Prisma_Access_Insights',
      label: `${ACCESS_DISPLAY_NAME} Insights Live Community`,
    },
    FT('PAE_WITH_FAWKES') ?
      {
        component_type: 'SET_UP_LINK',
        href: '',
        label: 'Autonomous DEM',
      } : {},
    {
      component_type: 'SETUP_SIMPLE_TEXT',
      style: {
        marginLeft: 10,
        marginTop: 10,
      },
      text: `Look for a email from ${ACCESS_DISPLAY_NAME} Customer Success with additional useful information.`,
    },
  ],
  keyFeature: [
    {
      title: 'Firewall as a Service',
      subTitle: '',
      content: `${ACCESS_DISPLAY_NAME} provides firewall as a service (FWaaS) that protects branch offices from threats while also providing the security services expected from a next-generation firewall. The full spectrum of FWaaS includes threat prevention, URL filtering, sandboxing, and more.`,
    },
    {
      title: 'DNS Security',
      subTitle: '',
      content: `${ACCESS_DISPLAY_NAME} delivers our DNS Security service, which provides a combination of predictive analytics, machine learning, and automation to combat threats in DNS traffic. Organizations can block known malicious domains, predict new malicious domains, and stop DNS tunneling.`,
    },
    {
      title: 'Threat Prevention',
      subTitle: '',
      content: `Threat Prevention: Using ${ACCESS_DISPLAY_NAME} for threat prevention combines the proven technologies in the Palo Alto Networks platform, together with global sources of threat intelligence and automation, to stop previously known or unknown attacks.`,
    },
    {
      title: 'Data Loss Prevention',
      subTitle: '',
      content: `${ACCESS_DISPLAY_NAME} combines integration with data loss prevention (DLP) controls that are API-driven (through Prisma SaaS) as well as in-line (through ${ACCESS_DISPLAY_NAME}). These DLP policies allow organizations to categorize data and establish policies that prevent data loss.`,
    },
    {
      title: 'Autonomous DEM',
      subTitle: '',
      content: `The Autonomous Digital Experience Management (ADEM) add-on for ${ACCESS_DISPLAY_NAME} provides visibility into user experience, application and network performance. With Autonomous DEM, you gain segment-wise insights across the entire service delivery path, with real and synthetic traffic analysis that enables the ability to drive autonomous remediation of digital experience problems when they arise.`,
    },
    {
      title: 'Cloud Access Security Broker',
      subTitle: '',
      content: `${ACCESS_DISPLAY_NAME} and Prisma SaaS implement security controls that combine in-line security API security and contextual controls, acting as a cloud access security broker (CASB) to determine access to sensitive information. These controls are implemented in an integrated manner and applied throughout all cloud application policies.`,
    },
    {
      title: 'Cloud Secure Web Gateway',
      subTitle: '',
      content: `Cloud Secure Web Gateway: ${ACCESS_DISPLAY_NAME} for secure web gateway (SWG) functionality is designed to maintain visibility into all types of traffic while stopping evasions that can mask threats. Our web filtering capabilities also drive our credential theft prevention technology, which can stop corporate credentials from being sent to previously unknown sites.`,
    },
  ],
}

export const PA = {
  theme: {
    primaryColor: '#2686cb',
    secondaryColor: '',
    textColor: '#fff',
  },
  stepsHelp: {
    helpVideo: 'https://players.brightcove.net/1050259881001/rJLR3mDB_default/index.html?videoId=6170482272001',
    helpLinks: [
      {
        label: `Activate ${ACCESS_DISPLAY_NAME} Guide (Cloud Managed)`,
        href: 'https://docs.paloaltonetworks.com/prisma/prisma-access/prisma-access-cloud-managed-admin/get-started-with-prisma-access/license-and-activate-prisma-access',
        border: false,
      },
      {
        label: `Activate ${ACCESS_DISPLAY_NAME} Guide (Panorama Managed)`,
        href: 'https://docs.paloaltonetworks.com/prisma/prisma-access/prisma-access-panorama-admin/license-prisma-access/activate-and-install-prisma-access.html',
        border: true,
      },
      {
        label: `Get help from the ${ACCESS_DISPLAY_NAME} LIVEcommunity`,
        href: 'https://live.paloaltonetworks.com/t5/prisma-access/ct-p/PrismaAccess',
        border: true,
      },
      {
        label: 'Need support?',
        href: 'https://knowledgebase.paloaltonetworks.com/KCSArticleDetail?id=kA10g000000ClNZCA0\n',
        border: false,
      },
    ],
  },
  steps: [
    {
      title: LABEL_SELECT_PRODUCTS,
      license_quantity_tooltip: true,
      icon: KeyIcon,
      style: {
        maxWidth: 500,
        minWidth: 300,
      },
      key: 0,
      allowedApps: [LGS_ID, PRISMA_ACCESS_PANORAMA, PRISMA_ACCESS, ...PA_ALL_EXT_APPS],
      comp_enum: 'SETUP_ACTIVATE_PRODUCT',
      setup_enum: SETUP_SELECT_PRODUCTS,
      description: 'The following products are available for activation. Please select which products you would like to activate now:',
      backBtnProps: { ...backBtnProps, label: 'Quit', href: '/apps' },
      nextBtnProps: { ...nextBtnProps, label: 'Start Activation', tooltip: 'Select this option to activate your products with Panorama or Cloud Management' },
      help: '',
      current: '0',
      prev: null,
      next: '1',
    },
    {
      title: LABEL_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
      icon: UserShieldLight,
      responsiveConfig: { xl: 14, xxl: 10, lg: 16, md: 18, sm: 23 },
      key: 1,
      comp_enum: 'SETUP_ACCOUNT_SELECTION',
      setup_enum: SETUP_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
      description: 'We have detected you have multiple support accounts. Please assign your new products to a support account, If you would like to assign these new products to different support accounts, you may do so.',
      backBtnProps,
      nextBtnProps,
      help: '',
      current: '1',
      prev: '0',
      next: '2',
      fields: false,
    },
    {
      title: `Choose how to manage ${ACCESS_DISPLAY_NAME}`,
      icon: CogsLight,
      responsiveConfig: { xl: 10, xxl: 8, lg: 12, md: 10, sm: 18 },
      style: {
        minWidth: 555,
      },
      key: 2,
      setup_enum: 'CHOOSE_HOW_TO_MANAGE_PRISMA_ACCESS',
      comp_enum: 'CHOOSE_HOW_TO_MANAGE_PRISMA_ACCESS',
      description: `For this evaluation, do you intend to share the security settings of your current Panorama environment or use our free Cloud-based ${ACCESS_DISPLAY_NAME} Management Console?`,
      backBtnProps,
      nextBtnProps,
      help: '',
      defaultSetupValues: {
        value: SETUP_NEW_PANORAMA,
      },
      current: '2',
      prev: '1',
      next: '3',
    },
    {
      title: 'Register New Panorama',
      icon: CogsLight,
      responsiveConfig: { xl: 10, xxl: 7, lg: 12, md: 12, sm: 18, xs: 23 },
      key: 3,
      setup_enum: 'REGISTER_NEW_PANORAMA',
      comp_enum: 'REGISTER_NEW_PANORAMA',
      description: 'We’re going to walk you through the steps of settings up a new Panorama.',
      backBtnProps,
      nextBtnProps,
      help: '',
      current: '3',
      prev: '2',
      next: '4',
    },
    {
      title: 'Finalize Selections',
      icon: CheckLight,
      responsiveConfig: { xl: 12, xxl: 9, lg: 15, md: 18, sm: 18, xs: 23 },
      key: 4,
      show_data_lake: true,
      show_cmp: ['CMP_SUPPORT_ACCOUNT', 'CMP_REGION', 'CMP_DATALAKE_READ', 'CMP_EXPIRATION', 'CMP_PRISMA_MANAGEMENT', 'CMP_TERM_CONDITION'],
      comp_enum: 'CONFIRMATION',
      description: 'Please finalize your selections below to complete your setup.',
      backBtnProps,
      defaultSetupValues: {
        value: `${ZINGBOX}:${SETUP_CREATE_NEW}`,
      },
      nextBtnProps: { ...nextBtnProps, label: 'Confirm Selections' },
      prev: '3',
      current: '4',
    },
  ],
  progressStep,
}

/**
 * @TODO this is temprary this needs to move once inside of usefulLinks once we remove FF
 */

const basedOnFF = () => {
  FT('PAE_WITH_FAWKES') ? progressStep?.recommendedSteps?.buttons?.push('REC_LAUNCH_A_DEM') : null
}
basedOnFF()
