/**
 * Created by vsuthar on 4/9/20
 * Project: App Portal ©Palo Alto Networks
 */
import React from 'react'
import { Row } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import SetUpErrorCard from '../../components/Setup/SetUpErrorCard'
import ErrorBoundary from '../../components/ErrorBoundary'

const renderDescription = (description, comp_enum, setFirewallListVisible) => {
  if (['SELECT_FIREWALL', 'SELECT_PRISMA_SAAS_FIREWALL', 'SELECT_STRATA_INSIGHTS_FIREWALL'].includes(comp_enum)) {
    const fields = description.split('__LINK__')
    return (
      <div className={'setup-description'}>
        <span>{fields[0]}</span>
        {fields[1] && <>
          <a href='#' onClick={() => setFirewallListVisible(true)}>Full Firewall List</a>
          <span>{fields[1]}</span>
        </>
        }
      </div>
    )
  }
  return (
    <div className={'setup-description'}>{description}</div>
  )
}

const SetupWrapper = ({ title, description, children, icon: BadgeIcon, comp_enum, setFirewallListVisible }) => {

  return (
    <Row className={'setup-card'}>
      <div className={'setup-card-header'}>
        <div className={'badge-icon'}><BadgeIcon style={{ width: 36 }} /></div>
        <h1 className={'setup-title'}>{title}</h1>
        {renderDescription(description, comp_enum, setFirewallListVisible)}
      </div>
      <ErrorBoundary component={<SetUpErrorCard /> }>
        {children}
      </ErrorBoundary>
    </Row>
  )
}

SetupWrapper.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.elementType,
  comp_enum: PropTypes.string,
  setFirewallListVisible: PropTypes.func
}

export default SetupWrapper

