import React, { useMemo, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { FT } from '@pan/cloud-appframework'
import {
  NOT_ALLOWED_ACTIVATE_ALONE_APPS_SET,
  SETUP_CTX_CDL,
} from '../../../constants/AppConstants'
import { ExclamationCircleIcon } from '../../../images/svg-icons'
import SetupInProgress from './SetupInProgress'
import SetupCompleted from './SetupCompleted'
import SetupFooter, { footerLabel } from '../SetupFooter'
import BatchError from '../common/BatchError'
import './SetupProgress.scss'

// const EMPTY_ARRAY = []
const EMPTY_OBJ = {}
const POLL_TIME = 2000 // 2s
const FORCE_REFRESH_INTERVAL = FT('NO_PUSH_EVENTS') ? 10000 : 60000 // 10s : 1min
const CONTACT_SUPPORT_URL = 'https://supportcases.paloaltonetworks.com/apex/Communities_CreateCaseWizard'
const SetupProgressPage = ({
  session = EMPTY_OBJ,
  progress = +session.progress || 0,
  progressStep = EMPTY_OBJ,
  selectedAccount,
  switchAccount,
  context,
  contextApp,
  history,
  fetching,
  stateRef,
  showEditInstanceModal,
  setFirewallSubscribedVisible,
  refreshSession,
  isFirewallSubscribedVisible,
  refreshEntitlementsAndCredentials,
  ...rest
}) => {
  const {
    title: {
      intermediate_title = 'Finalizing your products',
      intermediate_sub_title,
      final_title = 'Activation Completed',
      final_sub_title,
    } = EMPTY_OBJ,
  } = progressStep
  const { getAnyApp } = stateRef.current
  rest.getAnyApp = getAnyApp
  const inprogress = !session.done && !session.error
  const isInitFetching = fetching && !session.session_id
  const primaryAppId = session.primary_instance?.app_id

  const setupSelectedAccount = useMemo(() => {
    const index = session.support_account_id >= 0 && rest.supportAccounts.find(
      acc => +acc.support_account_id === +session.support_account_id
    )?.support_account_index
    return index || undefined
  }, [rest.supportAccounts, session.support_account_id])

  const finalSubTitle = useMemo(() => {
    if (final_sub_title) {
      return final_sub_title
    }
    const appDef = primaryAppId && getAnyApp(primaryAppId)
    if (appDef?.device_name) { // do not show for panorama devices
      return null
    }
    const appDisplayName = appDef?.display_name
    if (!appDisplayName) {
      return null
    }
    return `${appDisplayName} is ready to be launched.`
  }, [final_sub_title, getAnyApp, primaryAppId])

  const error = useMemo(() => {
    const message = session.error?.message || session.error
    if (!message || !_.isString(message)) {
      return null
    }
    const [, text = message, code = null] = message.match(/^(.+)\s*\(\s*(\w+)\s*\)\s*$/) || []
    return <>{code && <span className='progrss-error-code'>{code}: </span>}{text}</>
  }, [session.error])

  const showWatermark = useMemo(() => session.error || (
    !session.done && session.progress_steps?.some(step => step.retry || step.warning)
  ), [session.done, session.error, session.progress_steps])
  const watermark = useMemo(() => {
    if (!showWatermark) {
      return ''
    }
    const { hostname, pathname } = window.location
    const path = session.uuid && session.hash ? `/${context}/${session.uuid}\n#${session.hash}#` : pathname
    return `${hostname.replace('.paloaltonetworks.com', '')}${path}`
  }, [context, showWatermark, session.hash, session.uuid])

  progress = Math.max(progress, +session.progress || 0) // in case session updated while events missed
  const throttledRefreshSession = useMemo(() => Object.assign(
    _.throttle(refreshSession, POLL_TIME), { fn: refreshSession }
  ), [refreshSession]) // 5s
  useEffect(() => {
    if (selectedAccount >= 0 && setupSelectedAccount >= 0 && selectedAccount !== setupSelectedAccount) {
      switchAccount(setupSelectedAccount)
    }
  }, [selectedAccount, setupSelectedAccount, switchAccount])
  useEffect(() => { // refresh session when progress changed with 5s throttling
    if (progress === 100) {
      throttledRefreshSession.cancel()
      throttledRefreshSession.fn()
    }
    else if (progress > 0) {
      throttledRefreshSession()
    }
  }, [progress, getAnyApp, throttledRefreshSession]) // when progress update or entitlements loaded
  useEffect(() => {
    const fn = _.debounce(() => {
      if (document.visibilityState === 'visible' && Date.now() - fn.last > 60000) { // > 1min
        throttledRefreshSession()
        fn.last = Date.now()
      }
    }, 500)
    fn.last = Date.now()
    window.addEventListener('focus', fn, false)
    document.addEventListener('visibilitychange', fn, false)
    return () => {
      window.removeEventListener('focus', fn, false)
      document.removeEventListener('visibilitychange', fn, false)
    }
  }, [throttledRefreshSession])
  useEffect(() => {
    const internval = inprogress && setInterval(throttledRefreshSession, FORCE_REFRESH_INTERVAL)
    return () => {
      if (internval) {
        clearInterval(internval)
      }
    }
  }, [throttledRefreshSession, inprogress])

  const remainLicensesBtnProps = useMemo(() => {
    if (!context || !session.hash || !session.licenses) {
      return undefined
    }
    const activating = new Set(session.activating)
    const remainingLicenses = session.licenses.reduce((all, lic) => ( // TODO: need updated licenses list
      all.concat(Array.isArray(lic.licenses) ? lic.licenses : lic)
    ), []).filter(l =>
      l.app_id && !l.activated && l.auth_code &&
      !activating.has(l.auth_code || l.serial_number) &&
      !NOT_ALLOWED_ACTIVATE_ALONE_APPS_SET.has(l.app_id)
    )
    if (!remainingLicenses.length) {
      return undefined
    }
    // update context here using remaining licenses
    const contextSet = remainingLicenses.reduce((set, lic) => {
      const app = getAnyApp(lic.app_id)
      const setupContext = app?.app_id && (app.setup?.context ||
        (app.extension_to && getAnyApp(app.extension_to)?.setup?.context))
      if (setupContext) {
        set.add(setupContext)
      }
      return set
    }, new Set())
    let nextContext = context
    if (contextSet.size && !contextSet.has(context)) { // if cur ctx no longer avail
      const contexts = Array.from(contextSet)
      if (contexts.length === 1) {
        nextContext = contexts[0] // if only have one use that
      }
      else { // or use anything other than cdl, unless only cdl is avail
        nextContext = contexts.filter(ctx => ctx !== SETUP_CTX_CDL)[0] || contexts[0]
      }
    }
    const href = `/setup/${nextContext}/${encodeURIComponent(session.hash)}`
    const remainLicensesBtnProps = {
      to: href,
      children: 'Activate Additional Products',
    }
    return remainLicensesBtnProps
  }, [context, getAnyApp, session.activating, session.hash, session.licenses])

  const handleLogoClick = useCallback((e) => {
    if (e.button === 1) {
      history.push('/apps')
    }
  }, [history])

  const displayProgress = session.done ? 100 : progress || 0
  const logoFlashing = inprogress && progress < 100 ? 'panw-logo-waiting' : ''
  return (
    <section className='simple-setup-progress'>
      <div className={`setup-progress-main ${isInitFetching ? 'loading' : ''}`}>
        <div className='progress-card'>
          <div className={`progress-card-header ${(session.error || session.batch_errors) ? 'progress-error-header' : ''}`}>
            {(session.error || session.batch_errors) ? <>
              <ExclamationCircleIcon fill='#fff' width={70} height={70} />
              {session.error && <div className='progress-error-message'>{error}</div>}
              {session.batch_errors && <BatchError {...session } />}
            </> : <>
              <div className={`progress-panw-logo ${logoFlashing}`} onAuxClick={handleLogoClick}>
                <svg xmlns='http://www.w3.org/2000/svg' fill='#fff' viewBox='0 0 180 180'>
                  <path d='M64.4 25.5L0 90l25.7 25.7L90 51.2 64.4 25.5zM115.8 25.5L90.1 51.2 103 64l-64.4 64.4 25.7 25.7 25.7-25.7L77 115.6l64.4-64.4-25.7-25.7zM154.3 64L90 128.4l25.7 25.7L180 89.7 154.3 64z' />
                </svg>
              </div>
            </>}
            {!session.error && <>
              <div className='progress-card-title'>{inprogress ? intermediate_title : final_title}</div>
              <div className='progress-card-sub-title'>{inprogress ? intermediate_sub_title : finalSubTitle}</div>
            </>}
            {(inprogress || session.error) && <SetupInProgress
              session={session}
              progress={displayProgress}
              progressStep={progressStep}
              throttledRefreshSession={throttledRefreshSession}
            />}
            {session.error && <a
              className='btn-contact-support'
              href={CONTACT_SUPPORT_URL}
              target='_blank'
              rel='noopener noreferrer'
            >Contact Support</a>}
          </div>
          {session.done && <SetupCompleted
            stateRef={stateRef}
            session={session}
            progressStep={progressStep}
            getAnyApp={getAnyApp}
            getAppInstance={stateRef.current.getAppInstance}
            refreshSession={refreshSession}
          />}
        </div>
        {Boolean(remainLicensesBtnProps) && <Link className='activate-more-link' {...remainLicensesBtnProps} />}
        {watermark && <div className='url-watermark'>{watermark}</div>}
      </div>
      <SetupFooter footerLink={footerLabel} />
    </section>
  )
}

SetupProgressPage.propTypes = {
  history: PropTypes.object,
  session: PropTypes.object,
  stateRef: PropTypes.any,
  context: PropTypes.string,
  contextApp: PropTypes.object,
  progress: PropTypes.number,
  selectedAccount: PropTypes.number,
  fetching: PropTypes.bool,
  showEditInstanceModal: PropTypes.func,
  progressStep: PropTypes.object,
  refreshSession: PropTypes.func,
  switchAccount: PropTypes.func,
  refreshEntitlementsAndCredentials: PropTypes.func,
  setFirewallSubscribedVisible: PropTypes.func,
  isFirewallSubscribedVisible: PropTypes.bool,
}

export default React.memo(SetupProgressPage)
