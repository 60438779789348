/**
 * Created by vsuthar on 11/16/20
 * Project: App Portal ©Palo Alto Networks
 */

import React from 'react'
// import PropTypes from 'prop-types'
import SimpleCard from '../../../components/common/SimpleCard'
import ExternalLinkIcon from '../../../images/svg-icons/ExternalLinkIcon'
import './pa_insight_learn_more.scss'

const link = 'https://live.paloaltonetworks.com/t5/prisma-access-insights/ct-p/Prisma_Access_Insights'

const PAInsightsLearnmore = () => {
  const getFooter = () => (
    <div className={'vbox external-link'}>
      <a href={link} rel="noopener noreferrer" target={'_blank'} >Learn more about Insights <ExternalLinkIcon fill={'#006FCC'} style={{ width: 12 }} /> </a>
      <span>* Prisma Access Insights will be available on Hub once the Prisma Access provision is completed.</span>
    </div>
  )

  return (
    <SimpleCard title={'Introducing Prisma Access Insights'} footer={getFooter()}>
      {'Prisma Access Insights is a comprehensive network and end-user monitoring app in Prisma Access that helps administrators pinpoint network and user experience issues that could be caused by an end-user\'s device, network configurations, or applications that users are accessing'}
    </SimpleCard>
  )
}

// PAInsightsLearnmore.propTypes = {}
export default PAInsightsLearnmore

