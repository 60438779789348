import React, { useMemo, useReducer, useState, useEffect, useCallback, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect, batch } from 'react-redux'
import { appframeworkActions as actions } from '@pan/cloud-appframework'
import {
  switchAccountIndex,
  isNonemptyArray,
  useAxiosRequest,
  useMemoizeValueRef,
  getAppInstanceWithIndexing,
  sortSupportAccounts,
  encodeAuthCode,
  indexArray,
  allowAppInstance,
  getContextApp,
  ACTIVATE,
  FT,
} from '../../utils/activateUtils'
import {
  LGS_ID,
  PRISMA_ACCESS,
  PRISMA_ACCESS_PANORAMA,
  PANORAMA,
  RUNNING,
  NOT_ALLOWED_ACTIVATE_ALONE_APPS_SET,
  ACTIVATE_NEW,
  ELA, SETUP_CTX_PRISMA_ACCESS,
} from '../../constants/AppConstants'
import SetupBase from './SetupBase'
import setupReducer from './setupReducer'
import {
  SET_UP_INIT_LICENSE,
  CHOOSE_HOW_TO_MANAGE,
  HELP_DRAWER,
  SETUP_ACCOUNT_SELECT,
  APPS_LIST_UPDATE,
  PRODUCT_SELECT,
  SET_UP_NEXT_STEP,
  SET_UP_BACK_STEP,
  SET_CDL_SELECTION,
  SET_NEW_PANORAMA_SN,
  SET_DEVIECS,
  OVERRIDE_CONTEXT,
  FIREWALL_LIST_VISIBLE,
  SET_SUBSCRIBED_FIREWALL_POLL_TIME,
  SET_SELECTED_FIREWALL_DEVICES,
  SET_ERROR,
  SET_NEW_CLCS_TENANT,
} from '../../config/actions/ActionConstants'
import SetupFooter, { footerLabel } from './SetupFooter'
import SetupProgressPage from './SetupProgressPage'
import SimpleSetupProgress from './progress/SetupProgress'

const EMPTY_LICENSES = Object.freeze({ licenses: [] })

const SetupPage = (props) => {
  const {
    match,
    isGlobalLoading,
    entitledAppsList,
    setupProgress,
    selectedAccount,
    user_email,
    supportAccountIds,
    switchAccount,
    showEditInstanceModal,
    refreshEntitlementsAndCredentials,
    history,
  } = props
  const [states, dispatch] = useReducer(setupReducer, setupReducer.initialState)
  const stateRef = useMemoizeValueRef(states)
  const [isLoading, setLoading] = useState(false)
  const actions = useMemo(() => ({
    onNext: (current, formValue) => dispatch({
      type: SET_UP_NEXT_STEP,
      currentStep: current,
      formValue,
    }),
    onBack: (current) => dispatch({
      type: SET_UP_BACK_STEP,
      currentStep: current
    }),
    onProductChecked: (checkedValues) => dispatch({
      type: PRODUCT_SELECT,
      checkedValues
    }),
    openHelpDrawer: (isOpen) => dispatch({
      type: HELP_DRAWER,
      isOpen,
    }),
    overrideContext: (context) => dispatch({
      type: OVERRIDE_CONTEXT,
      context: context,
    }),
    setDevices: (devices) => dispatch({
      type: SET_DEVIECS,
      devices,
    }),
    setNewPanoramaSn: (serial_number) => dispatch({
      type: SET_NEW_PANORAMA_SN,
      serial_number,
    }),
    setFirewallListVisible: (isFirewallListVisible) => dispatch({
      type: FIREWALL_LIST_VISIBLE,
      isFirewallListVisible,
    }),
    setFirewallSubscribedVisible: (isFirewallSubscribedVisible) => dispatch({
      type: SET_SUBSCRIBED_FIREWALL_POLL_TIME,
      isFirewallSubscribedVisible,
    }),
    setSelectedFirewallDevices: ({ devices, licenses }) => dispatch({
      type: SET_SELECTED_FIREWALL_DEVICES,
      selectedFirewallDevices: devices,
      totalLicenses: licenses,
    }),
    setError: ({ currentStep, errorData = {} }) => dispatch({
      type: SET_ERROR,
      currentStep,
      errorData,
    }),
    setClcsTenant: (clcsTenant) => dispatch({
      type: SET_NEW_CLCS_TENANT,
      clcsTenant,
    }),

  }), [])
  actions.onAccountSelect = useCallback((perAccount, item) => {
    dispatch({
      type: SETUP_ACCOUNT_SELECT,
      setupSelectedAccount: item.key >= 0 ? +item.key : undefined,
    })
    switchAccount(item.key)
  }, [switchAccount])
  actions.onCDLChange = useCallback((cdlInstance) => {
    const cdlList = stateRef.current.getAnyApp(LGS_ID) || {}
    const { regions } = cdlList
    const region_display = regions?.find(r => r.name === cdlInstance.region)?.display
    // const cdlInstance = tenant_id && getAppInstanceWithIndexing(cdlList, tenant_id)
    dispatch({
      type: SET_CDL_SELECTION,
      cdlInstance: { ...cdlInstance, region_display },
    })
  }, [stateRef])
  actions.chooseHowToManage = useCallback((value) => {
    let cdlInstance
    if (value) {
      const { getAnyApp, toBeActivatedApp, context } = stateRef.current
      const [app_id, tenant_id] = value.split(/[:|]/, 2)
      const selectedApp = getAnyApp(app_id)
      const selectedInstance = getAppInstanceWithIndexing(getAnyApp(app_id), tenant_id)
      const cdlAsso = selectedInstance?.associations?.[LGS_ID]
      const cdlTenantId = cdlAsso?.tenant_id
      const cdlTenant = cdlTenantId && getAppInstanceWithIndexing(getAnyApp(LGS_ID), cdlTenantId)
      if (cdlTenantId) {
        const { serial_number, region = selectedInstance.region, is_clcs, is_telemetry } = cdlTenant || cdlAsso
        let { tenant_display_name = serial_number } = cdlTenant || {}
        let value = `${cdlTenantId}|${serial_number || ''}|${region}`
        if (is_clcs || is_telemetry) { // telemetry can be clcs enabled
          const auth_code = toBeActivatedApp.find(lic => lic.checked && lic.app_id === LGS_ID && lic.auth_code)?.auth_code
          if (auth_code) {
            value = `${value}|${auth_code}`
            tenant_display_name = ACTIVATE_NEW // clcs upgrade
          }
          // aiops supports telemetry association so, user coming back with existing aiops tenant with telemetry associations should not overwrite value
          else if (context !== 'aiops') {
            value = ''
            tenant_display_name = ''
          }
        }
        cdlInstance = {
          ...cdlAsso,
          ...cdlTenant,
          tenant_display_name,
          value,
          notAvailable: !cdlTenant || cdlTenant.instance_status !== RUNNING,
          fromInstanceAssociation: true,
          fromAppDisplayName: selectedApp.display_name,
        }
      }
    }
    dispatch({
      type: CHOOSE_HOW_TO_MANAGE,
      value,
      cdlInstance,
    })
  }, [stateRef])
  actions.activateAuthCode = useCallback(({ auth_code, display_name }) => {
    setLoading(true)
    history.push(ACTIVATE, {
      reference: encodeAuthCode(auth_code),
      display: display_name,
      referer: history.location.pathname,
    })
  }, [history])

  useLayoutEffect(() => { // pass entitledAppsList to reducer
    if (entitledAppsList.isLoading) {
      return // skip if not loaded yet
    }
    const getAnyApp = app_id => entitledAppsList.get(app_id)
    const getAppInstances = app_id => getAnyApp(app_id)?.tenants
    const getAppInstance = (appId, tenantId = appId.tenant_id) => {
      if (!appId || !tenantId) {
        return undefined
      }
      const app = getAnyApp(appId.app_id || appId)
      return getAppInstanceWithIndexing(app, tenantId)
    }
    dispatch({
      type: APPS_LIST_UPDATE,
      entitledAppsList,
      getAnyApp,
      getAppInstances,
      getAppInstance,
    })
  }, [entitledAppsList])

  const { hash, session, mode, context = match.path.match(session ? /^\/(\w+)\// : /^\/setup\/(\w+)\//)?.[1] } = match.params
  const { loading: fetching, hasError: hasLicensesError, data: licensesData, load: fetch } = useAxiosRequest()
  useEffect(() => {
    if (hash) {
      fetch({
        method: 'POST',
        url: '/hub/v2/licenses',
        data: {
          hash: hash.includes('%') ? decodeURIComponent(hash) : hash,
          hash_context: context,
          mode
        }
      })
    }
    else if (session) {
      fetch({
        method: 'POST',
        url: '/hub/v2/licenses/sessions',
        data: { session }
      })
    }
  }, [fetch, session, hash, context, mode])

  const { data: msspAccounts, load: getAccounts } = useAxiosRequest()
  useEffect(() => {
    if (!FT('MSP_FORK') && FT('HAS_MSP') && context === SETUP_CTX_PRISMA_ACCESS) {
      getAccounts({
        method: 'GET',
        url: '/hub/v2/accounts',
      })
    }
  }, [getAccounts, context])

  const { data: sgGroups, load: getSGroup } = useAxiosRequest()
  useEffect(() => {
    if (!FT('MSP_FORK') && FT('HAS_MSP') && context === SETUP_CTX_PRISMA_ACCESS) {
      getSGroup({
        method: 'GET',
        url: '/hub/v2/service_groups'
      })
    }
  }, [getSGroup, context])

  useEffect(() => {
    if (licensesData?.redirect) {
      window.open(licensesData.url, '_self')
    }
    if (licensesData?.has_firewall_bundle || (FT('ELA_IOT_SAAS') && mode === ELA)) {
      const accountIndex = supportAccountIds?.find(each => each.accountid === licensesData?.support_account_id)?.support_account_index
      if (accountIndex && accountIndex !== selectedAccount) {
        switchAccount(accountIndex)
      }
    }
  }, [licensesData, supportAccountIds, selectedAccount, switchAccount, mode])

  const { licenses, currentSession } = useMemo(() => {
    if (!hasLicensesError && licensesData && isNonemptyArray(licensesData.licenses)) {
      const {
        licenses,
        current,
        _sid_ = session || current,
        sessions: { [_sid_]: currentSession = { uuid: _sid_ } } = {}
      } = licensesData
      currentSession.licenses = licenses
      currentSession.hash = licensesData.hash
      if (licensesData?.has_firewall_bundle) {
        currentSession.has_firewall_bundle = licensesData.has_firewall_bundle
      }
      return { licenses, currentSession }
    }
    if (hasLicensesError && licensesData) {
      return {
        licenses: [],
        currentSession: { error: _.isString(licensesData) ? { message: licensesData } : licensesData },
      }
    }
    return EMPTY_LICENSES
  }, [hasLicensesError, licensesData, session])

  const { progress, data: progressData, reloadSession } = useMemo(() => {
    const sessionInfo = (session && setupProgress?.[session]) || {}
    const reloadSession = Boolean(sessionInfo?.data && (sessionInfo.data.error || sessionInfo.data.success))
    return { ...sessionInfo, reloadSession }
  }, [session, setupProgress])

  useEffect(() => { // reload session
    if (session && reloadSession) {
      fetch({
        method: 'POST',
        url: '/hub/v2/licenses/sessions',
        skipLoading: true,
        data: { session }
      })
    }
  }, [fetch, reloadSession, session])

  const { contextApp, loading, error, apps } = useMemo(() => {
    if (!entitledAppsList.isAppsLoaded) {
      return { loading: true }
    }
    const contextApp = getContextApp(context, entitledAppsList)
    if (!contextApp || !contextApp.enabled || contextApp.usePubSub) {
      // history.replace(APPS) // ignore for now
      return { error: 'Not available' }
    }
    const hasAuthCodeToken = hash && (contextApp.has_auth_code || entitledAppsList.some(app => app.has_auth_code && app.extension_to === context))
    const apps = licenses.map(({ app_id, auth_code, serial_number, display_name, ...rest }) => {
      // tranform panorama into pa-panorama for pa flow
      const appId = context === PRISMA_ACCESS && app_id === PANORAMA ? PRISMA_ACCESS_PANORAMA : app_id
      const hasApp = entitledAppsList.has(appId)
      const app = {
        ...rest,
        ...(hasApp && entitledAppsList.get(appId)),
        ...(display_name && { display_name }), // display_name override from license
        auth_code,
        serial_number,
        hidden: !hasApp,
        checked: hasApp, // default both are checked
      } // TODO: opimize
      return app
    })
    return { contextApp, hasAuthCodeToken, apps, loading: false }
  }, [entitledAppsList, context, hash, licenses])
  const hasAccounts = selectedAccount >= 0 && isNonemptyArray(supportAccountIds)
  const supportAccounts = useMemo(() => {
    if (hasAccounts) {
      const supportAccounts = sortSupportAccounts(supportAccountIds)
      return indexArray(supportAccounts, 'support_account_index')
    }
    else if (supportAccountIds.init) {
      return Object.assign(indexArray(), { init: true })
    }
    return indexArray()
  }, [hasAccounts, supportAccountIds])

  const onActivateStarted = useCallback((session) => {
    const { context, setupSelectedAccount } = stateRef.current
    history.replace(`/${context}/${session}`, { selectedAccount: setupSelectedAccount })
  }, [history, stateRef])

  const refreshSession = useCallback(() => {
    fetch({
      method: 'POST',
      url: '/hub/v2/licenses/sessions',
      skipLoading: true,
      data: { session, update_status: true, ts: Date.now() }
    })
  }, [session, fetch])
  refreshSession.fetching = fetching // ensure pass fetching status

  useLayoutEffect(() => {
    // directly redirect to progress screen, if all licenses activated, and only one session
    if (hash && licensesData && isNonemptyArray(licensesData.licenses) &&
      licensesData.licenses.every(l => l.activated || NOT_ALLOWED_ACTIVATE_ALONE_APPS_SET.has(l.app_id)) &&
      _.size(licensesData.sessions) === 1) {
      const session_id = Object.keys(licensesData.sessions)[0]
      if (session_id) {
        const { sessionContext = context } = licensesData.sessions[session_id]
        history.replace(`/${sessionContext}/${session_id}`)
      }
    }
  }, [licensesData, context, history, hash])

  useEffect(() => { // once when both supportAccounts and selectedAccount loaded
    if (selectedAccount >= 0 && supportAccounts.length && (stateRef.current.setupSelectedAccount === undefined || stateRef.current.setupSelectedAccount !== selectedAccount ||
      (!supportAccounts.init && stateRef.current.supportAccounts !== supportAccounts))) {
      dispatch({
        type: SETUP_ACCOUNT_SELECT,
        setupSelectedAccount: selectedAccount,
        supportAccounts,
      })
    }
  }, [selectedAccount, supportAccounts, stateRef])
  useEffect(() => {
    if (hash && !loading && apps?.length) {
      dispatch({ // will be ignored if hash not changed
        type: SET_UP_INIT_LICENSE,
        context,
        contextApp,
        hash,
        apps,
        session: currentSession,
        user_email,
        mode
      })
    }
  }, [apps, context, contextApp, currentSession, loading, hash, user_email, mode])

  // const accountIds = useMemo(() => {
  //   return _.keyBy(supportAccountIds, 'accountid')
  // }, [supportAccountIds])

  if (context && session) {
    const progressProps = {
      ...props,
      stateRef,
      fetching,
      context,
      contextApp,
      session: currentSession,
      selectedAccount,
      progress,
      progressData,
      supportAccounts,
      showEditInstanceModal,
      progressStep: states?.setupSteps?.[context]?.progressStep,
      refreshSession,
      switchAccount,
      setFirewallSubscribedVisible: actions.setFirewallSubscribedVisible,
      isFirewallSubscribedVisible: states?.isFirewallSubscribedVisible,
      refreshEntitlementsAndCredentials,
    }
    const SetupProgress = progressProps.progressStep?.useSimpleProgress && (!contextApp || contextApp.setup?.progress_steps) ? SimpleSetupProgress : SetupProgressPage
    return <SetupProgress {...progressProps} />
  }
  const setupProps = {
    context,
    hash,
    mode,
    session: currentSession,
    selectedAccount,
    supportAccounts,
    apps,
    error: error || (hasLicensesError && licensesData?.message),
    ...actions,
    switchAccount,
    history,
    stateRef,
    states: stateRef.current,
    ...FT('HAS_MSP') ? { msspAccounts: [],
      keyByMsspAccounts: _.keyBy(msspAccounts || [], 'supportAccountId'),
      // hasMSP: !FT('MSP_FORK') && FT('HAS_MSP') && Boolean(props?.msp?.has_msp) && (msspAccounts || [])?.some(each => accountIds[each.supportAccountId] && each.mssp),
      hasMsp: false,
      sgGroups: sgGroups || [] } : {},
    isLoading: Boolean(loading || fetching || isLoading || isGlobalLoading),
    setLoading,
    onActivateStarted,
  }
  return <div className={'vbox flex-box-fill'}>
    <SetupBase {...setupProps} />
    <SetupFooter footerLink={footerLabel} />
  </div>
}

SetupPage.propTypes = {
  selectedAccount: PropTypes.number,
  supportAccountIds: PropTypes.array,
  entitledAppsList: PropTypes.array,
  setupProgress: PropTypes.object,
  user_email: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
  isGlobalLoading: PropTypes.bool,
  switchAccount: PropTypes.func,
  showEditInstanceModal: PropTypes.func,
  refreshEntitlements: PropTypes.func,
  refreshEntitlementsAndCredentials: PropTypes.func,
  msp: PropTypes.object, // MSP reducer
}

const mapStateToProps = ({
  isAuthenticated,
  isLoading,
  selectedAccount,
  supportAccountIds,
  entitledAppsList,
  user_email,
  globalLoadingMask,
  setupProgress,
  msp,
}) => ({
  isLoading,
  selectedAccount,
  supportAccountIds,
  entitledAppsList,
  user_email,
  isGlobalLoading: globalLoadingMask || !(isAuthenticated && !supportAccountIds.init),
  setupProgress,
  msp,
})

const mapDispatchToProps = (dispatch) => ({
  switchAccount: (idx) => dispatch(switchAccountIndex(idx)),
  refreshEntitlements: (options) => dispatch(actions.fetchEntitlements(options)),
  refreshEntitlementsAndCredentials: ({ app_id, tenant_id } = {}) => {
    batch(() => {
      if (app_id && tenant_id) {
        dispatch((dispatch, getState) => {
          const { entitledAppsList, roles = {} } = getState()
          const app = entitledAppsList?.get(app_id)
          if (app && !allowAppInstance(app, tenant_id, null, roles)) { // not support role mapping
            dispatch(actions.fetchCredential())
          }
        })
      }
      dispatch(actions.fetchEntitlements())
    })
  },
  showEditInstanceModal: (tenant, application_name = tenant.app_id) =>
    dispatch({ type: 'SHOW_INSTANCE_RECORD_MODAL', instance: { ...tenant, application_name } }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SetupPage)
