/**
 * Created by vsuthar on 4/12/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { useLayoutEffect, useMemo, useRef, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FT } from '@pan/cloud-appframework'
import { Menu } from '@pan/cloud-base'
import ActivateMenuItem from '../../../components/Setup/ActivateMenuItem'
import SetupAdditionalProduct from '../SetupAdditionalProduct'
import CapacityRender from '../CapacityRender'
import {
  returnTrueFn,
  returnFalseFn,
  getAvailableAccountsProps,
  useMemoFieldDecorator,
  hasPrismaSaasRestTenant,
} from '../../../utils/activateUtils'
import './customerAccountSelection.scss'
import ActivateMenuItemText from '../../../components/Setup/ActivateMenuItemText'
import { PRISMA_ACCESS_EDITION, PRISMA_SAAS, PRISMA_SAAS_REST_ACCOUNT_MESSAGE } from '../../../constants/AppConstants'

export const fieldName = 'customer-account-selection'
const { Item } = Menu

const getActivationProduct = (toBeActivatedApp) => {
  return toBeActivatedApp.map(eachApp => {
    const { checked } = eachApp
    return checked ? getActivatedMenuItem(eachApp) : null
  })
}

const getActivatedMenuItem = (app) => {
  const { app_id,
    display_name,
    logo,
    purchased_size_render,
    license_expiration,
  } = app
  return <ActivateMenuItem
    key={app_id}
    icon={logo}>
    <ActivateMenuItemText title={display_name}
      subTitle={<CapacityRender purchased_size_render={purchased_size_render}
        license_expiration={license_expiration} app_id={app_id} />} />
    <SetupAdditionalProduct app={app}
    />
  </ActivateMenuItem>
}

getActivatedMenuItem.propTypes = {
  app_id: PropTypes.string,
  display_name: PropTypes.string,
  logo: PropTypes.string,
  purchased_size_render: PropTypes.node,
  license_expiration: PropTypes.string,
  additional_base_product: PropTypes.array,
  addons: PropTypes.array,
  sku: PropTypes.string,
  extra: PropTypes.object,
}

const individualProduct = (toBeActivatedApp) => {
  return <Menu>
    {
      toBeActivatedApp.map(eachApp => {
        const { app_id, checked } = eachApp
        return checked ? <Item key={app_id}>
          {getActivatedMenuItem(eachApp)}
        </Item> : null
      })
    }
  </Menu>

}

const MenuSingleSelect = React.forwardRef(({ value, onChange, onSelect, ...props }, ref) => {
  const selectHandler = useCallback((args) => {
    onSelect(args)
    onChange(args.key >= 0 ? +args.key : undefined)
  }, [onChange, onSelect])
  return <Menu ref={ref} {...props} onSelect={selectHandler} selectedKeys={[String(value)]} />
})
MenuSingleSelect.displayName = 'MenuSingleSelect'
MenuSingleSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
}

const CustomerAccountSelection = ({ toBeActivatedApp,
  supportAccounts,
  setupSelectedAccount,
  onAccountSelect,
  form: { validateFields, getFieldDecorator },
  getAppInstances,
  isLoading,
  stateRef,
  keyByMsspAccounts,
}) => {
  const { lockedInfo } = toBeActivatedApp.find(each => each.app_id === PRISMA_ACCESS_EDITION && each.checked && each.lockedInfo) || {}
  const wrapperRef = useRef()
  const support_account = supportAccounts.find(each => each.index = setupSelectedAccount)

  if (lockedInfo && support_account?.support_account_id !== lockedInfo.support_account_id) {
    const index = supportAccounts.find(each => each.support_account_id === lockedInfo.support_account_id)?.index
    if (index && index > 0) {
      onAccountSelect(false, { key: index })
      setupSelectedAccount = index
    }
  }

  useLayoutEffect(() => {
    const li = wrapperRef.current?.querySelector?.('li.ant-menu-item.pan-menu-item.ant-menu-item-selected')
    if (li?.scrollIntoViewIfNeeded) {
      li?.scrollIntoViewIfNeeded(true)
    }
    else if (li) {
      li.scrollIntoView({ block: 'center' })
    }
  }, [])

  const validateErrorRef = useRef()
  useEffect(() => {
    const singleTenantApp = toBeActivatedApp?.some(app => app.app_id === PRISMA_SAAS && app.checked)
    if (!singleTenantApp) {
      validateErrorRef.current = undefined
    }
    else if (isLoading) {
      validateErrorRef.current = 'Loading...'
    }
    else {
      const prismaSaaSTenants = getAppInstances(PRISMA_SAAS)
      if (hasPrismaSaasRestTenant(prismaSaaSTenants)) {
        validateErrorRef.current = PRISMA_SAAS_REST_ACCOUNT_MESSAGE
      }
      else {
        validateErrorRef.current = undefined
      }
    }
    validateFields([fieldName], { force: true })
  }, [getAppInstances, toBeActivatedApp, isLoading, validateFields, stateRef])

  // APPORTAL-4849 check if magiclink is for fedramp
  const isFederalLicense = useMemo(() => {
    return toBeActivatedApp.some(app => app.is_federal_license)
  }, [toBeActivatedApp])

  const isPerProductCheck = false
  const allowAppFn = returnTrueFn // undefined by default is allowAppAdmin
  const availableAccountsProps = useMemo(() => {
    const notAvailFn = lockedInfo?.support_account_id ? (csp_id) => {
      return !(lockedInfo?.support_account_id && csp_id === lockedInfo?.support_account_id)
    } : returnFalseFn
    const propsArray = getAvailableAccountsProps({
      apps: toBeActivatedApp,
      supportAccounts,
      notAvailFn,
      allowAppFn,
      isFederalLicense,
    })
    return propsArray // easier debugging
  }, [supportAccounts, toBeActivatedApp, allowAppFn, lockedInfo, isFederalLicense])



  const selectedAccountName = useMemo(() => {
    const account = supportAccounts.get(setupSelectedAccount)
    return account?.support_account_name || null
  }, [setupSelectedAccount, supportAccounts])

  const fieldDecorator = useMemoFieldDecorator(fieldName, {
    initialValue: setupSelectedAccount,
    valuePropName: 'value',
    rules: useMemo(() => [{
      validator(rule, value, callback) {
        const idx = +value
        const option = availableAccountsProps.find(acc => acc.index === idx)
        if (!option) {
          callback('The selected account is not available')
        }
        else if (option.disabled) {
          callback('The selected account has limited access')
        }
        else if (validateErrorRef.current) {
          callback(validateErrorRef.current)
        }
        else {
          callback()
        }
      }
    }], [availableAccountsProps])
  }, getFieldDecorator)

  return (
    <div className={'hbox customer-account-selection'}>
      <div className={'activation-left'}>
        {isPerProductCheck ? individualProduct(toBeActivatedApp) : <div className={'activation-menu-item-wrapper'}>
          {getActivationProduct(toBeActivatedApp)}
        </div>
        }
        <div className={'selected-account'}><span>Selected:</span>{selectedAccountName}</div>
      </div>
      <div className={'support-account-wrapper'} ref={wrapperRef}>
        {
          fieldDecorator(
            <MenuSingleSelect onSelect={onAccountSelect.bind(null, isPerProductCheck)}>
              {availableAccountsProps.map(({ index, children, disabled, is_federal, default_account, value, accountid }) => {
                const key = String(index)
                return <Item key={String(key)} disabled={disabled} className={is_federal ? 'federal-account' : ''}>
                  <span>{`${children} - ${value}`} </span>
                  {default_account && <span className={'default-account'}>(DEFAULT)</span>}
                  {FT('HAS_MSP') && keyByMsspAccounts?.[value]?.mssp ? <p className={'msp-name'}><b>(MSSP)</b></p> : null }
                </Item>
              })
              }
            </MenuSingleSelect>
          )
        }

      </div>
    </div>
  )
}

CustomerAccountSelection.propTypes = {
  toBeActivatedApp: PropTypes.array,
  supportAccounts: PropTypes.array,
  setupSelectedAccount: PropTypes.number,
  onAccountSelect: PropTypes.func,
  form: PropTypes.object,
  getAppInstances: PropTypes.func,
  isLoading: PropTypes.bool,
  stateRef: PropTypes.object,
  keyByMsspAccounts: PropTypes.object,
}

export default CustomerAccountSelection
