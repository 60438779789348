import React, { useImperativeHandle } from 'react'
import PropTypes from 'prop-types'

const FakeField = React.forwardRef(({ children }, ref) => {
  useImperativeHandle(ref, () => ({}), [])
  return children || null
})
FakeField.displayName = 'FakeField'
FakeField.propTypes = {
  children: PropTypes.node
}

export default FakeField
