const ERROR_CODES = {
  HUB_2021: '2021',
  HUB_2025: '2025',
  HUB_2026: 'ONBOARD_ERROR_HUB_2026',
  HUB_2027: 'ONBOARD_ERROR_HUB_2027',
  HUB_2028: 'ONBOARD_ERROR_HUB_2028',
  HUB_2029: 'ONBOARD_ERROR_HUB_2029',
  HUB_2030: 'ONBOARD_ERROR_HUB_2030',
  RBAC_5002: 'RBAC_5002',
  RBAC_5003: 'RBAC_5003',
  RBAC_5004: 'RBAC_5004',
  RBAC_5005: 'RBAC_5005',
  RBAC_5006: 'RBAC_5006',
  RBAC_5007: 'RBAC_5007',
  RBAC_5008: 'RBAC_5008',
  RBAC_5009: 'RBAC_5009',
  RBAC_5010: 'RBAC_5010',
  RBAC_5011: 'RBAC_5011',
  RBAC_5012: 'RBAC_5012',
  RBAC_5013: 'RBAC_5013',
  RBAC_5014: 'RBAC_5014',
  RBAC_5015: 'RBAC_5015',
  RBAC_5016: 'RBAC_5016',
  RBAC_5017: 'RBAC_5017',
  RBAC_5018: 'RBAC_5018',
  RBAC_5019: 'RBAC_5019',
  RBAC_5020: 'RBAC_5020',
  RBAC_5021: 'RBAC_5021',
  RBAC_5022: 'RBAC_5022',
  RBAC_5023: 'RBAC_5023',
  RBAC_5024: 'RBAC_5024',
}

const ERROR_TYPES = {
  FIREWALL_LICENSE_INCOMPATIBLE: 'firewall_license_incompatible',
  FIREWALL_NOT_FOUND: 'firewall_not_found',
  LICENSE_NOT_FOUND: 'license_not_found',
  NO_INSTANCES_FOUND: 'no_instances_found',
  NO_IOT_ADDON_AUTH_CODE_FOUND: 'no_iot_auth_code_found',
  NO_SAAS_INLINE_ADDON_AUTH_CODE_FOUND: 'no_saas_inline_auth_code_found',
  NO_DLP_ADDON_AUTH_CODE_FOUND: 'no_dlp_auth_code_found',
  RBAC_USER_NOT_ALLOWED: 'RBAC_USER_NOT_ALLOWED'
}

const ERROR_MESSAGES = {
  [ERROR_TYPES.FIREWALL_LICENSE_INCOMPATIBLE]: 'License cannot be applied to the selected firewall model.',
  [ERROR_TYPES.FIREWALL_NOT_FOUND]: 'The firewall(s) are not registered with your customer support account or was not found.',
  [ERROR_TYPES.LICENSE_NOT_FOUND]: 'The firewall(s) auth code are not found or not eligible for activation',
  [ERROR_TYPES.NO_INSTANCES_FOUND]: ERROR_CODES.HUB_2026,
  [ERROR_TYPES.NO_IOT_ADDON_AUTH_CODE_FOUND]: ERROR_CODES.HUB_2027,
  [ERROR_TYPES.RBAC_5002]: 'has been successfully created as a new role',
  [ERROR_TYPES.RBAC_5003]: 'has been deleted successfully',
  [ERROR_TYPES.RBAC_5004]: 'Invalid Scope',
  [ERROR_TYPES.RBAC_5005]: 'Invalid Schema',
  [ERROR_TYPES.RBAC_5006]: 'Required Field not supply',
  [ERROR_TYPES.RBAC_5007]: 'Invalid App Version or Schema not present for app',
  [ERROR_TYPES.RBAC_5008]: 'CSP Account Id not supplied',
  [ERROR_TYPES.RBAC_5009]: 'Invalid special_predefined Role',
  [ERROR_TYPES.RBAC_5010]: 'Could Not Remove Role',
  [ERROR_TYPES.RBAC_5011]: 'Invalid App Type',
  [ERROR_TYPES.RBAC_5012]: 'Invalid Type',
  [ERROR_TYPES.RBAC_5013]: 'Invalid Email Address',
  [ERROR_TYPES.RBAC_5014]: 'Invalid App Predefined Scopes',
  [ERROR_TYPES.RBAC_5015]: 'Scopes not supplied',
  [ERROR_TYPES.RBAC_5016]: 'App Predefined Scopes not supplied',
  [ERROR_TYPES.RBAC_5017]: 'Can not create duplicate role',
  [ERROR_TYPES.RBAC_5018]: 'Can not write role',
  [ERROR_TYPES.RBAC_5019]: 'Not have enough Privilege',
  [ERROR_TYPES.RBAC_5020]: 'This role_label is a app reserved word',
  [ERROR_TYPES.RBAC_5021]: 'Support Account not have any role',
  [ERROR_TYPES.RBAC_5022]: 'Can\'t clean up entitlement and rbac cache',
  [ERROR_TYPES.RBAC_5023]: 'Cant get custom roles',
  [ERROR_TYPES.RBAC_5024]: 'User Not Allowed',
}

module.exports = {
  ERROR_TYPES,
  ERROR_CODES,
  ERROR_MESSAGES,
}
