import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import FirewallTable from './FirewallTable'
import InputSearchBar from '../../../../components/common/InputSearchBar'
import { SEARCH_PLACE_HOLDER } from '../../../../constants/AppConstants'
import { Icon } from '@pan/cloud-base'
import { firewallDeviceSearch } from '../../../../utils/activateUtils'
import { indexArray } from '../../../../utils/common'
import './firewallSelection.scss'

const searchIconStyle = {
  color: '#A3A3A3',
  fontWeight: 900,
  fontSize: '16px',
  padding: '4px'
}

const FirewallSelection = React.forwardRef(({
  licenseData,
  deviceData,
  onChange,
  selectedFirewallDevices,
  setSelectedFirewallDevices,
  entitlementTenants,
  context
}, ref) => {
  const elaLicense = useMemo(() => licenseData?.licenses.find(license => Boolean(license?.auth_code)), [licenseData])
  const devices = useMemo(() => {
    return deviceData.map(each => {
      //check for already activated devices to set checked and disabled
      const tenant = entitlementTenants.get(each.serial_number)
      if (tenant && tenant?.associations?.[context]) {
        each.checked = true
        each.disabled = true
      }
      return each
    })
  }, [deviceData, entitlementTenants, context])

  const indexedDevices = useMemo(() => indexArray(devices, 'serial_number'), [devices])

  const handleInputSearch = useCallback((searchString) => {
    const filteredDevices = searchString ? firewallDeviceSearch(searchString, devices) : devices
    setDeviceList(filteredDevices)
  }, [devices])

  const handleDevicesSelected = (serialNumbers) => {
    const selectedList = serialNumbers.reduce((result, sn) => {
      const deviceDetails = indexedDevices.get(sn)
      if (deviceDetails && !deviceDetails.disabled) { // ignore disabled devices
        result.push({ 'serial_number': sn, auth_code: elaLicense?.auth_code })
      }
      return result
    }, [])

    setSelectedFirewallDevices({ devices: selectedList })
    onChange(selectedList)
  }
  const totalNonDisableDevices = devices.filter(each => !each?.disabled).length
  const selected = selectedFirewallDevices.length

  const [deviceList, setDeviceList] = useState(devices)

  return (
    <>
      <label className='fw-check-details-header'>{`${selected} selected of ${totalNonDisableDevices} available firewalls`}</label>
      <label className='fw-check-details-header fw-total'>{`Total firewalls: ${devices?.length}`}</label>
      <div className='fw-search-wrapper'>
        <InputSearchBar
          className={'fw-devices-search'}
          handleInputSearch={handleInputSearch}
          placeholder={SEARCH_PLACE_HOLDER}
          handleSuffixIcon={() => <Icon type='search' style={searchIconStyle} />}
        />
      </div>
      <div className='hbox fw-check-wrapper' ref={ref}>
        <div className='fw-check-devices'>
          <FirewallTable
            data={deviceList}
            selectedFirewallDevices={selectedFirewallDevices}
            handleDevicesSelected={handleDevicesSelected}
          />
        </div>
      </div>
    </>
  )
})


FirewallSelection.displayName = 'FirewallSelection'

FirewallSelection.defaultProps = {
  deviceData: [],
  selectedFirewallDevices: []
}

FirewallSelection.propTypes = {
  licenseData: PropTypes.object.isRequired,
  deviceData: PropTypes.array.isRequired,
  selectedFirewallDevices: PropTypes.array,
  setSelectedFirewallDevices: PropTypes.func,
  entitlementTenants: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  context: PropTypes.string,
}

export default FirewallSelection
