'use strict'

const APP_PORTAL = 'App Portal'
const HUB_ID = 'hub'
const DSS = 'Directory Sync'
const DSS_ID = 'directory_sync'
const LGS = 'Logging Service'
const LGS_ID = 'logging_service'
const LGS_DISPLAY = 'Cortex Data Lake'
const TRAPS = 'Traps'
const MGFR = 'Magnifier'
const LOG_FWD = 'Log Forwarding'
const EXPLORE_APP = 'explore'
const PRISMA_ACCESS = 'prisma_access'
const PRISMA_CLOUD = 'prisma_cloud'
const CORTEX_XDR_NOVA = 'cortex_xdr'
const XDR = 'XDR'
const DLP = 'dlp'
const FLS = 'fls'
const CDL = 'cdl'
const CGX = 'cgx'
const PRISMA_SAAS = 'aperture'
const PRISMA_SAAS_NGFW = 'aperture_ngfw'
const ZINGBOX = 'zingbox'
const ZINGBOX_NGFW = 'zingbox_ngfw'
const ZINGBOX_3P_ADDON = 'zingbox_3p_addon'
const IOT = 'iot'
const SAAS = 'saas'
const AIOPS = 'aiops'
const IOT_PACKAGE_2 = '2.0'
const DEFAULT_NGFW_VERSION = '1'
const ZINGBOX_URL_NAME = IOT
const PRISMA_ACCESS_PANORAMA = 'prisma_access_panorama'
const PRISMA_ACCESS_EDITION = 'prisma_access_edition'
const PRISMA_ACCESS_MOBILE_USERS = 'prisma_access_for_mobile_users'
const PRISMA_ACCESS_REMOTE_NETWORKS = 'prisma_access_for_remote_networks'
const PRISMA_ACCESS_FOR_CLEAN_PIPE = 'prisma_access_for_clean_pipe'
const NGFW = 'ngfw'
const PANORAMA = 'panorama'
const STRATA_INSIGHTS = 'strata_insights'
const STRATA_INSIGHTS_NGFW = 'strata_insights_ngfw'
const STRATA_INSIGHTS_FREE = 'strata_insights_free' // temporary app_id
const ELA = 'ela'
const SUPPORT = 'support'
const PA_EXT_APPS = [PRISMA_ACCESS_EDITION, PRISMA_ACCESS_MOBILE_USERS, PRISMA_ACCESS_REMOTE_NETWORKS, PRISMA_ACCESS_FOR_CLEAN_PIPE]
const ONPREM = 'onprem'
const _ONPREM = `_${ONPREM}`
const PA_ONPREM_EXT_APPS = PA_EXT_APPS.map(n => `${n}${_ONPREM}`)
const PAE_ONPREM = `${PRISMA_ACCESS_EDITION}${_ONPREM}`
const PA_ALL_EXT_APPS = PA_EXT_APPS.concat(PA_ONPREM_EXT_APPS)
const NOT_ALLOWED_ACTIVATE_ALONE_APPS = [PRISMA_ACCESS_PANORAMA, PANORAMA, NGFW] // need to be synced in webapp
const SETUP_CTX_MAPPING = PA_ALL_EXT_APPS.reduce((o, app_id) => {
  o[app_id] = PRISMA_ACCESS
  return o
}, { // TODO: move to manifest
  [PRISMA_ACCESS_PANORAMA]: PRISMA_ACCESS,
  [PRISMA_ACCESS]: PRISMA_ACCESS,
  [ZINGBOX]: IOT,
  [ZINGBOX_NGFW]: IOT,
  [ZINGBOX_3P_ADDON]: IOT,
  [CGX]: CGX,
  _substitute_: { // only when no match above
    [LGS_ID]: CDL,
  }
})

const TENANT_ID_NEW = 'new'
const TELEMETRY = 'telemetry'

const RBAC_ACCOUNT_SUPERUSER = 'account_superuser'
const RBAC_APP_SUPERUSER = 'app_superuser'

const STORAGE_INCLUDED = 'Storage Included'
const BUNDLED_TENANT = 'bundled_tenant'

const SETUP_PATH = '/setup/'

const EMPTY_SVG = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>' // TODO: should use default app icon

const REGION_NAME_MAPPING = {
  us: 'US',
  americas: 'US',
  eu: 'EU',
  europe: 'EU',
  uk: 'UK',
}

const ERRORS = require('./Errors')

const EASY_ON_ADDON = 'addon'
const FEDRAMP_ADDON = 'fedramp'
const LICENSE_TYPE_FED_HIGH = 'FEDRAMP-HIGH'
const LICENSE_TYPE_FED_MOD = 'FEDRAMP-MOD'

const TRIAL = 'trial'
const LAB = 'lab'
const PROD = 'prod'
const EVAL = 'eval'
const NFR = 'nfr'

const NONE = 'none'

const ATTACH_TSG_DEVICE = 'attach-tsg-device'
const SEND_NOTIFICATION = 'send-notification'

// SKUS
const CLCS_SKU = 'PAN-CLCS-0TB-1YR'
const CDL_TELEMETRY_SKU = 'PAN-LGS-METRICS'

const SAAS_INLINE_TYPE = 'SAASINLINE'
const SAAS_INL_TYPE = 'SAASINL'
const SAAS_INLINE_CASB = 'SAASINLINECASB'
const SAAS_API_CASB = 'SAASAPICASB'
const SAAS_API_TYPE = 'SAASAPI'

//custom license-types
const PRISMA_SAAS_API = 'saas_api'
const PRISMA_SAAS_INLINE = 'saas_inline'
const PRISMA_SAAS_API_INLINE = 'saas_api_inline'

// task types
const CREATE_INSTANCE = 'create-instance'
const CREATE_INSTANCES = 'create-instances'
const UPDATE_INSTANCE = 'modify-instance'

//instance status
const PROVISIONING = 'provisioning'
const PENDING = 'pending'
const RUNNING = 'running'

const ACTIVE = 'ACTIVE'
const EXPIRING = 'EXPIRING'
const EXPIRED = 'EXPIRED'
const AIOPS_PREMIUM_INSTANCE_TYPE = 'paid'
const AIOPS_FREE_INSTANCE_TYPE = 'free'

const NGFW_APPS = [ZINGBOX_NGFW, PRISMA_SAAS_NGFW, STRATA_INSIGHTS_NGFW]

const SPAKRY_FRAMEWORK = 'sparky-framework'

const APP_ID_TO_CONTEXT = {
  'mssp_backbone': 'backbone'
}

module.exports = {
  APP_PORTAL,
  HUB_ID,
  DSS,
  LGS,
  LGS_ID,
  LGS_DISPLAY,
  DSS_ID,
  LGS_V2: LGS_ID,
  DSS_V2: DSS_ID,
  TRAPS,
  MGFR,
  XDR,
  DLP,
  LOG_FWD,
  EXPLORE_APP,
  FLS,
  CDL,
  CGX,
  ZINGBOX,
  ZINGBOX_NGFW,
  ZINGBOX_3P_ADDON,
  IOT_PACKAGE_2,
  DEFAULT_NGFW_VERSION,
  IOT,
  ZINGBOX_URL_NAME,
  PRISMA_ACCESS,
  PRISMA_CLOUD,
  SAAS,
  AIOPS,
  PRISMA_SAAS,
  PRISMA_SAAS_NGFW,
  CORTEX_XDR_NOVA,
  PRISMA_ACCESS_EDITION,
  PAE: PRISMA_ACCESS_EDITION,
  PAE_ONPREM,
  ONPREM,
  _ONPREM,
  PRISMA_ACCESS_MOBILE_USERS,
  PRISMA_ACCESS_REMOTE_NETWORKS,
  PRISMA_ACCESS_FOR_CLEAN_PIPE,
  PA_EXT_APPS,
  PA_ONPREM_EXT_APPS,
  PA_ALL_EXT_APPS,
  NOT_ALLOWED_ACTIVATE_ALONE_APPS,
  PRISMA_ACCESS_PANORAMA,
  PANORAMA,
  STRATA_INSIGHTS,
  STRATA_INSIGHTS_NGFW,
  STRATA_INSIGHTS_FREE,
  ELA,
  SUPPORT,
  NGFW,
  STORAGE_INCLUDED,
  BUNDLED_TENANT,
  RBAC_APP_SUPERUSER,
  RBAC_ACCOUNT_SUPERUSER,
  SETUP_PATH,
  SETUP_CTX_MAPPING,
  REGION_NAME_MAPPING,
  EMPTY_SVG,
  EASY_ON_ADDON,
  FEDRAMP_ADDON,
  LICENSE_TYPE_FED_HIGH,
  LICENSE_TYPE_FED_MOD,
  TRIAL,
  LAB,
  PROD,
  EVAL,
  NFR,
  CLCS_SKU,
  CDL_TELEMETRY_SKU,
  SAAS_INLINE_TYPE,
  SAAS_INL_TYPE,
  SAAS_API_TYPE,
  SAAS_INLINE_CASB,
  SAAS_API_CASB,
  PRISMA_SAAS_INLINE,
  PRISMA_SAAS_API,
  PRISMA_SAAS_API_INLINE,
  NONE,
  TENANT_ID_NEW,
  CREATE_INSTANCE,
  CREATE_INSTANCES,
  UPDATE_INSTANCE,
  PROVISIONING,
  PENDING,
  RUNNING,
  ACTIVE,
  EXPIRING,
  EXPIRED,
  ATTACH_TSG_DEVICE,
  SEND_NOTIFICATION,
  NGFW_APPS,
  AIOPS_PREMIUM_INSTANCE_TYPE,
  AIOPS_FREE_INSTANCE_TYPE,
  TELEMETRY,
  SPAKRY_FRAMEWORK,
  APP_ID_TO_CONTEXT,
  ...ERRORS,
}
