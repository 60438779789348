import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Progress, Row } from '@pan/cloud-base'
import { CONTACT_SUPPORT_URL } from '../../constants/AppConstants'

const SetupProgressBar = ({ session, progress, launchInstanceBtnProps, isInitFetching }) => {

  const displayProgress = session.error || session.done ? 100 : progress || 0
  const progressCls = useMemo(() => {
    if (session.done) {
      return 'progress-done'
    }
    else if (session.error) {
      return 'progress-error'
    }
    else if (progress > 0 && progress < 100) {
      return 'progress-continue'
    }
    else {
      return 'progress-normal'
    }
  }, [session, progress])


  const getInfo = useCallback((session, progress, isInitFetching, launchInstanceBtnProps) => {
    const text = isInitFetching ? 'Loading ...' : session.error ? `${session.error.message}` : session.done ? 'Successfully Completed Setup.' : `${progress}% Complete`
    let color = {}
    if (progress > 5 && progress < 100 && !session.error) {
      color = { color: 'white' }
    }
    else if (session.done || progress < 5) {
      color = { color: 'black' }
    }
    else {
      color = { color: 'black' }
    }
    return (
      <Row type={'flex'} className={'progress-info'} aling={'center'} justify={'space-between'} >
        <Col className={'progress-complete-text'} style={color}>{text}</Col>
        {/* <Col className={'progress-remain-text'}>12 Minute Remaining</Col> */}
        {launchInstanceBtnProps && <Col>
          <Button v2={false} type={'primary'} {...launchInstanceBtnProps} />
        </Col>}
        {session.error && /contact support|\(\w+\)$/i.test(text) && <Col>
          <Button
            v2={false}
            className='btn-contact-support'
            type={'error'}
            href={CONTACT_SUPPORT_URL}
            target='_blank'
            rel='noopener noreferrer'
          >Contact Support</Button>
        </Col>}
      </Row>
    )
  }, [])

  return (
    <Progress className={progressCls}
      info={getInfo(session, progress, isInitFetching, launchInstanceBtnProps)}
      percent={displayProgress} size='large' />
  )
}

SetupProgressBar.propTypes = {
  session: PropTypes.object,
  progress: PropTypes.number,
  isInitFetching: PropTypes.bool,
  launchInstanceBtnProps: PropTypes.object,
}
export default SetupProgressBar
