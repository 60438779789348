import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import { Button } from '@pan/cloud-base'
import {
  LGS_ID,
  RUNNING,
} from '../../../constants/AppConstants'
import PanoramaOTP from './PanoramaOTP'
import FirewallPSK from './FirewallPSK'

const isRunningInstance = (inst) => Boolean(inst?.tenant_id) &&
  (inst.instance_status === RUNNING || inst.status === RUNNING)
const findInstanceFromSession = (session, app_id) => {
  if (!session?.instances || !app_id) {
    return undefined
  }
  const allInstances = session.instances.concat(session.request_instances || [])
  return allInstances.find(inst => inst.app_id === app_id)
}

const EMPTY_OBJ = {}
const SetupCompleted = ({
  session,
  progressStep,
  getAnyApp,
  getAppInstance,
  refreshSession,
}) => {
  const {
    launchButton: {
      text: launchBtnText,
      disabledByDefault,
    } = EMPTY_OBJ,
    recommendedSteps = EMPTY_OBJ,
    showOTP = recommendedSteps.showOTP,
    showPSK = recommendedSteps.showPSK,
  } = progressStep

  const [
    primaryAppId,
    primaryInstance,
    otp,
    psk,
  ] = useMemo(() => {
    const getRunningInstance = (inst) => {
      if (!inst) {
        return undefined
      }
      const entInst = getAppInstance(inst)
      if (entInst?.url && isRunningInstance(entInst)) {
        return { ...inst, ...entInst }
      }
      return isRunningInstance(inst) ? inst : undefined
    }
    const primaryInstance = getRunningInstance(session.primary_instance?.tenant_id ? session.primary_instance :
      findInstanceFromSession(session, session.primary_instance?.app_id))
    if (primaryInstance && !primaryInstance.platform_id) {
      primaryInstance.platform_id = session.platform_id
    }
    const cdlInstance = getRunningInstance(session.platform_id ? { app_id: LGS_ID, tenant_id: session.platform_id } :
      findInstanceFromSession(session, LGS_ID))
    const isCdlRunning = Boolean(cdlInstance?.url || session.clcs || session.otp || session.psk)
    const otp = showOTP && session.otp && isCdlRunning && isRunningInstance(primaryInstance) ? {
      otp: session.otp,
      expiration: session.otp_expiration,
    } : null
    const psk = showPSK && session.psk && isCdlRunning && isRunningInstance(primaryInstance) ? session.psk : null
    return [
      session.primary_instance?.app_id,
      primaryInstance,
      otp,
      psk,
    ]
  }, [session, showOTP, showPSK, getAppInstance])

  const launchInstanceBtnProps = useMemo(() => {
    let disabled = false
    if (!session.done) {
      return null
    }
    const primaryInstanceUrl = primaryInstance?.url
    if (!primaryInstanceUrl) {
      disabled = true
    }
    if (disabled && !disabledByDefault) { // hidden by default
      return null
    }
    if (primaryInstanceUrl && (!primaryInstanceUrl.startsWith('https:') ||
      primaryInstanceUrl.endsWith(`/${session.uuid}`))) { // do not show if it is current session url, ignore context for now
      return null // currently use for ignore panorama
    }
    let children = launchBtnText
    if (!children && primaryAppId) {
      const primaryAppDisplayName = getAnyApp(primaryAppId)?.display_name || ''
      children = primaryAppDisplayName ? `Launch ${primaryAppDisplayName}` : 'Launch'
    }
    return disabled ? { disabled, children } : { href: primaryInstanceUrl, children }
  }, [session.done, session.uuid, primaryInstance?.url, primaryAppId, disabledByDefault, launchBtnText, getAnyApp])

  return <div className='setup-completed progress-card-content'>
    {Boolean(launchInstanceBtnProps) &&
      <Button v2={false} type={'primary'} size='large' className='setup-launch-btn' {...launchInstanceBtnProps} />
    }
    {otp && <PanoramaOTP
      otp={otp.otp}
      otp_expiration={otp.expiration}
      has_psk={Boolean(psk)}
      refreshSession={refreshSession}
    />}
    {psk && <FirewallPSK
      psk={psk}
      has_otp={Boolean(otp)}
      session={session}
    />}
  </div>
}

SetupCompleted.propTypes = {
  session: PropTypes.object,
  progressStep: PropTypes.object,
  getAnyApp: PropTypes.func,
  getAppInstance: PropTypes.func,
  refreshSession: PropTypes.func,
  // stateRef: PropTypes.any,
}

export default SetupCompleted
