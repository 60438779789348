import React, { useMemo } from 'react'
import _ from 'lodash'
import { Icon, Popover } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import { IOT, SAAS, ZINGBOX_NGFW, PRISMA_SAAS_NGFW, CONTACT_SUPPORT_URL } from '../../../constants/AppConstants'
import './batchError.scss'

const appNgfwContext = {
  [IOT]: ZINGBOX_NGFW,
  [SAAS]: PRISMA_SAAS_NGFW
}

const BatchError = ({
  instances = [],
  request_instances = [],
  hash_context,
}) => {

  const totalActivatingInstances = request_instances?.find(each => each.app_id === appNgfwContext[hash_context])?.batch

  const errorDevices = useMemo(() => {
    const totalDevices = totalActivatingInstances?.map(each => each.serial_number)
    const activatedDevices = instances.map(each => each.serial_number)
    return _.differenceWith(totalDevices, activatedDevices, _.isEqual)
  }, [totalActivatingInstances, instances])

  const popoverTitle = useMemo(() => {
    const serialNumbers = errorDevices?.join(', ')
    return <>
      <div style={{ paddingBottom: '5px', textDecoration: 'underline' }}>Failed Firewall Serial Numbers</div>
      <span>{serialNumbers}</span>
    </>
  }, [errorDevices])

  const textColor = hash_context !== IOT ? '#fff' : ''
  return <div>
    <span style={{ color: textColor }}>{`${errorDevices?.length} out of ${totalActivatingInstances?.length} firewalls failed to activate`}</span>
    <Popover content={popoverTitle} placement='top' trigger='hover' overlayClassName='batch-error-popover'>
      <Icon type='info-circle-o' style={{ color: textColor || '#1f75b2', padding: '3px' }} />
    </Popover>
    <br />
    { hash_context !== IOT && <a
      href={CONTACT_SUPPORT_URL}
      target='_blank'
      rel='noopener noreferrer'
      className='ant-btn pan-button batch-support-btn'>
      Get Support Now
    </a>}
  </div>
}

BatchError.propTypes = {
  instances: PropTypes.array,
  request_instances: PropTypes.array,
  hash_context: PropTypes.string.isRequired,
}

export default BatchError

