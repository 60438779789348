import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from '@pan/cloud-base'

const getParent = e => e.parentNode
const TT = ({ children, ...props }) => (
  <Tooltip
    placement='right'
    getPopupContainer={getParent}
    {...props}
  >{children}</Tooltip>
)
TT.propTypes = {
  children: PropTypes.node,
}

export default TT
