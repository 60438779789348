/**
 * Created by vsuthar on 12/15/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { APP_REGION_FIELD, STRATA_INSIGHTS, NEW, CHOOSE_HOW_TO_MANAGE_FIELD, AIOPS_APP_REGION } from '../../../constants/AppConstants'
import { useMemoFieldDecorator, FT, isNonemptyArray } from '../../../utils/activateUtils'

const AiopsAppRegion = ({
  cdlInstance,
  getAnyApp,
  form: {
    getFieldValue,
    getFieldDecorator,
    setFieldsValue,
  },

}) => {
  const cdlRegion = cdlInstance?.region
  const [, aiopsPremiumTenantId] = getFieldValue(CHOOSE_HOW_TO_MANAGE_FIELD)?.split(/[:|]/, 2) || []
  const appRegion = useMemo(() => {
    if (aiopsPremiumTenantId && aiopsPremiumTenantId !== NEW) { // APPORTAL-4917 if existing aiops premium tenant selected, pick region from it
      const selectedAiops = getAnyApp(STRATA_INSIGHTS).tenants?.find(x => x.tenant_id === aiopsPremiumTenantId)
      if (selectedAiops) {
        const appRegions = getAnyApp(STRATA_INSIGHTS)?.regions
        return appRegions?.find(each => each.name === selectedAiops.region)
      }
    }
    else if (cdlRegion && FT('AIOPS_MULTI_REGION')) {
      const appRegions = getAnyApp(STRATA_INSIGHTS)?.regions
      return appRegions?.find(each => (isNonemptyArray(each.platform_regions) && each.platform_regions.includes(cdlRegion)))
    }
    else {
      return getAnyApp(STRATA_INSIGHTS)?.regions?.find(each => each.name === 'americas')
    }
  }, [cdlRegion, getAnyApp, aiopsPremiumTenantId])

  useEffect(() => {
    if (appRegion) {
      setFieldsValue({
        [APP_REGION_FIELD]: appRegion?.name
      })
    }
  }, [appRegion, setFieldsValue])

  const appRegionDecorator = useMemoFieldDecorator(APP_REGION_FIELD, {
    initialValue: appRegion?.name,
    validateFirst: true,
    rules: useMemo(() => ([{
      validator(rule, value, callback) {
        if (!value) {
          callback(`${AIOPS_APP_REGION} is required`)
        }
        else {
          callback()
        }
      }
    }]), []),
  }, getFieldDecorator)

  return <div className={'hbox middle space-between setup-item'}>
    <label>{AIOPS_APP_REGION}</label>
    {
      appRegionDecorator(
        <span>{appRegion?.display}</span>
      )
    }
  </div>
}

AiopsAppRegion.propTypes = {
  cdlInstance: PropTypes.object,
  getAnyApp: PropTypes.func,
  form: PropTypes.object,
}
export default AiopsAppRegion

