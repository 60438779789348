/**
 * Created by vsuthar on 4/14/20
 * Project: App Portal ©Palo Alto Networks
 */
import React, { useMemo, useCallback, useState } from 'react'
import _ from 'lodash'
import { List } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import './confirmActivation.scss'
import DataLake from '../DataLake'
import SetupBorder from '../../../components/Setup/SetupBorder'
import {
  PA_PANORAMA,
  SETUP_CREATE_NEW,
  LGS_ID,
  ZINGBOX_NGFW,
  NONE,
  PRISMA_ACCESS_EDITION,
  CDL_INSTANCE_FIELD,
  PANORAMA_INSTANCE_FIELD,
  CHOOSE_HOW_TO_MANAGE_FIELD,
  SUBDOMAIN_FIELD,
  FLS_DEVICES_FIELD,
  APP_REGION_FIELD,
  PANORAMA,
  ZINGBOX,
  SETUP_ZINGBOX_NEW,
  PAE_ADDON_ZINGBOX_TNT,
  PAE_ADDON_SAAS_TNT,
  PAE_ADDON_DLP_TNT,
  SETUP_ADDON_APP_IOT,
  SETUP_ADDON_APP_SAAS_INLINE,
  SETUP_ADDON_APP_DLP,
  SETUP_CTX_IOT,
  SETUP_CTX_PRISMA_ACCESS,
  SETUP_CTX_AIOPS,
  SETUP_CTX_PRISMA_SAAS,
  REGION_FIELD,
  RUNNING,
  PRISMA_SAAS,
  PRISMA_SAAS_NGFW,
  ELA,
  SETUP_CTX_PRIMARY_APP_REGION_LABELS,
  CIE,
  DIRECTORY_SYNC_FIELD,
  DIRECTORY_SYNC_APP_REGION_FIELD,
  DIRECTORY_SYNC_REGION,
  DSS_ID,
} from '../../../constants/AppConstants'
import ComponentFactory from '../../../components/Setup/ComponentFactory'
import SetupMiscItem from '../../../components/Setup/SetupMiscItem'
import SetupDataLakeView from '../../../components/Setup/SetupDataLakeView'
import Subscription from '../common/firewall_subscription/Subscription'
import SetupAdditionalProduct from '../SetupAdditionalProduct'
import CapacityRender from '../CapacityRender'
import { convertObjToString, FT, hasAddon, hasOnlyIoTPackageTwoActivation, hasOnlyIoTThirdPartyActivation, appDomainPostfix } from '../../../utils/activateUtils'
import IoTSecurityTenant from '../iot/IoTSecurityTenant'
import SetupSubdomainField from '../../../components/Activate/fields/SetupSubdomainField'
import IoTAppRegion from '../iot/IoTAppRegion'
import CGXFields from '../cgx/CGXFields'
import PrismaSaasSecurityFields from '../prisma_saas/PrismaSaasSecurityFields'
import DLPFields from '../dlp/DLPFields'
import ServiceGroupSelection from '../prisma_access/ServiceGroupSelection'
import { STRATA_INSIGHTS_NGFW } from '../../../../../server/src/constants'

const { Item } = List
const ConfirmActivation = (props) => {
  const {
    steps,
    productToBeActivated,
    supportAccounts,
    setupSelectedAccount,
    toBeActivatedApp,
    selectedFirewallDevices,
    session,
    form,
    hasMSP,
    sgGroups,
    ...rest
  } = props
  const { show_data_lake = true, show_cmp = [] } = rest.currentStepObj
  rest.toBeActivatedApp = toBeActivatedApp

  const [zingboxInstance, setZingBoxTenant] = useState(SETUP_ZINGBOX_NEW)

  const { cdlInstance, getAnyApp, formValues, context, stateRef, chooseHowToManage, getAppInstances } = rest
  const { getFieldValue, setFieldsValue } = form

  const showCmp = useMemo(() => {
    const obj = show_cmp.reduce((o, key) => {
      o[key] = true
      return o
    }, {})
    return key => obj[key] || false
  }, [show_cmp])
  const confirmSubdomain = form.getFieldValue('subdomain')
  const [subdomain, usePanorama, useNewPanorama, primaryAppId, appRegionDisplay, appRegionLabel] = useMemo(() => {
    const subdomain = confirmSubdomain || formValues[SUBDOMAIN_FIELD]
    const chooseHowToManage = formValues[CHOOSE_HOW_TO_MANAGE_FIELD]
    const [mgmtAppId, mgmtTenantId] = chooseHowToManage?.split(/[:|]/, 2) || []
    const usePanorama = mgmtAppId?.endsWith(PANORAMA)
    const appRegions = mgmtAppId && getAnyApp(mgmtAppId)?.regions
    const {
      region = formValues[REGION_FIELD],
      region_display = region && appRegions?.find(r => r.name === region)?.display
    } = cdlInstance || {}
    const appRegion = formValues[APP_REGION_FIELD] ?
      appRegions?.find(r => r.name === formValues[APP_REGION_FIELD])?.display :
      appRegions?.find(r => (r.logging_service_region || r.name) === region)?.display || region_display
    const appRegionLabel = SETUP_CTX_PRIMARY_APP_REGION_LABELS[context] || 'App Region'
    return [subdomain, usePanorama, usePanorama && mgmtTenantId === SETUP_CREATE_NEW, mgmtAppId, appRegion, appRegionLabel]
  }, [cdlInstance, getAnyApp, formValues, confirmSubdomain, context])

  const zingboxApp = useMemo(() => getAnyApp(ZINGBOX), [getAnyApp])
  const prismaSaasApp = useMemo(() => {
    return getAnyApp(PRISMA_SAAS)
  }, [getAnyApp])

  /**
   * WARNING: This is done due to requirement for PAE and IoT. They want to support following usecase
   * 1. CDL selection match IoT tenant
   * 2. Once CDL to Many IoT tenant. hence IoT dropdown menu will enabled/disabled menu item if they have more then one iot tenant mapped to selected CDL
   * 3. Select IoT tenant find CDL automatically based on region
   * 4. If User selected Panorama then dont allow to change CDL dropdown menu
   * 5. User can still select IoT tenant if Panorama mapped to CDL and that CDL have IoT tenant. So IoT dropdown should have
   * Active New and IoT tenant that is mapped to CDL and CDL mapped to Panroama
   * 6. If existing PAE want to add IoT then locked Panorama, CDL and Only Active New should be avaialble
   * 7. Changing CDL should mapped IoT, Also, IoT subdomain should filled automatically
   * 8. Select new IoT tenant should clear subdomain
   * 9. If PAE dont have iot then IoT option should not avaialble
   */

  /*------------------------------- PAE + IOT -------------------------------*/
  const { isIoTAddon, isIoTDisabled } = useMemo(() => {
    if (context !== SETUP_CTX_PRISMA_ACCESS) {
      return {}
    }
    /**
     * LST-4058
     * @isIoTDisabled: Keep IoT disabled when there is PAE customer wants to upgrade PAE tenant with new IoT onboarding
     * @type {*|boolean}
     */
    const isIoTPAEUpgrade = toBeActivatedApp.find(each => each.app_id === PRISMA_ACCESS_EDITION)?.lockedInfo || false
    const isIoTAddon = hasAddon(toBeActivatedApp, PRISMA_ACCESS_EDITION, SETUP_ADDON_APP_IOT)
    let isIoTDisabled = false
    let paeIoTInstance = zingboxInstance
    const findZingboxTenantBasedOnCDL = (cdlTenant_id) => {
      if (cdlTenant_id) {
        return zingboxApp?.tenants?.find(each => {
          return each.instance_status === RUNNING && each.associations?.logging_service?.tenant_id === cdlTenant_id
        })
      }
      return SETUP_ZINGBOX_NEW
    }

    if (zingboxInstance === SETUP_ZINGBOX_NEW && cdlInstance && cdlInstance?.tenant_id !== SETUP_CREATE_NEW && formValues[CHOOSE_HOW_TO_MANAGE_FIELD] === 'prisma_access_panorama:new') {
      const zin = zingboxApp?.tenants?.filter(each => each?.associations?.logging_service?.tenant_id === cdlInstance?.tenant_id)
      if (zin?.length) {
        const ztid = zin.find(each => each?.tenant_id === getFieldValue(PAE_ADDON_ZINGBOX_TNT)?.split(/[:|]/, 2)[1])
        paeIoTInstance = `${ZINGBOX}:${convertObjToString(ztid || zin[0])}`
        isIoTDisabled = !(zin.length > 1) && (!(cdlInstance.fromInstanceAssociation) || Boolean(isIoTPAEUpgrade))
      }
      else {
        // if zingbox tenant as new and we dont find any attached zingbox tenant to cdl and have IoT upgrade is true then make disabled
        if (Boolean(isIoTPAEUpgrade)) {
          isIoTDisabled = true
        }
        // If we dont find associated IoT tenant then mark it as Create New
        paeIoTInstance = SETUP_ZINGBOX_NEW
      }
    }
    else if (zingboxInstance !== SETUP_ZINGBOX_NEW && cdlInstance && cdlInstance?.tenant_id !== SETUP_CREATE_NEW) {
      const zin = findZingboxTenantBasedOnCDL(cdlInstance?.tenant_id)
      if (zin) {
        paeIoTInstance = `${ZINGBOX}:${convertObjToString(zin)}`
      }
      else {
        // If we dont find associated IoT tenant then mark it as Create New
        paeIoTInstance = SETUP_ZINGBOX_NEW
      }
    }
    setZingBoxTenant(paeIoTInstance)
    return { isIoTAddon, isIoTDisabled }
  }, [
    context,
    toBeActivatedApp,
    cdlInstance,
    zingboxApp,
    getFieldValue,
    zingboxInstance,
    setZingBoxTenant,
    formValues
  ])


  /**
   * This only needed for PAT_IoT
   * @type {function(*): void}
   */
  const handleIoTSelect = useCallback((value) => {
    setFieldsValue({ [SETUP_ADDON_APP_IOT]: value })
    const panorama = formValues['choose-how-to-manage']
    if (value !== SETUP_ZINGBOX_NEW || panorama === 'prisma_access_panorama:new') {
      chooseHowToManage(value)
    }
    else {
      chooseHowToManage(panorama)
    }
    setZingBoxTenant(value)
  }, [setFieldsValue, chooseHowToManage, formValues, setZingBoxTenant])
  /*------------------------------- PAE + IOT -------------------------------*/

  /*------------------------------- PAE + SaaS -------------------------------*/
  const { isSaaSAddon } = useMemo(() => {
    if (context !== SETUP_CTX_PRISMA_ACCESS) {
      return {}
    }
    const isSaaSAddon = hasAddon(toBeActivatedApp, PRISMA_ACCESS_EDITION, [SETUP_ADDON_APP_SAAS_INLINE])
    return { isSaaSAddon }
  }, [
    context,
    toBeActivatedApp,
  ])
  /*------------------------------- PAE + SaaS -------------------------------*/

  /*------------------------------- PAE + DLP -------------------------------*/
  const isDLPAddon = useMemo(() => {
    if (context !== SETUP_CTX_PRISMA_ACCESS) {
      return null
    }
    return hasAddon(toBeActivatedApp, PRISMA_ACCESS_EDITION, SETUP_ADDON_APP_DLP)
  }, [context, toBeActivatedApp])
  /*------------------------------- PAE + DLP -------------------------------*/

  const fwCountValue = useMemo(() => {
    if (!showCmp('FLS_DEVICE_COUNT')) {
      return null
    }
    const devices = _.get(formValues, FLS_DEVICES_FIELD, [])
    if (devices[0] === '*') {
      return 'All Firewalls'
    }
    const count = devices.length
    return `${count} ${count > 1 ? 'Firewalls' : 'Firewall'}`
  }, [formValues, showCmp])

  const getDescription = (app) => {
    const { purchased_size_render, app_id } = app
    return <>
      <CapacityRender purchased_size_render={purchased_size_render} app_id={app_id} />
      <SetupAdditionalProduct app={app}
      />
    </>
  }

  const cdlValue = getFieldValue(CDL_INSTANCE_FIELD)
  const panoramaInstance = formValues?.[PANORAMA_INSTANCE_FIELD]
  const directorySync = useMemo(() => {
    const dssTenantId = formValues?.[DIRECTORY_SYNC_FIELD]?.split(/[:|]/, 2)?.[1]
    return getAppInstances(DSS_ID)?.find(each => each?.tenant_id === dssTenantId)?.tenant_instance_name || dssTenantId
  }, [formValues, getAppInstances])


  const useCdlLic = useMemo(() => {
    if (!cdlValue) {
      return false
    }
    const cdl_auth_code = toBeActivatedApp.find(l => l.app_id === LGS_ID)?.auth_code
    if (!cdl_auth_code) {
      return false
    }
    return cdlValue.endsWith(`|${cdl_auth_code}`) || false
  }, [cdlValue, toBeActivatedApp])
  const list = useMemo(() => {
    const removeApps = [!useNewPanorama && PA_PANORAMA, !useCdlLic && LGS_ID].filter(Boolean)
    const list = toBeActivatedApp.filter(app => !removeApps.includes(app.app_id))
    return list.sort((a) => {
      if (a.app_id === LGS_ID) {
        return 1
      }
      return -1
    })
  }, [toBeActivatedApp, useNewPanorama, useCdlLic])

  const licExp = useMemo(() => {
    return list.find(each => {
      return each.license_expiration
    })?.license_expiration
  }, [list])

  const getSubscriptionData = useMemo(() => {
    let appLicenses = []
    switch (context) {
      case SETUP_CTX_IOT:
        appLicenses = session?.licenses?.filter(inst => inst.app_id === ZINGBOX_NGFW)
        break
      case SETUP_CTX_PRISMA_SAAS:
        appLicenses = session?.licenses?.filter(inst => [PRISMA_SAAS_NGFW, PRISMA_SAAS].includes(inst.app_id))
        break
      case SETUP_CTX_AIOPS:
        appLicenses = session?.licenses?.filter(inst => inst.app_id === STRATA_INSIGHTS_NGFW)
        break
      default:
        appLicenses
    }
    const subscriptions = appLicenses.map(obj => obj?.licenses || [])?.reduce(
      (res, lic) => [...res, ...lic], []
    )
    return {
      totalPurchased: subscriptions?.length,
      currentActivation: selectedFirewallDevices?.length,
      previouslyActivated: subscriptions?.filter(obj => obj.activated === true)?.length
    }
  }, [session, selectedFirewallDevices, context])

  const showCompStyle = {
    labelStyle: { style: { minWidth: 125, textAlign: 'end', flex: 1 } }
  }
  return <List className={'vbox confirm-activation setup-card-body'}>
    {
      list.map(eachProduct => {
        const { display_name,
          app_id,
          logo,
          checked,
        } = eachProduct
        const title = <div className={'hbox confirm-title'}>
          <img width={38} src={logo} alt={'logo'} style={{ paddingRight: '2px' }}/>
          <span style={{ margin: '5px 2px' }}>{display_name}</span>
        </div>
        return checked ?
          <Item key={app_id}>
            <Item.Meta
              title={title} description={<div className={'confirmation-prod-description'}>
                {getDescription(eachProduct)}
              </div>} />
          </Item> :
          null
      })
    }
    {/*-----------------SHOW/HIDE DATA LAKE------------------- */}
    {show_data_lake ? <>
      <DataLake
        dropDownProps={{ placeholder: 'Select DataLake', style: { width: 200 } }}
        primaryAppId={primaryAppId}
        useDataLakeRegion={showCmp('CMP_DATALAKE_READ')}
        {...props}
      />
    </> : null}

    {/*------------------------------- CGX -------------------------------*/}
    {showCmp('CGX_CFG_FIELDS') && <CGXFields {...props} />}

    {/*------------------------------- PAE + IOT -------------------------------*/}
    {
      FT('PAE_IOT') && isIoTAddon ? <>
        <IoTSecurityTenant {...props}
          region={cdlInstance?.region}
          initialValue={zingboxInstance}
          fieldName={PAE_ADDON_ZINGBOX_TNT}
          formValues={stateRef?.current?.formValues}
          isDisabledFn={(tenant) => {
            if (!isIoTDisabled && cdlInstance && cdlInstance?.tenant_id !== SETUP_CREATE_NEW) {
              return !(tenant?.associations?.logging_service?.tenant_id === cdlInstance?.tenant_id)
            }
            return false
          }}
          selectProps={{ style: { width: 200 }, disabled: isIoTDisabled, onSelect: handleIoTSelect }}
        />
        <IoTAppRegion {...props} />
        <SetupSubdomainField {...props}
          app={zingboxApp}
          tenant_selected_value={zingboxInstance}
          subdomainFieldName='extra.zingbox.subdomain'
          subdomainProps={{
            required: false,
            className: 'setup-item sub-domain',
            labelCol: { xs: { span: 3 }, sm: { span: 5 }, md: { span: 5 } },
            wrapperCol: { xs: { span: 19, offset: 0 }, sm: { span: 24, offset: 0 }, md: { span: 18, offset: 1 } },
            key: 'subdomain',
            selectedAccount: setupSelectedAccount,
            label: 'IoT Subdomain'
          }} />
      </> : null
    }
    {
      isSaaSAddon &&
      <PrismaSaasSecurityFields
        {...props}
        cdlInstance={cdlInstance}
        contextApp={prismaSaasApp}
        fieldName={PAE_ADDON_SAAS_TNT}
        subdomainFieldName='extra.aperture.subdomain'
        selectProps={{ style: { width: 200 } }}
        subdomainProps={{
          className: 'setup-item sub-domain',
          labelCol: { xs: { span: 3 }, sm: { span: 5 }, md: { span: 5 } },
          wrapperCol: { xs: { span: 19, offset: 0 }, sm: { span: 24, offset: 0 }, md: { span: 18, offset: 1 } },
        }}
      />
    }
    {
      FT('MULTI_DLP') && isDLPAddon &&
      <DLPFields
        {...props}
        fieldName={PAE_ADDON_DLP_TNT}
      />
    }
    { FT('MSP_FORK') && <ServiceGroupSelection form={form} sgGroups={sgGroups} /> }
    <div className={'vbox confirmation-misc'}>

      {/*----------------CSP_ACCOUNT_ID----------------*/}
      {showCmp('CMP_SUPPORT_ACCOUNT') ?
        ComponentFactory('CMP_SUPPORT_ACCOUNT', {
          supportAccounts,
          setupSelectedAccount,
          wrapperClass: 'hbox middle center',
          labelProps: showCompStyle.labelStyle,
          valueProps: { style: { flex: 1, overflow: 'hidden' } }
        }) : null}

      {/*-----------DOMAIN FOR THE PORTAL*--------------*/}
      {showCmp('IOT_PORTAL') ?
        ComponentFactory('IOT_PORTAL', {
          labelProps: showCompStyle.labelStyle,
          valueProps: { style: { flex: 1, overflow: 'hidden' } },
          value: `${subdomain}${appDomainPostfix(zingboxApp, formValues[APP_REGION_FIELD])}`,
          wrapperClass: 'hbox middle center',
        }) : null
      }
      {/*-----------DOMAIN FOR THE PRISMA SAAS *--------------*/}
      {showCmp('PRISMA_SAAS_URL') ?
        ComponentFactory('PRISMA_SAAS_URL', {
          labelProps: showCompStyle.labelStyle,
          valueProps: { style: { flex: 1, overflow: 'hidden' } },
          value: `${subdomain}${appDomainPostfix(prismaSaasApp, formValues[APP_REGION_FIELD])}`,
          wrapperClass: 'hbox middle center',
        }) : null
      }
      {/*---------- DATALAKE INSTANCE AND DOMAIN ------------*/}
      {showCmp('CMP_DATALAKE_READ') ?
        <>
          <SetupMiscItem
            label={appRegionLabel}
            value={appRegionDisplay}
            labelProps={showCompStyle.labelStyle}
            valueProps={{ style: { flex: 1 } }}
            wrapperClass={'hbox middle center'}
          />
          <SetupDataLakeView
            labelProps={showCompStyle.labelStyle}
            {...rest}
            wrapperClass={'hbox middle center'}
            valueProps={{ style: { flex: 1 } }}
            regionLabel={(hasOnlyIoTPackageTwoActivation(toBeActivatedApp) || hasOnlyIoTThirdPartyActivation(toBeActivatedApp)) ? 'Ingestion Region' : null}
          />
        </> : showCmp('CMP_REGION') ? <SetupMiscItem
          label={appRegionLabel}
          value={appRegionDisplay}
          labelProps={showCompStyle.labelStyle}
          valueProps={{ style: { flex: 1 } }}
          wrapperClass={'hbox middle center'}
        /> : null
      }


      {/*------------ IoT Panorama-----------------*/}
      {showCmp('IOT_PANORAMA') ?
        <>
          <SetupMiscItem label={'Panorama'}
            value={panoramaInstance !== NONE ? panoramaInstance : null}
            labelProps={showCompStyle.labelStyle}
            valueProps={{ style: { flex: 1 } }}
            wrapperClass={'hbox middle center'} />
        </> : null
      }

      {/*------------ Directory Sync-----------------*/}
      {FT('AIOPS_PREMIUM') && showCmp('CMP_DIRECTORY_SYNC') && directorySync ?
        <>
          {directorySync !== SETUP_CREATE_NEW && <SetupMiscItem label={`${CIE}`}
            value={directorySync}
            labelProps={showCompStyle.labelStyle}
            valueProps={{ style: { flex: 1 } }}
            wrapperClass={'hbox middle center'} />
          }
          <SetupMiscItem
            label={DIRECTORY_SYNC_REGION}
            value={getAnyApp(DSS_ID)?.regions?.find(r => r.name === formValues?.[DIRECTORY_SYNC_APP_REGION_FIELD])?.display}
            labelProps={showCompStyle.labelStyle}
            valueProps={{ style: { flex: 1 } }}
            wrapperClass={'hbox middle center'}
          />
        </> : null
      }

      {/*------------ ACTIVE SUBSCRIPTION -----------------*/}
      {showCmp('FIREWALL_SUBSCRIPTION') && stateRef.current?.mode !== ELA ? <Subscription
        labelProps={showCompStyle.labelStyle}
        subscriptionData={getSubscriptionData}
        valueProps={{ style: { flex: 1 } }}
        wrapperClass={'hbox middle center'}
      /> : null}

      {/*-------------PRISMA_ACCESS_MANAGEMENT-----------*/}
      {showCmp('CMP_PRISMA_MANAGEMENT') ? <SetupMiscItem
        labelProps={showCompStyle.labelStyle}
        wrapperClass={'hbox middle center'}
        valueProps={{ style: { flex: 1 } }}
        label={'Management'}
        value={usePanorama ? 'Panorama' : 'Cloud Based Prisma Access'}
      /> : null}

      {/*-------------EXPIRATION-CO-TERM------------------*/}
      {showCmp('CMP_EXPIRATION') && licExp ? ComponentFactory('CMP_EXPIRATION',
        {
          label: 'Co-Term Date / Expiration',
          date: licExp,
          wrapperClass: 'hbox middle center',
          labelProps: showCompStyle.labelStyle,
          valueProps: { style: { color: '#54b79f', flex: 1 } }
        }) : null}

      {showCmp('FLS_DEVICE_COUNT') ? <SetupMiscItem
        labelProps={showCompStyle.labelStyle}
        wrapperClass={'hbox middle center'}
        valueProps={{ style: { flex: 1 } }}
        label={'Connect to'}
        value={fwCountValue}
      /> : null}
    </div>

    {/*--------------- TERM AND CONDITION---------------*/}
    {
      showCmp('CMP_TERM_CONDITION') ? <>
        <SetupBorder />
        {ComponentFactory('CMP_TERM_CONDITION', { form: form || {} })}
      </> : null
    }

  </List>
}

ConfirmActivation.propTypes = {
  productToBeActivated: PropTypes.array,
  supportAccounts: PropTypes.array,
  setupSelectedAccount: PropTypes.number,
  toBeActivatedApp: PropTypes.array,
  form: PropTypes.object,
  steps: PropTypes.array,
  selectedFirewallDevices: PropTypes.array.isRequired,
  cdlInstance: PropTypes.object,
  session: PropTypes.object,
  hasMSP: PropTypes.bool,
  sgGroups: PropTypes.array,
}

ConfirmActivation.defaultProps = {
  productToBeActivated: [],
  supportAccounts: [],
  toBeActivatedApp: [],
  steps: [],
  selectedFirewallDevices: [],
  sgGroups: [],
  hasMSP: false,
}

export default ConfirmActivation
