/**
 * Created by vsuthar on 5/5/20
 * Project: App Portal ©Palo Alto Networks
 */
import React, { useRef, useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Form, Tooltip, Icon } from '@pan/cloud-base'
import { useAxiosRequest } from '../../utils/activateUtils'
import { RefreshIcon } from '../../images/svg-icons'
import './otp.scss'
import TokenTooltip from '../common/TokenTooltip'
import { PSK_DESCRIPTION, PSK_TECH_DOC_LABEL, PSK_TECH_DOC_LINK, KEY_EXPIRED, KEY_GENERATING, FAILED_GENERATING } from '../../constants/AppConstants'

const preventDefault = (e) => {
  e.preventDefault()
}

const PSK = (props) => {
  const { loading, load } = useAxiosRequest()
  const { inputProps, btnProps, pskData = {}, hasFormError, stateRef, session } = props
  let result = {}
  if (typeof pskData === 'string') { // backward compatibility for old sessions with only psk value
    result = { psk: pskData, expiry: null }
  }
  else {
    result = pskData
  }

  const [tokenData, setTokenData] = useState(result)

  const [isCopied, setCopied] = useState(false)
  const textAreaRef = useRef(null)
  const handleClick = useCallback(() => {
    textAreaRef.current.select()
    document.execCommand('copy')
    setCopied(true)
    setTimeout(() => setCopied(false), 1500)
  }, [])

  const handleRefresh = useCallback(() => {
    const { setupSelectedAccount } = stateRef.current
    load({
      method: 'post',
      url: '/hub/v2/licenses/psk',
      data: {
        session_id: session?.uuid,
        selectedAccount: +setupSelectedAccount,
      }
    }).then((response) => {
      if (response.data) {
        setTokenData(response.data)
      }
    }).catch((error) => {
      if (!error.data || error.data?.code !== 404) {
        const message = error.data?.message || error.message || error
        setTokenData({
          ...tokenData,
          error: FAILED_GENERATING
        })
        // eslint-disable-next-line no-console
        console.error(`psk:${message}`)
      }
    })

  }, [load, session, stateRef, tokenData])

  const selectText = useCallback((e) => {
    e.preventDefault()
    e.target.select()
  }, [])

  const formItemStyle = hasFormError ? 'style-2 gray-color' : 'style-1 white'
  const textStyle = { color: hasFormError ? '#808080' : '#fff' }

  const description = <>
    <span>{PSK_DESCRIPTION}</span>
    <a rel='external noopener noreferrer' target='_blank' style={{ padding: '2px' }} href={`${PSK_TECH_DOC_LINK}`}>{PSK_TECH_DOC_LABEL}</a>
  </>

  const help = useMemo(() => {
    return tokenData?.expiry ? `Valid until ${new Date(tokenData.expiry)?.toLocaleString()}` : null
  }, [tokenData])

  const infoTooltip = <TokenTooltip label='Copy Pre-Shared Key' description={description} />

  // backward compatibility for old sessions if no expiry found treat is as expired
  const isTokenExpired = !tokenData.expiry || moment().isAfter(tokenData?.expiry)
  const token = loading ? KEY_GENERATING : (isTokenExpired ? KEY_EXPIRED : tokenData?.psk)

  return (
    <div style={{ padding: '0px 5px 0 18px' }}>

      <Form.Item className={`otp-item ${formItemStyle}`} label={infoTooltip} help={help}>
        <div className={'hbox middle otp'}>
          <input className='otp-text-input' readOnly ref={textAreaRef} onChange={preventDefault} onFocus={selectText} {...inputProps} value={tokenData.error || token} />
          <Tooltip visible={isCopied} title={'Copied!'} placement={'right'}>
            <button style={textStyle} {...btnProps} className={`otp-btn center ${btnProps?.className || ''}`} onClick={handleClick}>
              <Icon type={'copy'} theme={'filled'} style={textStyle} />
            </button>
          </Tooltip>
          {(isTokenExpired || tokenData.error) &&
            <Tooltip title={'Refresh'}>
              <button style={textStyle} {...btnProps} className={`otp-btn center ${btnProps?.className || ''}`} onClick={handleRefresh} disabled={loading}>
                <RefreshIcon width='14px' height='14px' />
              </button>
            </Tooltip>
          }

        </div>
      </Form.Item>
    </div>
  )
}

PSK.propTypes = {
  inputProps: PropTypes.object,
  btnProps: PropTypes.object,
  pskData: PropTypes.object,
  hasFormError: PropTypes.bool,
  stateRef: PropTypes.any,
  session: PropTypes.object,
}
export default PSK

