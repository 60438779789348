/**
 * Created by vsuthar on 4/7/20
 * Project: App Portal ©Palo Alto Networks
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Row } from '@pan/cloud-base'
import './PrismaHelp.scss'
import ComponentFactory from '../../../components/Setup/ComponentFactory'

class PrismaHelp extends React.PureComponent {


  render() {
    return (
      <Row className={'prisma-help'} type={'flex'} align={'middle'} justify={'space-between'}>
        {
          this.props.usefulLinks.map(({ href, label, component_type, text, style = {} }, key) => (
            ComponentFactory(component_type, { key, href, label, text, style })
          ))
        }
      </Row>
    )
  }
}

PrismaHelp.propTypes = {
  usefulLinks: PropTypes.array,
}

export default PrismaHelp
