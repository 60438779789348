/**
 * Created by vsuthar on 11/16/20
 * Project: App Portal ©Palo Alto Networks
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Col, Icon, Button } from '@pan/cloud-base'
import classNames from 'classnames'
import './setup_simple_text.scss'


/**
 * Simple Setup Link that render under useful links
 * @param href
 * @param label
 * @returns {JSX.Element}
 * @constructor
 */

const SetupSimpleLink = ({ href, label }) => (
  <Col lg={24} className={'hbox space-between middle'}>
    <a href={href} rel="noopener noreferrer" target={'_blank'}>{label}</a>
    <Icon type="right" />
  </Col>
)

SetupSimpleLink.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
}


/**
 * Simple Text that can use for rendering. Please look for example under PrismaAccessSetup.js and search for
 * SET_UP_TYPE_SIMPLE_TEXT, You can also pass style from the setup itself
 * @param text
 * @param className
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const SetupSimpleText = ({ text, className, ...rest }) => (
  <p {...rest} className={classNames('setup-simple-text', className)}>{text}</p>
)

SetupSimpleText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
}


/**
 * Simple Button that render under Recommended Next Step
 */

const RecommendedBtn = ({ rightIconProps, btnProps, textColor, leftSideIcon, text, rightSideIcon }) => {

  return (
    <Button {...{ v2: false, type: 'primary', ...btnProps }}>
      <div className={`hbox middle space-between ${textColor}`}>
        <span className={'middle'}>
          {leftSideIcon}
          {text}
        </span>
        { rightSideIcon ? rightSideIcon : <Icon {...rightIconProps}/> }
      </div>
    </Button>
  )
}

RecommendedBtn.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  leftSideIcon: PropTypes.node,
  rightIconProps: PropTypes.object,
  leftSideIconProps: PropTypes.object,
  btnProps: PropTypes.object,
  textColor: PropTypes.string,
  rightSideIcon: PropTypes.node,
}

RecommendedBtn.defaultProps = {
  btnProps: {
    v2: false,
    type: 'primary',
  }
}


export { SetupSimpleLink, SetupSimpleText, RecommendedBtn }
