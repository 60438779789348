import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Modal, Button } from '@pan/cloud-base'
import { useMemoizeValue } from '../../../../utils/hooks'

const dismissBtnStyle = {
  color: '#1F75B2',
  border: '1px solid #D4D4D4',
  borderRadius: '4',
}

const headerTitleStyle = {
  fontWeight: '300',
  fontSize: '24px',
  lineHeight: '32px',
  color: '#333333',
  margin: '2px'
}

const headerSubTitleStyle = {
  fontWeight: '300',
  fontSize: '12px',
  lineHeight: '20px',
  color: '#808080',
  margin: '2px'
}

const FirewallModal = ({ headers, body, visible, setFirewallModalVisible }) => {
  const [modalVisible, setModalVisible] = useState(visible)
  const dismissBtnProps = useMemoizeValue({
    v2: false,
    style: dismissBtnStyle
  })

  useEffect(() => setModalVisible(visible), [visible])

  const renderHeaders = () => {
    return (
      <>
        <p style={headerTitleStyle}>{headers.title}</p>
        <p style={headerSubTitleStyle}>
          {headers.subTitle}
        </p>
      </>
    )
  }

  return (
    <Modal
      width={750}
      visible={modalVisible}
      className={'fw-modal'}
      title={renderHeaders()}
      closable={false}
      bodyStyle={{ height: '400px', overflow: 'auto' }}
      footer={<Button {...dismissBtnProps} onClick={() => setFirewallModalVisible(false)}>Dismiss</Button>}
    >
      {body}
    </Modal>
  )
}

FirewallModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  headers: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.object,
  }).isRequired,
  body: PropTypes.object.isRequired,
  setFirewallModalVisible: PropTypes.func.isRequired
}


export default FirewallModal
