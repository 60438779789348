/**
 * Created by vsuthar on 6/9/20
 * Project: App Portal ©Palo Alto Networks
 */

import React from 'react'
import PropTypes from 'prop-types'
import './iotngf.scss'

const StatusCard = ({ title, description, actionPanel, tenantName, style }) => {
  return (
    <div className={'vbox setup-data-lake-view-only'} style={style}>
      <label>{title}</label>
      <span style={{ margin: '5px 0' }}>{tenantName}</span>
      <p>{description}</p>
      <div className={'hbox middle space-between'}>
        {actionPanel}
      </div>
    </div>
  )
}

StatusCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  actionPanel: PropTypes.node,
  tenantName: PropTypes.string,
  style: PropTypes.object,
}
export default StatusCard

