import React, { useMemo, useCallback, useEffect } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Select } from '@pan/cloud-base'
import ListItem from '../../../components/ListItem'
import {
  renderInstances,
  useMemoFieldDecorator,
  useAxiosRequest,
  hasOnlyIoTPackageTwoActivation,
  validatePanoramaSerial
} from '../../../utils/activateUtils'
import {
  NEW,
  PANORAMA,
  NONE,
  PANORAMA_INSTANCE_FIELD as PANORAMA_FIELD,
  LGS_ID,
} from '../../../constants/AppConstants'

const renderOptions = deviceList => deviceList.map(({ key, display, extra, value, ...props }) => (
  <Select.Option {...props} key={key} value={`${value}`}>
    <ListItem toolTipProps={{ placement: 'right' }}>{display} {extra}</ListItem>
  </Select.Option>
))
const renderOptionGroups = optionsGroups => optionsGroups.map(([region, deviceList]) => {
  return renderOptions(deviceList)
})

const IoTPanorama = (props) => {
  const {
    devices,
    getAnyApp,
    cdlInstance,
    clcsTenant, // this is required for clcs flow for IoT 2.0
    setClcsTenant, // this is required for clcs flow for IoT 2.0
    setNewPanoramaSn,
    new_panorama_sn,
    toBeActivatedApp,
    form: {
      getFieldDecorator,
      setFieldsValue,
    },
    stateRef,
  } = props

  const { loading, load } = useAxiosRequest()

  const orderedPanoramaDevices = useMemo(() => {
    const filterDevices = devices?.filter(dev => dev?.serial_number && dev.app_id === PANORAMA)
    return _.orderBy(filterDevices, [clcsTenant ? dev => (dev.platform_id === clcsTenant ? 1 : 0) : dev => (!dev.platform_id ? 1 : 0)], ['desc'])
  }, [devices, clcsTenant])

  useEffect(() => {
    setFieldsValue({
      [PANORAMA_FIELD]: new_panorama_sn || NONE
    })
  }, [setFieldsValue, new_panorama_sn])

  const handlePanoramaChange = useCallback((value) => {
    setFieldsValue({
      [PANORAMA_FIELD]: value
    })
    setNewPanoramaSn(value)
  }, [setFieldsValue, setNewPanoramaSn])

  useEffect(() => {
    if (!hasOnlyIoTPackageTwoActivation(toBeActivatedApp)) { //IoT 1.0 or mixed case (1.0 & 2.0)
      setClcsTenant(cdlInstance?.tenant_id)
    }
    else { //IoT 2.0 without 1.0
      if (!cdlInstance) {
        return
      }
      if (!cdlInstance.tenant_id || cdlInstance.tenant_id === NEW) { //empty or active new
        setNewPanoramaSn() //APPORTAL-3922 reset panorama selection
        setClcsTenant() //APPORTAL-3922 reset clcs state
        const paidCdlList = getAnyApp(LGS_ID)?.tenants?.filter(t => !t.is_clcs && t.region === cdlInstance.region) || []
        if (paidCdlList.length === 1) { //only one paid cdl
          setClcsTenant(paidCdlList[0].tenant_id)
        }
        else if (paidCdlList.length > 1) { //multiple paid cdl
          const { setupSelectedAccount } = stateRef.current
          load({
            method: 'post',
            url: '/hub/v2/cspAccountDefaultClcs',
            data: {
              region: cdlInstance.region,
              selectedAccount: +setupSelectedAccount,
            }
          }).then((response) => {
            if (response.data > 0) {
              setClcsTenant(String(response.data))
            }
          }).catch((error) => {
            if (!error.data || error.data?.statusCode !== 404) {
              const message = error.data?.message || error.message || error
              // eslint-disable-next-line no-console
              console.error(`clcs:${message}`)
            }
          })
        }
      }
      else if (cdlInstance.tenant_id !== NEW) {//existing tenant
        setClcsTenant(cdlInstance.tenant_id)
      }
    }
  }, [cdlInstance, getAnyApp, stateRef, load, toBeActivatedApp, setNewPanoramaSn, setClcsTenant])

  useEffect(() => {
    const selectedCdlPanorama = clcsTenant && orderedPanoramaDevices?.find(device => device.platform_id === clcsTenant)
    if (selectedCdlPanorama && !new_panorama_sn) {
      setNewPanoramaSn(selectedCdlPanorama?.serial_number) // if selected cdl associated with panorama, default it to panorama
    }

  }, [orderedPanoramaDevices, clcsTenant, new_panorama_sn, setNewPanoramaSn])

  const renderedPanoramaDevices = useMemo(() => {
    const isCdlHasPanorama = orderedPanoramaDevices.some(device => device.platform_id === clcsTenant)

    const getInstances = () => {
      const list = orderedPanoramaDevices.map(({ serial_number, platform_id }) => {
        return {
          platform_id,
          serial_number,
          value: serial_number,
          instance_status: 'running', // so it will always showing up
        }
      })
      return list
    }

    const isDisabledFn = t => (isCdlHasPanorama ? t.platform_id !== clcsTenant : t.platform_id)

    const rendered = renderInstances({
      config: {
        app_id: PANORAMA,
        isDisabledFn,
        extraTenantInfoRenderFn: t => isDisabledFn(t) && '(used)'
      },
      appInfoMap: { // no need the full version from activate utils
        getAnyApp: () => ({ // fake app manifest for panorama
          enabled: true,
          display_name: 'Panorama',
        }),
        getInstances,
      },
      renderOptions,
      renderOptionGroups,
    })
    const defaultOption = [(
      <Select.Option
        key={NONE}
        value={NONE}
      >None</Select.Option>
    )]
    return defaultOption.concat(rendered)
  }, [clcsTenant, orderedPanoramaDevices])

  const panoramaDecorator = useMemoFieldDecorator(PANORAMA_FIELD, {
    initialValue: NONE,
    validateFirst: true,
    rules: useMemo(() => {
      const checkSupportRule = {
        validator(rule, value) {
          if (value === NONE) {
            return Promise.resolve()
          }
          return validatePanoramaSerial(value, stateRef.current, {
            register_new: false,
            has_support_license: false,
          })
        }
      }
      return [checkSupportRule]
    }, [stateRef])
  }, getFieldDecorator)

  return (
    <div className={'hbox middle space-between setup-item'}>
      <label>Panorama</label>
      {panoramaDecorator(
        <Select style={{ width: '70%', marginLeft: 10 }} onChange={handlePanoramaChange} disabled={loading}>
          {renderedPanoramaDevices}
        </Select>
      )}
    </div>
  )
}

IoTPanorama.propTypes = {
  form: PropTypes.object,
  devices: PropTypes.array,
  getAnyApp: PropTypes.func,
  cdlInstance: PropTypes.object,
  setNewPanoramaSn: PropTypes.func,
  new_panorama_sn: PropTypes.string,
  stateRef: PropTypes.any,
  supportAccounts: PropTypes.array.isRequired,
  toBeActivatedApp: PropTypes.array,
  clcsTenant: PropTypes.string,
  setClcsTenant: PropTypes.func
}

export default IoTPanorama
