/**
 * Created by vsuthar on 11/16/20
 * Project: App Portal ©Palo Alto Networks
 */

import React from 'react'
import PropTypes from 'prop-types'
import './simplecard.scss'

const SimpleCard = ({ children, title, footer }) => {
  return (
    <div className={'simple-card'}>
      <h4>{title}</h4>
      <p>{children}</p>
      <div>
        {footer}
      </div>
    </div>
  )
}

SimpleCard.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  children: PropTypes.node,
  footer: PropTypes.node,
}
export default SimpleCard

