import {
  SETUP_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
  LABEL_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
  LABEL_SELECT_PRODUCTS,
  SETUP_SELECT_PRODUCTS,
  LGS_ID,
  STRATA_INSIGHTS,
  STRATA_INSIGHTS_NGFW,
} from '../../../constants/AppConstants'
import { CheckLight, CogsLight, KeyIcon, UserShieldLight } from '../../../images/svg-icons'

const backBtnProps = {
  v2: false,
  label: 'Back',
}

const nextBtnProps = {
  v2: false,
  label: 'Next'
}

const progressStep = {
  useSimpleProgress: true,
  title: {
    intermediate_title: 'Finalizing your products',
    final_title: 'Activation Complete',
    final_sub_title: 'AIOps for NGFW is ready to be launched.',
  },
  miscComponent: [
    'CMP_SUPPORT_ACCOUNT',
    'CMP_REGION',
    'CMP_DATALAKE_READ',
    'FIREWALL_SUBSCRIPTION',
    'CMP_EXPIRATION',
    'CMP_DIRECTORY_SYNC',
  ],
  recommendedSteps: {
    showOTP: true,
    showPSK: true,
    showDataLakeStatus: true,
    showDeviceCertificate: true,
    buttons: [
      'REC_ASSIGN_ROLE',
      'REC_MANAGE_APPS'
    ]
  },
}
export const AIOPS = {
  theme: {
    primaryColor: '#5482B7',
    secondaryColor: ''
  },
  steps: [
    {
      title: LABEL_SELECT_PRODUCTS,
      icon: KeyIcon,
      style: {
        maxWidth: 820,
        minWidth: 820,
      },
      key: 0,
      allowedApps: [LGS_ID, STRATA_INSIGHTS, STRATA_INSIGHTS_NGFW],
      comp_enum: 'SETUP_ACTIVATE_PRODUCT',
      setup_enum: SETUP_SELECT_PRODUCTS,
      type: STRATA_INSIGHTS,
      description: 'The following products are available for activation. Please select which products you would like to activate now:',
      backBtnProps: { ...backBtnProps, label: 'Quit', href: '/apps' },
      nextBtnProps: { ...nextBtnProps, label: 'Start Activation' },
      help: '',
      current: '0',
      prev: null,
      next: '1',
    },
    {
      title: LABEL_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
      icon: UserShieldLight,
      style: {
        maxWidth: 820,
        minWidth: 820,
      },
      responsiveConfig: { xl: 12, xxl: 10, lg: 16, md: 18, sm: 23 },
      key: 1,
      comp_enum: 'SETUP_ACCOUNT_SELECTION',
      setup_enum: SETUP_CUSTOMER_SUPPORT_ACCOUNT_SELECTION,
      type: STRATA_INSIGHTS,
      description: 'We have detected you have multiple support accounts. Please assign your new products to a support account. If you would like to assign these new products to different support accounts, you may do so.',
      backBtnProps,
      nextBtnProps,
      help: '',
      current: '1',
      prev: '0',
      next: '2',
      fields: false
    },
    {
      title: 'AIOps For NGFW',
      icon: CogsLight,
      responsiveConfig: { xl: 10, xxl: 9, lg: 14, md: 18, sm: 23 },
      style: {
        maxWidth: 820,
        minWidth: 820,
      },
      key: 2,
      comp_enum: 'STRATA_INSIGHTS_SECURITY_SETUP',
      setup_enum: 'STRATA_INSIGHTS_SECURITY_SETUP',
      type: STRATA_INSIGHTS,
      description: 'Finalize your selections below to complete your setup',
      backBtnProps,
      nextBtnProps,
      help: '',
      current: '2',
      prev: '1',
      next: '3'
    },
    {
      title: 'Select Firewalls to enable AIOps',
      icon: CogsLight,
      style: {
        maxWidth: 1000,
        minWidth: 1000,
      },
      responsiveConfig: { xl: 12, xxl: 10, lg: 16, md: 16, sm: 18 },
      key: 3,
      comp_enum: 'SELECT_STRATA_INSIGHTS_FIREWALL',
      setup_enum: 'SELECT_STRATA_INSIGHTS_FIREWALL',
      type: STRATA_INSIGHTS,
      description: 'Select a model and then select the AIOps license type to assign to individual devices. Firewalls associated with other data lakes are listed in the __LINK__. To activate more firewalls later, click Activate in the original email.',
      backBtnProps,
      nextBtnProps,
      help: 'https://docs.paloaltonetworks.com/aiops/aiops-for-ngfw/get-started-with-aiops/onboard-devices',
      current: '3',
      prev: '2',
      next: '4',
    },
    {
      title: 'Activate Subscriptions',
      icon: CheckLight,
      style: {
        maxWidth: 820,
        minWidth: 820,
      },
      show_data_lake: false,
      show_cmp: ['CMP_SUPPORT_ACCOUNT', 'CMP_REGION', 'CMP_DATALAKE_READ', 'CMP_EXPIRATION', 'CMP_TERM_CONDITION', 'CMP_DIRECTORY_SYNC', 'FIREWALL_SUBSCRIPTION'],
      comp_enum: 'CONFIRMATION',
      type: STRATA_INSIGHTS,
      responsiveConfig: { xl: 8, xxl: 6, lg: 12, md: 18, sm: 18 },
      key: 4,
      description: 'Activate your subscriptions to complete the setup',
      backBtnProps,
      nextBtnProps: { ...nextBtnProps, label: 'Activate Subscription' },
      prev: '3',
      current: '4',
    }
  ],
  progressStep
}

