import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { PRISMA_SAAS, LGS_ID, SAAS_REGION, PRISMA_SAAS_NGFW, SETUP_CREATE_NEW, PAE_ADDON_SAAS_TNT, UPGRADE, SAAS_REST, SAAS_INLINE } from '../../../constants/AppConstants'
import PrismaSaasTenant from './PrismaSaasTenant'
import SetupSubdomainField from '../../../components/Activate/fields/SetupSubdomainField'
import DataCenterRegion from './DataCenterRegion'
import './prismaSaas.scss'
import { convertObjToString, isActivatingOnlySaasRest, isActivatingSaasInlineAndRest, isMultiRegionAllowed, selectedDevicesAndLicenses, useMemoFieldDecorator } from '../../../utils/activateUtils'

export const fieldName = 'support-account-saas-rest'
const PRISMA_SAAS_API = 'saas_api'
const PRISMA_SAAS_API_INLINE = 'saas_api_inline'
const PRISMA_SAAS_NEW = `${PRISMA_SAAS}:${SETUP_CREATE_NEW}`

const PrismaSaasSecurityFields = (props) => {
  const {
    form: { getFieldDecorator, getFieldValue },
    setupSelectedAccount,
    contextApp,
    getAnyApp,
    cdlInstance,
    toBeActivatedApp,
    fieldName,
    subdomainFieldName,
    selectProps = {},
    subdomainProps = {},
    setSelectedFirewallDevices,
    session,
    entitledAppsList,
    selectedFirewallDevices,
    supportAccounts,
  } = props

  const hasFirewallBundle = session?.has_firewall_bundle
  const isOnlyRestActivation = useMemo(() => isActivatingOnlySaasRest(toBeActivatedApp), [toBeActivatedApp])
  const appInfoMap = useMemo(() => {
    return Object.assign(new Map([[contextApp.app_id, contextApp]]), {
      subdomainMap: new Map([[PRISMA_SAAS, contextApp.domain_postfix]]),
      hasSubdomainField: true,
      customizable_subdomain: true,
      domainPostfix: contextApp.domain_postfix,
    })
  }, [contextApp])
  let prismaSaaSTenants = getAnyApp(PRISMA_SAAS)?.tenants

  const updatedPrismaSaaSValue = getFieldValue(fieldName)

  const isRestWithSelectedInlineTenant = useMemo(() => {
    return isOnlyRestActivation && updatedPrismaSaaSValue && updatedPrismaSaaSValue !== PRISMA_SAAS_NEW
  }, [isOnlyRestActivation, updatedPrismaSaaSValue])

  const selectedInlineTenant = useMemo(() => {
    const saasInlineTenantID = updatedPrismaSaaSValue?.split(/[:|]/, 3)?.[1]
    return prismaSaaSTenants?.find(tenant => tenant.tenant_id === saasInlineTenantID)
  }, [updatedPrismaSaaSValue, prismaSaaSTenants])

  const appRegions = useMemo(() => {
    return getAnyApp(PRISMA_SAAS)?.regions
  }, [getAnyApp])

  const cdlAssociatedSaasTenant = useMemo(() => {
    if (cdlInstance) {
      return getAnyApp(PRISMA_SAAS)?.tenants?.find(each => each?.associations[LGS_ID]?.tenant_id === cdlInstance?.tenant_id)
    }
  }, [cdlInstance, getAnyApp])

  const getAppRegion = useMemo(() => {
    if (isRestWithSelectedInlineTenant) {
      return selectedInlineTenant.region
    }
    /* handle backward compatible - eg: new saas UK data center
      users should not see saas uk option for cdl UK for new tenants,
      where as existing tenants we will keep as is i.e; display frankfurt
      hence relying on instance store value instead of reading from manifest for existing tenants
    */
    else if (cdlAssociatedSaasTenant) {
      return cdlAssociatedSaasTenant.region
    }
    else if (isMultiRegionAllowed(supportAccounts, setupSelectedAccount)) {
      return appRegions.find(r => r?.platform_regions?.find(pr => pr === cdlInstance?.region))?.name
    }
    else {
      return appRegions.find(r => r.logging_service_region === cdlInstance?.region)?.name
    }
  }, [appRegions, cdlInstance, isRestWithSelectedInlineTenant, setupSelectedAccount, selectedInlineTenant, supportAccounts, cdlAssociatedSaasTenant])

  // const prismaSaasValue = cdlAssociatedSaasTenant ? `${PRISMA_SAAS}:${convertObjToString(cdlAssociatedSaasTenant)}` : getFieldValue(fieldName)

  // filter saas tenants only for inline activations
  if (!isOnlyRestActivation && getAppRegion && prismaSaaSTenants) {
    prismaSaaSTenants = prismaSaaSTenants.filter(tenant => tenant?.region === getAppRegion)
  }

  const regionMatchedSaaS = prismaSaaSTenants?.[0]
  const tenantUpgradeContext = useMemo(() => {
    //applicable for magic link with only inline licenses, since duplicate Rest license is blocked during account selection step
    // (SaaS inline easy onboarding || PAE easy onboardingwith SaaS inline addon)
    if ((toBeActivatedApp?.some(app => app?.app_id === PRISMA_SAAS_NGFW && app?.checked) || fieldName === PAE_ADDON_SAAS_TNT) &&
      !prismaSaaSTenants?.some(t => t.license_type === PRISMA_SAAS_API_INLINE) && //verify no existing rest+inline tenant
      prismaSaaSTenants?.some(t => t.license_type === PRISMA_SAAS_API)// verify at-least one existing rest tenant
    ) {
      return SAAS_REST
    }
    //existing inline upgrade to rest with new "rest + inline" activate selection  OR
    // existing inline upgrade to rest with only "rest" activate selection
    else if ((cdlAssociatedSaasTenant && isActivatingSaasInlineAndRest(toBeActivatedApp)) || isRestWithSelectedInlineTenant) {
      return SAAS_INLINE
    }
  }, [cdlAssociatedSaasTenant, toBeActivatedApp, prismaSaaSTenants, fieldName, isRestWithSelectedInlineTenant])


  const prismaSaasValue = useMemo(() => {
    if (tenantUpgradeContext === SAAS_INLINE) {
      const restAuthCode = toBeActivatedApp.find(app => app.app_id === PRISMA_SAAS)?.auth_code
      const saasInlineTenant = isOnlyRestActivation ? selectedInlineTenant : cdlAssociatedSaasTenant
      return `${PRISMA_SAAS}:${convertObjToString(saasInlineTenant)}|${restAuthCode}|${UPGRADE}`
    }
    else if (cdlAssociatedSaasTenant) {
      /*if (fieldName === PAE_ADDON_SAAS_TNT && session?.licenses?.find(license => license.app_id === PRISMA_ACCESS_EDITION)?.addons?.some(({ app_id }) => app_id.toUpperCase() === 'CASB')) {
        // APPORTAL-5541 check CASB and pass upgrade into saas addon
        return `${PRISMA_SAAS}:${convertObjToString(cdlAssociatedSaasTenant)}||${UPGRADE}`
      }*/
      // has saas inline associated selected cdl
      return `${PRISMA_SAAS}:${convertObjToString(cdlAssociatedSaasTenant)}`
    }
    // force select to existing SaaS rest to use for saas inline
    // also make sure cdl region is selected (i.e cdlInstance exists)
    else if (tenantUpgradeContext === SAAS_REST && regionMatchedSaaS && cdlInstance?.region) {
      return `${PRISMA_SAAS}:${convertObjToString(regionMatchedSaaS)}||${UPGRADE}`
    }
    // show active new only when :
    // 1. tenantUpgradeContext is null,
    // 2. when rest tenant is upgrading and there is no existing saas_ngfw tenants (implies existing tenant already has Saas inline)
    else if (!(tenantUpgradeContext === SAAS_REST && !getAnyApp(PRISMA_SAAS_NGFW)?.tenants)) {
      return `${PRISMA_SAAS}:${SETUP_CREATE_NEW}`
    }
  }, [tenantUpgradeContext, cdlAssociatedSaasTenant, regionMatchedSaaS, cdlInstance?.region, getAnyApp, toBeActivatedApp, isOnlyRestActivation, selectedInlineTenant])

  useEffect(() => {
    if (hasFirewallBundle) {
      const { licenses, devices } = selectedDevicesAndLicenses(entitledAppsList, session?.licenses, PRISMA_SAAS_NGFW)
      setSelectedFirewallDevices({ devices, licenses })
    }
  }, [session, hasFirewallBundle, setSelectedFirewallDevices, entitledAppsList])

  //required for raptor device activations
  const fieldDecorator = useMemoFieldDecorator('firewalls', {
    initialValue: selectedFirewallDevices,
  }, getFieldDecorator)

  return (
    <>
      <PrismaSaasTenant {...props}
        initialValue={prismaSaasValue}
        isDisabled={Boolean(cdlAssociatedSaasTenant)}
        tenantUpgradeContext={tenantUpgradeContext}
        prismaSaasRegion={getAppRegion}
        isOnlyRestActivation={isOnlyRestActivation}
        selectProps={{ style: { width: '70%', marginLeft: 10 }, ...selectProps }} />
      <DataCenterRegion
        {...props}
        isOnlyRestActivation={isOnlyRestActivation}
        appRegion={getAppRegion}
        appRegionList={appRegions}
        dropDownProps={{ style: { width: '70%' }, placeholder: SAAS_REGION }}
      />
      <SetupSubdomainField
        {...props}
        app={contextApp}
        tenant_selected_value={prismaSaasValue}
        subdomainFieldName={subdomainFieldName}
        subdomainProps={{
          required: false,
          className: 'setup-item',
          labelCol: { xs: { span: 5 } },
          wrapperCol: { xs: { span: 17, offset: 2 } },
          key: 'subdomain',
          domainPostfix: appInfoMap.domainPostfix,
          selectedAccount: setupSelectedAccount,
          appInfoMap: appInfoMap,
          label: 'SaaS Subdomain',
          domainPattern: /^[a-z0-9]+$/i,
          ...subdomainProps
        }}
      />
      {hasFirewallBundle && fieldDecorator(<div />)}
    </>
  )
}

PrismaSaasSecurityFields.propTypes = {
  form: PropTypes.object,
  fieldName: PropTypes.string,
  subdomainFieldName: PropTypes.string,
  contextApp: PropTypes.object,
  formValues: PropTypes.object,
  cdlInstance: PropTypes.object,
  setupSelectedAccount: PropTypes.number,
  getAnyApp: PropTypes.func,
  toBeActivatedApp: PropTypes.array,
  selectProps: PropTypes.object,
  subdomainProps: PropTypes.object,
  setSelectedFirewallDevices: PropTypes.func,
  session: PropTypes.object,
  entitledAppsList: PropTypes.array,
  selectedFirewallDevices: PropTypes.array,
  supportAccounts: PropTypes.array
}

export default PrismaSaasSecurityFields
