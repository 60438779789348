/**
 * Created by vsuthar on 9/30/21
 * File: ServiceGroupSelection
 * Project: App Portal ©Palo Alto Networks
 * © Palo Alto Networks
 */

// import statement lib, local then scss or css

/**
 *   TODO: Brief what is this component/class do
 */
import React, { useCallback, useMemo, useState } from 'react'
import { Tree, Input } from '@pan/cloud-base'
import axios from 'axios'
import PropTypes from 'prop-types'
import './serviceGroupTree.scss'
import { useMemoFieldDecorator } from '../../../utils/hooks'
import ListItem from '../../../components/ListItem'
const { TreeNode } = Tree
const { Search } = Input
const ServiceGroupSelectionField = React.forwardRef(({ sgGroups, onChange }, ref) => {

  const [{ expandedKeys, searchValue, autoExpandParent }, setTreeState] = useState(
    {
      expandedKeys: [],
      searchValue: '',
      autoExpandParent: false
    })

  /**
   *     ========= Local State ===========
   */


  /**
   *     ========= Lifecycle =============
   */


  /**
   *     ========= Callback ===========
   */

  const getParentKey = useCallback((key, tree) => {
    let parentKey
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i]
      if (node.children) {
        if (node.children.some(item => item.id === key)) {
          parentKey = node.id
        }
        else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children)
        }
      }
    }
    return parentKey
  }, [])

  const onSelect = useCallback((selectedKey, { selectedNodes, node, event }) => {
    onChange(selectedKey[0])
  }, [onChange])

  const flattenSGGroup = useMemo(() => {
    const dataList = []
    const flattenTree = (arr) => {
      if (Array.isArray(arr)) {
        arr?.forEach((each) => {
          const { id, display_name } = each
          dataList.push({ key: id, display_name })
          if (each.children) {
            flattenTree(each.children)
          }
        })
      }
    }
    flattenTree(sgGroups)
    return dataList
  }, [sgGroups])

  const onSearch = useCallback((e) => {
    const { value } = e.target
    const expandedKeys = flattenSGGroup.map(each => {
      if (each.display_name.indexOf(value) > -1) {
        return getParentKey(each.key, sgGroups)
      }
      return null
    }).filter((item, i, self) => item && self.indexOf(item) === i)
    setTreeState({
      expandedKeys,
      autoExpandParent: true,
      searchValue: value
    })
  }, [getParentKey, setTreeState, flattenSGGroup, sgGroups])

  const getTreeNode = (data) => {
    return Array.isArray(data) && data.map(item => {
      const index = item.display_name.indexOf(searchValue)
      const beforeStr = item.display_name.substr(0, index)
      const afterStr = item.display_name.substr(index + searchValue?.length)
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span style={{ color: '#2279a4', backgroundColor: 'rgba(15,117,168,0.11)' }}>{searchValue}</span>
            {afterStr}
          </span>
        ) : (
          <ListItem><span>{item.display_name}</span></ListItem>
        )
      if (item.children) {
        return <TreeNode key={item.id} title={title}>
          {getTreeNode(item.children)}
        </TreeNode>
      }
      return <TreeNode key={item.id} title={title} />
    })
  }

  const onExpand = useCallback((expandedKeys) => {
    setTreeState({
      expandedKeys,
      autoExpandParent: false
    })
  }, [])

  return (
    <div className={'hbox align-item-baseline space-between setup-item'}>
      <div style={{ flex: .3 }}>
        <label>Select Service Group</label>
      </div>
      <div style={{ flex: .7 }} className={'service-group-tree'}>
        <Search placeholder={'Search Service Group'}
          onChange={onSearch}
          className={'tree-search-input'} />
        <div className={'sg-tree'}>
          <Tree onSelect={onSelect}
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}>
            {getTreeNode(sgGroups)}
          </Tree>
        </div>

      </div>
    </div>)
})

ServiceGroupSelectionField.propTypes = {
  sgGroups: PropTypes.array,
  onChange: PropTypes.func,
  flattenSGGroup: PropTypes.array,
}

ServiceGroupSelectionField.displayName = 'ServiceGroupSelectionField'

/**
 *
 * @param sgGroups
 * @param form
 * @returns {*}
 * @constructor
 */
const ServiceGroupSelection = ({ sgGroups, form: { getFieldDecorator } }) => {

  const fieldDecorator = useMemoFieldDecorator('tsg_id', {
    initialValue: '',
    validateFirst: true,
    rules: useMemo(() => {
      const requiredRule = { required: true, message: 'Please select Service Group account' }
      const checkSupportRule = {
        remote: '/hub/v2/validate/service_group',
        validator(rule, value, callback) {
          return axios.get(rule.remote, { params: { serviceGroupId: value } })
            .then(({ data }) => {
              if (data.success) {
                return callback()
              }
              return callback('This Service Group selection is not allowed')
            })
            .catch((error) => {
              if (!error.response || error.response.status !== 404) {
                callback('This Service Group selection is not allowed')
              }
              if (error) {
                callback('This Service Group selection is not allowed')
              }
            })
        }
      }
      return [requiredRule, checkSupportRule]
    }, [])
  }, getFieldDecorator)

  return (fieldDecorator(<ServiceGroupSelectionField sgGroups={sgGroups} />))
}

ServiceGroupSelection.propTypes = {
  sgGroups: PropTypes.array.isRequired,
}
export default ServiceGroupSelection

