import React, { useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@pan/cloud-base'
import ListItem from '../../../components/ListItem'
import { REGION_FIELD, PRISMA_ACCESS, _ONPREM } from '../../../constants/AppConstants'
import { useMemoFieldDecorator, filterRegions, useValueRef, renderInstances } from '../../../utils/activateUtils'
import './CGXFields.scss'

const renderOptions = tenants => tenants.map(({ key, group, display, extra, ...props }) => (
  <Select.Option {...props} key={key}>
    <ListItem toolTipProps={{ placement: 'right' }}>{display} {extra}</ListItem>
  </Select.Option>
))
const renderOptionGroups = optionsGroups => optionsGroups.map(([region, tenants]) => {
  return !region ? renderOptions(tenants) : <Select.OptGroup key={region} label={region}>
    {renderOptions(tenants)}
  </Select.OptGroup>
})

const DEPLOY_SIZE_FIELD_NAME = 'deployment_size'
const DEPLOY_SIZE_FIELD = `extra.cgx.${DEPLOY_SIZE_FIELD_NAME}`
const CGXFields = ({
  getAnyApp,
  contextApp,
  primaryAppId = contextApp?.app_id,
  stateRef,
  form: {
    getFieldDecorator,
    getFieldValue,
    validateFields,
    setFieldsValue,
  },
  dropDownProps,
}) => {
  const { accountIsFederal, session, setupSelectedAccount } = stateRef.current
  const region = getFieldValue(REGION_FIELD)
  const getPreservedFieldDecorator = useCallback((id, opt) => getFieldDecorator(id, { preserve: true, ...opt }), [getFieldDecorator])
  const [
    avaiRegions,
    // defaultRegion, // stop using defaultRegion
    deploySizeCfg,
    defaultDeploySize,
    existingInstRegion,
    blockDueToExistingErrMsg,
  ] = useMemo(() => {
    const cgxApp = primaryAppId && getAnyApp(primaryAppId)
    const appRegions = cgxApp?.regions
    const avaiRegions = filterRegions(appRegions, accountIsFederal)
    // .map((r) => ({
    //   ...r,
    //   value: `${r.name}|${r.logging_service_region}`,
    // }))
    if (!avaiRegions.length) {
      return [[]] // it should not happen, just in case
    }
    // const defaultRegion = avaiRegions[0].value // stop using defaultRegion
    const deploySizeCfg = cgxApp?.setup?.fields?.[DEPLOY_SIZE_FIELD_NAME] || {}
    const tenantSize = session?.licenses?.find(lic => lic.app_id === primaryAppId)?.tenant_size?.replace(/s+/g, '')
    const defaultDeploySize = tenantSize && deploySizeCfg.options?.some(o => o.value === tenantSize) ? tenantSize : deploySizeCfg.defaults
    // TODO: remove if support more than one
    const existingInst = cgxApp?.flags.account_single_tenant && cgxApp?.tenants?.find(inst => inst.tenant_id)
    const blockDueToExistingErrMsg = existingInst ?
      `Found an existing ${cgxApp?.display_name} in current account` : false
    return [
      avaiRegions,
      // defaultRegion,
      deploySizeCfg,
      defaultDeploySize,
      existingInst?.region,
      blockDueToExistingErrMsg,
    ]
  }, [accountIsFederal, session, getAnyApp, primaryAppId])
  const useExistingCgxRef = useValueRef(blockDueToExistingErrMsg)
  const regionDecorator = useMemoFieldDecorator(REGION_FIELD, {
    initialValue: existingInstRegion, // stop using defaultRegion
    validateFirst: true,
    rules: useMemo(() => [{
      validator: (rule, value, callback) => { // remove when support multiple tenant
        if (useExistingCgxRef.current) {
          return callback(useExistingCgxRef.current)
        }
        if (!value) {
          return callback('Region is required')
        }
        return callback()
      }
    }], [useExistingCgxRef]),
  }, getPreservedFieldDecorator)
  const deploySizeDecorator = useMemoFieldDecorator(DEPLOY_SIZE_FIELD, {
    initialValue: defaultDeploySize,
  }, getPreservedFieldDecorator)
  const selectFawkesDecorator = useMemoFieldDecorator(`instances.${PRISMA_ACCESS}`, {
  }, getPreservedFieldDecorator)

  const renderedFawkesInstances = useMemo(() => {
    const rendered = renderInstances({
      config: {
        app_id: PRISMA_ACCESS,
        // single_association: [PRISMA_ACCESS],
        region_matching: false,
      },
      appInfoMap: { // no need the full version from activate utils
        getAnyApp,
        getInstances: appId => (getAnyApp(appId)?.tenants || []).filter(inst => !inst.tenant_id.endsWith(_ONPREM)), // filter out onprem Fawkes
      },
      renderOptions,
      renderOptionGroups,
    })
    // add a none option since fawkes selection is optional
    const noneOption = <Select.Option key='None' value=''>None</Select.Option>
    rendered.unshift(noneOption)
    return rendered
  }, [getAnyApp])

  useEffect(() => { // ensure re-validate after switch account
    validateFields([REGION_FIELD], { force: true })
    // if region changes, reset the fawkes to none
    if (region) {
      setFieldsValue({ [`instances.${PRISMA_ACCESS}`]: '' })
    }
  }, [setupSelectedAccount, validateFields, setFieldsValue, region])

  return (
    <div className={'vbox'}>
      <div className={'hbox middle space-between setup-item'}>
        <label>Region</label>
        {regionDecorator(
          <Select
            {...dropDownProps}
            placeholder='Select Region'
            disabled={Boolean(blockDueToExistingErrMsg)}
          >
            {avaiRegions.map(({ display, value }) => {
              return <Select.Option key={value} value={value}>{display}</Select.Option>
            })}
          </Select>
        )}
      </div>
      {!blockDueToExistingErrMsg &&
        <div className={'hbox middle space-between setup-item'}>
          <label>Prisma Access</label>
          {selectFawkesDecorator(
            <Select
              {...dropDownProps}
              placeholder='Select Region First'
              disabled={!Boolean(region)}
            >
              {renderedFawkesInstances}
            </Select>
          )}
        </div>
      }
      {!blockDueToExistingErrMsg &&
        <div className={'hbox middle space-between setup-item'}>
          <label>{deploySizeCfg.label}</label>
          {deploySizeDecorator(
            <Select
              {...dropDownProps}
            >
              {deploySizeCfg.options?.map(({ display, value }) => {
                return <Select.Option key={value} value={value}>{display}</Select.Option>
              }) || null}
            </Select>
          )}
        </div>
      }
      {blockDueToExistingErrMsg ? (
        <div className='cgx-info region-warning'>{blockDueToExistingErrMsg}</div>
      ) : (
        <div className='cgx-info'>{deploySizeCfg.note}</div>
      )}
    </div>
  )
}

CGXFields.propTypes = {
  supportAccounts: PropTypes.array,
  selectedAccount: PropTypes.number,
  getAnyApp: PropTypes.func,
  toBeActivatedApp: PropTypes.array,
  stateRef: PropTypes.any,
  cdlInstance: PropTypes.object,
  region: PropTypes.string,
  primaryAppId: PropTypes.string,
  contextApp: PropTypes.object,
  form: PropTypes.object,
  dropDownProps: PropTypes.object,
  entitledAppsList: PropTypes.array,
}

CGXFields.defaultProps = {
  getAnyApp: () => undefined,
  useDataLakeRegion: false,
  supportAccountIds: [],
  toBeActivatedApp: [],
  cdlInstance: {},
  dropDownProps: {
    style: {
      width: 200,
    },
  }
}

export default CGXFields
