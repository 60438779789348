/**
 * Created by vsuthar on 12/10/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { useMemo, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@pan/cloud-base'
import {
  renderInstances,
  useMemoFieldDecorator,
} from '../../../utils/activateUtils'
import {
  STRATA_INSIGHTS,
  STRATA_INSIGHTS_NGFW,
  CHOOSE_HOW_TO_MANAGE_FIELD,
  SETUP_CREATE_NEW,
  AIOPS_PREMIUM_INSTANCE_TYPE
} from '../../../constants/AppConstants'
import ListItem from '../../../components/ListItem'

const STRATA_INSIGHTS_NEW = `${STRATA_INSIGHTS}:${SETUP_CREATE_NEW}`

const renderOptions = tenants => tenants.map(({
  key,
  group,
  display,
  extra,
  value,
  tenant_id,
  ...props
}) => {
  return <Select.Option {...props} key={key} value={`${STRATA_INSIGHTS}:${value}`}>
    <ListItem toolTipProps={{ placement: 'right' }}>{display} {extra}</ListItem>
  </Select.Option>
})

const renderOptionGroups = optionsGroups => optionsGroups.map(([region, tenants]) => {
  return !region ? renderOptions(tenants) : <Select.OptGroup key={region} label={region}>
    {renderOptions(tenants)}
  </Select.OptGroup>
})

const getAiopsInstanceValue = (currentStepObj, toBeActivatedApp) => {
  const hasNoAiopsNgfwActivate = !toBeActivatedApp.some(app => app.checked && [STRATA_INSIGHTS_NGFW].includes(app.app_id))
  if (hasNoAiopsNgfwActivate && currentStepObj?.setupValues?.value === STRATA_INSIGHTS_NEW) {
    return undefined //reset AIOps tenant to default
  }
  return currentStepObj?.setupValues?.value
}

const AiopsTenant = ({
  getAnyApp,
  toBeActivatedApp,
  chooseHowToManage,
  form,
  selectProps,
  currentStepObj,
  fieldName,
  initialValue,
  region,
}) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form
  useLayoutEffect(() => {
    const existingValue = getFieldValue(fieldName)
    if (existingValue !== initialValue) {
      setFieldsValue({ [fieldName]: initialValue })
    }
  }, [getFieldValue, setFieldsValue, initialValue, fieldName])

  const strataInsights = useMemo(() => getAiopsInstanceValue(
    currentStepObj,
    toBeActivatedApp,
    getAnyApp,
    chooseHowToManage,
  ), [currentStepObj, toBeActivatedApp, getAnyApp, chooseHowToManage])
  const renderedAiopsInstances = useMemo(() => {
    const rendered = renderInstances({
      config: {
        app_id: STRATA_INSIGHTS,
        /**
         * Make region_matching true when region is true
         */
        region_matching: Boolean(region),
      },
      appInfoMap: { // no need the full version from activate utils
        getAnyApp,
        // aiops easy onboarding only for premium instances hence allow only premium instances
        getInstances: appId => getAnyApp(appId)?.tenants?.filter(each => each.type === AIOPS_PREMIUM_INSTANCE_TYPE) || [],
      },
      renderOptions,
      renderOptionGroups,
      instanceRegionFilter: (instance) => {
        /**
         * If region dont pass by default then just show all tenant.
         */
        if (Boolean(region)) {
          return instance.region === region
        }
        return true
      },
    })
    /**
     * List of app_id allowed to make sure that we default set AIOps tenant to new as default option
     */
    if (toBeActivatedApp.some(app => app.checked && [STRATA_INSIGHTS_NGFW].includes(app.app_id))) {
      const newOption = <Select.Option
        key={STRATA_INSIGHTS_NEW}
        value={STRATA_INSIGHTS_NEW}
      >Activate New</Select.Option>
      rendered.unshift(newOption)
    }
    return rendered
  }, [getAnyApp, toBeActivatedApp, region])

  const getAiopsDecorator = useMemoFieldDecorator(fieldName, {
    initialValue: initialValue || strataInsights,
    rules: [{
      validator(rule, value, callback) {
        if (!value) {
          callback('AIOps tenant is required')
        }
        else {
          callback()
        }
      }
    }]
  }, getFieldDecorator)

  return (
    <div className={'hbox middle space-between setup-item'}>
      <label>Select AIOps tenant</label>
      {getAiopsDecorator(
        <Select {...selectProps} placeholder='Select AIOps tenant'>
          {renderedAiopsInstances}
        </Select>,
      )}
    </div>

  )
}

AiopsTenant.getAiopsInstanceValue = getAiopsInstanceValue
/**
 *
 * @type {{fieldName, getAnyApp, chooseHowToManage, currentStepObj, setSelectedFirewallDevices, form, selectProps, toBeActivatedApp, region, initialValue}}
 */
AiopsTenant.propTypes = {
  getAnyApp: PropTypes.func,
  toBeActivatedApp: PropTypes.array,
  setSelectedFirewallDevices: PropTypes.func,
  chooseHowToManage: PropTypes.func,
  form: PropTypes.object,
  selectProps: PropTypes.object,
  currentStepObj: PropTypes.object,
  fieldName: PropTypes.string.isRequired,
  /**
   * @initialValue set initial value tenantid|serail_number|region|authcode
   */
  initialValue: PropTypes.string,
  formValues: PropTypes.object,
  /**
   * @region Props passed from the parent to determine region of the AIOps tenant that needs to be filtered upon
   */
  region: PropTypes.string,
}

AiopsTenant.defaultProps = {
  fieldName: CHOOSE_HOW_TO_MANAGE_FIELD,
  chooseHowToManage: () => undefined,
}

export default AiopsTenant
