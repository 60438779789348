/**
 * Created by vsuthar on 12/15/20
 * Project: App Portal ©Palo Alto Networks
 */

import React, { useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Select } from '@pan/cloud-base'
import { useMemoFieldDecorator } from '../../../utils/activateUtils'
import { APP_REGION_FIELD, SAAS_REGION } from '../../../constants/AppConstants'

const DataCenterRegion = ({
  isOnlyRestActivation,
  appRegion,
  dropDownProps,
  appRegionList,
  form: {
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
  },
}) => {

  useEffect(() => {
    if (appRegion) {
      setFieldsValue({
        [APP_REGION_FIELD]: appRegion
      })
    }
  }, [appRegion, setFieldsValue])

  const appRegionHandler = useCallback((region) => {
    setFieldsValue({
      [APP_REGION_FIELD]: region
    })
  }, [setFieldsValue])

  const appRegionDisplay = useMemo(() => {
    return appRegionList.find(region => region.name === appRegion)?.display
  }, [appRegion, appRegionList])


  const displayRegionList = useMemo(() => {
    if (isOnlyRestActivation) {
      return appRegionList.filter(each => !each?.extra?.easy_onboarding_only)
    }
    return appRegionList
  }, [isOnlyRestActivation, appRegionList])

  const appRegionDecorator = useMemoFieldDecorator(APP_REGION_FIELD, {
    initialValue: getFieldValue(APP_REGION_FIELD),
    validateFirst: true,
    rules: useMemo(() => [
      { required: true, message: 'SaaS Region is required' },
    ], []),
  }, getFieldDecorator)

  return <div className={'hbox middle space-between setup-item'}>
    <label>{SAAS_REGION}</label>
    {
      appRegionDecorator(isOnlyRestActivation ? <Select
        {...dropDownProps}
        disabled={!isEmpty(appRegion)}
        onSelect={appRegionHandler}
      >
        {displayRegionList.map(({ display, name }) => {
          return <Select.Option key={name} value={name}>{display}</Select.Option>
        })}
      </Select> : <span>{appRegionDisplay}</span>
      )
    }
  </div>
}

DataCenterRegion.propTypes = {
  isOnlyRestActivation: PropTypes.bool,
  appRegion: PropTypes.string,
  form: PropTypes.object,
  dropDownProps: PropTypes.object,
  appRegionList: PropTypes.array,
}

DataCenterRegion.defaultProps = {
  getAnyApp: () => undefined,
  dropDownProps: {
    style: {
      width: 200,
    },
  }
}
export default DataCenterRegion

