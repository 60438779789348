import React, { useMemo, useCallback, useLayoutEffect, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import DataLake from '../DataLake'
import IoTPanorama from './IoTPanorama'
import {
  ZINGBOX,
  SETUP_CREATE_NEW,
  ZINGBOX_NGFW,
  ZINGBOX_NGFW_DRDL,
  SUBDOMAIN_FIELD,
  CDL_INSTANCE_FIELD,
  ZINGBOX_3P_ADDON,
} from '../../../constants/AppConstants'
import {
  getSubdomainFromEmail,
  hasAppActivation,
  hasOnlyIoTPackageTwoActivation,
  hasOnlyIoTThirdPartyActivation,
  useMemoFieldDecorator,
  selectedDevicesAndLicenses,
} from '../../../utils/activateUtils'
import IoTIngestionRegion from './IoTIngestionRegion'
import IoTSecurityTenant from './IoTSecurityTenant'
import SetupSubdomainField from '../../../components/Activate/fields/SetupSubdomainField'
import IoTAppRegion from './IoTAppRegion'
import './iotSecurity.scss'

const IoTSecuritySetup = (props) => {
  const lastValuesRef = useRef({})
  const {
    setupSelectedAccount,
    form,
    contextApp,
    getAnyApp,
    chooseHowToManage,
    currentStepObj,
    formValues,
    cdlInstance,
    user_email,
    stateRef,
    onCDLChange,
    setSelectedFirewallDevices,
    toBeActivatedApp,
    setNewPanoramaSn,
    setClcsTenant,
    session,
    selectedFirewallDevices,
    entitledAppsList,
  } = props
  const hasFirewallBundle = session?.has_firewall_bundle
  const zingbox = useMemo(() => IoTSecurityTenant.getIoTInstanceValue(
    currentStepObj,
    toBeActivatedApp,
    getAnyApp,
    chooseHowToManage
  ), [currentStepObj, toBeActivatedApp, getAnyApp, chooseHowToManage])
  const { validateFields, setFieldsValue, resetFields, getFieldValue } = form

  const selectedExistingZingboxSubdomain = useMemo(() => {
    const [, tenant_id] = zingbox?.split(/[:|]/, 2) || []
    if (tenant_id && tenant_id !== SETUP_CREATE_NEW) {
      const instance = getAnyApp(ZINGBOX).tenants?.find(x => x.tenant_id === tenant_id)
      if (instance?.url) {
        const [subdomain] = instance.url.replace(/^https?:\/\//i, '').split('.', 1)
        if (subdomain) {
          return { subdomain, isEmpty: false }
        }
      }
    }
    return { isEmpty: true }
  }, [zingbox, getAnyApp])
  const subdomain = selectedExistingZingboxSubdomain.subdomain || lastValuesRef.current.subdomain ||
    formValues.subdomain || getSubdomainFromEmail(user_email)

  useLayoutEffect(() => { // apply value from state/default to form field
    const curValue = getFieldValue(SUBDOMAIN_FIELD)
    if (subdomain !== curValue) {
      setFieldsValue({ [SUBDOMAIN_FIELD]: subdomain })
    }
  }, [selectedExistingZingboxSubdomain.subdomain, subdomain, getFieldValue, setFieldsValue, validateFields])
  useLayoutEffect(() => { // apply value from state/default to form field
    const curValue = getFieldValue(CDL_INSTANCE_FIELD)
    const { value, fromInstanceAssociation } = cdlInstance || {}
    const newValue = fromInstanceAssociation ? value : null //IOT-222 setting to null
    if (newValue !== curValue) {
      if (newValue) {
        setFieldsValue({ [CDL_INSTANCE_FIELD]: newValue })
      }
      else {
        resetFields([CDL_INSTANCE_FIELD]) // so it will use default value
      }
    }
  }, [cdlInstance, getFieldValue, resetFields, setFieldsValue, validateFields])


  useEffect(() => {
    if (hasFirewallBundle) {
      const { licenses, devices } = selectedDevicesAndLicenses(entitledAppsList, session?.licenses, ZINGBOX_NGFW, ZINGBOX_NGFW_DRDL)
      setSelectedFirewallDevices({ devices, licenses })
    }
  }, [session, hasFirewallBundle, setSelectedFirewallDevices, entitledAppsList])

  const appInfoMap = useMemo(() => { // just a mock one
    return Object.assign(new Map([[contextApp.app_id, contextApp]]), {
      subdomainMap: new Map([[ZINGBOX, contextApp.domain_postfix]]),
      hasSubdomainField: true,
      customizable_subdomain: true,
      domainPostfix: contextApp.domain_postfix,
    })
  }, [contextApp])

  const onCDLChangeOverride = useCallback((cdlInstance) => {
    const { cdlInstance: currentCdl } = stateRef.current
    if (currentCdl?.tenant_id !== cdlInstance?.tenant_id) {
      if (!hasFirewallBundle) {
        setSelectedFirewallDevices({}) //reset firewall device selection
      }
      setNewPanoramaSn(null) //reset IoT panorama selection
    }
    onCDLChange(cdlInstance)
  }, [stateRef, onCDLChange, setSelectedFirewallDevices, setNewPanoramaSn, hasFirewallBundle])

  const isAddonActivation = hasAppActivation(toBeActivatedApp, ZINGBOX_3P_ADDON)

  const isDisabledFn = useCallback((t) => {
    return isAddonActivation ? t.has_addon : false
  }, [isAddonActivation])

  const extraTenantInfoRenderFn = useCallback((t) => {
    return isAddonActivation ? t.has_addon ? '(add-on activated)' : '' : ''
  }, [isAddonActivation])

  const handleIoTSelection = useCallback((value) => {
    if (!hasFirewallBundle) {
      setSelectedFirewallDevices({}) //reset firewall device selection
    }
    chooseHowToManage(value)
    setNewPanoramaSn(null) //reset IoT panorama selection
    setClcsTenant(null) //reset clcsTenant
    setTimeout(() => validateFields([SUBDOMAIN_FIELD, CDL_INSTANCE_FIELD], { force: true }), 100)
  }, [setSelectedFirewallDevices, chooseHowToManage, setNewPanoramaSn, setClcsTenant, validateFields, hasFirewallBundle])

  //required for raptor device activations
  const fieldDecorator = useMemoFieldDecorator('firewalls', {
    initialValue: selectedFirewallDevices,
  }, form)

  return (
    <div className={'iot-security setup-card-body iot-security'}>
      <IoTSecurityTenant {...props}
        initialValue={zingbox}
        isDisabledFn={isDisabledFn}
        extraTenantInfoRenderFn={extraTenantInfoRenderFn}
        selectProps={{ style: { width: '70%', marginLeft: 10 }, onSelect: handleIoTSelection }} />
      <SetupSubdomainField
        {...props}
        app={contextApp}
        tenant_selected_value={zingbox}
        subdomainProps={{
          required: false,
          className: 'setup-item',
          labelCol: { xs: { span: 5 } },
          wrapperCol: { xs: { span: 17, offset: 2 } },
          key: 'subdomain',
          domainPostfix: appInfoMap.domainPostfix,
          selectedAccount: setupSelectedAccount,
          appInfoMap: appInfoMap,
          label: 'App Subdomain'
        }}
      />
      {
        (hasOnlyIoTPackageTwoActivation(toBeActivatedApp)) || hasOnlyIoTThirdPartyActivation(toBeActivatedApp) ?
          <IoTIngestionRegion {...props} /> :
          <DataLake
            {...props}
            onCDLChange={onCDLChangeOverride}
            dropDownProps={{ style: { width: '70%' }, placeholder: 'Select DataLake' }}
            dataLakeLabel={'Data Lake'}
            dataLakeRegionLabel={'Data Lake Region'}
            useDataLakeRegion={true}
            showDataLake={false}
          />
      }
      <IoTAppRegion {...props} />
      {hasAppActivation(toBeActivatedApp, ZINGBOX_NGFW_DRDL) && <IoTPanorama {...props} />}
      {hasFirewallBundle && fieldDecorator(<div />)}
    </div>
  )
}

IoTSecuritySetup.propTypes = {
  form: PropTypes.object,
  stateRef: PropTypes.any,
  contextApp: PropTypes.object,
  formValues: PropTypes.object,
  cdlInstance: PropTypes.object,
  setupSelectedAccount: PropTypes.number,
  user_email: PropTypes.string,
  currentStepObj: PropTypes.object,
  getAnyApp: () => undefined,
  chooseHowToManage: () => undefined,
  onCDLChange: PropTypes.func,
  setSelectedFirewallDevices: PropTypes.func,
  toBeActivatedApp: PropTypes.array,
  setNewPanoramaSn: PropTypes.func.isRequired,
  setClcsTenant: PropTypes.func.isRequired,
  session: PropTypes.object,
  selectedFirewallDevices: PropTypes.array,
  entitledAppsList: PropTypes.array,
}

export default IoTSecuritySetup
