
import React, { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Select } from '@pan/cloud-base'
import {
  convertObjToString,
  FT,
  renderInstances,
  useMemoFieldDecorator,
} from '../../../utils/activateUtils'
import {
  DIRECTORY_SYNC_FIELD,
  SETUP_CREATE_NEW,
  DSS_ID,
  CIE,
  STRATA_INSIGHTS,
} from '../../../constants/AppConstants'
import ListItem from '../../../components/ListItem'

const DIRECTORY_SYNC_NEW = `${DSS_ID}:${SETUP_CREATE_NEW}`

const renderOptions = tenants => tenants.map(({
  key,
  group,
  display,
  extra,
  value,
  tenant_id,
  primaryAppId,
  ...props
}) => {
  return <Select.Option {...props} key={key} value={`${DSS_ID}:${value}`}>
    <ListItem toolTipProps={{ placement: 'right' }}>{display} {extra}</ListItem>
  </Select.Option>
})

const renderOptionGroups = optionsGroups => optionsGroups.map(([region, tenants]) => {
  return !region ? renderOptions(tenants) : <Select.OptGroup key={region} label={region}>
    {renderOptions(tenants)}
  </Select.OptGroup>
})

const DirectorySync = ({
  getAnyApp,
  form,
  selectProps,
  fieldName,
  formValues,
  selectedPrimaryAppValue,
  region,
  getAppInstances,
  primaryAppId,
  cdlInstance
}) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form
  const [existingDssTenant, setExistingDssTenant] = useState(false)
  const isAIopsMultiRegion = FT('AIOPS_MULTI_REGION') && primaryAppId === STRATA_INSIGHTS
  const cdlRegion = cdlInstance?.region

  const resetFields = useCallback(() => {
    setFieldsValue({ [fieldName]: undefined })
    if (isAIopsMultiRegion) {
      setExistingDssTenant(!cdlRegion)
    }
    else {
      setExistingDssTenant(false)
    }
  }, [fieldName, setFieldsValue, isAIopsMultiRegion, cdlRegion])

  const dssInitialValue = useMemo(() => {
    const primaryAppTenantId = selectedPrimaryAppValue?.split(/[:|]/, 2)?.[1]
    const existingPrimaryTenant = primaryAppTenantId && primaryAppTenantId !== SETUP_CREATE_NEW
    if (existingPrimaryTenant) {
      const dssTenant = getAppInstances(primaryAppId)?.find(tenant =>
        tenant?.tenant_id === primaryAppTenantId
      )?.associations?.[DSS_ID]

      if (dssTenant) {
        setExistingDssTenant(true)
        const value = `${DSS_ID}:${convertObjToString(dssTenant)}`
        setFieldsValue({ [fieldName]: value })
        return value
      }
      else { //reset
        resetFields()
      }
    }
    else {
      resetFields()
    }
  }, [fieldName, getAppInstances, primaryAppId, resetFields, selectedPrimaryAppValue, setFieldsValue])

  const directorySync = getFieldValue(fieldName) || formValues[fieldName] || dssInitialValue

  const handleDssSelectHandler = useCallback((value) => {
    setFieldsValue({ [fieldName]: value })
  }, [fieldName, setFieldsValue])


  const renderedAiopsInstances = useMemo(() => {
    let aiopsRegionMatching = false
    if (isAIopsMultiRegion) {
      aiopsRegionMatching = getAnyApp(STRATA_INSIGHTS)?.associations?.find(each => each.app_id === DSS_ID)?.region_matching || false
    }

    const rendered = renderInstances({
      config: {
        app_id: DSS_ID,
        /**
        * Make region_matching true when region is true
        */
        region_matching: Boolean(region) || aiopsRegionMatching,
      },
      appInfoMap: { // no need the full version from activate utils
        getAnyApp,
        getInstances: appId => getAnyApp(appId)?.tenants || [],
      },
      renderOptions,
      renderOptionGroups,
      instanceRegionFilter: (instance) => {
        /**
        * If region dont pass by default then just show all tenant.
        */
        if (isAIopsMultiRegion && cdlRegion) {
          return Boolean(dssInitialValue) || instance.region === cdlRegion
        }

        if (Boolean(region)) {
          return instance.region === region
        }
        return true
      },
    })
    /**
    * List of app_id allowed to make sure that we default set DSS tenant to new as default option
    */
    const newOption = <Select.Option
      key={DIRECTORY_SYNC_NEW}
      value={DIRECTORY_SYNC_NEW}
    >Activate New</Select.Option>
    rendered.unshift(newOption)

    return rendered
  }, [dssInitialValue, getAnyApp, region, cdlRegion, isAIopsMultiRegion])

  const getAiopsDecorator = useMemoFieldDecorator(fieldName, {
    initialValue: directorySync,
    rules: [{
      validator(rule, value, callback) {
        callback()
      }
    }]
  }, getFieldDecorator)

  return (
    <div className={'hbox middle space-between setup-item'}>
      <label>{CIE}</label>
      {getAiopsDecorator(
        <Select
          {...selectProps}
          placeholder='Select Cloud Identity Engine'
          disabled={existingDssTenant}
          onSelect={handleDssSelectHandler}
        >
          {renderedAiopsInstances}
        </Select>,
      )}
    </div>

  )
}
/**
*
* @type {{fieldName, getAnyApp, setSelectedFirewallDevices, form, selectProps, region, selectedPrimaryAppValue}}
*/
DirectorySync.propTypes = {
  getAnyApp: PropTypes.func,
  setSelectedFirewallDevices: PropTypes.func,
  form: PropTypes.object,
  selectProps: PropTypes.object,
  fieldName: PropTypes.string.isRequired,
  /**
  * @initialValue set initial value tenantid|serial_number|region|authcode
  */
  selectedPrimaryAppValue: PropTypes.string,
  formValues: PropTypes.object,
  /**
  * @region Props passed from the parent to determine region of the AIOps tenant that needs to be filtered upon
  */
  region: PropTypes.string,
  getAppInstances: PropTypes.func,
  primaryAppId: PropTypes.string,
  cdlInstance: PropTypes.object
}

DirectorySync.defaultProps = {
  fieldName: DIRECTORY_SYNC_FIELD,
  selectProps: {
    style: {
      width: '70%'
    },
  }
}

export default DirectorySync
