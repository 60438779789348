import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Icon } from '@pan/cloud-base'
import FirewallModelTable from './FirewallModelTable'
import FirewallDeviceTable from './FirewallDeviceTable'
import './firewallDetails.scss'
import {
  SEARCH_PLACE_HOLDER,
  EVAL,
  TRIAL,
  DEFAULT_NGFW_VERSION,
  IOT_PACKAGE_1,
  IOT_PACKAGE_2,
  ZINGBOX_NGFW,
  ZINGBOX_NGFW_DRDL,
  LICENSE_DEVICE_MATRIX,
  MIXED,
  DEVICE_LICENSE_MATRIX,
  DEVICE_LICENSE_CONVERSION_INITIAL_MATRIX,
  DEVICE_LICENSE_CONVERSION_UPGRADE_MATRIX,
} from '../../../../constants/AppConstants'
import InputSearchBar from '../../../../components/common/InputSearchBar'
import { firewallDeviceSearch, hasAppActivation, firewallModelMatch } from '../../../../utils/activateUtils'
import LicenseTypeCounter from './LicenseTypeCounter'

const searchIconStyle = {
  color: '#A3A3A3',
  fontWeight: 900,
  fontSize: '16px',
  padding: '4px'
}
const emptyArray = []

const constructModelTableData = ({ licenses, fwDevices, isAgnosticLicense, value }) => {
  const result = []
  const currentSerialNumbers = value.map(each => each.serial_number)
  const deviceGroupBy = _.groupBy(fwDevices, 'model')
  Object.keys(deviceGroupBy).forEach((model) => {
    const obj = deviceGroupBy[model]
    let currentSelection = 0

    obj.forEach(each => {
      if (currentSerialNumbers.includes(each.serial_number)) {
        currentSelection += 1
      }
    })

    if (isAgnosticLicense) {
      result.push({
        model,
        licenses: {
          selected: currentSelection,
        }
      })
    }
    else {
      const unusedLicenses = licenses.filter(
        license => firewallModelMatch(model, license?.part_number || license?.sku) &&
          !license?.activated //TODO remove license?.part_number since replaced with SKU
      )

      result.push({
        model,
        licenses: {
          selected: currentSelection,
          unused: unusedLicenses,
        }
      })
    }
  })
  return result
}

const getDevices = (deviceData) => {
  const clonedDeviceData = _.cloneDeep(deviceData)
  return clonedDeviceData.map(device => {
    // includes: PAN-PA-XX-EMP || PAN-PA-VM-XX-E30,  excludes: PAN-PA-200E
    const trimModel = device?.model?.match(/^(PA-\d+-)|(PA-VM-\d+-)|(PAN-PRA-\d+-)/g)
    if (trimModel) {
      const str = trimModel[0]
      device.model = str.replace(/-$/, '') //remove trailing hyphen
    }
    return device
  })
}

const filterLicenses = (licenseData, toBeActivatedApp) => {

  let licenses = licenseData?.licenses.filter(license => license.license_term >= 0) || []

  if (!hasAppActivation(toBeActivatedApp, ZINGBOX_NGFW)) { //not checked filter out ngfw
    licenses = licenses.filter(license => license.app_id !== ZINGBOX_NGFW)
  }
  if (!hasAppActivation(toBeActivatedApp, ZINGBOX_NGFW_DRDL)) { //not checked filter out drdl
    licenses = licenses.filter(license => license.app_id !== ZINGBOX_NGFW_DRDL)
  }

  return licenses
}


// please do not set default value, antd will throw warning
const FirewallDetails = React.forwardRef(({
  licenseData,
  deviceData,
  entitlementTenants,
  value = emptyArray,
  onChange,
  setSelectedFirewallDevices,
  toBeActivatedApp,
  context,
  cdlList,
  currentStepObj,
}, ref) => {
  const licenses = useMemo(() => filterLicenses(licenseData, toBeActivatedApp), [licenseData, toBeActivatedApp]) // de-license can have license_term in negative (already expired licenses)
  const fwDevices = useMemo(() => getDevices(deviceData), [deviceData])
  const isEvalLicense = useMemo(() => licenses.some(license => license?.asset_type?.toLowerCase() === EVAL), [licenses])
  const isTrialLicense = useMemo(() => licenses.some(license => license?.asset_type?.toLowerCase() === TRIAL), [licenses])
  const isAgnosticLicense = isEvalLicense || isTrialLicense
  const modelTableData = constructModelTableData({ licenses, fwDevices, isAgnosticLicense, value })

  const [licenseList, setLicenseList] = useState(modelTableData)
  const [deviceList, setDeviceList] = useState([])
  const [selectedRow, setSelectedRow] = useState(null)
  const [licenseGroup, setLicenseGroup] = useState({})
  const [searchText, setSearchText] = useState(null)
  const [selectedDevices, setSelectedDevices] = useState(value)

  const licenseDeviceMatrix = LICENSE_DEVICE_MATRIX[context]
  const deviceLicenseMatrix = DEVICE_LICENSE_MATRIX[context]
  const deviceLicenseConversionCurrentMatrix = DEVICE_LICENSE_CONVERSION_INITIAL_MATRIX[context]
  const deviceLicenseConversionNewMatrix = DEVICE_LICENSE_CONVERSION_UPGRADE_MATRIX[context]

  //Refer APPORTAL-3368 for license upgrade compatible matrix
  const isUpgradeAllowed = useCallback((device) => {

    if (value.find(each => each.serial_number === device.serial_number)) { // device already in selected list indicates upgradable
      return true
    }
    const unusedLicenses = selectedRow?.licenses?.unused
    const availableLicenses = Object.keys(_.groupBy(unusedLicenses, 'asset_type'))
    const currentLicenses = deviceLicenseConversionCurrentMatrix[device.asset_type]
    const newLicenses = deviceLicenseConversionNewMatrix[device.asset_type]

    if (currentLicenses?.includes(device?.license_asset_type) && newLicenses?.some(nl => availableLicenses?.some(al => al === nl))) {
      return true
    }
  }, [selectedRow, value, deviceLicenseConversionCurrentMatrix, deviceLicenseConversionNewMatrix])


  const isDeviceSelectable = useCallback((device, selectedModelRow) => {
    const { asset_type: deviceType } = device
    let licenseContext

    if (isAgnosticLicense) {
      if (isEvalLicense) {
        licenseContext = EVAL
      }
      else if (isTrialLicense) {
        licenseContext = TRIAL
      }
      return licenseDeviceMatrix[licenseContext]?.includes(deviceType)
    }
    else {
      const unusedLicenses = selectedModelRow?.licenses?.unused
      const unusedLicenseCount = unusedLicenses?.length

      if (!unusedLicenseCount) { // no unused licenses remaining - early return
        return false
      }

      const findLicenseContext = () => {
        const groupByLicenseType = _.groupBy(unusedLicenses, 'asset_type')
        for (const each of Object.keys(groupByLicenseType)) {
          // license type number of licenses matches with total unused licenses implies all are same type of licenses
          if (groupByLicenseType[each]?.length === unusedLicenseCount) {
            return each
          }
        }
        return MIXED
      }

      const licenseContext = findLicenseContext()
      if (licenseContext === MIXED) { // mixed  refers licenses (prod,lab,nfr)
        return deviceLicenseMatrix[deviceType].some(
          each => unusedLicenses.some(unusedLic => unusedLic?.asset_type?.toLowerCase() === each)
        )
      }
      else {
        return licenseDeviceMatrix[licenseContext]?.includes(deviceType)
      }
    }
  }, [isAgnosticLicense, isEvalLicense, isTrialLicense, licenseDeviceMatrix, deviceLicenseMatrix])

  const updateDeviceList = useCallback((filterDeviceList, selectedModelRow) => {
    const filterDevicesClone = _.cloneDeep(filterDeviceList)
    filterDevicesClone.forEach(device => {
      const tenant = entitlementTenants.find(tenant => tenant.serial_number === device.serial_number)
      const { license_asset_type, license_term, license_unit, license_version } = value.find(each => each.serial_number === device.serial_number) || {}
      device.license_asset_type = license_asset_type || tenant?.license_type?.toLowerCase()
      device.license_term = license_term || tenant?.license_term
      device.license_unit = license_unit || tenant?.license_unit
      // currently only for IoT ngfw flow license_version is required
      // IOT-ENT is treated as IoT 2.0 pkg
      device.license_version = license_version || (tenant?.app_id === ZINGBOX_NGFW ? (tenant?.skus?.[0]?.match('-IOT-DRDL' || '-IOT-ENT') ? IOT_PACKAGE_2 : IOT_PACKAGE_1) : DEFAULT_NGFW_VERSION)
      device.isDisable = tenant?.license_type ? !(isUpgradeAllowed(device)) : !isDeviceSelectable(device, selectedModelRow)
    })
    return _.sortBy(filterDevicesClone, 'isDisable') //move disabled devices to end
  }, [entitlementTenants, value, isDeviceSelectable, isUpgradeAllowed])

  const handleInputSearch = useCallback((searchString) => {
    const filterDeviceList = fwDevices.filter(device => device.model === selectedRow.model)
    let devices = updateDeviceList(filterDeviceList, selectedRow)
    if (searchString) {
      devices = firewallDeviceSearch(searchString, devices)
    }
    setSearchText(searchString)
    setDeviceList(devices)
  }, [selectedRow, fwDevices, updateDeviceList])

  const authCodeUsedCount = useCallback((firewalls) => {
    const filterModelSerialNumbers = fwDevices.reduce((serialNumbers, currentValue) => {
      if (currentValue.model === selectedRow?.model) {
        serialNumbers.push(currentValue.serial_number)
      }
      return serialNumbers
    }, [])
    return _.intersection(firewalls, filterModelSerialNumbers).length
  }, [fwDevices, selectedRow])


  const updateLicenseList = (devices) => {
    const updateList = licenseList.map((each) => {
      if (each.model === selectedRow?.model) {
        each.licenses = {
          ...each.licenses,
          selected: authCodeUsedCount(devices.map(each => each.serial_number))
        }
      }
      return each
    })
    setLicenseList(updateList)
  }


  const handleUnselect = (serialNumber) => {
    const devices = selectedDevices.filter(device => device.serial_number !== serialNumber)
    onChange(devices)
    setSelectedDevices(devices)
    setSelectedFirewallDevices({ devices, licenses })
    updateLicenseList(devices)
  }

  const handleSelectOption = (serialNumber, licenseVersion, licenseAssetType, licenseTerm) => {
    const parsedLicenseTerm = parseInt(licenseTerm, 10)
    let deviceLicense = {}
    let license
    const devices = selectedDevices
    const { model } = fwDevices.find(x => x.serial_number === serialNumber)
    const tenant = entitlementTenants.find(tenant => tenant.serial_number === serialNumber)

    const findLicense = (list) => {
      return list.find(each => !devices.map(x => x.auth_code).includes(each.auth_code)) || {}
    }

    if (isAgnosticLicense) { // for eval and trial license types
      const list = licenses.filter(license => !license.activated && license.license_version === licenseVersion && license.asset_type === licenseAssetType && license.license_term === parsedLicenseTerm)
      if (list.length) {
        license = findLicense(list)
      }
    }
    else { //NON-EVAL auth_code_used cell update
      const licenseObj = licenseList.find(license => license.model === model)
      const list = licenseObj.licenses.unused.filter(each => each.license_version === licenseVersion && each.asset_type === licenseAssetType && each.license_term === parsedLicenseTerm)
      if (list.length) {
        license = findLicense(list)
      }
    }

    /* if authCode is undefined implies user has selected more devices w.r.t auth_code licenses and triggers form error*/
    deviceLicense = {
      model,
      serial_number: serialNumber,
      license_asset_type: licenseAssetType,
      tenant_id: tenant?.tenant_id, // required for license conversion
      license_term: parsedLicenseTerm,
      auth_code: license.auth_code,
      license_unit: license.license_unit,
      license_version: license.license_version,
      app_id: license.app_id,
    }

    const index = devices.findIndex((device) => device.serial_number === serialNumber)

    if (index === -1) {
      devices.push(deviceLicense)
    }
    else {
      devices[index] = deviceLicense
    }


    setSelectedDevices(devices)
    setSelectedFirewallDevices({ devices, licenses })
    updateLicenseList(devices)
    onChange(devices)
  }

  const handleModelSelected = useCallback((rowInfo) => {
    setSelectedRow(rowInfo)
    const groupByVersion = _.groupBy(rowInfo.licenses.unused, 'license_version')
    setLicenseGroup(groupByVersion)

    const filterDeviceList = fwDevices.filter(device => device.model === rowInfo.model)
    let devices = updateDeviceList(filterDeviceList, rowInfo)
    if (searchText) {
      devices = firewallDeviceSearch(searchText, devices)
    }
    setDeviceList(devices)
  }, [fwDevices, updateDeviceList, searchText])

  const authCodesList = useMemo(() => licenses.reduce((result, license) => {
    if (!license.activated) {
      result.push(license)
    }
    return result
  }, []), [licenses])


  const renderLicenseGroupCount = useCallback(() => {

    const licenses = isAgnosticLicense ? _.groupBy(authCodesList, 'license_version') : licenseGroup
    const currentRowModel = !isAgnosticLicense ? selectedRow?.model : null
    return <LicenseTypeCounter currentRowModel={currentRowModel} licenses={licenses} formValue={value} isAgnosticLicense={isAgnosticLicense} />
  }, [isAgnosticLicense, authCodesList, licenseGroup, value, selectedRow])

  return (
    <>
      <div className='hbox fw-wrapper' ref={ref}>
        <div className='fw-models'>
          <FirewallModelTable
            data={licenseList}
            handleModelSelected={handleModelSelected}
            isAgnosticLicense={isAgnosticLicense}
            selectedDevices={selectedDevices}
            totalAvailableAuthCodes={authCodesList.length}
          />
        </div>
        <div className='fw-devices'>
          <label className='fw-details-header'>Firewall Details</label>
          {renderLicenseGroupCount()}
          <div className='search-wrapper'>
            <InputSearchBar
              className={'fw-devices-search'}
              handleInputSearch={handleInputSearch}
              placeholder={SEARCH_PLACE_HOLDER}
              handleSuffixIcon={() => <Icon type='search' style={searchIconStyle} />}
            />
          </div>
          <FirewallDeviceTable
            cdlList={cdlList}
            data={deviceList}
            handleSelectOption={handleSelectOption}
            handleUnselect={handleUnselect}
            selectedDevices={selectedDevices}
            selectedRow={selectedRow}
            isAgnosticLicense={isAgnosticLicense}
            licenses={licenses}
            context={context}
            currentStepObj={currentStepObj}
          />
        </div>
      </div>
    </>
  )
})

FirewallDetails.displayName = 'FirewallDetails'

FirewallDetails.propTypes = {
  licenseData: PropTypes.object.isRequired,
  deviceData: PropTypes.array.isRequired,
  handleSelectedDevices: PropTypes.func,
  entitlementTenants: PropTypes.array.isRequired,
  value: PropTypes.array,
  onChange: PropTypes.func,
  selectedFirewallDevices: PropTypes.array.isRequired,
  setSelectedFirewallDevices: PropTypes.func,
  toBeActivatedApp: PropTypes.array.isRequired,
  context: PropTypes.string.isRequired,
  cdlList: PropTypes.array,
  currentStepObj: PropTypes.object,
}

export default FirewallDetails
