/**
 * Created by vsuthar on 4/28/20
 * Project: App Portal ©Palo Alto Networks
 */
import React from 'react'
const SetupBorder = (props) => {
  return <hr style={{ margin: '5px 0' }} {...props}/>
}

export default SetupBorder
